import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { getToken } from "../../utils/Authentication";
// import { downloadInvoicepreview } from "./../../dealer/actions/dealerAction";
import { useEffect, useState } from "react";
import { SpinnerRoundFilled } from "spinners-react";

export default function PreviewPdfmodel(props) {
  const { open, handleClose, invoiceid, url, title = "Invoice Preview" } = props;
  //   const [pdfData, setPdfData] = useState("");
  const [Error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [base64STR, setBase64STR] = useState('');

  useEffect(() => {
    // const imageUrlToBase64 = async (url) => {
    //     const config = { responseType: 'blob','Access-Control-Allow-Origin': '*','Authorization': `Bearer ${getToken() || ''}` };
    //     const data = await axios.get(url, config);
    //     console.log('imageee',data)
    //     const blob = new Blob([data.data],{type:'application/pdf'})
    //     const pdfblob=URL.createObjectURL(blob)
    //     setBase64STR(pdfblob);
    //   }
    const imageUrlToBase64 = async (url) => {
      const headers = {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      };

      let config = {
        url: url,
        headers: headers,
        method: "get",
      };
      config.responseType = 'blob'

      const response = await axios.request(config)
      const urlNew = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      setBase64STR(urlNew)
    }
    imageUrlToBase64(url)

  }, [url]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{
          lg: { height: "96%" },
          sx: { height: "96%" },
          md: { height: "96%" },
        }}
      >
        <DialogTitle>
          {title || "Invoice Preview"}
          <IconButton onClick={handleClose} style={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: "90%" }}>
          <>
            {url ? (
              base64STR ?
                <iframe
                  //   title="PDF Viewer"
                  type="application/pdf"
                  // src={base64STR}
                  src={`${base64STR}#toolbar=0`}
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                /> : null

              // base64STR ? (<embed src={`data:application/pdf;base64,${base64STR}`} type="application/pdf" />):null
              // base64STR ? (<embed src={base64STR} type="application/pdf"style={{
              //     position: "relative",
              //     width: "100%",
              //     height: "100%",
              //   }}  />):null

            ) : (
              <div style={{ marginTop: "20px" }}>
                <Typography
                  variant="h4"
                  sx={{ display: "flex", justifyContent: "center" }}
                  className="error-o"
                >
                  {Error}
                </Typography>
              </div>
            )}
          </>

        </DialogContent>
      </Dialog>
    </div>
  );
}
