/* eslint-disable no-script-url */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validator from "validator";
import HelpIcon from "@mui/icons-material/HelpOutline";
import {
  Container,
  Grid,
  TextField,
  FormHelperText,
  FormControlLabel,
  FormControl,
  Checkbox,
  InputLabel,
  Slider,
  Box,
  Typography,
  InputAdornment,
  Button,
  Input,
  FormLabel,
  MenuItem,
  Select,
  Divider,
} from "@material-ui/core";
import { Popover as AntdPopover, Input as AntdInput } from "antd";
import Tooltip from "@mui/material/Tooltip";
import {
  addInventoryData,
  individualBrowseWatch,
  searchProducts,
  searchLocation,
} from "../actions/dealerAction";
import searchinventry from "../../dashboard/actions/dashboardaapi";
import AddIcon from "../../../../assests/images/add.png";
import _ from "lodash";
import { SpinnerRoundFilled } from "spinners-react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import LimitReachedComponent from "../../subscription/LimitReachedComponent";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getComponentActionList,
  isActionAllowed,
  getComponentViewsList,
} from "../../../../common/components/Helper/Utils";
import AccessRestricted from "../../common/components/accessRestricted";
import FileUploadGroup from "../../../../common/components/FileuploadGroup";
import { getBusinessAccountIds } from "../../dashboard/actions/dashboardAction";

const followersMarks1 = [
  {
    value: 1,
    scaledValue: 1,
    label: "Pre-Owned",
  },
  {
    value: 2,
    scaledValue: 2,
    label: "Mint",
  },
  {
    value: 3,
    scaledValue: 3,
    label: "Retail Ready",
  },
  {
    value: 4,
    scaledValue: 4,
    label: "New",
  },
];
const scale = (value) => {
  return (value * 25.4).toFixed(2) + " mm";
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
class DealerProductInventoryIntakeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: sessionStorage.getItem("userDetails")
        ? JSON.parse(sessionStorage.getItem("userDetails"))
        : null,
      accountId: window.sessionStorage.getItem("accountId"),
      accountIds: [],
      open: false,
      setOpen: false,
      ModelNumber: "",
      //selectedDate : Date('03/29/2021'),
      PurchasedDate: "",
      PurchasedDate1: "",
      archiveDate: "",
      cursor: 0,
      PurchasedFrom: "",
      purchasedFromArray: [],
      purchaseFromContactId: null,
      dataset: [],
      isPopOverPurchasedFrom: false,
      isPopOverMemoDetails: false,
      isPopOverPartnershipDetails: false,
      PurchasedPrice: "",
      SerialNumber: "",
      InventoryType: "",
      MemoDetails: "",
      memoDetailsArray: [],
      memoContactId: null,
      PartnershipDetails: "",
      partnershipDetailsArray: [],
      partnerContactId: null,
      Condition: "1",
      BandSize: "",
      NumberOfLinks: "",
      DealerNotes: "",
      WholeSalePrice: "",
      RetailPrice: "",
      RetailWirePrice: "",
      DateFnsUtils: "",
      value: 0,
      disbleseach: false,
      value1: 0,
      IndividualBrowseWatchesData: "",
      onModelNumberErrorText: "",
      onPurchasedFromErrorText: "",
      onPurchasedDateErrorText: "",
      onPurchasedPriceErrorText: "",
      onSerialNumberErrorText: "",
      onConditionErrorText: "",
      modificationDetailsError: "",
      onCoditionTenSuccessText: "",
      onBandSizeErrorText: "",
      onDealerNotesErrorText: "",
      onNumberOfLinksErrorText: "",
      onWholeSalePriceErrorText: "",
      onRetailPriceErrorText: "",
      onRetailWirePriceErrorText: "",
      onTagPriceErrorText: "",
      onPurchaseExpenseErrorText: "",
      onPurchaseExpenseNoteErrorText: "",
      onPublicDealerNoteErrorText: "",
      onInventoryStatusErrorText: "",
      onpaymentDateErrorText: "",
      individualsearchdata: false,
      bulkUploadFile: [],
      autocomplete: {
        currentInput: "",
        loading: false,
        data: [],
      },
      selectedProduct: { productId: "" },
      selected: { product: null },
      loaded: false,
      typeOfPaper: null,
      fileArray: [],
      limitReachedModalOpen: false,
      inventoryItem: "",
      editableItem: "false",
      currentUser: "",
      inventory_title: "",
      title_without_edit: "",
      inventory_status: "",
      public_dealer_note: "",
      updatememo: "",
      updatepartnershipnumber: "",
      total_cost: "",
      tag_price: "",
      ismodified: false,
      modification_deatils: "",
      purchase_expense: "",
      purchase_expense_note: "",
      inventoryitemfocused: "",
      locationAutoCompleteData: {
        data: [],
      },
      locationSearch: "",
      locationloading: false,
      warentypaperset: "",
      archivePaperSet: "",
      warrentyExpiryDate: false,
      accessData: [],
      inventryPricelist: [],
      ebayAmout: "",
      croono21Ammount: "",
      retailAmount: "",
      is_inventory_title_changed: false,
      viewData: [],
      paidStatus: true,
      dealerInv: true,
      notforSale: false,
      onPaymentDetailsErrorText: "",
      PaymentDetailsText: "",
      paymentDate: null,
      purchaseDueDate: null,
      isService: false,
      serviceDate: "",
      error: {},
      fieldLoader: false,
    };
    this.onchangeModelNumber = this.onchangeModelNumber.bind(this);
    this.handlePaidStatusCheck = this.handlePaidStatusCheck.bind(this);
    this.handleNotforSaleCheck = this.handleNotforSaleCheck.bind(this);
    this.handlePayDateChange = this.handlePayDateChange.bind(this);
    this.handleDueDateChange = this.handleDueDateChange.bind(this);
    this.onchangePaymentDetails = this.onchangePaymentDetails.bind(this);
    this.handleSelectedFiles = this.handleSelectedFiles.bind(this);
    this.onchangeLocation = this.onchangeLocation.bind(this);
    this.getpriceListing = this.getpriceListing.bind(this);
    this.onchangePurchasedDate = this.onchangePurchasedDate.bind(this);
    this.onchangePurchasedPrice = this.onchangePurchasedPrice.bind(this);
    this.onchangeSerialNumber = this.onchangeSerialNumber.bind(this);
    this.onchangeCondition = this.onchangeCondition.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onchangeBandSize = this.onchangeBandSize.bind(this);
    this.onchangeNumberOfLinks = this.onchangeNumberOfLinks.bind(this);
    this.onchangeDealerNotes = this.onchangeDealerNotes.bind(this);
    this.onchangeWholeSalePrice = this.onchangeWholeSalePrice.bind(this);
    this.onchangeRetailPrice = this.onchangeRetailPrice.bind(this);
    this.onchangeRetailWirePrice = this.onchangeRetailWirePrice.bind(this);
    this.autocomplete = this.autocomplete.bind(this);
    this.locationAutoComplete = this.locationAutoComplete.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.editinvnetrydeatils = this.editinvnetrydeatils.bind(this);
    this.handleCheck1 = this.handleCheck1.bind(this);
    this.handleInclude = this.handleInclude.bind(this);
    // this.handleCheck = this.handleCheck.bind(this)
    this.submitForm = this.submitForm.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.valuetext = this.valuetext.bind(this);
    this.getAutocomplete = _.debounce(this.getAutocomplete, 500);
    this.getLocationAutocomplete = _.debounce(
      this.getLocationAutocomplete,
      500
    );
    this.checloaader = this.checloaader.bind(this);
    this.onchangeTagPrice = this.onchangeTagPrice.bind(this);
    this.onchangePurchaseExpense = this.onchangePurchaseExpense.bind(this);
    this.onchangePurchaseExpenseNote =
      this.onchangePurchaseExpenseNote.bind(this);
    this.onchangeModificationDetails =
      this.onchangeModificationDetails.bind(this);
    this.onchangeTotalCost = this.onchangeTotalCost.bind(this);
    this.onchangePublicDealerNote = this.onchangePublicDealerNote.bind(this);
    this.onchangeInventoryStatus = this.onchangeInventoryStatus.bind(this);
    this.onchangeInventoryTitle = this.onchangeInventoryTitle.bind(this);
    this.handleCheck2 = this.handleCheck2.bind(this);
    this.backNavigation = this.backNavigation.bind(this);
    this.debouncedGetContactDetails = _.debounce(this.getContactDetails, 1000);
  }

  componentWillMount() {
    const accessData = getComponentActionList("add_inventory");
    const viewData = getComponentViewsList("add_inventory");
    this.setState({ accessData: accessData, viewData: viewData });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 900);
    let PId = null;
    try {
      PId = this.props.match.params.productId;
      if (PId !== undefined) {
        // this.state.selectedProduct.productId = PId;
        // this.state.ModelNumber = PId
        this.setState({
          ...this.state,
          searchString: this.state.searchString
            ? this.state.searchString
            : this.props.individualBrowseWatchesInfo.data &&
            this.props.individualBrowseWatchesInfo.data.prodattr &&
            (this.props.individualBrowseWatchesInfo.data.prodattr.model
              ? this.props.individualBrowseWatchesInfo.data.prodattr.model
              : this.props.individualBrowseWatchesInfo.data.prodattr
                .reference),
          inventory_title: this.state.searchString
            ? this.state.searchString
            : this.props.individualBrowseWatchesInfo.data &&
            this.props.individualBrowseWatchesInfo.data.prodattr &&
            this.props.individualBrowseWatchesInfo.data.prodattr.model,
          title_without_edit: this.state.searchString
            ? this.state.searchString
            : this.props.individualBrowseWatchesInfo.data &&
            this.props.individualBrowseWatchesInfo.data.prodattr &&
            this.props.individualBrowseWatchesInfo.data.prodattr.model,
          ModelNumber: this.state.searchString
            ? this.state.searchString
            : this.props.individualBrowseWatchesInfo.data.productname, // remove productname concept
          disbleseach: true,
        });
        this.props.individualBrowseWatch(PId);
        this.getpriceListing(PId);
      }
    } catch (error) {
      console.log(error);
    }
    this.getAccountIds();
  }

  onProductChange = _.debounce((e, v, r) => {
    // this.props.onTextInputAdd(text);
    if (v) {
      switch (e) {
        case "product_selected":
          this.setState({
            ...this.state,
            selected: { product: v },
          });
          // this.props.history.push('/browsewatches/' + v.model)
          break;
        case "input_changed":
          this.state.autocomplete.currentInput = v;

          this.state.autocomplete.loading = true;
          this.setState({
            ...this.state,
          });
          if (v.length >= 3 && r == "input") {
            this.props.searchProducts({ searchText: v }, (error, res) => { });
          }
          break;
      }
    } else {
      // console.log("dddd");
    }
  }, 600);

  onSelected() { }
  backNavigation() {
    const { history } = this.props;
    history.push("/create-inventory");
  }

  autocomplete(evt) {
    let text = evt.target.value;
    this.setState({
      searchString: text,
      ModelNumber: text,
      inventory_title: text,
    });

    this.setState({ loading: true });
    this.getAutocomplete(text);
  }

  getAutocomplete = (text) => {
    const evt = text && text.replace(/[&\/\\#,+!@^&()$~%.'":;*?<>{}]/g, "");
    this.props.searchProducts({ searchText: evt }, (error, res) => {
      this.setState({ searchItems: this.state.autocomplete.data });
      this.setState({ loading: false });
      if (this.state.autocomplete?.data?.length > 0) {
        this.setState({
          individualsearchdata: true,
        });
      } else {
        this.setState({
          individualsearchdata: false,
        });
      }
    });
  };
  hanldeKeyup(evt) {
    //if (evt.keyCode === 27) {
    //  this.setState({ searchItems: [] });
    //   return false;
    //  }
  }

  hanldeKeydown(evt) {
    // console.log(evt.target.value)
  }

  locationAutoComplete = (evt) => {
    let text = evt.target.value;
    this.setState({ locationSearch: text, locationloading: true });
    this.getLocationAutocomplete(text);
  }

  getLocationAutocomplete = (evt) => {
    // if (evt.length > 0) {
    searchLocation({ locationSearch: evt || null }, (error, res) => {
      this.setState({ locationloading: false });
      this.setState({ locationAutoCompleteData: { data: res?.data } });
    });
    // } else {
    //   this.setState({ locationloading: false });
    //   this.setState({ locationAutoCompleteData: { data: [] } });
    // }
  };

  handleLocationFocus = (event) => {
    if (!event.target.value) {
      this.locationAutoComplete(event);
    }
  };

  handleLocationBlur = () => {
    this.setState({
      locationAutoCompleteData: {
        data: [],
      }
    })
  };

  handleListKeydown(evt) {
    // console.log(evt);
  }
  handlePaidStatusCheck = (event) => {
    this.setState({
      paidStatus: event.target.checked,
    });
  };
  handleNotforSaleCheck = (event) => {
    this.setState({
      notforSale: event.target.checked,
    });
  };

  handleInclude = (event) => {
    this.setState({
      dealerInv: event.target.checked,
    });
  };

  handlePayDateChange = (date, e) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          ["PPD"]: "",
        },
        paymentDate: e,
        onpaymentDateErrorText: "",
      });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ["PPD"]: `Invalid Date`,
        },
      });
    }
  };

  handleDueDateChange = (date, e) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          ["PPDD"]: "",
        },
        purchaseDueDate: e,
      });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ["PPDD"]: `Invalid Date`,
        },
      });
    }
  };

  onchangePaymentDetails(event) {
    this.setState({
      onPaymentDetailsErrorText: "",
      PaymentDetailsText: event.target.value,
    });
  }

  handleChange(evt) {
    this.setState({ item: { [evt.target.name]: evt.target.value } });
  }
  componentWillReceiveProps(newProps) {
    this.state.autocomplete.data = newProps.autocompleteProducts.list;
    this.setState({
      ...this.state,
    });
    const individualBrowseWatchesStatus = newProps.individualBrowseWatchesInfo;
    if (individualBrowseWatchesStatus.success === 0) {
      this.setState({
        onModelNumberErrorText: individualBrowseWatchesStatus.message,
        isValid: false,
      });
    } else {
      const individualBrowseWatchesData =
        newProps.individualBrowseWatchesInfo.data;
      if (
        individualBrowseWatchesData &&
        individualBrowseWatchesData.productid
      ) {
        this.setState({
          IndividualBrowseWatchesData: individualBrowseWatchesData,
          onModelNumberErrorText: "",
          isValid: true,
        });
      }
    }
  }

  onchangeModelNumber(product, productId) {
    this.setState({
      IndividualBrowseWatchesData: product,
      disbleseach: true,
      searchString: product.modelname
        ? product?.modelname
        : product?.model
          ? product?.model
          : product?.reference,
      ModelNumber: product.productid,
      nonsearchstring: false,
      editableItem: true,
      inventoryItem: product.modelname,
      inventory_title: product.modelname,
      title_without_edit: product.modelname,
      searchStringdetails: product.modelname,
      // searchStringdetails: product.productname, // remove productname concept
      autocomplete: { data: [] },
    });
    if (product.productid == null) {
      this.setState({
        individualsearchdata: true,
        disbleseach: true,
      });
    } else {
      this.setState({
        individualsearchdata: true,
      });
    }
    this.props.individualBrowseWatch(product.productid);
    this.getpriceListing(product.productid);

    this.setState({
      onModelNumberErrorText: "",
      isValid: true,
      nonsearchstring: false,
      individualsearchdata: false,
    });
  }
  onchangeLocation(product) {
    this.setState({
      locationAutoCompleteData: { data: [] },
      locationSearch: product.locations,
      isValid: true,
    });
  }
  getpriceListing(id) {
    searchinventry.getinventrypriceList(id).then((res) => {
      this.setState({
        inventryPricelist: res.data.data,
        loaded: true,
      });
      for (let i = 0; i < this.state.inventryPricelist.length; i++) {
        if (this.state.inventryPricelist[i].watch_source == "Chrono24") {
          this.setState({
            croono21Ammount: this.state.inventryPricelist[i].avg,
          });
        } else if (this.state.inventryPricelist[i].watch_source == "Retail") {
          this.setState({ retailAmount: this.state.inventryPricelist[i].avg });
        } else if (this.state.inventryPricelist[i].watch_source == "EBay") {
          this.setState({ ebayAmout: this.state.inventryPricelist[i].avg });
        }
      }
    });
  }

  getAccountIds = () => {
    getBusinessAccountIds(this.state.accountId, (error, res) => {
      if (res.data && res.success == 1) {
        this.setState({
          accountIds: res.data,
        });
      }
    });
  };

  getContactDetails = (type, contactType, callback) => {
    let payload = {
      key: this.state[type],
      type: contactType,
      accountids: this.state.accountIds,
    };
    searchinventry.getInventoryContactDetails(payload, (error, response) => {
      if (response?.data && response?.status == 1) {
        callback(response.data, null);
      }
      if (error) {
        callback(null, error);
      }
    });
  };

  onchangeContactSuggestion = (event, type, contactType) => {
    let value = event.target.value;
    let newState = { isValid: true, fieldLoader: true };
    if (type === "PurchasedFrom") {
      newState.PurchasedFrom = value;
    } else if (type === "MemoDetails") {
      newState.MemoDetails = value;
    } else if (type === "PartnershipDetails") {
      newState.PartnershipDetails = value;
    }
    this.setState(newState, () => {
      if (value && value?.length > 2) {
        this.debouncedGetContactDetails(
          type,
          contactType,
          (data = [], error) => {
            if (data?.length > 0) {
              const filteredData = this.destructureData(data);
              this.setState(prevState => ({
                dataset: filteredData,
                purchasedFromArray: type === "PurchasedFrom" ? data : prevState.purchasedFromArray,
                memoDetailsArray: type === "MemoDetails" ? data : prevState.memoDetailsArray,
                partnershipDetailsArray: type === "PartnershipDetails" ? data : prevState.partnershipDetailsArray,
                isPopOverPurchasedFrom: type == "PurchasedFrom" ? true : false,
                isPopOverMemoDetails: type == "MemoDetails" ? true : false,
                isPopOverPartnershipDetails:
                  type == "PartnershipDetails" ? true : false,
                fieldLoader: false,
              }));
            } else {
              this.setState(prevState => ({
                purchasedFromArray: type === "PurchasedFrom" ? [] : prevState.purchasedFromArray,
                memoDetailsArray: type === "MemoDetails" ? [] : prevState.memoDetailsArray,
                partnershipDetailsArray: type === "PartnershipDetails" ? [] : prevState.partnershipDetailsArray,
                dataset: [],
                fieldLoader: false,
              }));
            }
          }
        );
      } else {
        this.setState(prevState => ({
          purchasedFromArray: type === "PurchasedFrom" ? [] : prevState.purchasedFromArray,
          memoDetailsArray: type === "MemoDetails" ? [] : prevState.memoDetailsArray,
          partnershipDetailsArray: type === "PartnershipDetails" ? [] : prevState.partnershipDetailsArray,
          dataset: [],
          fieldLoader: false,
        }));
      }
    });
  }

  destructureData = (data) => {
    const groupedByEmail = {};
    data.forEach((value) => {
      const Email = value.email;
      groupedByEmail[Email] = groupedByEmail[Email] || [];
      groupedByEmail[Email].push(value);
    });
    const resultArray = Object.values(groupedByEmail);
    return resultArray;
  };

  handleContactSelection = (data, type) => {
    const {
      isPopOverPurchasedFrom,
      isPopOverMemoDetails,
      isPopOverPartnershipDetails,
    } = this.state;
    const fieldName = type;
    const value = [
      data?.businessname,
      (data?.firstname && data?.lastname) ? `${data?.firstname} ${data?.lastname}` : (data?.firstname || data?.lastname)
    ]
      ?.filter(Boolean)
      ?.join(", ");
    const contactId = data?.contactid;
    this.setState({
      [fieldName]: value,
      isPopOverPurchasedFrom:
        fieldName === "PurchasedFrom" ? false : isPopOverPurchasedFrom,
      isPopOverMemoDetails:
        fieldName === "MemoDetails" ? false : isPopOverMemoDetails,
      isPopOverPartnershipDetails:
        fieldName === "PartnershipDetails"
          ? false
          : isPopOverPartnershipDetails,
      purchaseFromContactId: fieldName === "PurchasedFrom" ? contactId : null,
      memoContactId: fieldName === "MemoDetails" ? contactId : null,
      partnerContactId: fieldName === "PartnershipDetails" ? contactId : null,
    });
  };

  handleContact = (val) => {
    return [
      [val?.firstname, val?.lastname].filter(Boolean).join(" "),
      val?.businessname,
      val?.billingaddress1,
      val?.billingaddress2,
      val?.billingcity,
      val?.billingstate,
      val?.billingcountry,
      val?.billingzipcode
    ].filter(Boolean).join(", ");
  };

  clearContactSuggestion = (type) => {
    const { purchaseFromContactId, memoContactId, partnerContactId } = this.state;
    this.setState({
      [type]: "",
      [`${type.charAt(0).toLowerCase() + type.slice(1)}Array`]: [],
      dataset: [],
      purchaseFromContactId: type === "PurchasedFrom" ? null : purchaseFromContactId,
      memoContactId: type === "MemoDetails" ? null : memoContactId,
      partnerContactId: type === "PartnershipDetails" ? null : partnerContactId,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({
      isPopOverPurchasedFrom: visible,
      isPopOverMemoDetails: visible,
      isPopOverPartnershipDetails: visible,
    });
  };

  onchangePurchasedDate(date, event) {
    let PurchasedDateVal = event.target.value;
    if (PurchasedDateVal) {
      this.setState({
        onPurchasedDateErrorText: "Choose the correct Purchased Date",
        isValid: false,
      });
    } else {
      this.setState({
        onPurchasedDateErrorText: "",
        selectedDate: event.target.value,
        selectedDate: date,
        isValid: true,
      });
    }
  }

  onchangePurchasedPrice(event) {
    if (event.target.value !== "") {
      let PurchasedPriceVal = validator.isNumeric(event.target.value);
      if (!PurchasedPriceVal) {
        this.setState({
          onPurchasedPriceErrorText: "Choose the correct Purchased Price",
          isValid: false,
        });
      } else {
        const purchaseExpensePrice =
          this.state.purchase_expense?.length > 0
            ? Number(this.state.purchase_expense)
            : 0;
        let totalCost = Number(event.target.value);
        totalCost = totalCost + purchaseExpensePrice;
        this.setState({
          onPurchasedPriceErrorText: "",
          PurchasedPrice: event.target.value,
          total_cost: totalCost,
          isValid: true,
        });
      }
    } else {
      const purchaseExpensePrice =
        this.state.purchase_expense?.length > 0
          ? Number(this.state.purchase_expense)
          : 0;
      let totalCost = purchaseExpensePrice;

      this.setState({
        onPurchasedPriceErrorText: "",
        PurchasedPrice: event.target.value,
        total_cost: totalCost,
        isValid: true,
      });
    }
  }

  onchangeSerialNumber(event) {
    if (event.target.value !== "") {
      let SerialNumberVal = event.target.value;
      if (!SerialNumberVal) {
        this.setState({
          onSerialNumberErrorText: "Choose the correct Serial Number",
          isValid: false,
        });
      } else {
        this.setState({
          onSerialNumberErrorText: "",
          SerialNumber: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangeTagPrice(event) {
    if (event.target.value !== "") {
      let TagPriceVal = validator.isNumeric(event.target.value);
      if (!TagPriceVal) {
        this.setState({
          onTagPriceErrorText: "Choose the correct Tag Price",
          isValid: false,
        });
      } else {
        this.setState({
          onSerialNumberErrorText: "",
          tag_price: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangePurchaseExpense(event) {
    if (event.target.value !== "") {
      let PurchaseExpenseVal = validator.isNumeric(event.target.value);
      if (!PurchaseExpenseVal) {
        this.setState({
          onTagPriceText: "Enter the correct purchase expenses",
          isValid: false,
        });
      } else {
        let totalCost = Number(event.target.value);
        const purchasePriceCalc =
          this.state.PurchasedPrice?.length > 0
            ? Number(this.state.PurchasedPrice)
            : 0;
        totalCost = totalCost + purchasePriceCalc;
        this.setState({
          onPurchaseExpenseErrorText: "",
          purchase_expense: event.target.value,
          isValid: true,
          total_cost: totalCost,
        });
      }
    } else {
      const purchasePriceCalc =
        this.state.PurchasedPrice?.length > 0
          ? Number(this.state.PurchasedPrice)
          : 0;
      const totalCost = purchasePriceCalc;
      this.setState({
        onPurchaseExpenseErrorText: "",
        purchase_expense: event.target.value,
        isValid: true,
        total_cost: totalCost,
      });
    }
  }

  onchangePurchaseExpenseNote(event) {
    if (event.target.value !== "") {
      let PurchaseExpenseNoteVal = event.target.value;
      if (!PurchaseExpenseNoteVal) {
        this.setState({
          onPurchaseExpenseNoteErrorText:
            "Enter the correct Purchase Expense Notes",
          isValid: false,
        });
      } else {
        this.setState({
          onPurchaseExpenseNoteErrorText: "",
          purchase_expense_note: event.target.value,
          isValid: true,
        });
      }
    }
  }
  onchangePublicDealerNote(event) {
    // if (event.target.value !== "") {
    let PublicDealerNoteVal = event.target.value;
    // if (!PublicDealerNoteVal) {
    //   this.setState({
    //     onPublicDealerNoteErrorText: "Enter the correct Purchase Expense Notes",
    //     isValid: false
    //   })
    // }
    // else {
    this.setState({
      onPublicDealerNoteErrorText: "",
      public_dealer_note: event.target.value,
      isValid: true,
    });
    // }
    // }
  }

  onchangeModificationDetails(event) {
    // if (event.target.value !== "") {
    let ModificationDetailsVal = event.target.value;
    if (!ModificationDetailsVal) {
      this.setState({
        modification_deatils: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        modification_deatils: event.target.value,
        isValid: true,
      });
    }
    // }
  }

  onchangeTotalCost(event) {
    if (event.target.value !== "") {
      let TotalCostVal = validator.isNumeric(event.target.value);
      if (!TotalCostVal) {
        this.setState({
          isValid: false,
        });
      } else {
        this.setState({
          total_cost: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangeInventoryStatus(event) {
    if (event.target.value !== "") {
      let InventoryStatusVal = event.target.value;
      if (!InventoryStatusVal) {
        this.setState({
          onInventoryStatusErrorText: "Enter the correct inventory status",
          isValid: false,
        });
      } else {
        this.setState({
          onInventoryStatusErrorText: "",
          inventory_status: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangeCondition(event, value) {
    let ConditionVal = value;
    if (!ConditionVal) {
      this.setState({
        onConditionErrorText: "Choose the correct Condition",
        isValid: false,
      });
    } else if (ConditionVal === 5) {
      this.setState({
        onCoditionTenSuccessText: "Pre Owned",
        onConditionErrorText: "",
        Condition: value,
        isValid: true,
      });
    } else {
      this.setState({
        onConditionErrorText: "",
        onCoditionTenSuccessText: "",
        Condition: value,
        isValid: true,
      });
    }
  }

  // onchangeBandSize(event, value) {
  //   let BandSizeVal = value;
  //   if (!BandSizeVal) {
  //     this.setState({
  //       onBandSizeErrorText: "Choose the correct Band Size",
  //       isValid: false
  //     })
  //   }
  //   else {
  //     this.setState({
  //       onBandSizeErrorText: "",
  //       BandSize: value,
  //       isValid: true
  //     })
  //   }
  // }

  handleCheck1 = (event) => {
    if (event.target.checked == true) {
      this.setState({
        box: true,
      });
      localStorage.setItem("warranty_box", "1");
    } else {
      this.setState({
        box: false,
      });
      localStorage.setItem("warranty_box", "0");
    }
  };

  handleCheck2 = (event) => {
    if (event.target.checked) {
      this.setState({
        ismodified: true,
      });
    } else {
      this.setState({
        ismodified: false,
      });
    }
  };

  onchangeDealerNotes(event) {
    if (event.target.value !== "") {
      let DealerNotesVal = event.target.value;
      if (!DealerNotesVal) {
        this.setState({
          onDealerNotesErrorText: "Choose the correct Dealer Notes",
          isValid: false,
        });
      } else {
        this.setState({
          onDealerNotesErrorText: "",
          DealerNotes: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangeInventoryTitle(event) {
    this.setState({
      inventory_title: event.target.value,
    });

    if (event.target.value === "") {
      this.setState({
        is_inventory_title_changed: true,
      });
      setTimeout(() => {
        this.setState({
          is_inventory_title_changed: false,
        });
      }, 3000);
    }
  }

  onchangeNumberOfLinks(event) {
    if (event.target.value !== "") {
      let NumberOfLinksVal = validator.isFloat(event.target.value);
      if (!NumberOfLinksVal) {
        this.setState({
          onNumberOfLinksErrorText: "Choose the number of links / screws",
          isValid: false,
        });
      } else {
        this.setState({
          onNumberOfLinksErrorText: "",
          NumberOfLinks: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangeBandSize(event) {
    if (event.target.value !== "") {
      let BandSizeVal = validator.isNumeric(event.target.value);
      if (!BandSizeVal) {
        this.setState({
          onBandSizeErrorText: "Choose the correct Band Size",
          isValid: false,
        });
      } else {
        this.setState({
          onBandSizeErrorText: "",
          BandSize: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangeWholeSalePrice(event) {
    if (event.target.value !== "") {
      let WholeSalePriceeVal = validator.isNumeric(event.target.value);
      if (!WholeSalePriceeVal) {
        this.setState({
          onWholeSalePriceErrorText: "Choose the correct Whole Sale Price",
          isValid: false,
        });
      } else {
        this.setState({
          onWholeSalePriceErrorText: "",
          WholeSalePrice: event.target.value,
          isValid: true,
        });
      }
    }
  }
  editinvnetrydeatils() {
    this.setState({
      disbleseach: false,
      searchString: "",
      IndividualBrowseWatchesData: [],
      inventory_title: "",
      title_without_edit: "",
      autocomplete: { data: [] },
    });
  }

  onchangeRetailPrice(event) {
    if (event.target.value !== "") {
      let RetailPriceeVal = validator.isNumeric(event.target.value);
      if (!RetailPriceeVal) {
        this.setState({
          onRetailPriceErrorText: "Choose the correct Retail Price",
          isValid: false,
        });
      } else {
        this.setState({
          onRetailPriceErrorText: "",
          RetailPrice: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onchangeRetailWirePrice(event) {
    if (event.target.value !== "") {
      let RetailWirePriceVal = validator.isNumeric(event.target.value);
      if (!RetailWirePriceVal) {
        this.setState({
          onRetailWirePriceErrorText: "Choose the correct Retail Wire Price",
          isValid: false,
        });
      } else {
        this.setState({
          onRetailWirePriceErrorText: "",
          RetailWirePrice: event.target.value,
          isValid: true,
        });
      }
    }
  }

  onProductChange(e, v, r) {
    switch (e) {
      case "product_selected":
        this.onchangeModelNumber(v.productid);
        break;
      case "input_changed":
        this.state.autocomplete.currentInput = v;
        this.setState({
          ...this.state,
        });

        // if (!this.state.autocomplete.loading) {
        this.state.autocomplete.loading = true;
        this.setState({
          ...this.state,
        });

        // setTimeout(() => {
        if (v.length >= 3 && r == "input") {
          this.props.searchProducts({ searchText: v }, (error, res) => {
            console.log(error, res);
            // this.state.autocomplete.loading = false
            // this.setState({
            //   ...this.state
            // })
          });
        }
        // }, 500)
        // }
        break;
    }
  }

  valuetext = (value) => {
    return "mm";
  };
  handleClickOpen = () => {
    this.setState({
      setOpen: "true",
    });
  };

  handleClose = () => {
    this.setState({
      setOpen: "false",
    });
  };
  handleSelectedFiles(value, disablevalue) {
    const imageFile = disablevalue;
    let fileArray = [];
    for (let i = 0; i < imageFile.length; i++) {
      if (imageFile?.name) {
        fileArray.push(URL.createObjectURL(imageFile[i]));
        continue;
      }
      //fileArray.push(imageFile[i])
    }

    // this.setState({ file: this.fileArray })
    this.setState({
      bulkUploadFile: imageFile,
      fileArray: fileArray,
    });
  }
  bulkUploadFile = ({ target }) => {
    const imageFile = target.files;
    let fileArray = [];
    for (let i = 0; i < imageFile.length; i++) {
      fileArray.push(URL.createObjectURL(imageFile[i]));
    }
    this.setState({ file: this.fileArray });
    this.setState({
      bulkUploadFile: imageFile,
      fileArray: fileArray,
    });
  };

  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({
        warrentyExpiryDate: true,
        WarentyPaper: true,
        warentypaperset: "1",
        typeOfPaper: "1",
      });
    } else {
      this.setState({
        warrentyExpiryDate: false,
        WarentyPaper: false,
        warentypaperset: "0",
        typeOfPaper: "0",
      });
    }
  };

  handleTypeOfPaperCheck = (event) => {
    const { value, checked } = event.target;
    if (value === "Warranty" && checked) {
      this.setState({
        warrentyExpiryDate: true,
        WarentyPaper: true,
        warentypaperset: "1",
      });
    } else if (value === "Warranty" && !checked) {
      this.setState({
        warrentyExpiryDate: false,
        WarentyPaper: false,
        warentypaperset: "",
      });
    } else if (value === "Archive" && checked) {
      this.setState({
        archivePaperSet: "1",
        archivePaper: true,
      });
    } else if (value === "Archive" && !checked) {
      this.setState({
        archivePaperSet: "",
        archivePaper: false,
      });
    } else if (value === "isService" && checked) {
      this.setState({
        isService: true,
      });
    } else if (value === "isService" && !checked) {
      this.setState({
        isService: false,
      });
    }
  };

  validateInput() {
    let onModelNumberErrorText = "";
    // let onPurchasedFromErrorText = ''
    // let onPurchasedDateErrorText = '';
    // let onPurchasedPriceErrorText = '';
    let onSerialNumberErrorText = "";
    let onConditionErrorText = "";
    let modificationDetailsError = "";
    let onpaymentDateErrorText = "";
    // let onBandSizeErrorText = '';
    // let onNumberOfLinksErrorText = '';
    // let onDealerNotesErrorText = '';
    // let onWholeSalePriceErrorText = '';
    // let onRetailPriceErrorText = '';
    // let onRetailWirePriceErrorText = '';
    let isValid = true;
    if (this.state.ModelNumber?.length === 0) {
      onModelNumberErrorText = "Enter search watches";
      isValid = false;
    }
    if (
      this.state.ismodified &&
      this.state.modification_deatils &&
      this.state.modification_deatils?.length === 0
    ) {
      modificationDetailsError = "Enter Modification Details";
      isValid = false;
    }
    // if (this.state.paidStatus && !this.state.paymentDate) {
    //   onpaymentDateErrorText = 'Enter Payment Date';
    //   isValid = false;
    // }
    // if (this.state.PurchasedDate.length === 0) {
    //   onPurchasedDateErrorText = 'Enter Purchased Date';
    //   isValid = false;
    // }
    // if (this.state.PurchasedPrice.length === 0) {
    //   onPurchasedPriceErrorText = 'Enter Purchased Price';
    //   isValid = false;
    // }
    if (!this.state.SerialNumber) {
      onSerialNumberErrorText = "Enter Serial Number";
      isValid = false;
    }
    if (!this.state.Condition) {
      onConditionErrorText = "Enter Condition";
      isValid = false;
    }
    // if (this.state.BandSize.length === 0) {
    //   onBandSizeErrorText = 'Enter BandSize';
    //   isValid = false;
    // }
    // if (this.state.NumberOfLinks.length === 0) {
    //   onNumberOfLinksErrorText = 'Enter Number Of Links';
    //   isValid = false;
    // }
    // if (this.state.WholeSalePrice.length === 0) {
    //   onWholeSalePriceErrorText = 'Enter WholeSale Price';
    //   isValid = false;
    // }
    // if (this.state.RetailPrice.length === 0) {
    //   onRetailPriceErrorText = 'Enter Retail Price';
    //   isValid = false;
    // }
    // if (this.state.RetailWirePrice.length === 0) {
    //   onRetailWirePriceErrorText = 'Enter Retail Wire Price';
    //   isValid = false;
    // }
    this.setState({
      ...this.state,
      onModelNumberErrorText,
      onpaymentDateErrorText,
      // onPurchasedFromErrorText,
      // onPurchasedDateErrorText,
      // onPurchasedPriceErrorText,
      onSerialNumberErrorText,
      onConditionErrorText,
      modificationDetailsError,
      // onBandSizeErrorText,
      // onNumberOfLinksErrorText,
      // onDealerNotesErrorText,
      // onWholeSalePriceErrorText,
      // onRetailPriceErrorText,
      // onRetailWirePriceErrorText,
    });
    return isValid;
  }
  submitForm(event) {
    let x = localStorage.getItem("warranty_box");
    if (this.validateInput()) {
      var attributes = {
        reference: this.state.IndividualBrowseWatchesData.model
          ? this.state.IndividualBrowseWatchesData.model
          : "",
        productname: this.state.IndividualBrowseWatchesData.productname
          ? this.state.IndividualBrowseWatchesData.productname
          : "",
        modelname: this.state.IndividualBrowseWatchesData.modelname
          ? this.state.IndividualBrowseWatchesData.modelname
          : "",
        productfamily: this.state.IndividualBrowseWatchesData.productfamily
          ? this.state.IndividualBrowseWatchesData.productfamily
          : "",
        bezel: "",
        gender: "",
        glass: this.state.IndividualBrowseWatchesData?.prodattr?.glass
          ? this.state.IndividualBrowseWatchesData.prodattr.glass
          : "",
        shape: this.state.IndividualBrowseWatchesData?.prodattr?.shape
          ? this.state.IndividualBrowseWatchesData.prodattr.shape
          : "",
        waterresistant: this.state.IndividualBrowseWatchesData.waterresistant
          ? this.state.IndividualBrowseWatchesData.waterresistant
          : "",
        caliber: this.state.IndividualBrowseWatchesData?.prodattr?.caliber
          ? this.state.IndividualBrowseWatchesData.waterresistant
          : "",
        movement: this.state.IndividualBrowseWatchesData?.prodattr?.movement
          ? this.state.IndividualBrowseWatchesData?.prodattr?.movement
          : "",
        casesize: this.state.IndividualBrowseWatchesData?.prodattr?.casesize
          ? this.state.IndividualBrowseWatchesData?.prodattr?.casesize
          : "",
        powerreserve: this.state.IndividualBrowseWatchesData?.prodattr
          ?.powerreserve
          ? this.state.IndividualBrowseWatchesData?.prodattr?.powerreserve
          : "",
        indices: this.state.IndividualBrowseWatchesData?.prodattr?.indices
          ? this.state.IndividualBrowseWatchesData?.prodattr?.indices
          : "",
        casematerial: this.state.IndividualBrowseWatchesData?.prodattr
          ?.casematerial
          ? this.state.IndividualBrowseWatchesData?.prodattr?.casematerial
          : "",
        bandmaterial: this.state.IndividualBrowseWatchesData?.prodattr
          ?.bandmaterial
          ? this.state.IndividualBrowseWatchesData?.prodattr?.bandmaterial
          : "",
        dialcolor: this.state.IndividualBrowseWatchesData?.prodattr?.dialcolor
          ? this.state.IndividualBrowseWatchesData.prodattr.dialcolor
          : "",
        circa: "",
        productcode: "",
        luminous: "",
        bandcolor: "",
        bandtype: "",
        buckletype: "",
      };
      if (this.state.BandSize === 0) {
        this.setState({
          BandSize: "",
        });
      }
      if (this.state.warentypaperset === 1) {
        let data = {
          product_id: this.state.IndividualBrowseWatchesData.productid
            ? this.state.IndividualBrowseWatchesData.productid
            : "",
          attributes: attributes ? attributes : "",
          accountId: this.state.accountId,
          purchase_price: this.state.PurchasedPrice
            ? this.state.PurchasedPrice
            : "",
          purchase_date: this.state.PurchasedDate
            ? this.state.PurchasedDate
            : "",
          purchase_from: this.state.PurchasedFrom
            ? this.state.PurchasedFrom
            : "",
          serial_no: this.state.SerialNumber ? this.state.SerialNumber : "",
          inventory_type: this.state.InventoryType
            ? this.state.InventoryType
            : null,
          memo_details: this.state.MemoDetails ? this.state.MemoDetails : null,
          partnership_detais: this.state.PartnershipDetails
            ? this.state.PartnershipDetails
            : null,
          condition: this.state.Condition ? this.state.Condition : "1",
          band_size: this.state.BandSize ? this.state.BandSize : "",
          links: this.state.NumberOfLinks ? this.state.NumberOfLinks : "",
          note: this.state.DealerNotes ? this.state.DealerNotes : "",
          wholesale_price: this.state.WholeSalePrice
            ? this.state.WholeSalePrice
            : "",
          retail_price: this.state.RetailPrice ? this.state.RetailPrice : "",
          retailwire_price: this.state.RetailWirePrice
            ? this.state.RetailWirePrice
            : "",
          warranty_paper: this.state.warentypaperset
            ? this.state.warentypaperset
            : "",
          archive: this.state.archivePaperSet ? this.state.archivePaperSet : "",
          is_service: this.state.isService ? this.state.isService : "",
          service_date: this.state.serviceDate ? this.state.serviceDate : "",
          warranty_box: x ? x : "",
          warranty_date: this.state.PurchasedDate1
            ? this.state.PurchasedDate1
            : "",
          created_by: this.props.currentUser.userId
            ? this.state.PurchasedDate1
            : "",
          inventory_title:
            this.state.inventory_title && this.state.inventory_title !== ""
              ? this.state.inventory_title
              : this.state.IndividualBrowseWatchesData &&
                this.state.IndividualBrowseWatchesData.prodattr
                ? this.state.IndividualBrowseWatchesData.prodattr?.model
                : "",
          inventory_status: this.state.inventory_status
            ? this.state.inventory_status
            : null,
          public_dealer_note: this.state.public_dealer_note
            ? this.state.public_dealer_note
            : "",
          memo_number: this.state.updatememo ? this.state.updatememo : "",
          partnership_number: this.state.updatepartnershipnumber || null,
          total_cost: this.state.total_cost ? this.state.total_cost : "",
          tag_price: this.state.tag_price ? this.state.tag_price : "",
          modification_deatils: this.state.modification_deatils
            ? this.state.modification_deatils
            : "",
          ismodified: this.state.ismodified || false,
          purchase_expense: this.state.purchase_expense
            ? this.state.purchase_expense
            : "",
          purchase_invoice_paid: this.state.paidStatus
            ? this.state.paidStatus
            : false,
          purchase_payment_date: this.state.paymentDate || null,
          purchase_payment_details: this.state.PaymentDetailsText
            ? this.state.PaymentDetailsText
            : "",
          purchase_payment_duedate: this.state.purchaseDueDate || null,
          purchase_expense_note: this.state.purchase_expense_note
            ? this.state.purchase_expense_note
            : "",
          paperType: this.state.typeOfPaper || null,
          // "is_notsale"  :this.state.notforSale,
          is_include_dealerfeed: this.state.dealerInv,
          inventory_location: this.state.locationSearch || "",
          // ismodified:
          //   this.state.inventory_title?.toLowerCase() ===
          //     this.state.title_without_edit?.toLowerCase()
          //     ? false
          //     : true,
          purchasefromcontactid: this.state.purchaseFromContactId || null,
          memocontactid: this.state.memoContactId || null,
          partnercontactid: this.state.partnerContactId || null,
        };
        if (
          this.state.IndividualBrowseWatchesData &&
          this.state.IndividualBrowseWatchesData?.productid &&
          this.state.IndividualBrowseWatchesData?.productid?.length === 0
        ) {
          delete data.product_id;
        }
        if (attributes?.length === 0) {
          delete data.attributes;
        }
        if (!this.state.PurchasedPrice) {
          delete data.purchase_price;
        }
        if (!this.state.PurchasedDate) {
          delete data.purchase_date;
        }
        if (!this.state.PurchasedFrom) {
          delete data.purchase_from;
        }
        if (!this.state.SerialNumber) {
          delete data.serial_no;
        }
        if (!this.state.Condition) {
          delete data.condition;
        }
        if (!this.state.BandSize) {
          delete data.band_size;
        }
        if (!this.state.NumberOfLinks) {
          delete data.links;
        }
        if (!this.state.DealerNotes) {
          delete data.note;
        }
        if (!this.state.WholeSalePrice) {
          delete data.wholesale_price;
        }
        if (!this.state.RetailPrice) {
          delete data.retail_price;
        }
        if (!this.state.RetailWirePrice) {
          delete data.retailwire_price;
        }
        if (!this.state.archivePaperSet || this.state.archivePaperSet === "") {
          delete data.archive;
        }
        if (x?.length === 0) {
          delete data.warranty_box;
        }
        if (!this.state.PurchasedDate1) {
          delete data.warranty_date;
        }
        if (!this.state.archiveDate) {
          delete data.archive_date;
        }
        if (!this.state.isService) {
          delete data.is_service;
        }
        if (!this.state.serviceDate) {
          delete data.service_date;
        }
        if (this.props.currentUser.userId) {
          delete data.created_by;
        }

        if (!this.state.total_cost) {
          delete data.total_cost;
        }
        if (!this.state.tag_price) {
          delete data.tag_price;
        }
        if (!this.state.purchase_expense) {
          delete data.purchase_expense;
        }
        if (!this.state.purchase_expense_note) {
          delete data.purchase_expense_note;
        }
        if (!this.state.locationSearch) {
          delete data.inventory_location;
        }
        if (!this.state.public_dealer_note) {
          delete data.public_dealer_note;
        }

        if (!this.state.inventory_status) {
          delete data.inventory_status;
        }

        if (
          (this.state.MemoDetails && !this.state.MemoDetails) ||
          !this.state.MemoDetails
        ) {
          delete data.memo_details;
        }
        if (
          (this.state.updatememo && !this.state.updatememo) ||
          !this.state.updatememo
        ) {
          delete data.memo_number;
        }
        if (
          (this.state.updatepartnershipnumber &&
            !this.state.updatepartnershipnumber) ||
          !this.state.updatepartnershipnumber
        ) {
          delete data.partnership_number;
        }
        if (
          (this.state.PartnershipDetails && !this.state.PartnershipDetails) ||
          !this.state.PartnershipDetails
        ) {
          delete data.partnership_detais;
        }
        if (
          !this.state.modification_deatils ||
          (this.state.modification_deatils && !this.state.modification_deatils)
        ) {
          delete data.modification_deatils;
        }
        if (
          !data.purchase_payment_date ||
          !this.state.paymentDate ||
          !this.state.paidStatus
        ) {
          delete data.purchase_payment_date;
        }
        if (
          !data.purchase_payment_duedate ||
          !this.state.purchaseDueDate ||
          this.state.paidStatus
        ) {
          delete data.purchase_payment_duedate;
        }
        if (!this.state.purchaseFromContactId) {
          delete data.purchasefromcontactid;
        }
        if (!this.state.memoContactId) {
          delete data.memocontactid;
        }
        if (!this.state.partnerContactId) {
          delete data.partnercontactid;
        }
        let formData = new FormData();
        for (const key of Object.keys(this.state.bulkUploadFile)) {
          formData.append("image", this.state.bulkUploadFile[key]);
        }
        for (const key in data) {
          formData.set(key, data[key]);
        }
        addInventoryData(formData, (error, res) => {
          if (error) {
            toast("Same Model and Serial Number", {
              autoClose: 2000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else if (res.success === 0 && res.data && res.data.isLimitExited) {
            this.setState({
              limitReachedModalOpen: true,
            });
          } else {
            this.props.submitted(res?.data.inventoryid);
            localStorage.removeItem("warranty_box");
            this.setState({
              inventoryid: res?.data,
            });
          }
        });
      } else {
        if (this.state.BandSize === 0) {
          this.setState({
            BandSize: "",
          });
        }
        let data = {
          product_id: this.state.IndividualBrowseWatchesData.productid
            ? this.state.IndividualBrowseWatchesData.productid
            : "",
          attributes: attributes ? attributes : "",
          accountId: this.state.accountId,
          purchase_price: this.state.PurchasedPrice
            ? this.state.PurchasedPrice
            : "",
          purchase_date: this.state.PurchasedDate
            ? this.state.PurchasedDate
            : "",
          purchase_from: this.state.PurchasedFrom
            ? this.state.PurchasedFrom
            : "",
          serial_no: this.state.SerialNumber ? this.state.SerialNumber : "",
          inventory_type: this.state.InventoryType
            ? this.state.InventoryType
            : null,
          memo_details: this.state.MemoDetails ? this.state.MemoDetails : null,
          partnership_detais: this.state.PartnershipDetails
            ? this.state.PartnershipDetails
            : null,
          condition: this.state.Condition ? this.state.Condition : "",
          band_size: this.state.BandSize ? this.state.BandSize : "",
          links: this.state.NumberOfLinks ? this.state.NumberOfLinks : "",
          note: this.state.DealerNotes ? this.state.DealerNotes : "",
          wholesale_price: this.state.WholeSalePrice
            ? this.state.WholeSalePrice
            : "",
          retail_price: this.state.RetailPrice ? this.state.RetailPrice : "",
          retailwire_price: this.state.RetailWirePrice
            ? this.state.RetailWirePrice
            : "",
          warranty_paper: this.state.warentypaperset
            ? this.state.warentypaperset
            : "",
          archive: this.state.archivePaperSet ? this.state.archivePaperSet : "",
          is_service: this.state.isService ? this.state.isService : "",
          service_date: this.state.serviceDate ? this.state.serviceDate : "",
          warranty_box: x ? x : "0",
          created_by: this.props.currentUser.userId
            ? this.state.PurchasedDate1
            : "",
          warranty_date: this.state.PurchasedDate1
            ? this.state.PurchasedDate1
            : "",
          archive_date: this.state.archiveDate ? this.state.archiveDate : "",
          inventory_title: this.state.inventory_title
            ? this.state.inventory_title
            : this.state.IndividualBrowseWatchesData &&
              this.state.IndividualBrowseWatchesData.prodattr
              ? this.state.IndividualBrowseWatchesData.prodattr?.model
              : "",
          inventory_status: this.state.inventory_status
            ? this.state.inventory_status
            : null,
          public_dealer_note: this.state.public_dealer_note
            ? this.state.public_dealer_note
            : "",
          memo_number: this.state.updatememo ? this.state.updatememo : "",
          partnership_number: this.state.updatepartnershipnumber || null,
          total_cost: this.state.total_cost ? this.state.total_cost : "",
          tag_price: this.state.tag_price ? this.state.tag_price : "",
          modification_deatils: this.state.modification_deatils
            ? this.state.modification_deatils
            : "",
          "ismodified": this.state.ismodified || false,
          purchase_expense: this.state.purchase_expense
            ? this.state.purchase_expense
            : "",
          purchase_invoice_paid: this.state.paidStatus
            ? this.state.paidStatus
            : false,
          purchase_payment_date: this.state.paymentDate || null,
          purchase_payment_details: this.state.PaymentDetailsText
            ? this.state.PaymentDetailsText
            : "",
          purchase_payment_duedate: this.state.purchaseDueDate || null,
          purchase_expense_note: this.state.purchase_expense_note
            ? this.state.purchase_expense_note
            : "",
          paperType: this.state.typeOfPaper || null,
          inventory_location: this.state.locationSearch || "",
          // ismodified:
          //   this.state.inventory_title?.toLowerCase() !==
          //     this.state.title_without_edit?.toLowerCase()
          //     ? true
          //     : false,
          // "is_notsale"  :this.state.notforSale,
          is_include_dealerfeed: this.state.dealerInv,
          purchasefromcontactid: this.state.purchaseFromContactId || null,
          memocontactid: this.state.memoContactId || null,
          partnercontactid: this.state.partnerContactId || null,
        };
        if (this.state.IndividualBrowseWatchesData?.productid?.length === 0) {
          delete data.product_id;
        }
        if (attributes?.length === 0) {
          delete data.attributes;
        }
        if (!this.state.PurchasedPrice) {
          delete data.purchase_price;
        }
        if (!this.state.PurchasedDate) {
          delete data.purchase_date;
        }
        if (!this.state.PurchasedFrom) {
          delete data.purchase_from;
        }
        if (!this.state.SerialNumber) {
          delete data.serial_no;
        }
        if (!this.state.Condition) {
          delete data.condition;
        }
        if (!this.state.BandSize) {
          delete data.band_size;
        }
        if (!this.state.NumberOfLinks) {
          delete data.links;
        }
        if (!this.state.DealerNotes) {
          delete data.note;
        }
        if (!this.state.WholeSalePrice) {
          delete data.wholesale_price;
        }
        if (!this.state.RetailPrice) {
          delete data.retail_price;
        }
        if (this.state.RetailWirePrice === "") {
          delete data.retailwire_price;
        }
        if (this.state.warentypaperset === "" || !this.state.warentypaperset) {
          delete data.warranty_paper;
        }
        if (this.state.archivePaperSet === "" || !this.state.archivePaperSet) {
          delete data.archive;
        }
        if (!this.state.isService) {
          delete data.is_service;
        }
        if (!this.state.serviceDate) {
          delete data.service_date;
        }
        if (!this.state.modification_deatils) {
          delete data.modification_deatils;
        }
        if (x === "") {
          delete data.warranty_box;
        }
        if (this.state.PurchasedDate1 === "") {
          delete data.warranty_date;
        }
        if (this.props.currentUser.userId) {
          delete data.created_by;
        }

        if (!this.state.total_cost) {
          delete data.total_cost;
        }
        if (!this.state.tag_price) {
          delete data.tag_price;
        }
        if (!this.state.purchase_expense) {
          delete data.purchase_expense;
        }
        if (!this.state.purchase_expense_note) {
          delete data.purchase_expense_note;
        }
        if (!this.state.locationSearch) {
          delete data.inventory_location;
        }
        if (!this.state.public_dealer_note) {
          delete data.public_dealer_note;
        }
        if (!this.state.inventory_status) {
          delete data.inventory_status;
        }
        if (
          (this.state.MemoDetails && !this.state.MemoDetails) ||
          !this.state.MemoDetails
        ) {
          delete data.memo_details;
        }
        if (
          (this.state.updatememo && !this.state.updatememo) ||
          !this.state.updatememo
        ) {
          delete data.memo_number;
        }
        if (
          (this.state.updatepartnershipnumber &&
            !this.state.updatepartnershipnumber) ||
          !this.state.updatepartnershipnumber
        ) {
          delete data.partnership_number;
        }
        if (
          (this.state.PartnershipDetails && !this.state.PartnershipDetails) ||
          !this.state.PartnershipDetails
        ) {
          delete data.partnership_detais;
        }
        if (
          !this.state.modification_deatils ||
          (this.state.modification_deatils && !this.state.modification_deatils)
        ) {
          delete data.modification_deatils;
        }
        if (
          !data.purchase_payment_date ||
          !this.state.paymentDate ||
          !this.state.paidStatus
        ) {
          delete data.purchase_payment_date;
        }
        if (
          !data.purchase_payment_duedate ||
          !this.state.purchaseDueDate ||
          this.state.paidStatus
        ) {
          delete data.purchase_payment_duedate;
        }
        if (!this.state.purchaseFromContactId) {
          delete data.purchasefromcontactid;
        }
        if (!this.state.memoContactId) {
          delete data.memocontactid;
        }
        if (!this.state.partnerContactId) {
          delete data.partnercontactid;
        }
        let formData = new FormData();
        for (const key of Object.keys(this.state.bulkUploadFile)) {
          formData.append("image", this.state.bulkUploadFile[key]);
        }
        for (const key in data) {
          formData.set(key, data[key]);
        }
        addInventoryData(formData, (error, res) => {
          this.props.submitted(res?.data.inventoryid);
          if (error) {
            toast("Same Model and Serial Number", {
              autoClose: 2000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else if (res.success === 0 && res.data && res.data.isLimitExited) {
            this.setState({
              limitReachedModalOpen: true,
            });
          } else {
            localStorage.removeItem("warranty_box");
            this.setState({
              inventoryid: res?.data,
            });
          }
        });
      }
    }
  }

  checloaader() {
    this.setState({ loaded: false });
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 3000);
  }

  handleDateChange = (date, e) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          ["PD"]: "",
        },
        PurchasedDate: e,
      });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ["PD"]: `Invalid Date`,
        },
      });
    }
  };

  handleDateChange1 = (date, e, type) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          [type]: "",
        },
      });
      if (type === "Warranty") {
        this.setState({
          PurchasedDate1: e,
        });
      } else if (type === "Archive") {
        this.setState({
          archiveDate: e,
        });
      } else if (type === "Service") {
        this.setState({
          serviceDate: e,
        });
      }
    } else {
      this.setState({
        error: {
          ...this.state.error,
          [type]: `Invalid Date`,
        },
      });
    }
  };

  isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  endAdornment = () => {
    const values = this.state.key;
    if (values) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              this.handleClearButton();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    }
  };

  inventoryPage = () => {
    const { searchItems, cursor, item } = this.state;
    const handleChange = (event, newValue) => {
      this.setState({
        onBandSizeErrorText: "",
        BandSize: newValue,
        isValid: true,
        value: newValue,
      });
    };
    const TextFieldComponent = (props) => {
      return <TextField {...props} disabled={true} />;
    };
    const marks = [
      {
        value: 0,
        label: "0mm",
      },
    ];
    return (
      <div className="space-1">
        {this.state.loaded == false ? (
          <div class="d-spinner">
            <SpinnerRoundFilled
              size={51}
              thickness={125}
              speed={80}
              color="rgba(57, 107, 172, 1)"
              className="spp"
              enabled={this.state.loaded == false}
            />
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.submitForm(e);
            }}
            id="add_inventory_form"
          >
            <Container>
              <div>
                <Typography
                  variant="h6"
                  component="p"
                  className="primary-text uppercase"
                ></Typography>
              </div>
              <div>
                <div
                  style={{
                    marginTop: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIcon
                      style={{
                        color: "action",
                        paddingRight: "10px",
                        fontSize: 40,
                        cursor: "pointer",
                      }}
                      onClick={this.backNavigation}
                    >
                      {" "}
                    </ArrowBackIcon>
                  </div>
                  <div>
                    <Typography
                      variant="h6"
                      component="h6"
                      className="color-CEA05F"
                      style={{
                        fontFamily: "Gill Sans",
                        fontStyle: "initial",
                        fontSize: "25px",
                      }}
                    >
                      STANDARD WATCH
                    </Typography>
                  </div>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            <div>
                              <div>
                                <Input
                                  type="text"
                                  variant="outline"
                                  id="autocomplete"
                                  placeholder="SEARCH WATCHES"
                                  onChange={this.autocomplete}
                                  onKeyUp={this.hanldeKeyup}
                                  onKeyDown={this.hanldeKeydown}
                                  disabled={this.state.disbleseach === true}
                                  value={this.state.searchString}
                                  className="form-width-70  form-control searchoption boxwidth"
                                  // className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Button
                                        onClick={this.editinvnetrydeatils}
                                        disabled={
                                          this.state.selectedProduct.productId
                                        }
                                      >
                                        <EditIcon />
                                      </Button>
                                    </InputAdornment>
                                  }
                                />
                                {this.state.loading ? (
                                  <CircularProgress />
                                ) : this.state.autocomplete.data.length > 1 &&
                                  this.state.individualsearchdata ? (
                                  <List
                                    className="searchlist MuiPaper-elevation1 inventrysearch"
                                    style={{ listStyle: "none" }}
                                  >
                                    {this.state.autocomplete.data.map(
                                      (item, idx) => (
                                        <div>
                                          <ListItem
                                            className={
                                              cursor === idx ? "active" : ""
                                            }
                                            key={idx}
                                            onClick={() =>
                                              this.onchangeModelNumber(
                                                item,
                                                item.productId
                                              )
                                            }
                                            onKeyDown={(evt) =>
                                              this.handleListKeydown(
                                                evt,
                                                item.model
                                              )
                                            }
                                          >
                                            <ListItemIcon>
                                              <IconButton color="primary">
                                                <img
                                                  width="45"
                                                  src={
                                                    item.imagepaths?.images
                                                      ?.toString()
                                                      .split(",")[0]
                                                  }
                                                  alt="Product Image"
                                                />
                                              </IconButton>
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={
                                                item.brandname +
                                                " " +
                                                item.model
                                              }
                                              secondary={item.family}
                                              style={{
                                                marginTop: "25px",
                                                marginLeft: "80px",
                                              }}
                                            />
                                          </ListItem>
                                          <Divider
                                            variant="inset"
                                            component="li"
                                          />
                                        </div>
                                      )
                                    )}
                                  </List>
                                ) : null}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="bodyloader">
                      <FormControl className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC">
                        {/* <FormHelperText className="error-o">{this.state.onModelNumberErrorText}</FormHelperText> */}
                      </FormControl>
                      {/* <Loader loaded={this.state.loaded}> */}
                      {this.state.searchString ? (
                        <Card
                          className="form-width-70 margin-t-b-5 margin-p-b-20"
                          style={{ marginBottom: "1rem" }}
                        >
                          <CardContent>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              style={{ color: "black" }}
                            >
                              TECHNICAL DETAILS
                            </Typography>
                            <Typography>
                              <b>Modal Name :</b>{" "}
                              {this.state.IndividualBrowseWatchesData &&
                                this.state.IndividualBrowseWatchesData.prodattr
                                ? this.state.IndividualBrowseWatchesData
                                  .prodattr?.model
                                : ""}
                              <br />
                              <b>Brand Name :</b>{" "}
                              {this.state.IndividualBrowseWatchesData &&
                                this.state.IndividualBrowseWatchesData.brandname
                                ? this.state.IndividualBrowseWatchesData
                                  .brandname
                                : ""}
                              <br />
                              <b>Model Number :</b>{" "}
                              {this.state.IndividualBrowseWatchesData &&
                                this.state.IndividualBrowseWatchesData.prodattr
                                ? this.state.IndividualBrowseWatchesData
                                  .prodattr.reference
                                : ""}
                              <br />
                              <b>Case Material :</b>{" "}
                              {this.state.IndividualBrowseWatchesData &&
                                this.state.IndividualBrowseWatchesData.prodattr
                                ? this.state.IndividualBrowseWatchesData
                                  .prodattr.casematerial
                                : ""}
                              <br />
                              <b>Case Size :</b>{" "}
                              {this.state.IndividualBrowseWatchesData &&
                                this.state.IndividualBrowseWatchesData.prodattr
                                ? this.state.IndividualBrowseWatchesData
                                  .prodattr.casesize
                                : ""}
                              <br />
                              <b>Dial Color :</b>{" "}
                              {this.state.IndividualBrowseWatchesData &&
                                this.state.IndividualBrowseWatchesData.prodattr
                                ? this.state.IndividualBrowseWatchesData
                                  .prodattr.dialcolor
                                : ""}
                              <br />
                              <b>Band Material :</b>{" "}
                              {this.state.IndividualBrowseWatchesData &&
                                this.state.IndividualBrowseWatchesData.prodattr
                                ? this.state.IndividualBrowseWatchesData
                                  .prodattr.bandmaterial
                                : ""}
                              <br />
                              <b>Retail Price :</b>{" "}
                              {this.state.retailAmount
                                ? this.state.retailAmount
                                : ""}
                            </Typography>
                          </CardContent>
                        </Card>
                      ) : (
                        <FormControl className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC">
                          <FormHelperText className="error-o">
                            {this.state.onModelNumberErrorText}
                          </FormHelperText>
                        </FormControl>
                      )}
                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                        <InputLabel
                          htmlFor="component-simple"
                          style={{ color: "#424242" }}
                        >
                          INVENTORY TITLE
                        </InputLabel>
                        <Input
                          id="component-simple"
                          onChange={this.onchangeInventoryTitle}
                          onFocus={(e) =>
                            this.setState({ inventoryitemfocused: true })
                          }
                          onBlur={(e) =>
                            this.setState({ inventoryitemfocused: false })
                          }
                          value={
                            this.state.inventory_title
                              ? this.state.inventory_title
                              : this.state.is_inventory_title_changed
                                ? this.state.inventory_title
                                : this.state.IndividualBrowseWatchesData &&
                                  this.state.IndividualBrowseWatchesData.prodattr
                                  ? this.state.IndividualBrowseWatchesData.prodattr
                                    ?.model
                                    ? this.state.IndividualBrowseWatchesData
                                      .prodattr?.model
                                    : this.state.IndividualBrowseWatchesData
                                      .prodattr?.reference
                                  : ""
                          }
                        ></Input>
                        <FormHelperText className="error-o"></FormHelperText>
                      </FormControl>
                      {/* {isActionAllowed(this.state.viewData,
                        "purchase_from") && (
                          <>
                            <FormControl className="form-width-70 " style={{ marginTop: '8px' }}>
                              <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>PURCHASED FROM</InputLabel>
                              <Input id="component-simple"
                                onChange={this.onchangePurchasedFrom}
                              />
                              <FormHelperText className="error-o">{this.state.onPurchasedFromErrorText}</FormHelperText>
                            </FormControl>
                          </>
                        )} */}
                      {isActionAllowed(
                        this.state.viewData,
                        "purchase_from"
                      ) && (
                          <>
                            <FormControl
                              className="form-width-70 "
                              style={{ marginTop: "8px" }}
                            >
                              <InputLabel
                                htmlFor="component-simple"
                                shrink={this.state.PurchasedFrom ? true : false}
                                style={{ color: "#424242" }}
                              >
                                PURCHASED FROM
                              </InputLabel>
                              <AntdInput
                                id="PurchasedFrom"
                                type="text"
                                autoComplete="off"
                                // ref={inputRef}
                                value={this.state.PurchasedFrom}
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "10px",
                                  fontSize: "16px",
                                  paddingLeft: 0,
                                }}
                                onChange={(e) =>
                                  this.onchangeContactSuggestion(
                                    e,
                                    "PurchasedFrom",
                                    ["supplier", "memo_supplier"]
                                  )
                                }
                                bordered={false}
                              />
                              {/* <FormHelperText className="error-o">{this.state.onPurchasedFromErrorText}</FormHelperText> */}
                              <Divider style={{ marginTop: "5px" }} />
                              <div className="contactSearchIcon">
                                <Grid container>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <>
                                      {!this.state.PurchasedFrom ? (
                                        <SearchIcon
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <>
                                          {this.state.fieldLoader ? (
                                            <CircularProgress
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                            />
                                          ) : (
                                            <ClearIcon
                                              id="clearBtn"
                                              onClick={() =>
                                                this.clearContactSuggestion(
                                                  "PurchasedFrom"
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  </Grid>
                                </Grid>
                              </div>
                            </FormControl>
                            {this.state.purchasedFromArray &&
                              this.state.purchasedFromArray.length > 0 &&
                              this.state.PurchasedFrom?.length > 0 ? (
                              <div>
                                <AntdPopover
                                  visible={this.state.isPopOverPurchasedFrom}
                                  content={
                                    <div
                                      style={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      {this.state.dataset
                                        ?.slice(0, 15)
                                        .map((value, key) => {
                                          return value.map((el, index) => {
                                            if (index < 1) {
                                              const displayName = `${el?.firstname ?? ""
                                                } ${el?.lastname ?? ""}`;
                                              return (
                                                <>
                                                  {/* <Accordion>
                                                    <AccordionSummary
                                                      aria-controls="panel1d-content"
                                                      id="panel1d-header"
                                                    >
                                                      <Typography
                                                        style={{
                                                          padding: 10,
                                                          width: 300,
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {(el?.businessname ||
                                                          displayName) ??
                                                          ""}
                                                      </Typography>
                                                    </AccordionSummary> */}

                                                  {this.state.purchasedFromArray
                                                    ?.filter(
                                                      (v) =>
                                                        v.email == el.email
                                                    )
                                                    .map((el, index) => {
                                                      return (
                                                        <AccordionDetails
                                                          key={index}
                                                        >
                                                          <Typography
                                                            style={{
                                                              cursor:
                                                                "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.handleContactSelection(
                                                                el,
                                                                "PurchasedFrom"
                                                              )
                                                            }
                                                          >
                                                            {this.handleContact(
                                                              el
                                                            )}
                                                          </Typography>
                                                        </AccordionDetails>
                                                      );
                                                    })}
                                                  {/* </Accordion> */}
                                                </>
                                              );
                                            }
                                          });
                                        })}
                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottomRight"
                                  // placement="bottomLeft"
                                  onVisibleChange={this.handleVisibleChange}
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      position: "absolute",
                                    }}
                                  />
                                </AntdPopover>
                              </div>
                            ) : (this.state.PurchasedFrom.length > 2 && !this.state.purchasedFromArray.length && !this.state.fieldLoader) &&
                            (<AntdPopover
                              // id={column.id}
                              visible={this.state.PurchasedFrom?.length > 2 && !this.state.purchasedFromArray.length && !this.state.fieldLoader}
                              content={
                                <div
                                  style={{ overflow: "auto" }}
                                >
                                  <a
                                    className="dataItem"
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      // this.setState({
                                      //   PurchasedFrom: "",
                                      // });
                                      const typeData = [
                                        { id: "supplier", value: "Supplier" },
                                        { id: "memo_supplier", value: "Memo Supplier" }
                                      ];
                                      const encodedTypeData = btoa(JSON.stringify(typeData));
                                      window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                      // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                    }
                                    }
                                    style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                  >
                                    Click here to Create New Contact
                                  </a>
                                </div>
                              }
                              trigger="click"
                              placement="bottomRight"
                              overlayStyle={{ marginTop: "5px" }}
                            >
                              <div
                                style={{
                                  width: 0,
                                  height: 0,
                                  position: "absolute",
                                }}
                              />
                            </AntdPopover>)}
                          </>
                        )}

                      {isActionAllowed(
                        this.state.viewData,
                        "purchase_date"
                      ) && (
                          <>
                            <div>
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    // className="color-BCBCBC"
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="MM/dd/yyyy"
                                    disableFuture={true}
                                    value={this.state.PurchasedDate || null}
                                    onChange={this.handleDateChange}
                                    isClearable
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                    // TextFieldComponent={TextFieldComponent}
                                    placeholder="PURCHASED DATE - MM/DD/YYYY"
                                    helperText={
                                      this.state.error["PD"] ? "Invalid date" : ""
                                    }
                                    error={this.state.error["PD"]}
                                  />
                                </MuiPickersUtilsProvider>
                                <FormHelperText className="error-o">
                                  {this.state.onPurchasedDateErrorText}
                                </FormHelperText>
                              </FormControl>
                            </div>
                          </>
                        )}
                      <FormControl
                        className="form-width-70 margin-t-b-20 margin-p-b-20"
                        style={{
                          marginTop: "1rem",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "2rem",
                        }}
                      >
                        <p>
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            style={{ color: "#424242" }}
                          >
                            PURCHASE PAYMENT STATUS
                          </FormLabel>
                        </p>
                        <div style={{ marginBottom: "8px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB1"
                                value={this.state.paidStatus}
                                checked={this.state.paidStatus}
                                className="float-right"
                                onChange={this.handlePaidStatusCheck}
                              />
                            }
                            label="Paid"
                          />
                          {/* </FormControl> */}
                        </div>
                      </FormControl>
                      <div className="space-top-1">
                        {this.state.paidStatus ? (
                          <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                // label='PURCHASE PAYMENT DATE'
                                id="date-picker-purchase"
                                format="MM/dd/yyyy"
                                disableFuture={true}
                                value={this.state.paymentDate || null}
                                onChange={this.handlePayDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputLabelProps={{
                                  // shrink: this.state.paymentDate ? true : false,
                                  style: { color: "black" },
                                }}
                                placeholder="PURCHASE PAYMENT DATE - MM/DD/YYYY"
                                helperText={
                                  this.state.error["PPD"] ? "Invalid date" : ""
                                }
                                error={this.state.error["PPD"]}
                              />
                            </MuiPickersUtilsProvider>
                            <FormHelperText className="error-o">
                              {this.state.onpaymentDateErrorText}
                            </FormHelperText>
                          </FormControl>
                        ) : (
                          <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                // label='PURCHASE PAYMENT DUE DATE'
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                //disableFuture={true}

                                value={this.state.purchaseDueDate || null}
                                onChange={this.handleDueDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                placeholder="PURCHASE PAYMENT DUE DATE - MM/DD/YYYY"
                                InputLabelProps={{
                                  // shrink: this.state.purchaseDueDate ? true : false,
                                  style: { color: "black" },
                                }}
                                helperText={
                                  this.state.error["PPDD"] ? "Invalid date" : ""
                                }
                                error={this.state.error["PPDD"]}
                              />
                            </MuiPickersUtilsProvider>
                            {/* <FormHelperText className="error-o">{this.state.onpurchaseDueDateErrorText}</FormHelperText> */}
                          </FormControl>
                        )}
                      </div>
                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                        <InputLabel
                          htmlFor="component-simple"
                          style={{ color: "#424242" }}
                        >
                          PURCHASE PAYMENT DETAILS
                        </InputLabel>
                        <Input
                          id="component-simple"
                          style={{ marginBottom: "10px" }}
                          multiline
                          onChange={this.onchangePaymentDetails}
                          value={
                            this.state.PaymentDetailsText
                              ? this.state.PaymentDetailsText
                              : ""
                          }
                        />
                        <FormHelperText className="error-o">
                          {this.state.onPaymentDetailsErrorText}
                        </FormHelperText>
                      </FormControl>
                      {isActionAllowed(
                        this.state.viewData,
                        "purchase_price"
                      ) && (
                          <>
                            <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                PURCHASED PRICE ($){" "}
                              </InputLabel>
                              <Input
                                id="component-simple"
                                onChange={this.onchangePurchasedPrice}
                              // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              />
                              <FormHelperText className="error-o">
                                {this.state.onPurchasedPriceErrorText}
                              </FormHelperText>
                            </FormControl>
                          </>
                        )}
                      {isActionAllowed(
                        this.state.viewData,
                        "purchase_expense"
                      ) && (
                          <>
                            <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                PURCHASE EXPENSE ($)
                              </InputLabel>
                              <Input
                                id="component-simple"
                                onChange={this.onchangePurchaseExpense}
                              />
                              <FormHelperText className="error-o"></FormHelperText>
                            </FormControl>

                            <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                {" "}
                                PURCHASE EXPENSE NOTE
                              </InputLabel>
                              <Input
                                id="component-simple"
                                onChange={this.onchangePurchaseExpenseNote}
                              // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              />
                              <FormHelperText className="error-o"></FormHelperText>
                            </FormControl>
                          </>
                        )}
                      {isActionAllowed(this.state.viewData, "total_cost") && (
                        <>
                          <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                            <InputLabel
                              htmlFor="component-simple"
                              style={{ color: "#424242" }}
                            >
                              {" "}
                              TOTAL COST ($)
                            </InputLabel>
                            <Input
                              id="component-simple"
                              disabled
                              value={this.state.total_cost}
                            // onChange={this.onchangeTotalCost}
                            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            ></Input>
                            <FormHelperText className="error-o"></FormHelperText>
                          </FormControl>
                        </>
                      )}
                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                        <InputLabel
                          htmlFor="component-simple"
                          style={{ color: "#424242" }}
                        >
                          SERIAL NUMBER
                        </InputLabel>
                        <Input
                          id="component-simple"
                          onChange={this.onchangeSerialNumber}
                        />
                        <FormHelperText className="error-o">
                          {this.state.onSerialNumberErrorText}
                        </FormHelperText>
                      </FormControl>
                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                        <InputLabel
                          htmlFor="component-simple"
                          style={{ color: "#424242" }}
                        >
                          INVENTORY TYPE
                        </InputLabel>
                        <Select
                          id="component-simple"
                          onChange={(e) =>
                            this.setState({
                              InventoryType: e.target.value,
                              MemoDetails: "",
                              PartnershipDetails: "",
                              memoContactId: null,
                              partnerContactId: null,
                            })
                          }
                        >
                          <MenuItem value="1">Regular Inventory</MenuItem>
                          <MenuItem value="2">Memo Inventory</MenuItem>
                          <MenuItem value="3">Partnership Inventory</MenuItem>
                        </Select>
                      </FormControl>

                      {this.state.InventoryType === "2" && (
                        <>
                          <FormControl
                            className="form-width-70 "
                            style={{ marginTop: "8px" }}
                          >
                            <InputLabel
                              htmlFor="component-simple"
                              shrink={this.state.MemoDetails ? true : false}
                              style={{ color: "#424242" }}
                            >
                              Memo Details
                            </InputLabel>
                            <AntdInput
                              id="MemoDetails"
                              type="text"
                              autoComplete="off"
                              value={this.state.MemoDetails}
                              style={{
                                marginBottom: "0px",
                                marginTop: "10px",
                                fontSize: "16px",
                                paddingLeft: 0,
                              }}
                              onChange={(e) =>
                                this.onchangeContactSuggestion(
                                  e,
                                  "MemoDetails",
                                  ["memo_supplier"]
                                )
                              }
                              bordered={false}
                            />
                            <Divider style={{ marginTop: "5px" }} />
                            <div className="contactSearchIcon">
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                  <>
                                    {!this.state.MemoDetails ? (
                                      <SearchIcon
                                        style={{ cursor: "pointer" }}
                                      />
                                    ) : (
                                      <>
                                        {this.state.fieldLoader ? (
                                          <CircularProgress
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        ) : (
                                          <ClearIcon
                                            id="clearBtn"
                                            onClick={() =>
                                              this.clearContactSuggestion(
                                                "MemoDetails"
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                </Grid>
                              </Grid>
                            </div>
                          </FormControl>
                          {this.state.memoDetailsArray &&
                            this.state.memoDetailsArray.length > 0 &&
                            this.state.MemoDetails?.length > 0 ? (
                            <div>
                              <AntdPopover
                                visible={this.state.isPopOverMemoDetails}
                                content={
                                  <div
                                    style={{
                                      height: "200px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {this.state.dataset
                                      ?.slice(0, 15)
                                      .map((value, key) => {
                                        return value.map((el, index) => {
                                          if (index < 1) {
                                            const displayName = `${el?.firstname ?? ""
                                              } ${el?.lastname ?? ""}`;
                                            return (
                                              <>
                                                {/* <Accordion>
                                                  <AccordionSummary
                                                    aria-controls="panel1d-content"
                                                    id="panel1d-header"
                                                  >
                                                    <Typography
                                                      style={{
                                                        padding: 10,
                                                        width: 300,
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {(el?.businessname ||
                                                        displayName) ??
                                                        ""}
                                                    </Typography>
                                                  </AccordionSummary> */}

                                                {this.state.memoDetailsArray
                                                  ?.filter(
                                                    (v) =>
                                                      v.email == el.email
                                                  )
                                                  .map((el, index) => {
                                                    return (
                                                      <AccordionDetails
                                                        key={index}
                                                      >
                                                        <Typography
                                                          style={{
                                                            cursor:
                                                              "pointer",
                                                          }}
                                                          onClick={() =>
                                                            this.handleContactSelection(
                                                              el,
                                                              "MemoDetails"
                                                            )
                                                          }
                                                        >
                                                          {this.handleContact(
                                                            el
                                                          )}
                                                        </Typography>
                                                      </AccordionDetails>
                                                    );
                                                  })}
                                                {/* </Accordion> */}
                                              </>
                                            );
                                          }
                                        });
                                      })}
                                  </div>
                                }
                                trigger="click"
                                placement="bottomRight"
                                // placement="bottomLeft"
                                onVisibleChange={this.handleVisibleChange}
                              >
                                <div
                                  style={{
                                    width: 0,
                                    height: 0,
                                    position: "absolute",
                                  }}
                                />
                              </AntdPopover>
                            </div>
                          ) : (this.state.MemoDetails?.length > 2 && !this.state.memoDetailsArray.length && !this.state.fieldLoader) &&
                          <AntdPopover
                            // id={column.id}
                            visible={this.state.MemoDetails?.length > 2 && !this.state.memoDetailsArray.length && !this.state.fieldLoader}
                            content={
                              <div
                                style={{ overflow: "auto" }}
                              >
                                <a
                                  className="dataItem"
                                  onClick={() => {
                                    // this.setState({
                                    //   MemoDetails: "",
                                    // });
                                    const typeData = [{ id: "memo_supplier", value: "Memo Supplier" }];
                                    const encodedTypeData = btoa(JSON.stringify(typeData));
                                    window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                    // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                  }
                                  }
                                  style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                >
                                  Click here to Create New Contact
                                </a>
                              </div>
                            }
                            trigger="click"
                            placement="bottomRight"
                            overlayStyle={{ marginTop: "5px" }}
                          >
                            <div
                              style={{
                                width: 0,
                                height: 0,
                                position: "absolute",
                              }}
                            />
                          </AntdPopover>
                          }
                        </>
                      )}

                      {this.state.InventoryType === "3" && (
                        <>
                          <FormControl
                            className="form-width-70 "
                            style={{ marginTop: "8px" }}
                          >
                            <InputLabel
                              htmlFor="component-simple"
                              shrink={
                                this.state.PartnershipDetails ? true : false
                              }
                              style={{ color: "#424242" }}
                            >
                              Partnership Details
                            </InputLabel>
                            <AntdInput
                              id="PartnershipDetails"
                              type="text"
                              autoComplete="off"
                              value={this.state.PartnershipDetails}
                              style={{
                                marginBottom: "0px",
                                marginTop: "10px",
                                fontSize: "16px",
                                paddingLeft: 0,
                              }}
                              onChange={(e) =>
                                this.onchangeContactSuggestion(
                                  e,
                                  "PartnershipDetails",
                                  ["partner"]
                                )
                              }
                              bordered={false}
                            />
                            <Divider style={{ marginTop: "5px" }} />
                            <div className="contactSearchIcon">
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                  <>
                                    {!this.state.PartnershipDetails ? (
                                      <SearchIcon
                                        style={{ cursor: "pointer" }}
                                      />
                                    ) : (
                                      <>
                                        {this.state.fieldLoader ? (
                                          <CircularProgress
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        ) : (
                                          <ClearIcon
                                            id="clearBtn"
                                            onClick={() =>
                                              this.clearContactSuggestion(
                                                "PartnershipDetails"
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                </Grid>
                              </Grid>
                            </div>
                          </FormControl>
                          {this.state.partnershipDetailsArray &&
                            this.state.partnershipDetailsArray.length > 0 &&
                            this.state.PartnershipDetails?.length > 0 ? (
                            <div>
                              <AntdPopover
                                visible={
                                  this.state.isPopOverPartnershipDetails
                                }
                                content={
                                  <div
                                    style={{
                                      height: "200px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {this.state.dataset
                                      ?.slice(0, 15)
                                      .map((value, key) => {
                                        return value.map((el, index) => {
                                          if (index < 1) {
                                            const displayName = `${el?.firstname ?? ""
                                              } ${el?.lastname ?? ""}`;
                                            return (
                                              <>
                                                {/* <Accordion>
                                                  <AccordionSummary
                                                    aria-controls="panel1d-content"
                                                    id="panel1d-header"
                                                  >
                                                    <Typography
                                                      style={{
                                                        padding: 10,
                                                        width: 300,
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {(el?.businessname ||
                                                        displayName) ??
                                                        ""}
                                                    </Typography>
                                                  </AccordionSummary> */}

                                                {this.state.partnershipDetailsArray
                                                  ?.filter(
                                                    (v) =>
                                                      v.email == el.email
                                                  )
                                                  .map((el, index) => {
                                                    return (
                                                      <AccordionDetails
                                                        key={index}
                                                      >
                                                        <Typography
                                                          style={{
                                                            cursor:
                                                              "pointer",
                                                          }}
                                                          onClick={() =>
                                                            this.handleContactSelection(
                                                              el,
                                                              "PartnershipDetails"
                                                            )
                                                          }
                                                        >
                                                          {this.handleContact(
                                                            el
                                                          )}
                                                        </Typography>
                                                      </AccordionDetails>
                                                    );
                                                  })}
                                                {/* </Accordion> */}
                                              </>
                                            );
                                          }
                                        });
                                      })}
                                  </div>
                                }
                                trigger="click"
                                placement="bottomRight"
                                // placement="bottomLeft"
                                onVisibleChange={this.handleVisibleChange}
                              >
                                <div
                                  style={{
                                    width: 0,
                                    height: 0,
                                    position: "absolute",
                                  }}
                                />
                              </AntdPopover>
                            </div>
                          ) : (this.state.PartnershipDetails.length > 2 && !this.state.partnershipDetailsArray.length && !this.state.fieldLoader) &&
                          <AntdPopover
                            // id={column.id}
                            visible={this.state.PartnershipDetails?.length > 2 && !this.state.partnershipDetailsArray.length && !this.state.fieldLoader}
                            content={
                              <div
                                style={{ overflow: "auto" }}
                              >
                                <a
                                  className="dataItem"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    // this.setState({
                                    //   PartnershipDetails: "",
                                    // });
                                    const typeData = [{ id: "partner", value: "Partner" }];
                                    const encodedTypeData = btoa(JSON.stringify(typeData));
                                    window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                    // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                  }
                                  }
                                  style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                >
                                  Click here to Create New Contact
                                </a>
                              </div>
                            }
                            trigger="click"
                            placement="bottomRight"
                            overlayStyle={{ marginTop: "5px" }}
                          >
                            <div
                              style={{
                                width: 0,
                                height: 0,
                                position: "absolute",
                              }}
                            />
                          </AntdPopover>
                          }
                        </>
                      )}

                      {(this.state.InventoryType === "2" ||
                        this.state.InventoryType === "3") && (
                          <>
                            {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                {this.state.InventoryType === "2"
                                  ? "Memo Details"
                                  : "Partnership Details"}
                              </InputLabel>
                              <Input
                                id="component-simple"
                                onChange={(e) => {
                                  this.setState({
                                    MemoDetails:
                                      this.state.InventoryType === "2"
                                        ? e.target.value
                                        : null,
                                    PartnershipDetails:
                                      this.state.InventoryType === "3"
                                        ? e.target.value
                                        : null,
                                  });
                                }}
                              />
                            </FormControl> */}
                            <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                {this.state.InventoryType === "2"
                                  ? "Memo Number"
                                  : "Partnership Number"}
                              </InputLabel>
                              <Input
                                id="component-simple"
                                onChange={(e) => {
                                  this.setState({
                                    updatememo:
                                      this.state.InventoryType === "2"
                                        ? e.target.value
                                        : null,
                                    updatepartnershipnumber:
                                      this.state.InventoryType === "3"
                                        ? e.target.value
                                        : null,
                                  });
                                }}
                              />
                            </FormControl>
                          </>
                        )}
                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                        <InputLabel
                          htmlFor="component-simple"
                          style={{ color: "#424242" }}
                        >
                          INVENTORY STATUS
                        </InputLabel>
                        <Select
                          id="component-simple"
                          onChange={this.onchangeInventoryStatus}
                        >
                          <MenuItem value="1">Inhand</MenuItem>
                          <MenuItem value="2">Incoming</MenuItem>
                          <MenuItem value="3">Out on Memo</MenuItem>
                          <MenuItem value="4">On Hold / Reserved</MenuItem>
                          <MenuItem value="5">Available to Order</MenuItem>
                          <MenuItem value="6">Needs Service / Polish</MenuItem>
                          <MenuItem value="7">At Service</MenuItem>
                          <MenuItem value="8">Need to Ship</MenuItem>
                          <MenuItem value="9">
                            Shipped waiting on payment
                          </MenuItem>
                          <MenuItem value="10">Sold & Cashed Out</MenuItem>
                          <MenuItem value="11">Intake Complete</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"  >
                          <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>INVENTORY LOCATION</InputLabel>
                          <Input id="component-simple"
                          />
                          <FormHelperText className="error-o"></FormHelperText>
                        </FormControl> */}
                      <div>
                        <Input
                          type="text"
                          variant="outline"
                          id="autocomplete1"
                          placeholder="INVENTORY LOCATION"
                          onChange={this.locationAutoComplete}
                          onFocus={this.handleLocationFocus}
                          onBlur={this.handleLocationBlur}
                          value={this.state.locationSearch}
                          className="form-width-70  form-control searchoption boxwidth"
                        />
                        {this.state.locationloading ? (
                          <CircularProgress />
                        ) :
                          this.state.locationAutoCompleteData?.data?.length > 0 ? (
                            <List className="searchlist MuiPaper-elevation1 inventrysearch" style={{ listStyle: "none" }}>
                              {this.state.locationAutoCompleteData.data.map((item, idx) => (
                                <div>
                                  <ListItem
                                    className={cursor === idx ? "active" : ""}
                                    key={idx}
                                    style={{ cursor: "pointer" }}
                                    onMouseDown={() => this.onchangeLocation(item)}
                                  >
                                    <ListItemText
                                      primary={item.locations}
                                      style={{
                                        marginTop: "10px",
                                        marginLeft: "50px",
                                      }}
                                    />
                                  </ListItem>
                                  <Divider
                                    variant="inset"
                                    component="li"
                                    style={{ margin: "5px" }}
                                  />
                                </div>
                              )
                              )}
                            </List>
                          ) : null}
                      </div>
                      <Box
                        component="fieldset"
                        borderColor="transparent"
                        className="padding-0 "
                        style={{ margin: "20px 0px" }}
                      >
                        <InputLabel
                          htmlFor="component-simple"
                          style={{ color: "#424242", marginBottom: "10px" }}
                        >
                          CONDITION
                        </InputLabel>
                        <FormControl className="form-width-70 sliderclass">
                          <Typography
                            style={{
                              textAlign: "right",
                              marginTop: "-20px",
                              visibility: "hidden",
                            }}
                          >
                            {" "}
                            {scale(this.state.Condition)}
                          </Typography>
                          <Slider
                            defaultValue={0}
                            aria-labelledby="discrete-slider-always"
                            valueLabelDisplay="auto"
                            step={1}
                            min={1}
                            marks={followersMarks1}
                            max={4}
                            onChange={this.onchangeCondition}
                            className="MuiOutlinedInput"
                            ariaLabelledBy="non-linear-slider"
                          />
                          <FormHelperText className="error-o">
                            {this.state.onConditionErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                        <InputLabel
                          style={{ color: "#424242", marginBottom: "10px" }}
                        >
                          BAND SIZE
                        </InputLabel>
                        <Input
                          id="component-simple"
                          onChange={this.onchangeBandSize}
                        />
                        <FormHelperText className="error-o">
                          {this.state.onBandSizeErrorText}
                        </FormHelperText>
                      </FormControl>
                      {this.state.viewData &&
                        this.state.viewData?.length === 0 && (
                          <>
                            <div style={{ paddingBottom: "20px" }}>
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel style={{ color: "#424242" }}>
                                  NUMBER OF LINKS / SCREWS
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  onChange={this.onchangeNumberOfLinks}
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onNumberOfLinksErrorText}
                                </FormHelperText>
                              </FormControl>
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  INTERNAL DEALER NOTE
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  multiline
                                  // rows={3}
                                  onChange={this.onchangeDealerNotes}
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onDealerNotesErrorText}
                                </FormHelperText>
                              </FormControl>

                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  PUBLIC DEALER NOTES
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  onChange={this.onchangePublicDealerNote}
                                />
                                <FormHelperText className="error-o"></FormHelperText>
                              </FormControl>
                            </div>
                          </>
                        )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div>
                      {this.state.viewData &&
                        this.state.viewData?.length > 0 && (
                          <>
                            <div style={{ paddingBottom: "20px" }}>
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel style={{ color: "#424242" }}>
                                  NUMBER OF LINKS / SCREWS
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  onChange={this.onchangeNumberOfLinks}
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onNumberOfLinksErrorText}
                                </FormHelperText>
                              </FormControl>
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  INTERNAL DEALER NOTE
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  multiline
                                  // rows={3}
                                  onChange={this.onchangeDealerNotes}
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onDealerNotesErrorText}
                                </FormHelperText>
                              </FormControl>
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  PUBLIC DEALER NOTES
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  onChange={this.onchangePublicDealerNote}
                                />
                                <FormHelperText className="error-o"></FormHelperText>
                              </FormControl>
                            </div>
                          </>
                        )}
                      <div>
                        <Typography className="color-232323">
                          <b>TARGET PRICING DETAILS</b>
                        </Typography>
                      </div>
                      <div className="space-top-15">
                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            TARGET WHOLESALE PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeWholeSalePrice}
                          />
                          <FormHelperText className="error-o">
                            {this.state.onWholeSalePriceErrorText}
                          </FormHelperText>
                        </FormControl>
                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            TARGET END CUSTOMER PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeRetailPrice}
                          />
                          <FormHelperText className="error-o">
                            {this.state.onRetailPriceErrorText}
                          </FormHelperText>
                        </FormControl>
                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            TARGET END CUSTOMER WIRE PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeRetailWirePrice}
                          // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          />
                          <FormHelperText className="error-o">
                            {this.state.onRetailWirePriceErrorText}
                          </FormHelperText>
                        </FormControl>
                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            {" "}
                            TAG PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeTagPrice}
                          />
                          <FormHelperText
                            className="error-o"
                            type="number"
                            pattern="[0-9]*"
                          ></FormHelperText>
                        </FormControl>
                        {/* <InputLabel htmlFor="component-simple" style={{ color: '#424242', marginBottom: '20px', marginTop: '20px' }} >Purchase Invoice</InputLabel>
                        <input accept="image/*" className="input-upload" id="image" name="image" type="file" onChange={this.bulkUploadFile} multiple />
                        <label htmlFor="image">
                          <div className="primary-border bg-color-F5F5F5" style={{ boxShadow: '0 10px 6px -6px #777', width: '200px', marginBottom: '30px' }}>
                            <div className="space-5 text-center1" style={{ width: "100%" }}>
                              <div className="text-center1">
                                <img src={AddIcon} alt="Addicon" style={{ cursor: 'pointer' }} />
                                <Typography variant="body2">Upload Invoice Images  </Typography>
                              </div>
                            </div>
                          </div>
                        </label>
                        <div className="form-group multi-preview" style={{ display: 'block' }}>
                          {(this.state.fileArray || []).map(url => (
                            <img src={url} alt="..." width='200px'
                              height='150px' />
                          ))}
                        </div> */}
                        <Grid item md={6.1} xl={6.1} lg={6.1} sm={12} xs={12}>
                          <div>
                            <InputLabel
                              htmlFor="component-simple"
                              style={{
                                color: "#424242",
                                marginBottom: "20px",
                                float: "left",
                                marginTop: "20px",
                              }}
                            >
                              Upload Invoice Images or File&nbsp;
                            </InputLabel>
                            {/* <Typography component="p" sx={{ mb: 1, color: 'black' }} className='xxs-normal'>Upload Invoice Images and/or videos&nbsp; */}
                            {/* <Tooltip title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/BMP/WEBP and videos MP4/WEBM/OGG/MKV/AVI files, NOT embedded in other document types such as Word or PowerPoint."  style={{position: 'relative', top: 3, cursor: 'pointer'}}> */}
                            <Tooltip
                              title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/PDF/DOC"
                              style={{
                                position: "relative",
                                top: 3,
                                cursor: "pointer",
                              }}
                            >
                              <span>
                                <HelpIcon
                                  fontSize="10px"
                                  style={{
                                    color: "#CEA05F",
                                    marginTop: "14px",
                                  }}
                                />
                              </span>
                            </Tooltip>
                            {/* </Typography> */}
                            <FileUploadGroup
                              onFilesChange={this.handleSelectedFiles}
                            />
                          </div>
                        </Grid>
                        <div>
                          <FormControlLabel
                            className="MuiFormControlLabel-labelPlacement"
                            style={{ color: "#424242" }}
                            control={
                              <Checkbox
                                name="checkedB"
                                className="float-right"
                                onClick={this.handleCheck1}
                              />
                            }
                            label="I have a box"
                          />
                          <br />
                        </div>
                        {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20" style={{ marginTop: "1rem", display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "2rem" }}> */}
                        {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">

<p><FormLabel id="demo-radio-buttons-group-label" style={{ color: '#424242' }}>SALE STATUS</FormLabel></p>
<div  >

  <FormControlLabel
    control={
      <Checkbox
        name="checkedB1"
        value={this.state.notforSale}
        checked={this.state.notforSale}
        className="float-right"
        onChange={this.handleNotforSaleCheck}
      />
    }
    label= "Not for sale"
  />

</div>
</FormControl> */}
                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                          <p>
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{ color: "#424242" }}
                            >
                              TYPE OF PAPER
                            </FormLabel>
                          </p>
                          <div style={{ marginBottom: "8px" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  value="Warranty"
                                  className="float-right"
                                  onChange={this.handleTypeOfPaperCheck}
                                />
                              }
                              label="Warranty"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  className="float-right"
                                  value="Archive"
                                  onChange={this.handleTypeOfPaperCheck}
                                />
                              }
                              label="Archive"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  className="float-right"
                                  value="isService"
                                  onChange={this.handleTypeOfPaperCheck}
                                />
                              }
                              label="Service Card/Paper"
                            />
                            {/* </FormControl> */}
                          </div>
                          {this.state.warrentyExpiryDate && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="color-BCBCBC"
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                // disablePast={true}
                                value={this.state.PurchasedDate1 || null}
                                onChange={(date, e) => {
                                  this.handleDateChange1(date, e, "Warranty");
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "0px",
                                }}
                                // TextFieldComponent={TextFieldComponent}
                                helperText={
                                  this.state.error["Warranty"]
                                    ? "Invalid date"
                                    : ""
                                }
                                error={this.state.error["Warranty"]}
                                placeholder="Warranty - MM/DD/YYYY"
                                onPaste={(event) => event.preventDefault()}
                              />
                            </MuiPickersUtilsProvider>
                          )}
                          {this.state.archivePaperSet && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="color-BCBCBC"
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                // disablePast={true}
                                value={this.state.archiveDate || null}
                                onChange={(date, e) => {
                                  this.handleDateChange1(date, e, "Archive");
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "0px",
                                }}
                                // TextFieldComponent={TextFieldComponent}
                                helperText={
                                  this.state.error["Archive"]
                                    ? "Invalid date"
                                    : ""
                                }
                                error={this.state.error["Archive"]}
                                placeholder="Archive - MM/DD/YYYY"
                              />
                            </MuiPickersUtilsProvider>
                          )}
                          {this.state.isService && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="color-BCBCBC"
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                // disablePast={true}
                                value={this.state.serviceDate || null}
                                onChange={(date, e) => {
                                  this.handleDateChange1(date, e, "Service");
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "0px",
                                }}
                                // TextFieldComponent={TextFieldComponent}
                                helperText={
                                  this.state.error["Service"]
                                    ? "Invalid date"
                                    : ""
                                }
                                error={this.state.error["Service"]}
                                placeholder="Service Card / Paper - MM/DD/YYYY"
                              />
                            </MuiPickersUtilsProvider>
                          )}
                        </FormControl>
                        <FormControl className="form-width-70 ">
                          <p>
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{ color: "#424242" }}
                            >
                              MODIFICATION
                            </FormLabel>
                          </p>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  className="float-right"
                                  value={this.state.ismodified}
                                  onChange={this.handleCheck2}
                                />
                              }
                              label="Modified"
                            />
                          </div>
                          {this.state.ismodified && (
                            <FormControl>
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                MODIFICATION DETAILS
                              </InputLabel>
                              <Input
                                id="component-simple"
                                value={this.state.modification_deatils}
                                onChange={this.onchangeModificationDetails}
                                multiline
                                maxRows={4}
                              />
                              <FormHelperText className="error-o">
                                {this.state.modificationDetailsError}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </FormControl>
                        {this.state.userData &&
                          (this.state.userData.dealerInventorySubscription ===
                            "access_only" ||
                            this.state.userData.dealerInventorySubscription ===
                            "no_subscription") ? null : (
                          <FormControl className="form-width-70 ">
                            <p>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                style={{ color: "#424242" }}
                              >
                                DEALER FEED
                              </FormLabel>
                            </p>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB1"
                                    value={this.state.dealerInv}
                                    checked={this.state.dealerInv}
                                    className="float-right"
                                    onChange={this.handleInclude}
                                  />
                                }
                                label="Include in the Dealer Feed"
                              />
                            </div>
                          </FormControl>
                        )}
                        <div
                          style={{ marginTop: "13px" }}
                          onClick={this.props.handleNext}
                        >
                          <Button
                            type="submit"
                            className="primary-bg color-white text-center1 form-width-70"
                            onClick={this.validateInput}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
            {this.state.limitReachedModalOpen && (
              <LimitReachedComponent
                isOpen={this.state.limitReachedModalOpen}
                onClose={this.closeLimitReachedModal}
                keyword="Product search"
              />
            )}
          </form>
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  };

  render() {
    return (
      <>
        {isActionAllowed(this.state.accessData, "add_inventory") ? (
          this.inventoryPage()
        ) : (
          <AccessRestricted />
        )}
      </>
    );
  }
}

function mapStateToProps({ individualBrowseWatchesInfo, auth, dealer }) {
  localStorage.setItem("menuTitle", "ADD INVENTORY");
  return {
    individualBrowseWatchesInfo:
      individualBrowseWatchesInfo.individualBrowseWatchesInfo,
    currentUser: auth.currentUser,
    autocompleteProducts: dealer.autoCompleteProducts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addInventoryData,
      individualBrowseWatch,
      searchProducts,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerProductInventoryIntakeComponent);
