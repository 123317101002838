
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Container, Paper, FormControlLabel, Switch, Box,
    Typography, FormControl, InputLabel, Select, MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Checkbox,
    Grid,
    Input,
    TextField,
    Divider,
    Chip,
    Tooltip
} from '@material-ui/core'
import { Table, TableBody, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { InputBase } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { SpinnerRoundFilled } from "spinners-react";
import alt from "../../../../assests/images/img-soon.png";
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import {
    fetchDealerWatches,
    brandList,
    filterBySearch,
    inventoryDetailById,
    SearchInventoryWatches,
    fetchInventoryExcelList,
} from "../../dashboard/actions/dashboardAction";
import DealerDetailedCardView from './detailed-view';
import CombinedDealer from './combineddealerView';
import CloseIcon from '@material-ui/icons/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { deleteFilterDetails, getDealerFilterDetails, saveFilterDetails } from '../actions/dealerInventoryaction';
import { removeSpecialChar, showErrorToast, showSuccessToast } from '../../../../common/components/Helper/Utils';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import UpgradePlan from '../../common/components/upgradePlan';
import Loader from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    gridItem: {
        display: 'flex',
        alignItems: 'end',
        columnGap: "15px",
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
    customInput: {
        width: '0 !important',
    },
}));

const inventoryType = [
    {
        id: "standard_watch",
        value: "Standard Watch"
    },
    {
        id: "misc_watch",
        value: "Misc Watch"
    },
    {
        id: "misc_product",
        value: "Misc Product"
    }
]

const condition = [
    {
        id: "ALL",
        value: "New"
    },
    {
        id: "NEW",
        value: "New"
    },
    {
        id: "OLD",
        value: "Pre-Owned"
    },
]

const inventoryStatus = [
    {
        id: "1",
        value: "Inhand"
    },
    {
        id: "5",
        value: "Available to Order"
    },
    {
        id: "Not Specified",
        value: "Not Specified"
    }
]

const columns = [
    { id: 'checkbox', label: '' },
    { id: 'filtername', label: 'Filter Name' },
    { id: 'business_name', label: 'Dealer Name' },
    { id: "inventory_type", label: "Type", minWidth: 100 },
    {
        id: "brand",
        label: "Brand",
        minWidth: 100,
    },
    {
        id: "inventory_status",
        label: "Inventory Status",
        minWidth: 100,
    },
    {
        id: "conditions",
        label: "Condition",
        minWidth: 100,
    },
    {
        id: "dialcolor",
        label: "Dial Color",
        minWidth: 100,
    },
    {
        id: "keyword",
        label: "Keyword",
        minWidth: 100,
    },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const setSessionStorageItem = (key, value) => {
    if (value !== null && value !== undefined) {
        sessionStorage.setItem(key, value);
    }
};

function DealerInventory(props) {
    const history = useHistory();
    const classes = useStyles();
    // const location = useLocation();
    // const { payloadData } = location.state || {};
    const userData = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null;
    const latestDealerFilter = sessionStorage.getItem("dealerFilter") ? JSON.parse(sessionStorage.getItem("dealerFilter")) : null;
    const latestKey = sessionStorage.getItem("dealerFilterKey") ?? null;
    const latestDialColour = sessionStorage.getItem("dealerFilterDial") ?? null;
    const latestDealerName = sessionStorage.getItem("dealerName") ?? null;
    const latestSortByValue = sessionStorage.getItem("sortByValue") ?? null;
    const [dealerFilter, setDealerFilter] = useState({
        brand: latestDealerFilter?.brand || [],
        inventoryStatus: latestDealerFilter?.inventoryStatus || "",
        condition: latestDealerFilter?.condition || 'ALL',
        type: latestDealerFilter?.type || 'standard_watch',
        sort: latestSortByValue || 'newestFirst'
    })
    const [sortOption, setSortOption] = useState(latestSortByValue ?? "newestFirst");
    const [dialColor, setdialColor] = useState(latestDialColour ?? "")
    const [modelkey, setmodelkey] = useState(latestKey ?? "")
    const [loader, setloader] = useState(true);
    const [rowsPerPage, setrowsPerPage] = useState(12);
    const [page, setPage] = useState(0);
    const [dealerlist, setdealerList] = useState([]);
    const [brandInfo, setbrandInfo] = useState([]);
    const [businessName, setBusinessName] = useState(latestDealerName ?? "");
    const [sameItemStatus, setSameItemStatus] = useState(true);
    const [applyFilter, setApplyFilter] = useState(false);
    const [saveFilter, setSaveFilter] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [filterName, setFilterName] = useState("");
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [filterTitle, setFilterTitle] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(100);
    const [noItemFound, setNoItemFound] = useState(null);

    const getOptionStyles = (option) => ({
        cursor: 'pointer',
        paddingBottom: '1px',
        fontSize: '13px',
        borderBottom: sortOption === option ? '2px solid #CEA05F' : '',
        fontWeight: sortOption === option ? 'bold' : '',
    });

    const openApplyFilter = () => {
        setApplyFilter(true);
    };

    const handleFilter = () => {
        setSaveFilter(true);
    };

    const handleClose = () => {
        setApplyFilter(false);
        setSaveFilter(false);
        setFilterName("");
        setSelectedRowIds([]);
    };

    const getValue = useCallback((type, id) => {
        if (type == "inventory_type") {
            const type = inventoryType.find(item => item.id === id);
            return type ? type.value : id;
        }
        else if (type == "conditions") {
            const type = condition.find(item => item.id === id);
            return type ? type.value : id;
        }
        else if (type == "inventory_status") {
            if (id === "Not Specified" || id == null) {
                return "Not Specified";
            }
            const type = inventoryStatus.find(item => item.id === id);
            return type ? type.value : id;
        }
        else {
            return id
        }
    }, []);

    const removeEmptyKeys = (obj) => {
        const filteredEntries = Object.entries(obj).filter(([key, value]) => !(value === '' || value === undefined || (Array.isArray(value) && value.length === 0)) || key === "inventory_status");
        return Object.fromEntries(filteredEntries);
    };

    const handlesameItemCheck = () => {
        setSameItemStatus(!sameItemStatus)
    }

    const DealerWatchFetch = async (reCheckpayload = undefined) => {
        setloader(true);
        setPageNumber(1);
        let searchData = "";
        if (modelkey) {
            searchData = modelkey.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
            searchData = searchData.replaceAll("-", "");
            searchData = searchData.trim();
        }
        else if (latestKey) {
            searchData = latestKey.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
            searchData = searchData.replaceAll("-", "");
            searchData = searchData.trim();
        }
        const payload = {
            brand: dealerFilter.brand,
            key: searchData,
            condition: dealerFilter.condition,
            dial_color: dialColor,
            inventory_status: dealerFilter.inventoryStatus,
            type: dealerFilter.type,
            business_name: dealerFilter.type == 'standard_watch' ? "" : businessName,
            sort: dealerFilter.sort
        }
        const organisedpayload = reCheckpayload ? reCheckpayload : payload
        const clearObj = removeEmptyKeys(organisedpayload)
        setdealerList([]);
        setPage(0);
        // setSortOption("newestFirst");
        if (clearObj.inventory_status == "Not Specified") {
            clearObj.inventory_status = null;
        }
        if (['misc_watch', 'misc_product'].includes(dealerFilter.type)) {
            clearObj.is_group = sameItemStatus.toString()
        }
        if (payload.inventory_status === null) {
            setDealerFilter((prevState) => ({ ...prevState, inventoryStatus: "Not Specified" }))
        }
        // if (clearObj.condition == "ALL") {
        //     clearObj.condition = '';
        // }
        clearObj.page_no = 1;
        clearObj.limit = pageLimit;
        const result = await props.fetchDealerWatches(clearObj);
        setloader(false);
        if (result.data.status === 1) {
            setNoItemFound(result.data.data?.length === 0 ? "No More Items Left" : null);
        } else if (result.data.data?.length === 0) {
            setdealerList([]);
        }
        if (result?.data?.data.length > 0) {
            // const filterValue = result?.data?.data.sort((a, b) => {
            //     if (a?.type == 'misc_watch' || a?.type == 'misc_product') {
            //         if (a.total_cost === null || a.total_cost === undefined) return 1;
            //         if (b.total_cost === null || b.total_cost === undefined) return -1;
            //         return a.total_cost - b.total_cost;
            //     }
            //     else {
            //         if (a.lowest_price === null || a.lowest_price === undefined) return 1;
            //         if (b.lowest_price === null || b.lowest_price === undefined) return -1;
            //         return a.lowest_price - b.lowest_price;
            //     }
            // });
            // setdealerList(filterValue);
            // switch (sortOption) {
            //     case 'lowToHigh':
            //         filterDealerLowToHigh(result?.data?.data);
            //         break;
            //     case 'highToLow':
            //         filterDealerHighToLow(result?.data?.data);
            //         break;
            //     case 'newestFirst':
            //     default:
            //         filterDealerNewestFirst(result?.data?.data);
            //         break;
            // }
            setdealerList(result?.data?.data);
            setSortOption(sortOption);
            sessionStorage.setItem("sortByValue", sortOption);
        }
    }

    const fetchMoreData = () => {
        setPageNumber((prev) => prev + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            let scrollTop = document.documentElement.scrollHeight;
            if (scrollTop >= 800) {
                let searchData = "";
                if (modelkey) {
                    searchData = modelkey.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "").replaceAll("-", "").trim();
                } else if (latestKey) {
                    searchData = latestKey.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "").replaceAll("-", "").trim();
                }
                const payload = {
                    brand: dealerFilter.brand,
                    key: searchData,
                    condition: dealerFilter.condition,
                    dial_color: dialColor,
                    inventory_status: dealerFilter.inventoryStatus,
                    type: dealerFilter.type,
                    business_name: dealerFilter.type === 'standard_watch' ? "" : businessName,
                    sort: dealerFilter.sort
                };
                const organisedPayload = removeEmptyKeys(payload);
                if (organisedPayload.inventory_status === "Not Specified") {
                    organisedPayload.inventory_status = null;
                }
                if (['misc_watch', 'misc_product'].includes(dealerFilter.type)) {
                    organisedPayload.is_group = sameItemStatus.toString();
                }
                if (payload.inventory_status === null) {
                    setDealerFilter((prevState) => ({ ...prevState, inventoryStatus: "Not Specified" }));
                }
                organisedPayload.page_no = pageNumber;
                organisedPayload.limit = pageLimit;
                const res = await props.fetchDealerWatches(organisedPayload);
                if (res.data.status === 1) {
                    setdealerList((prevList) => [...prevList, ...res.data.data]);
                    setNoItemFound(res.data.data?.length === 0 ? "No More Items Left" : null);
                } else if (res.data.data?.length === 0) {
                    setdealerList([]);
                }
            }
        };

        if (pageNumber > 1) {
            fetchData();
        }
    }, [pageNumber]);


    useEffect(() => {
        props.brandList();
        getFilterDetails();
    }, [])

    useEffect(() => {
        setbrandInfo(props.brandInfo?.data)
    }, [props.brandInfo])

    useEffect(() => {
        sessionStorage.setItem("dealerFilter", JSON.stringify(dealerFilter));
        DealerWatchFetch();
    }, [dealerFilter, sameItemStatus])

    useEffect(() => {
        setSessionStorageItem("dealerFilterKey", modelkey);
        setSessionStorageItem("dealerFilterDial", dialColor);
        setSessionStorageItem("dealerName", businessName);
    }, [modelkey, dialColor, businessName])

    const getFilterDetails = () => {
        let payload = {
            userId: userData?.userId
        };
        getDealerFilterDetails(payload, (err, response) => {
            if (response?.data) {
                setFilterData(response?.data);
            }
            if (err) {
                console.log("ERROR", err);
                showErrorToast(err);
            }
        });
    };

    const handleSaveFilter = () => {
        let payload = {
            userId: userData?.userId,
            filterName: filterName,
            inventory_type: dealerFilter.type,
            inventory_status: dealerFilter.inventoryStatus === "Not Specified" ? null : dealerFilter.inventoryStatus,
            brand: dealerFilter.brand,
            conditions: dealerFilter.condition,
            dialcolor: dialColor,
            keyword: modelkey,
            dealer_name: businessName
        };
        const clearObj = removeEmptyKeys(payload)
        saveFilterDetails(clearObj, (err, response) => {
            if (response?.status == 1) {
                handleClose();
                getFilterDetails();
                showSuccessToast("Saved Successfully");
            }
            if (err) {
                console.log("ERROR", err);
                showErrorToast(err);
            }
        });
    };

    const handleCheckboxChange = (rowId) => (event) => {
        setSelectedRowIds((prevSelected) => {
            if (event.target.checked) {
                return [...prevSelected, rowId];
            } else {
                return prevSelected.filter((id) => id !== rowId);
            }
        });
    };

    const handleDeleteConfirm = () => {
        setOpenDeleteModal(true)
    }

    const handleCloseDeleteConfirm = () => {
        setOpenDeleteModal(false);
    }

    const handleDeleteFilter = () => {
        let payload = {
            userId: userData?.userId,
            id: selectedRowIds
        };
        deleteFilterDetails(payload, (err, response) => {
            if (response?.status == 1) {
                setSelectedRowIds([]);
                handleClose();
                handleCloseDeleteConfirm();
                getFilterDetails();
                showSuccessToast("Deleted Successfully");
            }
            if (err) {
                console.log("ERROR", err);
                showErrorToast(err);
            }
        });
    };

    const handleApplyFilter = () => {
        const selectedRow = filterData.find(row => row.id === selectedRowIds?.[0]);
        let val = selectedRow?.inventory_status;
        let inventory_status = (val === "Not Specified" || val === null) ? "Not Specified" : val;
        let obj = {
            brand: selectedRow.brand?.brand ?? [],
            inventoryStatus: inventory_status ?? '',
            condition: selectedRow?.conditions ?? 'ALL',
            type: selectedRow?.inventory_type ?? 'standard_watch'
        }
        sessionStorage.setItem("dealerFilter", JSON.stringify(obj));
        setSessionStorageItem("dealerFilterKey", selectedRow?.keyword);
        setSessionStorageItem("dealerFilterDial", selectedRow?.dialcolor);
        setSessionStorageItem("dealerName", selectedRow?.dealer_name);
        setDealerFilter(obj);
        setmodelkey(selectedRow?.keyword ?? "");
        setdialColor(selectedRow?.dialcolor ?? "");
        setBusinessName(selectedRow?.dealer_name ?? "")
        setFilterTitle(selectedRow?.filtername ?? "");
        // DealerWatchFetch();
        handleClose();
    }

    const handleClearFilter = () => {
        let obj = {
            brand: [],
            inventoryStatus: '',
            condition: 'ALL',
            type: 'standard_watch'
        }
        sessionStorage.setItem("dealerFilter", JSON.stringify(obj));
        sessionStorage.setItem("dealerFilterKey", "");
        sessionStorage.setItem("dealerFilterDial", "");
        sessionStorage.setItem("dealerName", "");
        setDealerFilter(obj);
        setmodelkey("");
        setdialColor("");
        setBusinessName("")
        setFilterTitle("");
    }

    // useEffect(() => {
    //     const updatedFilter = {
    //         brandName: payloadData?.brand_id,
    //         inventoryStatus: payloadData?.inventory_status,
    //         condition: payloadData?.condition,
    //         type: payloadData?.type,
    //     };
    //     if (payloadData && (JSON.stringify(updatedFilter) === JSON.stringify(dealerFilter))) {
    //         setTimeout(() => {
    //             DealerWatchFetch();
    //         }, 2000);
    //     }
    //     else {
    //         DealerWatchFetch();
    //     }
    // }, [dealerFilter]);

    // useEffect(() => {
    //     const updatedFilter = {
    //         brandName: payloadData?.brand_id,
    //         inventoryStatus: payloadData?.inventory_status,
    //         condition: payloadData?.condition,
    //         type: payloadData?.type,
    //     };
    //     if (!payloadData && (JSON.stringify(prevDealerFilter.current) !== JSON.stringify(dealerFilter))) {
    //         DealerWatchFetch();
    //         prevDealerFilter.current = dealerFilter;
    //     }
    //     else if (payloadData && (JSON.stringify(updatedFilter) === JSON.stringify(dealerFilter))) {
    //         DealerWatchFetch();
    //         setIsFiltered(true)
    //     }
    //     else if (isFiltered) {
    //         DealerWatchFetch();
    //     }
    // }, [dealerFilter])


    // useEffect(() => {
    //     if (payloadData) {
    //         setDealerFilter((prevState) => {
    //             const updatedFilter = {
    //                 brandName: payloadData.brand_id,
    //                 inventoryStatus: payloadData.inventory_status,
    //                 condition: payloadData.condition,
    //                 type: payloadData.type,
    //             };
    //             return {
    //                 ...prevState,
    //                 ...updatedFilter,
    //             };
    //         });
    //         setmodelkey((prevState) => payloadData.key || prevState);
    //         setdialColor((prevState) => payloadData.dial_color || prevState);
    //     }
    // }, [payloadData]);

    const handleChangeOfFields = (event) => {
        // if (event.target.name === 'brandName') {
        //     setDealerFilter({ ...dealerFilter, brandName: event.target.value, brandId: event.currentTarget.id })
        // }
        if (event === 'highToLow' || event === 'lowToHigh' || event === 'newestFirst') {
            setDealerFilter({ ...dealerFilter, sort: event });
            setSortOption(event);
            sessionStorage.setItem("sortByValue", event);
        } else {
            setDealerFilter({ ...dealerFilter, [event.target.name]: event.target.value });
        }
    }

    const handleAutoComplete = (event, newValue) => {
        setDealerFilter((prev) => ({ ...prev, brand: newValue }));
    }

    const handleNextPage = (data, inventory_date) => {
        let payloadData = {
            brand: dealerFilter.brand,
            key: modelkey,
            dial_color: dialColor,
            condition: dealerFilter.type == 'standard_watch' ? dealerFilter.condition : data?.conditions,
            inventory_status: dealerFilter.inventoryStatus == 'Not Specified' ? null : dealerFilter.inventoryStatus,
            type: dealerFilter.type,
            accountid: data?.accountid,
            business_name: data?.businessname,
            productid: data?.productid ?? null,
            model_no: data?.model_no ?? "",
            inventory_date: inventory_date,
            sort: dealerFilter.sort
        };
        history.push(`dealerinventory/view`, { payloadData });
    };

    const handleEnterEvent = (event) => {
        if (event.key == 'Enter') {
            DealerWatchFetch()
        }
    }

    const handleChangePage = (event, newpage) => {
        setPage(newpage);
    };

    const filterDealerLowToHigh = (data = dealerlist) => {
        const filterValue = data.sort((a, b) => {
            if (a?.type == 'misc_watch' || a?.type == 'misc_product') {
                if (a.total_cost === null || a.total_cost === undefined) return 1;
                if (b.total_cost === null || b.total_cost === undefined) return -1;
                return a.total_cost - b.total_cost;
            }
            else {
                if (a.lowest_price === null || a.lowest_price === undefined) return 1;
                if (b.lowest_price === null || b.lowest_price === undefined) return -1;
                return a.lowest_price - b.lowest_price;
            }

        });
        setdealerList(filterValue);
        setSortOption("lowToHigh");
        sessionStorage.setItem("sortByValue", "lowToHigh");
    }

    const filterDealerHighToLow = (data = dealerlist) => {
        const filterValue = data.sort((a, b) => {
            if (a?.type == 'misc_watch' || a?.type == 'misc_product') {
                if (a.total_cost === null || a.total_cost === undefined) return 1;
                if (b.total_cost === null || b.total_cost === undefined) return -1;
                return b.total_cost - a.total_cost;
            }
            else {
                if (a.highest_price === null || a.highest_price === undefined) return 1;
                if (b.highest_price === null || b.highest_price === undefined) return -1;
                return b.highest_price - a.highest_price;
            }

        });
        setdealerList(filterValue);
        setSortOption("highToLow");
        sessionStorage.setItem("sortByValue", "highToLow");
    }

    const filterDealerNewestFirst = (data = dealerlist) => {
        const filterDates = data.sort((a, b) => {
            if (a?.type == 'misc_watch' || a?.type == 'misc_product') {
                if (a.createdon === null || a.createdon === undefined) return 1;
                if (b.createdon === null || b.createdon === undefined) return -1;
                return new Date(b.createdon) - new Date(a.createdon);
            }
            else {
                if (a.inventory_date === null || a.inventory_date === undefined) return 1;
                if (b.inventory_date === null || b.inventory_date === undefined) return -1;
                return new Date(b.inventory_date) - new Date(a.inventory_date);
            }

        });
        setdealerList(filterDates);
        setSortOption("newestFirst");
        sessionStorage.setItem("sortByValue", "newestFirst");
    }

    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(+event.target.value);
        setPage(0);
    };

    const removeType = () => {
        setDealerFilter({ ...dealerFilter, type: '' })
    }

    const removeCondition = () => {
        setDealerFilter({ ...dealerFilter, condition: '' })
    }

    const dialColorAdornment = () => {
        const values = dialColor;
        if (values) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => {
                            setdialColor('');
                            let key = modelkey ? removeSpecialChar(modelkey) : "";
                            const payload = {
                                brand: dealerFilter.brand,
                                key: key,
                                condition: dealerFilter.condition,
                                dial_color: "",
                                inventory_status: dealerFilter.inventoryStatus,
                                type: dealerFilter.type,
                                sort: dealerFilter.sort
                            }
                            DealerWatchFetch(payload);
                        }}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={() => DealerWatchFetch()}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        } else {
            return (
                <InputAdornment position="end">
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={() => DealerWatchFetch()}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        }
    };

    const dealernameAdornment = () => {
        const values = businessName;
        if (values) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => {
                            setBusinessName('');
                            let key = modelkey ? removeSpecialChar(modelkey) : "";
                            const payload = {
                                brand: dealerFilter.brand,
                                key: key,
                                condition: dealerFilter.condition,
                                dial_color: dialColor,
                                business_name: "",
                                inventory_status: dealerFilter.inventoryStatus,
                                type: dealerFilter.type,
                                sort: dealerFilter.sort
                            }
                            DealerWatchFetch(payload);

                        }}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={() => DealerWatchFetch()}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        } else {
            return (
                <InputAdornment position="end">
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={() => DealerWatchFetch()}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        }
    };

    const endAdornment = () => {
        const values = modelkey;
        if (values) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => {
                            setmodelkey('')
                            const payload = {
                                brand: dealerFilter.brand,
                                key: "",
                                condition: dealerFilter.condition,
                                dial_color: dialColor,
                                inventory_status: dealerFilter.inventoryStatus,
                                type: dealerFilter.type,
                                sort: dealerFilter.sort,
                            }
                            DealerWatchFetch(payload);
                        }}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={() => DealerWatchFetch()}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        } else {
            return (
                <InputAdornment position="end">
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={() => DealerWatchFetch()}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        }
    };

    // const imageDestructure = (path) => {

    //     if (dealerFilter.type == 'misc_watch' || dealerFilter.type == 'misc_product') {
    //         const image = path.imagepaths ? JSON.parse(path.imagepaths) : null
    //         return image ? image : alt;
    //     }
    //     else {
    //         const parseValue = path.imagepaths ? JSON.parse(path.imagepaths) : null
    //         const imageurl = parseValue?.images ? parseValue.images[0].split(
    //             ","
    //         )[0]
    //             : alt
    //         return imageurl;
    //     }
    // }
    // const onImagesShow = (row) => { };

    const sortedBrandInfo = brandInfo.slice().sort((a, b) => {
        if (a.sortby === null && b.sortby === null) {
            return a.brandname.localeCompare(b.brandname);
        }
        if (a.sortby === null) {
            return 1;
        }
        if (b.sortby === null) {
            return -1;
        }
        return a.sortby - b.sortby;
    });

    const getOptionSelected = (option, value) => option.brandid === value.brandid;

    return (
        (userData.isDealerInventoryExpired || userData.dealerInventorySubscription === 'no_subscription') ?
            <div style={{ marginTop: "25px" }}> <UpgradePlan /> </div> :
            <>
                <Tooltip title="Scroll to top" arrow placement="bottom">
                    <div style={{
                        position: "fixed",
                        bottom: "65px",
                        right: "45px"
                    }}>
                        <ScrollUpButton
                            EasingType="easeInOutBounce"
                            ShowAtPosition={1000}
                            AnimationDuration={500}
                            style={{
                                backgroundColor: '#CEA05F',
                                borderRadius: '50%',
                                padding: "10px",
                                border: "none",
                                fill: "#fff",
                                bottom: "60px"
                            }}
                        />
                    </div>
                </Tooltip>
                <div className="space-bottom-30 someextrahight" style={{ marginTop: '10px' }}>
                    <Container>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <Typography type='title' color='inherit'>
                                    <h1><b>DEALER INVENTORY</b></h1></Typography>
                            </Grid>
                            <Grid item xs={12} md={8} style={{ display: "flex", columnGap: "10px" }} alignItems='center' justifyContent='flex-end'>
                                {filterTitle && (
                                    <div style={{
                                        display: 'flex', alignItems: 'center', padding: "3px 7px",
                                        borderRadius: "5px", border: "1px solid rgba(0, 0, 0, 0.23)"
                                    }}>
                                        <Typography variant="p">
                                            Filter Name - {filterTitle}
                                        </Typography>
                                        <IconButton onClick={handleClearFilter} aria-label="clear filter"
                                            size="small" style={{ marginLeft: '10px' }}>
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                )}
                                <Button variant='contained' className="text-capitalize primary-bg color-white"
                                    onClick={openApplyFilter} endIcon={filterTitle ? <FilterAltIcon /> : <FilterAltOffIcon />}>
                                    Apply Filter
                                </Button>
                                <Button variant='contained' className="text-capitalize primary-bg color-white"
                                    onClick={handleFilter}>Save Filter</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ margin: "-10px" }}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <span
                                                onClick={removeType}
                                                style={{ float: "right" }}
                                            >
                                                <Icon
                                                    className={""
                                                        // dealerFilter.type ? "fa fa-times-circle" : ""
                                                    }
                                                />
                                            </span>
                                            <FormControl variant="outlined" className="formControl">
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label"
                                                    className="font-14"
                                                >
                                                    Type
                                                </InputLabel>
                                                <Select
                                                    style={{ color: "black" }}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    onChange={handleChangeOfFields}
                                                    label="Type"
                                                    name='type'
                                                    value={dealerFilter.type}
                                                >
                                                    <MenuItem value="standard_watch">Standard Watch</MenuItem>
                                                    <MenuItem value="misc_watch">Misc Watch</MenuItem>
                                                    <MenuItem value="misc_product">Misc Product</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <span style={{ float: "right" }} > <Icon className="" /> </span>
                                            {/* <FormControl variant="outlined" className="formControl">
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"
                                        className="font-14"
                                    >
                                        Brand
                                    </InputLabel>
                                    <Select
                                            style={{ color: "black" }}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            onChange={handleChangeOfFields}
                                            label="Brand"
                                            name='brandName'
                                            value={dealerFilter.brandName}
                                        >
                                            <MenuItem value="ALL">All</MenuItem>
                                            {brandInfo &&
                                                brandInfo.map((row) => (
                                                    <MenuItem id={row.brandid} value={row.brandname}>{row.brandname}</MenuItem>
                                                )
                                                )}
                                        </Select>
                                </FormControl> */}
                                            <Autocomplete
                                                multiple
                                                name="brand"
                                                limitTags={1}
                                                options={sortedBrandInfo}
                                                groupBy={(option) => (option.sortby !== null ? 'sorted' : 'unsorted')}
                                                renderGroup={(params) => (
                                                    <React.Fragment key={params.key}>
                                                        {params.group === 'unsorted' && <Divider />}
                                                        {params.children}
                                                    </React.Fragment>
                                                )}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            checked={selected}
                                                        />
                                                        {option.brandname}
                                                    </React.Fragment>
                                                )}
                                                getOptionLabel={(option) => option.brandname}
                                                getOptionSelected={getOptionSelected}
                                                value={dealerFilter.brand}
                                                onChange={handleAutoComplete}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Brand"
                                                        InputLabelProps={{
                                                            className: 'font-14',
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            classes: {
                                                                input: classes.customInput,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <span
                                                // onClick={() => setDealerFilter({ ...dealerFilter, inventoryStatus: '' })}
                                                style={{ float: "right" }}
                                            >
                                                <Icon
                                                    className={""
                                                        // dealerFilter.inventoryStatus ? "fa fa-times-circle" : ""
                                                    }
                                                />
                                            </span>
                                            <FormControl variant="outlined" className="formControl">
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label"
                                                    className="font-14"
                                                >
                                                    Inventory Status
                                                </InputLabel>
                                                <Select
                                                    style={{ color: "black" }}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    onChange={handleChangeOfFields}
                                                    label="Inventory Status"
                                                    name='inventoryStatus'
                                                    defaultValue={dealerFilter.inventoryStatus}
                                                    value={dealerFilter.inventoryStatus}
                                                >
                                                    <MenuItem value="1">Inhand</MenuItem>
                                                    <MenuItem value="2">Incoming</MenuItem>
                                                    <MenuItem value="5">Available to Order</MenuItem>
                                                    <MenuItem value={'Not Specified'}>Not Specified</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <span
                                                onClick={removeCondition}
                                                style={{ float: "right" }}
                                            >
                                                <Icon
                                                    className={
                                                        dealerFilter.condition ? "fa fa-times-circle" : ""
                                                    }
                                                />
                                            </span>
                                            <FormControl variant="outlined" className="formControl">
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label"
                                                    className="font-14"
                                                >
                                                    Condition
                                                </InputLabel>
                                                <Select
                                                    style={{ color: "black" }}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    onChange={handleChangeOfFields}
                                                    label="Condition"
                                                    name='condition'
                                                    value={dealerFilter.condition}
                                                >
                                                    <MenuItem value="ALL">All</MenuItem>
                                                    <MenuItem value="NEW">New</MenuItem>
                                                    <MenuItem value="OLD">Pre-Owned</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={dealerFilter.type == 'misc_watch' || dealerFilter.type == 'misc_product' ? 4 : 6}>
                                        <FormControl
                                            className="form-width-99"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            <div style={{ marginTop: "18px", marginLeft: "0px", marginBottom: "20px" }}>
                                                <Paper
                                                    // component="form"
                                                    sx={{ p: "2px 4px" }}
                                                    style={{ height: "50px", display: "flex" }}
                                                >
                                                    <InputBase
                                                        fullWidth
                                                        sx={{ ml: 3, flex: 1 }}
                                                        inputProps={{
                                                            "aria-label": "Enter Dial Color",
                                                        }}
                                                        placeholder="Enter Dial Color"
                                                        defaultValue={dialColor}
                                                        onChange={(e) => setdialColor(e.target.value)}
                                                        onKeyPress={handleEnterEvent}
                                                        value={dialColor}
                                                        endAdornment={dialColorAdornment()}
                                                    />
                                                </Paper>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={dealerFilter.type == 'misc_watch' || dealerFilter.type == 'misc_product' ? 4 : 6}>
                                        <FormControl
                                            className="form-width-99"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            <div style={{ marginTop: dealerFilter.type == 'misc_watch' || dealerFilter.type == 'misc_product' ? "4.4%" : "3%", marginBottom: "20px" }}>
                                                <Paper
                                                    // component="form"
                                                    sx={{ p: "2px 4px" }}
                                                    style={{ height: "50px", display: "flex" }}
                                                >
                                                    <InputBase
                                                        fullWidth
                                                        sx={{ ml: 3, flex: 1 }}
                                                        inputProps={{
                                                            "aria-label": "Enter Model Number / Keyword",
                                                        }}
                                                        placeholder="Enter Model Number / Keyword"
                                                        name='keyword'
                                                        onChange={(e) => setmodelkey(e.target.value)}
                                                        onKeyPress={handleEnterEvent}
                                                        value={modelkey}
                                                        endAdornment={endAdornment()}
                                                    />
                                                </Paper>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    {dealerFilter.type == 'misc_watch' || dealerFilter.type == 'misc_product' ?

                                        <Grid item xs={12} md={4}>
                                            <FormControl
                                                className="form-width-99"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <div style={{ marginTop: "18px", marginLeft: "0px", marginBottom: "20px" }}>
                                                    <Paper
                                                        // component="form"
                                                        sx={{ p: "2px 4px" }}
                                                        style={{ height: "50px", display: "flex" }}
                                                    >
                                                        <InputBase
                                                            fullWidth
                                                            sx={{ ml: 3, flex: 1 }}
                                                            inputProps={{
                                                                "aria-label": "Enter Dealer Name",
                                                            }}
                                                            placeholder="Enter Dealer Name"
                                                            defaultValue={businessName}
                                                            onChange={(e) => { setBusinessName(e.target.value) }}
                                                            onKeyPress={handleEnterEvent}
                                                            value={businessName}
                                                            endAdornment={dealernameAdornment()}
                                                        />
                                                    </Paper>
                                                </div>
                                            </FormControl>
                                        </Grid>

                                        : null}

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ marginBottom: '3%' }}>
                            <Grid container spacing={2}>
                                {/* {dealerFilter.type == 'misc_watch' || dealerFilter.type == 'misc_product' ?
                            <Grid item xs={12} md={6}>
                                <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "2rem" }}>
                                    <Typography style={{ fontSize: '12px !important', fontWeight: 'bold !important' }}>Show Multiples of Same Items</Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={sameItemStatus}
                                                onChange={handlesameItemCheck}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid> : <Grid item xs={12} md={6}></Grid>} */}
                                <Grid item xs={12} className={classes.gridItem}>
                                    <Grid mx={3} style={{ fontWeight: 'bold' }}>Sort by</Grid>
                                    <Grid mx={3} style={getOptionStyles("newestFirst")} onClick={() => handleChangeOfFields('newestFirst')}>Newest First</Grid>
                                    <Grid mx={3} style={getOptionStyles("lowToHigh")} onClick={() => handleChangeOfFields('lowToHigh')}>Price - Low to High</Grid>
                                    <Grid mx={3} style={getOptionStyles("highToLow")} onClick={() => handleChangeOfFields('highToLow')}>Price - High to Low</Grid>
                                </Grid>
                            </Grid>
                            <div
                                style={{
                                    border: "0.2px solid #ECDBC2",
                                    margin: "10px auto",
                                    width: "100%",
                                }}
                            ></div>
                        </Grid>
                        {loader ? (
                            <div className="d-spinner">
                                <SpinnerRoundFilled
                                    size={51}
                                    thickness={125}
                                    speed={80}
                                    color="rgba(57, 107, 172, 1)"
                                    className="spp"
                                    enabled={loader}
                                />
                            </div>
                        ) : (
                            <InfiniteScroll
                                style={{ overflow: "hidden" }}
                                dataLength={dealerlist.length ?? 0}
                                next={() => fetchMoreData()}
                                hasMore={noItemFound ? false : true}
                                loader={
                                    <div style={{ margin: "25px auto", width: "100px" }}>
                                        <Loader
                                            type="Puff"
                                            color="#9F7C50"
                                            height={50}
                                            width={50}
                                        />
                                        <div style={{ marginLeft: "-10px" }}>
                                            <h4 className="color-p">Loading....</h4>
                                        </div>
                                    </div>
                                }
                            >
                                <Grid container spacing={2}>
                                    {dealerlist && dealerlist.length > 0 && (
                                        dealerlist &&
                                        // dealerlist
                                        //     .slice(
                                        //         page * rowsPerPage,
                                        //         page * rowsPerPage +
                                        //         rowsPerPage
                                        //     )
                                        dealerlist.map((row) => {
                                            return (
                                                <>
                                                    {(row.type === 'misc_product' || row.type === 'misc_watch')
                                                        ? <DealerDetailedCardView item={row} />
                                                        : <CombinedDealer item={row} handleNextPage={handleNextPage} type={dealerFilter.type} page={1} />
                                                    }

                                                </>
                                            );
                                        })
                                    )}
                                </Grid>
                            </InfiniteScroll>
                        )}
                        {dealerlist?.length === 0 && !loader && noItemFound &&
                            <Box
                                component="span"
                                sx={{
                                    display: "block",
                                    border: "2px dashed grey",
                                    width: "100%",
                                    padding: "50px 72px",
                                    // marginTop: "20px",
                                }}
                            >
                                {" "}
                                <Container>
                                    <Typography
                                        variant="h6"
                                        className="color-BCBCBC"
                                        component="p"
                                        align="center"
                                    >
                                        <b>No Records Found</b>
                                    </Typography>
                                </Container>
                            </Box>
                        }
                        {/* {dealerlist && dealerlist.length > 0 &&
                    <TablePagination
                        rowsPerPageOptions={[12, 36, 60, 100]}
                        component="div"
                        count={dealerlist.length}
                        SelectProps={{
                            native: true
                        }}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />} */}

                        <Dialog
                            open={applyFilter}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="lg"
                        >
                            <DialogTitle id="alert-dialog-title">APPLY FILTER
                                <IconButton onClick={() => { handleClose() }} style={{ float: 'right' }} className="noprint">
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent >
                                <DialogContentText id="alert-dialog-description">
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <StyledTableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filterData?.length ? (
                                                    <>
                                                        {filterData?.map((row, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={row?.code}
                                                                >
                                                                    {columns.map((column) => {
                                                                        if (column.id === 'checkbox') {
                                                                            return (
                                                                                <TableCell key={row.id}>
                                                                                    <Checkbox color="primary"
                                                                                        checked={selectedRowIds.includes(row.id)}
                                                                                        onChange={handleCheckboxChange(row.id)} />
                                                                                </TableCell>
                                                                            );
                                                                        }
                                                                        let value = row[column.id];
                                                                        if (column.id === 'brand') {
                                                                            value = value?.brand ? value?.brand.map((brand) => brand.brandname).join(', ') : "";
                                                                        } else {
                                                                            value = getValue(column.id, value);
                                                                        }
                                                                        return (
                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                            >
                                                                                {value}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}{" "}
                                                    </>
                                                ) : (
                                                    <TableCell colspan={12} style={{ textAlign: "center" }}>
                                                        <Typography variant="h6" className="color-BCBCBC">
                                                            No Record Found
                                                        </Typography>
                                                    </TableCell>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="error"
                                    disabled={selectedRowIds?.length === 0} onClick={handleDeleteConfirm}>
                                    Delete
                                </Button>
                                <Button className="primary-bg color-white"
                                    variant="contained" color="primary"
                                    disabled={selectedRowIds?.length === 0 || selectedRowIds?.length > 1}
                                    style={{
                                        opacity: (selectedRowIds?.length === 0 || selectedRowIds?.length > 1) ? 0.4 : 1,
                                        marginRight: "25px"
                                    }}
                                    onClick={handleApplyFilter}>
                                    Apply
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={saveFilter}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="md"
                        >
                            <DialogTitle id="alert-dialog-title">ADD FILTER
                                <IconButton onClick={() => { handleClose() }} style={{ float: 'right' }} className="noprint">
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent >
                                <DialogContentText id="alert-dialog-description">
                                    <FormControl style={{ width: '400px', margin: '20px 0px' }}>
                                        {/* <TextField
                                    id="outlined-basic"
                                    label="Enter Filter Name"
                                    variant="standard"
                                    value={filterName}
                                    onChange={(e) => {
                                        setFilterName(e.target.value);
                                    }}
                                /> */}
                                        <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>ENTER FILTER NAME</InputLabel>
                                        <Input id="component-simple"
                                            value={filterName}
                                            onChange={(e) => {
                                                setFilterName(e.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="inherit" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button className="primary-bg color-white"
                                    style={{ opacity: !filterName ? 0.4 : 1 }}
                                    variant="contained" color="primary"
                                    disabled={!filterName}
                                    onClick={handleSaveFilter}>
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openDeleteModal}
                            onClose={handleCloseDeleteConfirm}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth={true}
                            maxWidth={'sm'}
                        >
                            <DialogTitle >
                                Delete Filter
                                <IconButton onClick={() => { handleCloseDeleteConfirm() }} style={{ float: 'right' }}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <div> Are you sure you want to <b>Delete</b>? </div>
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" autoFocus onClick={handleCloseDeleteConfirm}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={handleDeleteFilter}>Ok</Button>
                            </DialogActions>
                        </Dialog>

                    </Container >
                </div>
            </>
    )
}

function mapStateToProps(state) {
    const { InventoryList, auth, browseWatchesInfo, inventoryDetailByIdInfo } =
        state;
    return {
        InventoryList: InventoryList.InventoryList,
        currentUser: auth.currentUser,
        brandInfo: browseWatchesInfo.brandInfo,
        inventoryXlIdInfo: InventoryList.inventoryXlIdInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchDealerWatches,
            filterBySearch,
            brandList,
            inventoryDetailById,
            fetchInventoryExcelList,
            SearchInventoryWatches,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(DealerInventory);


