import React, { useEffect } from "react";
import {
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import watchlogo from "../../assests/images/watch-ops-logo.png";
import logo from "../../assests/images/logo.png";
import icon from "../../assests/images/Group 1332.png";
import DashboardIcon from "../../assests/images/dashboard/Group 1919.svg";
import ProfileIcon from "../../assests/images/dashboard/Group 1912.svg";
import InventoryIcon from "../../assests/images/dashboard/Group 1899.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withRouter } from "react-router-dom";
import AdministrationIcon from "@mui/icons-material/SupervisorAccount";
import MyProfile from "@mui/icons-material/AccountCircleOutlined";
import BulkUpload from "@mui/icons-material/UploadFileOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { Link as RouterLink } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
const themeImpresari = createMuiTheme({
  palette: {
    primary: {
      light: "#CAAB74",
      main: "#CEA05F",
      dark: "#CAAB74",
      contrastText: "#fff",
    },
    secondary: {
      light: "#CAAB74",
      main: "#383838",
      dark: "#CAAB74",
      contrastText: "#fff",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    marginTop: "10px",

    marginRight: "10px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
    },
  },
  headerOptions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    minHeight: "40px",
  },
  alighRight: {
    textAlign: "right",
  },
}));
let menuItemsdata = [
  {
    menuTitle: "SEARCH",
    pageURL: "/search",
    isPublic: false,
  },
  {
    menuTitle: "ADD INVENTORY",
    pageURL: "/create-inventory",
    isPublic: false,
  },
  {
    menuTitle: "MY INVENTORY",
    pageURL: "/myinventory",
    isPublic: false,
  },
  {
    menuTitle: "DEALER INVENTORY",
    pageURL: "/dealerinventory",
    isPublic: false,
  },
  {
    menuTitle: "INVOICES",
    pageURL: "/invoices",
    isPublic: false,
  },
  // {
  //Comment on Jan 25
  //   menuTitle: "SUBSCRIPTION",
  //   pageURL: "/subscription",
  //   isPublic: true,
  // },
  {
    menuTitle: "LOGIN",
    pageURL: "/login",
    isPublic: true,
  },
];

const Header = (props) => {
  const activeStyle = { color: "red" };
  const { history, currentUser, logoutAction } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Active, setActive] = React.useState(false);

  const [menuItems, setmenuItems] = React.useState(menuItemsdata);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [userData, setUserData] = React.useState(
    window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null
  );

  useEffect(() => {
    setUserData(
      window.sessionStorage.getItem("userDetails")
        ? JSON.parse(window.sessionStorage.getItem("userDetails"))
        : null
    );
  }, [window.sessionStorage.getItem("userDetails")]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (menu) => {
    const userDetails = window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null;
    // if(userDetails && !userDetails?.membertierid){
    //   return
    // }else{
    if (menu.menuTitle == "HOME") {
      window.location.href = "http://www.watchops.com/";
    } else {
      history.push(menu.pageURL);
    }
    setActive(menu.menuTitle);
    localStorage.setItem("menuTitle", menu.menuTitle);
  };

  const handleMobileMenuClick = (menu) => {
    console.log("menu", menu);
    history.push(menu.pageURL);
    setActive(menu.menuTitle);
    localStorage.setItem("menuTitle", menu.menuTitle);
  };

  const handleButtonClick = (pageURL) => {
    history.push(pageURL);
  };

  const logout = () => {
    window.sessionStorage.removeItem("userDetails");
    window.sessionStorage.removeItem("sortByValue");
    window.sessionStorage.removeItem("dealerFilter");
    window.sessionStorage.removeItem("dealerFilterKey");
    window.sessionStorage.removeItem("dealerFilterDial");
    window.sessionStorage.removeItem("dealerName");
    logoutAction(null);
    // history.push("https://watchops.com/");
    localStorage.removeItem("UserBusinessDetails");
    localStorage.removeItem("UserId");
    history.push("/login");
  };
  const jump_to = (link) => {
    history.push(link);
  };

  useEffect(() => {
    //Comment on Jan 25
    // if (userData) {
    //   if(userData.membertierId === 11){
    //     const menuItemsdata = [
    //       {
    //         menuTitle: "SEARCH",
    //         pageURL: "/search",
    //         isPublic: false,
    //       },
    //       // {
    //       //   menuTitle: "SUBSCRIPTION",
    //       //   pageURL: "/dealersubscribtions",
    //       //   isPublic: true,
    //       // },
    //       {
    //         menuTitle: "LOGIN",
    //         pageURL: "/login",
    //         isPublic: true,
    //       },

    //     ];
    //     setmenuItems(menuItemsdata);

    //   }else if(userData.membertierId === 12){
    //     const menuItemsdata = [
    //       {
    //         menuTitle: "SEARCH",
    //         pageURL: "/search",
    //         isPublic: false,
    //       },
    //       {
    //         menuTitle: "ADD INVENTORY",
    //         pageURL: "/create-inventory",
    //         isPublic: false,
    //       },
    //       {
    //         menuTitle: "MY INVENTORY",
    //         pageURL:"/myinventory",
    //         isPublic: false,
    //       },
    //       // {
    //       //   menuTitle: "SUBSCRIPTION",
    //       //   pageURL: "/subscription",
    //       //   isPublic: true,
    //       // },
    //       {
    //         menuTitle: "LOGIN",
    //         pageURL: "/login",
    //         isPublic: true,
    //       },

    //     ];
    //     setmenuItems(menuItemsdata);

    //   }else if ((userData.membertierId === 13 || userData.membertierId === 14) ){

    //     if( (userData.roleId === 7 || userData.roleId === 8)){
    //       const menuItemsdata = [
    //         {
    //           menuTitle: "SEARCH",
    //           pageURL: "/search",
    //           isPublic: false,
    //         },
    //         {
    //           menuTitle: "ADD INVENTORY",
    //           pageURL: "/create-inventory",
    //           isPublic: false,
    //         },
    //         {
    //           menuTitle: "MY INVENTORY",
    //           pageURL:"/myinventory",
    //           isPublic: false,
    //         },
    //         {
    //           menuTitle: "INVOICES",
    //           pageURL: "/invoices",
    //           isPublic: false,
    //         },
    //         // {
    //         //   menuTitle: "SUBSCRIPTION",
    //         //   pageURL: "/subscribtion",
    //         //   isPublic: true,
    //         // },
    //         {
    //           menuTitle: "LOGIN",
    //           pageURL: "/login",
    //           isPublic: true,
    //         },

    //       ];
    //       setmenuItems(menuItemsdata);
    //     }

    //   }else{
    //     const menuItemsdata = [
    //       {
    //         menuTitle: "SEARCH",
    //         pageURL: "/search",
    //         isPublic: false,
    //       },
    //       {
    //         menuTitle: "ADD INVENTORY",
    //         pageURL: "/create-inventory",
    //         isPublic: false,
    //       },
    //       //temporarly added
    //       {
    //         menuTitle: "MY INVENTORY",
    //         pageURL:"/myinventory",
    //         isPublic: false,
    //       },
    //        //temporarly added
    //       {
    //         menuTitle: "INVOICES",
    //         pageURL: "/invoices",
    //         isPublic: false,
    //       },
    //       // {
    //       //   menuTitle: "SUBSCRIPTION",
    //       //   pageURL: "/subscription",
    //       //   isPublic: true,
    //       // },
    //       // {
    //       //   menuTitle: "MY DEALER INVENTORY",
    //       //   pageURL: `/privatedealerfeed`,
    //       //   isPublic: true,
    //       // },
    //       {
    //         menuTitle: "LOGIN",
    //         pageURL: "/login",
    //         isPublic: true,
    //       },

    //     ];
    //     setmenuItems(menuItemsdata);
    //   }

    // }
    if (userData) {
      if (userData && (userData.roleId === 7 || userData.roleId === 9)) {
        if (
          userData.dealerInventorySubscription === "no_subscription" ||
          userData.dealerInventorySubscription === null
        ) {
          let menuItemsdata = [
            {
              menuTitle: "SEARCH",
              pageURL: "/search",
              isPublic: false,
            },
            {
              menuTitle: "MY INVENTORY",
              pageURL: "/myinventory",
              isPublic: false,
            },
            {
              menuTitle: "INVOICES",
              pageURL: "/invoices",
              isPublic: false,
            },
            {
              menuTitle: "LOGIN",
              pageURL: "/login",
              isPublic: true,
            },
          ];
          setmenuItems(menuItemsdata);
        } else {
          let menuItemsdata = [
            {
              menuTitle: "SEARCH",
              pageURL: "/search",
              isPublic: false,
            },
            {
              menuTitle: "MY INVENTORY",
              pageURL: "/myinventory",
              isPublic: false,
            },
            {
              menuTitle: "DEALER INVENTORY",
              pageURL: "/dealerinventory",
              isPublic: false,
            },
            {
              menuTitle: "INVOICES",
              pageURL: "/invoices",
              isPublic: false,
            },
            {
              menuTitle: "LOGIN",
              pageURL: "/login",
              isPublic: true,
            },
          ];
          setmenuItems(menuItemsdata);
        }
      }
      if (userData && userData.roleId === 8) {
        if (
          userData.dealerInventorySubscription === "no_subscription" ||
          userData.dealerInventorySubscription === null
        ) {
          let menuItemsdata = [
            {
              menuTitle: "SEARCH",
              pageURL: "/search",
              isPublic: false,
            },
            {
              menuTitle: "MY INVENTORY",
              pageURL: "/myinventory",
              isPublic: false,
            },
            {
              menuTitle: "INVOICES",
              pageURL: "/invoices",
              isPublic: false,
            },
            {
              menuTitle: "LOGIN",
              pageURL: "/login",
              isPublic: true,
            },
          ];
          setmenuItems(menuItemsdata);
        } else {
          let menuItemsdata = [
            {
              menuTitle: "SEARCH",
              pageURL: "/search",
              isPublic: false,
            },
            {
              menuTitle: "MY INVENTORY",
              pageURL: "/myinventory",
              isPublic: false,
            },
            {
              menuTitle: "DEALER INVENTORY",
              pageURL: "/dealerinventory",
              isPublic: false,
            },
            {
              menuTitle: "INVOICES",
              pageURL: "/invoices",
              isPublic: false,
            },
            {
              menuTitle: "LOGIN",
              pageURL: "/login",
              isPublic: true,
            },
          ];
          setmenuItems(menuItemsdata);
        }
      }
      if (
        userData.dealerInventorySubscription === "no_subscription" ||
        userData.dealerInventorySubscription === null
      ) {
        let menuItemsdata = [
          {
            menuTitle: "SEARCH",
            pageURL: "/search",
            isPublic: false,
          },
          {
            menuTitle: "ADD INVENTORY",
            pageURL: "/create-inventory",
            isPublic: false,
          },
          {
            menuTitle: "MY INVENTORY",
            pageURL: "/myinventory",
            isPublic: false,
          },
          {
            menuTitle: "INVOICES",
            pageURL: "/invoices",
            isPublic: false,
          },
          {
            menuTitle: "LOGIN",
            pageURL: "/login",
            isPublic: true,
          },
        ];
        setmenuItems(menuItemsdata);
      }
    } else if (!userData) {
      const filterItem = [
        {
          menuTitle: "HOME",
          pageURL: "www.watchops.com",
          isPublic: true,
        },
        // {
        // Comment on Jan 25
        //   menuTitle: "SUBSCRIPTION",
        //   pageURL: "/subscription",
        //   isPublic: true,
        // },
        {
          menuTitle: "CONTACT US",
          pageURL: "/contactus",
          isPublic: true,
        },
        {
          menuTitle: "LOGIN",
          pageURL: "/login",
          isPublic: true,
        },
      ];
      setmenuItems(filterItem);
    }
  }, [userData]);

  const loginMenu = {
    menuTitle: "LOGIN",
    pageURL: "/login",
    isPublic: true,
  };
  return (
    <ThemeProvider theme={themeImpresari}>
      <div className={classes.root}>
        <AppBar position="static" color="secondary">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <RouterLink
                to="/search"
              // style={{
              //   ':hover': {
              //     backgroundColor: "transparent !important",
              //   },
              // }}
              >
                <img
                  className={classes.logo}
                  src={logo}
                  alt="Logo"
                  style={{ display: "block" }}
                />
              </RouterLink>
            </Typography>

            {isMobile ? (
              <>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenu}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  className="width-100"
                >
                  {menuItems.map((menuItem) => {
                    const { menuTitle, isPublic } = menuItem;
                    return (
                      <div
                        style={{
                          backgroundColor: "#383838",
                          borderBottom: "1px solid #545454",
                        }}
                        className="topnav"
                      >
                        <MenuItem style={{ fontWeight: "5px" }}>
                          {menuTitle === "LOGIN" ? (
                            <div>
                              {!currentUser ? (
                                <div>
                                  <Link
                                    size="large"
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      jump_to("/login");
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <img src={icon} alt="user" width="20px" />
                                    &nbsp;&nbsp;LOGIN
                                  </Link>
                                </div>
                              ) : (
                                <div>
                                  <Link
                                    aria-controls="simple-menu"
                                    className={
                                      currentUser &&
                                        currentUser.firstName === menuTitle
                                        ? "color-CEA05F fontw-500"
                                        : "text-white"
                                    }
                                    aria-haspopup="true"
                                    // onClick={handleClick1}
                                    style={{ display: "flex", color: "" }}
                                  >
                                    <img
                                      src={
                                        (currentUser &&
                                          currentUser.profilePic) ||
                                        icon
                                      }
                                      alt="user"
                                      width="20px"
                                      style={{
                                        display: "inline-block",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                      }}
                                    />
                                    <span
                                      className="space-left-10"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {currentUser != undefined
                                        ? currentUser.firstName +
                                        " " +
                                        currentUser.lastName
                                        : "LOGIN"}
                                    </span>
                                    <ArrowDropDownIcon />
                                  </Link>
                                  <MenuItem
                                    className="mobileMenu topnav"
                                    style={{
                                      borderBottom: "0.1px solid #524e4e",
                                      marginTop: "4px",
                                    }}
                                  >
                                    <Link
                                      className="text-white"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        history.push({
                                          pathname: "/administration",
                                        });
                                      }}
                                    >
                                      <AdministrationIcon />
                                      &nbsp;Administration
                                    </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className="mobileMenu topnav"
                                    style={{
                                      borderBottom: "0.1px solid #524e4e",
                                      marginTop: "4px",
                                    }}
                                  >
                                    <Link
                                      className="text-white"
                                      // href="/dashboard"
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        // jump_to("dashboard");
                                        history.push({
                                          pathname: "/dashboard",
                                        });
                                      }}
                                    >
                                      <i
                                        class="fa fa-dashboard"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      &nbsp;Dashboard
                                    </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className="mobileMenu topnav"
                                    style={{
                                      borderBottom: "0.1px solid #524e4e",
                                      marginTop: "4px",
                                    }}
                                  >
                                    <Link
                                      className="text-white"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      // href="/dashboard"
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        // jump_to("dashboard");
                                        localStorage.removeItem("menuTitle");
                                        history.push({
                                          pathname: "/myprofile",
                                        });
                                      }}
                                    >
                                      <MyProfile />
                                      &nbsp;My Profile
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className="mobileMenu topnav"
                                    style={{
                                      borderBottom: "0.1px solid #524e4e",
                                      marginTop: "4px",
                                    }}
                                  >
                                    <Link
                                      className="text-white"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        localStorage.removeItem("menuTitle");
                                        history.push({
                                          pathname: "/contactmanagement",
                                        });
                                      }}
                                    >
                                      <ContactPageOutlinedIcon />
                                      &nbsp;Contact Management
                                    </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className="mobileMenu topnav"
                                    style={{
                                      borderBottom: "0.1px solid #524e4e",
                                      marginTop: "4px",
                                    }}
                                  >
                                    <Link
                                      className="text-white"
                                      style={{display:'flex',alignItems:'center'}}
                                      // href="/dashboard"
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        // jump_to("dashboard");
                                        localStorage.removeItem("menuTitle")
                                        history.push({
                                              pathname: "/bulk-upload",
                                            });
                                      }}
                                    >
                                      <BulkUpload />{" "}
                                      &nbsp;Bulk Upload
                                    </Link>
                                  </MenuItem> */}

                                  <MenuItem className="mobileMenu topnav">
                                    <Link
                                      className="text-white"
                                      onClick={() => {
                                        localStorage.removeItem("menuTitle");
                                        logout();
                                      }}
                                      href="javascript:void(0);"
                                    >
                                      <i
                                        class="fa fa-sign-out"
                                        aria-hidden="true"
                                        style={{ paddingLeft: "4px" }}
                                      ></i>
                                      &nbsp; Sign Out
                                    </Link>
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <Link
                                color="inherit"
                                size="large"
                                href="javascript:void(0);"
                                className={
                                  localStorage.getItem("menuTitle") ===
                                    menuTitle
                                    ? "color-CEA05F fontw-500"
                                    : "text-white"
                                }
                                onClick={() =>
                                  handleMenuClick(
                                    isPublic || currentUser
                                      ? menuItem
                                      : loginMenu
                                  )
                                }
                              >
                                {menuTitle}
                              </Link>
                            </div>
                          )}
                        </MenuItem>
                      </div>
                    );
                  })}
                </Menu>
              </>
            ) : (
              <div className={classes.headerOptions}>
                {menuItems.map((menuItem) => {
                  const { menuTitle, isPublic } = menuItem;

                  return (
                    <div>
                      {menuTitle === "LOGIN" ? (
                        <div>
                          {!currentUser ? (
                            <div>
                              <Link
                                size="large"
                                href="javascript:void(0);"
                                onClick={() => {
                                  jump_to("/login");
                                }}
                                style={{
                                  display: "flex",
                                  color: "",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={icon}
                                  alt="user"
                                  style={{
                                    display: "inline-block",
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                />
                                &nbsp;&nbsp;LOGIN
                              </Link>
                            </div>
                          ) : (
                            <div>
                              <Link
                                aria-controls="simple-menu"
                                className={
                                  currentUser &&
                                    currentUser.firstName === menuTitle
                                    ? "color-CEA05F fontw-500"
                                    : "text-white"
                                }
                                aria-haspopup="true"
                                onClick={handleClick}
                                style={{
                                  display: "flex",
                                  color: "",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={currentUser?.profilePic || icon}
                                  alt="user"
                                  style={{
                                    display: "inline-block",
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                />
                                <span
                                  className="space-left-10"
                                  style={{ cursor: "pointer" }}
                                >
                                  {currentUser.firstName || currentUser.lastName
                                    ? currentUser.firstName +
                                    " " +
                                    currentUser.lastName
                                    : "LOGIN"}
                                </span>
                                <ArrowDropDownIcon />
                              </Link>

                              <Menu
                                className="menu1"
                                style={{ top: "35px", left: "10px" }}
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem>
                                  <Link
                                    className="color-383838"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      history.push({
                                        pathname: "/administration",
                                      });
                                    }}
                                  >
                                    <AdministrationIcon
                                      sx={{ width: "20px" }}
                                    ></AdministrationIcon>{" "}
                                    &nbsp;Administration
                                  </Link>
                                </MenuItem>

                                {/* <MenuItem>
                                  <Link
                                    className="color-383838"
                                    // href="/dashboard"
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      // jump_to("dashboard");
                                      history.push({ pathname: "/dashboard" });
                                    }}
                                  >
                                    <img
                                      src={DashboardIcon}
                                      alt="DashboardIcon"
                                      width="20px"
                                    />{" "}
                                    &nbsp;Dashboard
                                  </Link>
                                </MenuItem> */}
                                <MenuItem>
                                  <Link
                                    className="color-383838"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    // href="/dashboard"
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      // jump_to("dashboard");
                                      localStorage.removeItem("menuTitle");
                                      history.push({
                                        pathname: "/myprofile",
                                      });
                                    }}
                                  >
                                    <MyProfile />
                                    &nbsp;My Profile
                                  </Link>
                                </MenuItem>
                                {[2, 6, 3].includes(userData?.roleId) ? (
                                  <MenuItem>
                                    <Link
                                      className="color-383838"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      // href="/dashboard"
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        localStorage.removeItem("menuTitle");
                                        history.push({
                                          pathname: "/dashboard",
                                        });
                                      }}
                                    >
                                      <GridViewIcon />
                                      &nbsp;Dashboard
                                    </Link>
                                  </MenuItem>
                                ) : null}
                                <MenuItem>
                                  <Link
                                    className="color-383838"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      localStorage.removeItem("menuTitle");
                                      history.push({
                                        pathname: "/contactmanagement",
                                      });
                                    }}
                                  >
                                    <ContactPageOutlinedIcon />
                                    &nbsp;Contact Management
                                  </Link>
                                </MenuItem>
                                {userData?.accountdetails?.length ? (
                                  <>
                                    <hr />
                                    {userData?.accountdetails?.map(
                                      (account, index) => {
                                        return (
                                          <MenuItem
                                            onClick={() => {
                                              if (
                                                window.sessionStorage.getItem(
                                                  "accountId"
                                                ) !== account.accountid
                                              ) {
                                                window.sessionStorage.setItem(
                                                  "accountId",
                                                  account.accountid
                                                );
                                                window.location.reload();
                                              }
                                            }}
                                          >
                                            <Link
                                              className="color-383838"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              href="javascript:void(0);"
                                            >
                                              {account.accountid ===
                                                window.sessionStorage.getItem(
                                                  "accountId"
                                                ) ? (
                                                <span
                                                  style={{
                                                    height: "10px",
                                                    width: "10px",
                                                    borderRadius: "50%",
                                                    backgroundColor: "green",
                                                    marginLeft: "5px",
                                                  }}
                                                ></span>
                                              ) : (
                                                <span
                                                  style={{
                                                    height: "10px",
                                                    width: "10px",
                                                    borderRadius: "50%",
                                                    backgroundColor: "#ccc",
                                                    marginLeft: "5px",
                                                  }}
                                                ></span>
                                              )}
                                              &nbsp;
                                              <span
                                                style={{
                                                  display: "block",
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  maxWidth: "180px",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {account.businessname}
                                              </span>
                                            </Link>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                    {/* {userData?.accountdetails?.length === 1 && 
                                 <MenuItem>
                                   <Link
                                     className="color-383838"
                                     style={{ display: 'flex', alignItems: 'center' }}
                                     href="javascript:void(0);"
                                    //  onClick={()=>setBusinessEnable(!businessEnable)}
                                   >
                                     <PersonAddIcon style={{ height: '20px' }} />
                                     &nbsp;Add another business
                                   </Link>
                                 </MenuItem>
                                } */}
                                  </>
                                ) : (
                                  ""
                                )}
                                <MenuItem>
                                  <Link
                                    className="color-383838"
                                    onClick={() => {
                                      localStorage.removeItem("menuTitle");
                                      logout();
                                    }}
                                    href="javascript:void(0);"
                                  >
                                    <i
                                      class="fa fa-sign-out"
                                      aria-hidden="true"
                                      style={{ paddingLeft: "4px" }}
                                    ></i>
                                    &nbsp; Sign Out
                                  </Link>
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <Link
                            color="inherit"
                            size="large"
                            href="javascript:void(0);"
                            className={
                              localStorage.getItem("menuTitle") === menuTitle
                                ? "color-CEA05F fontw-500"
                                : "text-white"
                            }
                            onClick={() =>
                              handleMenuClick(
                                isPublic || currentUser ? menuItem : loginMenu
                              )
                            }
                          >
                            {menuTitle}
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </Toolbar>
        </AppBar>
        {/* <nav className={classes.alighRight} >
          <Button color="primary" onClick={() => handleButtonClick("/")}>HOME</Button>
          <Button color="inherit" onClick={() => handleButtonClick("/register")}>Register</Button>
          <Button color="inherit" onClick={() => handleButtonClick("/login")}>Login</Button>
          <Button color="inherit" onClick={() => handleButtonClick("/forgot-password")}>Forgot Password</Button>
          <Button color="inherit" onClick={() => handleButtonClick("/reset-password")}>Reset Password</Button>
      </nav> */}
      </div>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return { currentUser: state.auth.currentUser };
};
function mapDispatchToProps(dispatch) {
  return {
    logoutAction: (payload) => dispatch({ type: "LOGOUT", payload }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
