import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validator from "validator";
// import HelpIcon from '@mui/icons-material/Help';
import HelpIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import {
  Container,
  Grid,
  TextField,
  FormHelperText,
  Paper,
  FormControlLabel,
  FormLabel,
  FormControl,
  Checkbox,
  InputLabel,
  Slider,
  Box,
  Typography,
  Button,
  Input,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Popover as AntdPopover, Input as AntdInput } from "antd";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  individualBrowseWatch,
  searchProducts,
  searchLocation,
} from "../../dealer/actions/dealerAction";
import searchinventry from "../actions/dashboardaapi";
import AddIcon from "../../../../assests/images/add.png";
import {
  getComponentActionList,
  isActionAllowed,
  getComponentViewsList,
  formatDate,
} from "../../../../common/components/Helper/Utils";
import _ from "lodash";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import Header from "../../../../common/components/Header";
import Footer from "../../../../common/components/Footer";
import { SpinnerRoundFilled } from "spinners-react";
import { Divider, List } from "antd";
import {
  brandList,
  caseMaterial,
  brandMaterial,
} from "../../browse-watches/actions/browseWatchesActions";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import FileUploadGroup from "../../../../common/components/FileuploadGroup";
import { getBusinessAccountIds } from "../actions/dashboardAction";
const Loader = require("react-loader");

const followersMarks1 = [
  {
    value: 1,
    scaledValue: 1,
    label: "Pre-Owned",
  },
  {
    value: 2,
    scaledValue: 2,
    label: "Mint",
  },
  {
    value: 3,
    scaledValue: 3,
    label: "Retail Ready",
  },
  {
    value: 4,
    scaledValue: 4,
    label: "New",
  },
];

const followersMarks2 = [
  {
    value: 1,
    scaledValue: 1,
    label: "New",
  },
  {
    value: 2,
    scaledValue: 2,
    label: "Used",
  },
];

const condition = [
  {
    value: 0,
    scaledValue: 1,
    label: "0 Inch",
  },
  {
    value: 5,
    scaledValue: 5,
    label: "5 Inc",
  },
];

const scale = (value) => {
  return (value * 25.4).toFixed(2) + " mm";
};

function numFormatter(num) {
  return num.toFixed(1) + " Inc"; // convert to K for number from > 1000 < 1 million
}

const scale1 = (value) => {
  return value;
};


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

class inventryupdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: sessionStorage.getItem("userDetails")
        ? JSON.parse(sessionStorage.getItem("userDetails"))
        : null,
      accountid: window.sessionStorage.getItem("accountId"),
      accountIds: [],
      open: false,
      setOpen: false,
      ModelNumber: "",
      purchaseDate: "",
      purchaseDueDate: null,
      purchaseDate1: null,
      archiveDate: null,
      cursor: 0,
      PurchasedFrom: "",
      purchasedFromArray: [],
      purchaseFromContactId: null,
      dataset: [],
      isPopOverPurchasedFrom: false,
      isPopOverMemoDetails: false,
      isPopOverPartnershipDetails: false,
      PurchasedPrice: "",
      SerialNumber: "",
      InventoryType: "",
      MemoDetails: "",
      memoDetailsArray: [],
      memoContactId: null,
      PartnershipDetails: "",
      partnershipDetailsArray: [],
      partnerContactId: null,
      purchasefrom: "",
      Condition: "1",
      BandSize: "",
      NumberOfLinks: "",
      DealerNotes: "",
      WholeSalePrice: "",
      RetailPrice: "",
      RetailWirePrice: "",
      DateFnsUtils: "",
      value: 0,
      finalcondition: "",
      finalbandsize: Number,
      isProduct_title_Changed: false,
      value1: 0,
      number: 0,
      serialno: "",
      WarentyPaper: "",
      warentypaperset: "",
      warranty_paper: "",
      inventoryDetailByIdDetails: [],
      onModelNumberErrorText: "",
      onPurchasedFromErrorText: "",
      onpurchaseDateErrorText: "",
      onPurchasedPriceErrorText: "",
      onSerialNumberErrorText: "",
      modificationDetailsError: "",
      onConditionErrorText: "",
      onCoditionTenSuccessText: "",
      onBandSizeErrorText: "",
      onDealerNotesErrorText: "",
      onNumberOfLinksErrorText: "",
      onWholeSalePriceErrorText: "",
      onRetailPriceErrorText: "",
      onRetailWirePriceErrorText: "",
      onPublicDealerNoteErrorText: "",
      onInventoryStatusErrorText: "",
      onProductTitleErrorText: "",
      onProductNameErrorText: "",
      onDescriptionErrorText: "",
      onModelNumberErrorText: "",
      onQuantityErrorText: "",
      onCaseSizeErrorText: "",
      onBandMaterialErrorText: "",
      onBrandNameErrorText: "",
      onCaseMaterialErrorText: "",
      onpurchaseDueDateErrorText: "",
      individualsearchdata: false,
      box: false,
      warranty_box: "",
      warrantybox: "",
      autocomplete: {
        currentInput: "",
        loading: false,
        data: [],
      },
      selectedProduct: { productId: "" },
      selected: { product: null },
      loaded: false,
      purchaseprice: "",
      modification_deatils: "",
      ismodified: false,
      public_dealer_note: "",
      InventoryStatus: "",
      inventory_title: "",
      title_without_edit: "",
      purchase_expense: "",
      total_cost: "",
      brandname: "",
      quantity: "",
      updatememo: "",
      brand_name: "",
      product_name: "",
      case_material: "",
      band_material: "",
      case_size: "",
      dial_color: "",
      model_number: "",
      updatepartnershipnumber: "",
      purchase_expense_note: "",
      locationAutoCompleteData: {
        data: [],
      },
      locationSearch: "",
      locationloading: false,
      tag_price: "",
      archivePaperSet: "",
      archive: "",
      isService: false,
      serviceDate: "",
      warrentyExpiryDate: false,
      brandloading: false,
      brandAutoCompleteData: {
        data: [],
      },
      caseMaterialloading: false,
      caseMaterialAutoCompleteData: {
        data: [],
      },
      bandMaterialloading: false,
      bandMaterialAutoCompleteData: {
        data: [],
      },
      disabled: false,
      type: null,
      bulkUploadFile: [],
      fileArray: [],
      purchaseInvoice: null,
      inventryPricelist: [],
      ebayAmout: "",
      croono21Ammount: "",
      retailAmount: "",
      accessData: [],
      viewData: [],
      paymentDetailsText: "",
      onPaymentDetailsErrorText: "",
      paidStatus: true,
      paymentDate: null,
      notforSale: false,
      dealerInv: true,
      error: {},
      memoId: null,
      sold: null,
    }
    this.props.brandList()
    this.props.caseMaterial()
    this.props.brandMaterial()
    this.onchangeModelNumber = this.onchangeModelNumber.bind(this);
    this.handleNotforSaleCheck = this.handleNotforSaleCheck.bind(this);

    this.handleSelectedFiles = this.handleSelectedFiles.bind(this);
    this.DestructurefileType = this.DestructurefileType.bind(this);
    this.onchangePurchasedFrom = this.onchangePurchasedFrom.bind(this);
    this.onchangepurchaseDate = this.onchangepurchaseDate.bind(this);
    this.onchangepurchaseDate1 = this.onchangepurchaseDate1.bind(this);
    this.onchangePurchasedPrice = this.onchangePurchasedPrice.bind(this);
    this.onchangeSerialNumber = this.onchangeSerialNumber.bind(this);
    this.onchangeCondition = this.onchangeCondition.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onchangeBandSize = this.onchangeBandSize.bind(this);
    this.onchangeNumberOfLinks = this.onchangeNumberOfLinks.bind(this);
    this.onchangeDealerNotes = this.onchangeDealerNotes.bind(this);
    this.onchangeWholeSalePrice = this.onchangeWholeSalePrice.bind(this);
    this.onchangeRetailPrice = this.onchangeRetailPrice.bind(this);
    this.onchangeRetailWirePrice = this.onchangeRetailWirePrice.bind(this);
    this.onchangePaymentDetails = this.onchangePaymentDetails.bind(this);
    this.handleDateChange1 = this.handleDateChange1.bind(this);
    this.handleCheck1 = this.handleCheck1.bind(this);
    // this.autocomplete = this.autocomplete.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.valuetext = this.valuetext.bind(this);
    // this.getAutocomplete = _.debounce(this.getAutocomplete, 500);
    this.checloaader = this.checloaader.bind(this);
    this.getInventryDetails = this.getInventryDetails.bind(this);
    this.onchangeModificationDetails =
      this.onchangeModificationDetails.bind(this);
    this.handleCheck2 = this.handleCheck2.bind(this);
    this.onchangeInventoryStatus = this.onchangeInventoryStatus.bind(this);
    this.onchangePublicDealerNote = this.onchangePublicDealerNote.bind(this);
    this.onchangeInventoryTitle = this.onchangeInventoryTitle.bind(this);
    this.onchangePurchaseExpense = this.onchangePurchaseExpense.bind(this);
    this.onchangePurchaseExpenseNote =
      this.onchangePurchaseExpenseNote.bind(this);
    this.onchangeLocation = this.onchangeLocation.bind(this);
    // this.locationAutoComplete = this.locationAutoComplete.bind(this);
    // this.getLocationAutocomplete = _.debounce(this.getLocationAutocomplete, 500);
    this.onchangeTagPrice = this.onchangeTagPrice.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePurchaseDate = this.handlePurchaseDate.bind(this);
    this.handleDueDateChange = this.handleDueDateChange.bind(this);
    this.onchangeProductName = this.onchangeProductName.bind(this);
    this.onchangeProductTitle = this.onchangeProductTitle.bind(this);
    this.onchangeDescription = this.onchangeDescription.bind(this);
    this.onchangeProductModelNumber =
      this.onchangeProductModelNumber.bind(this);
    this.onchangeQuantity = this.onchangeQuantity.bind(this);
    this.onchangeCaseMaterial = this.onchangeCaseMaterial.bind(this);
    this.onchangecasematerial = this.onchangecasematerial.bind(this);
    this.onchangeBandMaterial = this.onchangeBandMaterial.bind(this);
    this.onchangebandmaterial = this.onchangebandmaterial.bind(this);
    this.onchangeBrandName = this.onchangeBrandName.bind(this);
    this.onchangebrand = this.onchangebrand.bind(this);
    this.onchangeCaseSize = this.onchangeCaseSize.bind(this);
    this.onchangeDialColor = this.onchangeDialColor.bind(this);
    this.backNavigation = this.backNavigation.bind(this);
    this.handleClickBreadCrumbs = this.handleClickBreadCrumbs.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.bulkUploadFile = this.bulkUploadFile.bind(this);
    this.getpriceListing = this.getpriceListing.bind(this);
    this.componentlist = this.componentlist.bind(this);
    this.debouncedGetContactDetails = _.debounce(this.getContactDetails, 1000);
    this.purchasedFromRef = React.createRef();
    this.memoDetailsRef = React.createRef();
    this.partnershipDetailsRef = React.createRef();
  }

  componentWillUnmount() { }
  componentlist = async () => {
    const accessDataObject = await getComponentActionList("edit_inventory");
    const viewDataObject = await getComponentViewsList("edit_inventory");
    this.setState({ accessData: accessDataObject, viewData: viewDataObject });
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true, number: 50 });
    }, 900);
    // this.state.autocomplete.data = newProps.autocompleteProducts.list;
    this.componentlist();
    let PId = null;
    try {
      PId = this.props.match.params.inventryId;
      if (PId != undefined) {
        this.state.selectedProduct.productId = PId;
        this.getInventryDetails(PId);
        this.setState({
          ...this.state,
        });
      }
    } catch (error) {
      console.log(error);
    }
    // this.onProductChange();
    this.getAccountIds();
  }

  getInventryDetails(id) {
    searchinventry.getinventryDetails(id).then((res) => {
      if (res.data.success === 1) {
        var condition =
          res.data.data && res.data.data.condition
            ? res.data.data.condition
            : "1";
        var modelnumber =
          res.data.data && res.data.data.productid
            ? res.data.data.productid
            : "";

        if (Number(res.data.data.quantity) > 1) {
          this.setState({
            disabled: true,
            SerialNumber: "",
          });
        } else {
          this.setState({
            SerialNumber:
              res.data.data && res.data.data.serialno
                ? res.data.data.serialno
                : "",
          });
        }
        // if(this.state.inventoryDetailByIdDetails.type === "misc_watch" || this.state.inventoryDetailByIdDetails.type === "misc_product"){
        //   this.setState({

        //   })
        // }

        let technical_details = {};
        if (
          res.data?.data?.technical_details &&
          typeof res.data.data.technical_details === "string"
        ) {
          technical_details = JSON.parse(res.data?.data?.technical_details);
        } else {
          technical_details = res.data?.data?.technical_details
            ? res.data?.data?.technical_details
            : {};
        }

        this.setState({
          inventoryDetailByIdDetails: res.data.data,
          dateformat: res.data.data.purchasedate,
          product_name:
            res.data.data && technical_details?.product_name
              ? technical_details.product_name
              : "",
          model_number:
            res.data.data && technical_details?.model_number
              ? technical_details.model_number
              : "",
          band_material:
            res.data.data && technical_details?.band_material
              ? technical_details.band_material
              : "",
          brand_name:
            res.data.data && technical_details?.brand_name
              ? technical_details.brand_name
              : "",
          case_material:
            res.data.data && technical_details?.case_material
              ? technical_details.case_material
              : "",
          case_size:
            res.data.data && technical_details?.case_size
              ? technical_details.case_size
              : "",
          dial_color:
            res.data.data && technical_details?.dial_color
              ? technical_details.dial_color
              : "",
          product_title:
            res.data.data && technical_details?.product_title
              ? technical_details.product_title
              : "",
          description:
            res.data.data && technical_details?.description
              ? technical_details.description
              : "",
          Condition: condition,
          dealerInv:
            res.data.data && res.data.data.is_include_dealerfeed
              ? res.data.data.is_include_dealerfeed
              : false,
          BandSize:
            res.data.data && res.data.data.bandsize
              ? res.data.data.bandsize
              : "",
          ModelNumber: modelnumber,
          PurchasedFrom:
            res.data.data && res.data.data.purchasefrom
              ? res.data.data.purchasefrom
              : "",
          quantity:
            res.data.data && res.data.data.quantity
              ? res.data.data.quantity
              : "",
          // purchaseDate: ((res.data.data) && (res.data.data.purchasedate)) ? res.data.data.purchasedate : "",
          PurchasedPrice:
            res.data.data && res.data.data.purchaseprice
              ? res.data.data.purchaseprice
              : "",
          // paymentDate: ((res.data.data) && (res.data.data.purchase_payment_date)) ? res.data.data.purchase_payment_date : null,
          // purchaseDueDate: ((res.data.data) && (res.data.data.purchase_payment_duedate)) ? res.data.data.purchase_payment_duedate : null,
          paymentDetailsText:
            res.data.data && res.data.data.purchase_payment_details
              ? res.data.data.purchase_payment_details
              : "",
          paidStatus:
            res.data.data && res.data.data.purchase_invoice_paid
              ? res.data.data.purchase_invoice_paid
              : false,
          // notforSale: ((res.data.data) && (res.data.data.is_notsale)) ? res.data.data.is_notsale : false,
          // SerialNumber: ,
          InventoryType:
            res.data.data && res.data.data.inventorytype
              ? res.data.data.inventorytype
              : "",
          InventoryStatus:
            res.data.data && res.data.data.inventory_status
              ? res.data.data.inventory_status
              : "",
          MemoDetails:
            res.data.data &&
              res.data.data.memodetails &&
              res.data.data.memodetails !== "null"
              ? res.data.data.memodetails
              : "",
          PartnershipDetails:
            res.data.data &&
              res.data.data.partnershipdetais &&
              res.data.data.partnershipdetais !== "null"
              ? res.data.data.partnershipdetais
              : "",
          NumberOfLinks:
            res.data.data && res.data.data.links ? res.data.data.links : "",
          DealerNotes:
            res.data.data && res.data.data.note ? res.data.data.note : "",
          WholeSalePrice:
            res.data.data && res.data.data.wholesaleprice
              ? res.data.data.wholesaleprice
              : "",
          RetailPrice:
            res.data.data && res.data.data.retailprice
              ? res.data.data.retailprice
              : "",
          RetailWirePrice:
            res.data.data && res.data.data.retailwireprice
              ? res.data.data.retailwireprice
              : "",
          brandname:
            res.data.data && res.data.data.brandname
              ? res.data.data.brandname
              : "",
          purchase_expense:
            res.data.data && res.data.data.purchase_expense
              ? res.data.data.purchase_expense
              : "",
          purchase_expense_note:
            res.data.data && res.data.data.purchase_expense_note
              ? res.data.data.purchase_expense_note
              : "",
          public_dealer_note:
            res.data.data && res.data.data.public_dealer_note
              ? res.data.data.public_dealer_note
              : "",
          tag_price:
            res.data.data && res.data.data.tag_price
              ? res.data.data.tag_price
              : "",
          updatememo:
            res.data.data && res.data.data.memo_number
              ? res.data.data.memo_number
              : "",
          updatepartnershipnumber:
            res.data.data && res.data.data.partnership_number
              ? res.data.data.partnership_number
              : "",
          ismodified:
            res.data.data && res.data.data.ismodified
              ? res.data.data.ismodified
              : false,
          modification_deatils:
            res.data.data && res.data.data.modification_deatils
              ? res.data.data.modification_deatils
              : "",
          // ismodified: ((res.data.data) && (res.data.data.modification_deatils)) ? true : false,
          total_cost:
            res.data.data && res.data.data.total_cost
              ? res.data.data.total_cost
              : "",
          locationSearch:
            res.data.data && res.data.data.inventory_location
              ? res.data.data.inventory_location
              : "",
          inventory_title:
            res.data.data && res.data.data.inventory_title
              ? res.data.data.inventory_title
              : "",
          title_without_edit:
            res.data.data && res.data.data?.invattr?.model
              ? res.data.data?.invattr?.model
              : "",
          type: res.data.data && res.data.data.type ? res.data.data.type : null,
          fileArray:
            res.data.data && res.data.data.purchaseinvoiceimages
              ? res.data.data.purchaseinvoiceimages.images
              : [],
          purchaseInvoice:
            res.data.data && res.data.data.purchaseinvoiceimages
              ? res.data.data.purchaseinvoiceimages
              : null,
          warranty_paper:
            res.data.data && res.data.data.warranty_paper
              ? res.data.data.warranty_paper
              : "",
          archive:
            res.data.data && res.data.data.archive ? res.data.data.archive : "",
          isService:
            res.data.data && res.data.data.is_service
              ? res.data.data.is_service
              : false,
          purchaseFromContactId: res?.data?.data?.purchasefromcontactid || null,
          memoContactId: res?.data?.data?.memocontactid || null,
          partnerContactId: res?.data?.data?.partnercontactid || null,
          memoId: res.data?.data?.memoid || "",
          sold: res.data?.data?.sold || null
        });
        if (res.data.data && res.data.data.productid) {
          this.getpriceListing(res.data.data.productid);
        }
        this.checkboxselection();
        this.boxcheckboxselection();
        // this.archiveCheckboxselection()
        let date = res.data.data.purchasedate;
        let date1 = res.data.data.warrantydate;
        let date2 = res.data.data.archive_date;
        let date3 = res.data.data.service_date;
        let date4 = res.data.data.purchase_payment_date;
        let date5 = res.data.data.purchase_payment_duedate;
        // const NewDate = date ? moment(date).format('MM/DD/YYYY') : null;
        // const NewDate1 = date1 ? moment(date1).format('MM/DD/YYYY') : null;
        // const NewDate2 = date2 ? moment(date2).format('MM/DD/YYYY') : null;
        // const NewDate3 = date3 ? moment(date3).format('MM/DD/YYYY') : null;
        const NewDate = date ? formatDate(date, "MM/DD/YYYY") : null;
        const NewDate1 = date1 ? formatDate(date1, "MM/DD/YYYY") : null;
        const NewDate2 = date2 ? formatDate(date2, "MM/DD/YYYY") : null;
        const NewDate3 = date3 ? formatDate(date3, "MM/DD/YYYY") : null;
        const NewDate4 = date4 ? formatDate(date4, "MM/DD/YYYY") : null;
        const NewDate5 = date5 ? formatDate(date5, "MM/DD/YYYY") : null;
        this.setState(prevState => ({
          purchaseDate: NewDate,
          purchaseDate1: NewDate1,
          archiveDate: NewDate2,
          serviceDate: NewDate3,
          paymentDate: NewDate4,
          purchaseDueDate: NewDate5,
          // purchasedFromArray: res.data?.data?.purchasefrom ?
          //   [{ userId: this.props.currentUser.userId }] : prevState.purchasedFromArray,
          // memoDetailsArray: res.data?.data?.memodetails ?
          //   [{ userId: this.props.currentUser.userId }] : prevState.memoDetailsArray,
          // partnershipDetailsArray: res.data?.data?.partnershipdetais ?
          //   [{ userId: this.props.currentUser.userId }] : prevState.partnershipDetailsArray,
        }));
      } else if (res.data.success === 0) {
        this.setState({ loaded: true });
        window.location.href = "/login";
      }
    });
  }
  getpriceListing(id) {
    searchinventry.getinventrypriceList(id).then((res) => {
      this.setState({
        inventryPricelist: res.data.data,
        loaded: true,
      });
      for (let i = 0; i < this.state.inventryPricelist.length; i++) {
        if (this.state.inventryPricelist[i].watch_source == "Chrono24") {
          this.setState({
            croono21Ammount: this.state.inventryPricelist[i].avg,
          });
        } else if (this.state.inventryPricelist[i].watch_source == "Retail") {
          this.setState({ retailAmount: this.state.inventryPricelist[i].avg });
        } else if (this.state.inventryPricelist[i].watch_source == "EBay") {
          this.setState({ ebayAmout: this.state.inventryPricelist[i].avg });
        }
      }
    });
  }
  handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  handleGoBack() {
    this.props.history.goBack();
  }

  backNavigation() {
    const { history } = this.props;
    history.push("/myinventory", this.props.history.location.state);
  }
  checkboxselection() {
    if (this.state.inventoryDetailByIdDetails.warranty_paper === "1") {
      this.setState({
        warranty_paper: "1",
      });
    } else {
      this.setState({
        warranty_paper: "",
      });
    }
  }
  archiveCheckboxselection() {
    if (this.state.inventoryDetailByIdDetails.archive === 1) {
      this.setState({
        archive: true,
      });
    } else {
      this.setState({
        archive: false,
      });
    }
  }
  boxcheckboxselection() {
    if (this.state.inventoryDetailByIdDetails.warrantybox === "1") {
      this.setState({
        box: true,
      });
      localStorage.setItem("warranty_box", "1");
    } else {
      this.setState({
        box: false,
      });
      localStorage.setItem("warranty_box", "0");
    }
  }

  onProductChange = _.debounce((e, v, r) => {
    if (v) {
      switch (e) {
        case "product_selected":
          this.setState({
            ...this.state,
            selected: { product: v },
          });
          break;
        case "input_changed":
          this.state.autocomplete.currentInput = v;

          this.state.autocomplete.loading = true;
          this.setState({
            ...this.state,
          });
          if (v.length >= 3 && r == "input") {
            this.props.searchProducts({ searchText: v }, (error, res) => { });
          }
          break;
      }
    } else {
    }
  }, 600);

  //   autocomplete(evt) {
  //     console.log(evt.target.value, "autocomptete", this.state.loaded)
  //     let text = evt.target.value;
  //     this.setState({ searchString: text });
  //     this.setState({ loading: true });
  //     this.getAutocomplete(text);
  //   }
  //   getAutocomplete = (evt) => {
  //     console.log(evt, "getauto")
  //     this.props.searchProducts({ searchText: evt }, (error, res) => {
  //       this.setState({ searchItems: this.state.autocomplete.data });
  //       this.setState({ loading: false });
  //       if (this.state.autocomplete.data.length > 0) {
  //         this.setState({
  //           individualsearchdata: true,
  //         })
  //       } else {
  //         this.setState({
  //           individualsearchdata: false,

  //         })
  //         console.log("false1  false", this.state.autocomplete.data.length > 0, this.state.loaded)

  //       }
  //     });

  //     console.log(this.state.autocomplete.data);
  //   };
  hanldeKeyup(evt) { }

  hanldeKeydown(evt) {
    // console.log(evt.target.value)
  }

  handleListKeydown(evt) {
    // console.log(evt);
  }

  handleChange(evt) {
    this.setState({ item: { [evt.target.name]: evt.target.value } });
  }
  componentWillReceiveProps(newProps) {
    this.state.autocomplete.data = newProps.autocompleteProducts.list;
    this.setState({
      ...this.state,
    });
    const individualBrowseWatchesStatus = newProps.individualBrowseWatchesInfo;
    if (individualBrowseWatchesStatus.success === 0) {
      this.setState({
        onModelNumberErrorText: individualBrowseWatchesStatus.message,
        isValid: false,
      });
    } else if (
      individualBrowseWatchesStatus.success === 1 &&
      individualBrowseWatchesStatus?.data &&
      individualBrowseWatchesStatus.data?.inventoryid
    ) {
      const inventoryDetailByIdDetails =
        newProps.individualBrowseWatchesInfo.data;
      if (
        inventoryDetailByIdDetails &&
        inventoryDetailByIdDetails.prodattr &&
        !inventoryDetailByIdDetails.invattr
      ) {
        inventoryDetailByIdDetails.invattr =
          inventoryDetailByIdDetails.prodattr;
      }
      if (
        inventoryDetailByIdDetails &&
        inventoryDetailByIdDetails.productid &&
        (!this.state.inventoryDetailByIdDetails ||
          (this.state.inventoryDetailByIdDetails &&
            this.state.inventoryDetailByIdDetails.productid !==
            inventoryDetailByIdDetails.productid))
      ) {
        this.getpriceListing(inventoryDetailByIdDetails.productid);
      }
      this.setState({
        inventoryDetailByIdDetails: inventoryDetailByIdDetails,
        ModelNumber:
          inventoryDetailByIdDetails && inventoryDetailByIdDetails.productid
            ? inventoryDetailByIdDetails.productid
            : "",
        onModelNumberErrorText: "",
        isValid: true,
      });
    }
  }

  handleSelectedFiles(value, disablevalue) {
    const imageFile = disablevalue;
    let fileArray = [];
    for (let i = 0; i < imageFile.length; i++) {
      if (imageFile?.name) {
        fileArray.push(URL.createObjectURL(imageFile[i]));
        continue;
      }
      //fileArray.push(imageFile[i])
    }

    // this.setState({ file: this.fileArray })
    this.setState({
      bulkUploadFile: imageFile,
      fileArray: fileArray,
    });
  }
  DestructurefileType = () => {
    const resultary = this.state.fileArray;
    const result = resultary.map((value) => {
      return {
        fileName: "image",
        imageUrl: value,
      };
    });
  };
  bulkUploadFile = ({ target }) => {
    const imageFile = target.files;
    let fileArray = [];
    for (let i = 0; i < imageFile.length; i++) {
      fileArray.push(URL.createObjectURL(imageFile[i]));
    }
    this.setState({ file: this.fileArray });
    this.setState({
      bulkUploadFile: imageFile,
      fileArray: fileArray,
    });
  };

  onchangeModelNumber(product, productId) {
    this.setState({
      inventoryDetailByIdDetails: product,
      inventory_title: product.modelname,
      title_without_edit: product.modelname,
      // loaded: true
    });
    // if(product && product.productid){
    //   this.getpriceListing(product.productid)
    // }
    this.setState({
      nonsearchstring: false,
    });
    this.setState({
      searchString: product.productname,
      ModelNumber: product.productid,
    });
    this.setState({ searchStringdetails: product.productname });
    this.setState({ autocomplete: { data: [] } });
    // this.props.fetchSearchBrowseWatches(productId);
    if (product.productid == null) {
      this.setState({
        individualsearchdata: true,
      });
    } else {
      this.setState({
        individualsearchdata: true,
      });
    }
    this.props.individualBrowseWatch(product.productid);
    this.setState({
      onModelNumberErrorText: "",
      isValid: true,
      nonsearchstring: false,
      individualsearchdata: false,
    });
  }

  onchangeModificationDetails(event) {
    // if (event.target.value !== "") {
    let ModificationDetailsVal = event.target.value;
    if (!ModificationDetailsVal) {
      this.setState({
        modification_deatils: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        modification_deatils: event.target.value,
        isValid: true,
      });
    }
    // }
  }

  onchangeCaseSize(event) {
    if (event.target.value !== "") {
      let CaseSizeVal = validator.isNumeric(event.target.value);
      if (!CaseSizeVal) {
        this.setState({
          onCaseSizeErrorText: "Enter the correct Case Size",
          isValid: false,
        });
      } else {
        this.setState({
          onCaseSizeErrorText: "",
          case_size: event.target.value,
          isValid: true,
        });
      }
    } else {
      this.setState({
        onCaseSizeErrorText: "",
        case_size: "",
        isValid: true,
      });
    }
  }

  onchangebrand(product) {
    this.setState({
      brandAutoCompleteData: { data: [] },
      brand_name: product.brandname,
      isValid: true,
    });
  }

  onchangeBrandName(event) {
    this.setState({
      brandloading: true,
    });
    if (event.target.value.length > 0) {
      const searchString = event.target.value.toLowerCase();
      const filterArray = this.props.brandInfo.data.filter(function (data) {
        const brandName = data.brandname.toLowerCase();
        return !(brandName.indexOf(searchString) === -1);
      });
      this.setState({
        onBrandNameErrorText: "",
        brand_name: event.target.value,
        isValid: true,
        brandloading: false,
        brandAutoCompleteData: { data: filterArray },
      });
    } else {
      this.setState({
        brandloading: false,
        onBrandNameErrorText: "",
        brand_name: event.target.value,
        isValid: true,
        brandAutoCompleteData: { data: [] },
      });
    }
  }

  onchangecasematerial(product) {
    this.setState({
      caseMaterialAutoCompleteData: { data: [] },
      case_material: product.casematerial,
      isValid: true,
    });
  }

  onchangeCaseMaterial(event) {
    this.setState({
      caseMaterialloading: true,
    });

    if (event.target.value.length > 0) {
      const searchString = event.target.value.toLowerCase();
      const filterArray = this.props.caseMaterialInfo.data.filter(function (
        data
      ) {
        const caseMaterial = data.casematerial.toLowerCase();
        return !(caseMaterial.indexOf(searchString) === -1);
      });
      this.setState({
        onCaseMaterialErrorText: "",
        case_material: event.target.value,
        isValid: true,
        caseMaterialloading: false,
        caseMaterialAutoCompleteData: { data: filterArray },
      });
    } else {
      this.setState({
        caseMaterialloading: false,
        onCaseMaterialErrorText: "",
        case_material: event.target.value,
        isValid: true,
        caseMaterialAutoCompleteData: { data: [] },
      });
    }
  }

  onchangebandmaterial(product) {
    this.setState({
      bandMaterialAutoCompleteData: { data: [] },
      band_material: product.bandmaterial,
      isValid: true,
    });
  }

  onchangeBandMaterial(event) {
    this.setState({
      bandMaterialloading: true,
    });

    if (event.target.value.length > 0) {
      const searchString = event.target.value.toLowerCase();
      const filterArray = this.props.brandMaterialInfo.data.filter(function (
        data
      ) {
        const bandMaterial = data.bandmaterial.toLowerCase();
        return !(bandMaterial.indexOf(searchString) === -1);
      });
      this.setState({
        onBandMaterialErrorText: "",
        band_material: event.target.value,
        isValid: true,
        bandMaterialloading: false,
        bandMaterialAutoCompleteData: { data: filterArray },
      });
    } else {
      this.setState({
        bandMaterialloading: false,
        onBandMaterialErrorText: "",
        band_material: event.target.value,
        isValid: true,
        bandMaterialAutoCompleteData: { data: [] },
      });
    }
  }

  onchangeDialColor(event) {
    if (event.target.value !== "") {
      let DialColorVal = event.target.value;
      if (!DialColorVal) {
        this.setState({
          onDialColorErrorText: "Enter the correct Case Material",
          isValid: false,
        });
      } else {
        this.setState({
          onDialColorErrorText: "",
          dial_color: event.target.value,
          isValid: true,
        });
      }
    } else {
      this.setState({
        onDialColorErrorText: "",
        dial_color: "",
        isValid: true,
      });
    }
  }
  handleCheck2 = (event) => {
    if (event.target.checked) {
      this.setState({
        ismodified: true,
      });
    } else {
      this.setState({
        ismodified: false,
      });
    }
  };

  handleDealerInclude = (event) => {
    this.setState({ dealerInv: event.target.checked });
  };

  onchangePurchasedFrom(event) {
    this.setState({
      onPurchasedFromErrorText: "",
      PurchasedFrom: event.target.value,
      isValid: true,
    });
    if (event.target.value !== "") {
      //   let PurchasedFromVal = event.target.value;
      //   if (!PurchasedFromVal) {
      //     this.setState({
      //       onPurchasedFromErrorText: "Choose proper Purchased From",
      //       isValid: false
      //     })
    } else {
      // this.setState({
      //   onPurchasedFromErrorText: "",
      //   PurchasedFrom: event.target.value,
      //   isValid: true
      // })
    }
    // }
  }


  getAccountIds = () => {
    getBusinessAccountIds(this.state.accountid, (error, res) => {
      if (res.data && res.success == 1) {
        this.setState({
          accountIds: res.data,
        });
      }
    });
  };

  getContactDetails = (type, contactType, callback) => {
    let payload = {
      key: this.state[type],
      type: contactType,
      accountids: this.state.accountIds,
    };
    searchinventry.getInventoryContactDetails(payload, (error, response) => {
      if (response?.data && response?.status == 1) {
        callback(response.data, null);
      }
      if (error) {
        callback(null, error);
      }
    });
  };

  onchangeContactSuggestion = (event, type, contactType) => {
    const { purchaseFromContactId, memoContactId, partnerContactId } = this.state;
    let value = event.target.value;
    let newState = { isValid: true, fieldLoader: true };
    if (type === "PurchasedFrom") {
      newState.PurchasedFrom = value;
    } else if (type === "MemoDetails") {
      newState.MemoDetails = value;
    } else if (type === "PartnershipDetails") {
      newState.PartnershipDetails = value;
    }
    this.setState(newState, () => {
      if (value && value?.length > 2) {
        this.debouncedGetContactDetails(
          type,
          contactType,
          (data = [], error) => {
            if (data?.length > 0) {
              const filteredData = this.destructureData(data);
              this.setState(prevState => ({
                dataset: filteredData,
                purchasedFromArray: type == "PurchasedFrom" ? data : prevState.purchasedFromArray,
                memoDetailsArray: type == "MemoDetails" ? data : prevState.memoDetailsArray,
                partnershipDetailsArray:
                  type == "PartnershipDetails" ? data : prevState.partnershipDetailsArray,
                isPopOverPurchasedFrom: type == "PurchasedFrom" ? true : false,
                isPopOverMemoDetails: type == "MemoDetails" ? true : false,
                isPopOverPartnershipDetails:
                  type == "PartnershipDetails" ? true : false,
                fieldLoader: false,
              }));
            } else {
              this.setState(prevState => ({
                purchasedFromArray: type === "PurchasedFrom" ? [] : prevState.purchasedFromArray,
                memoDetailsArray: type === "MemoDetails" ? [] : prevState.memoDetailsArray,
                partnershipDetailsArray: type === "PartnershipDetails" ? [] : prevState.partnershipDetailsArray,
                dataset: [],
                fieldLoader: false,
                purchaseFromContactId: type === "PurchasedFrom" ? null : purchaseFromContactId,
                memoContactId: type === "MemoDetails" ? null : memoContactId,
                partnerContactId: type === "PartnershipDetails" ? null : partnerContactId,
              }));
            }
          }
        );
      } else {
        this.setState(prevState => ({
          purchasedFromArray: type === "PurchasedFrom" ? [] : prevState.purchasedFromArray,
          memoDetailsArray: type === "MemoDetails" ? [] : prevState.memoDetailsArray,
          partnershipDetailsArray: type === "PartnershipDetails" ? [] : prevState.partnershipDetailsArray,
          dataset: [],
          fieldLoader: false,
          purchaseFromContactId: type === "PurchasedFrom" ? null : purchaseFromContactId,
          memoContactId: type === "MemoDetails" ? null : memoContactId,
          partnerContactId: type === "PartnershipDetails" ? null : partnerContactId,
        }));
      }
    });
  }

  clearContactSuggestion = (type) => {
    const { purchaseFromContactId, memoContactId, partnerContactId } = this.state;
    this.setState({
      [type]: "",
      [`${type.charAt(0).toLowerCase() + type.slice(1)}Array`]: [],
      dataset: [],
      purchaseFromContactId: type === "PurchasedFrom" ? null : purchaseFromContactId,
      memoContactId: type === "MemoDetails" ? null : memoContactId,
      partnerContactId: type === "PartnershipDetails" ? null : partnerContactId,
    });
  };

  destructureData = (data) => {
    const groupedByEmail = {};
    data.forEach((value) => {
      const Email = value.email;
      groupedByEmail[Email] = groupedByEmail[Email] || [];
      groupedByEmail[Email].push(value);
    });
    const resultArray = Object.values(groupedByEmail);
    return resultArray;
  };

  handleContactSelection = (data, type) => {
    const {
      isPopOverPurchasedFrom,
      isPopOverMemoDetails,
      isPopOverPartnershipDetails,
      purchaseFromContactId,
      memoContactId,
      partnerContactId
    } = this.state;
    const fieldName = type;
    const value = [
      data?.businessname,
      (data?.firstname && data?.lastname) ? `${data?.firstname} ${data?.lastname}` : (data?.firstname || data?.lastname)
    ]
      ?.filter(Boolean)
      ?.join(", ");
    const contactId = data?.contactid;
    this.setState({
      [fieldName]: value,
      isPopOverPurchasedFrom:
        fieldName === "PurchasedFrom" ? false : isPopOverPurchasedFrom,
      isPopOverMemoDetails:
        fieldName === "MemoDetails" ? false : isPopOverMemoDetails,
      isPopOverPartnershipDetails:
        fieldName === "PartnershipDetails"
          ? false
          : isPopOverPartnershipDetails,
      purchaseFromContactId: fieldName === "PurchasedFrom" ? contactId : purchaseFromContactId,
      memoContactId: fieldName === "MemoDetails" ? contactId : memoContactId,
      partnerContactId: fieldName === "PartnershipDetails" ? contactId : partnerContactId,
    });
  };

  handleContact = (val) => {
    return [
      [val?.firstname, val?.lastname].filter(Boolean).join(" "),
      val?.businessname,
      val?.billingaddress1,
      val?.billingaddress2,
      val?.billingcity,
      val?.billingstate,
      val?.billingcountry,
      val?.billingzipcode
    ].filter(Boolean).join(", ");
  };

  handleVisibleChange = (visible) => {
    this.setState({
      isPopOverPurchasedFrom: visible,
      isPopOverMemoDetails: visible,
      isPopOverPartnershipDetails: visible,
    });
  };

  isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  handlePurchaseDate = (date, e) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          ["PD"]: "",
        },
        purchaseDate: e,
      });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ["PD"]: `Invalid Date`,
        },
      });
    }
  };

  handleDueDateChange = (date, e) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          ["PPDD"]: "",
        },
        purchaseDueDate: e,
      });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ["PPDD"]: `Invalid Date`,
        },
      });
    }
  };

  onchangepurchaseDate(date, event) {
    this.setState({
      onpurchaseDateErrorText: "",
      selectedDate: event.target.value,
      selectedDate: date,
      isValid: true,
    });

    let purchaseDateVal = event.target.value;
    if (purchaseDateVal) {
      this.setState({
        onpurchaseDateErrorText: "Choose the correct Purchased Date",
        isValid: false,
      });
    } else {
    }
  }
  onchangepurchaseDate1(date1, event) {
    this.setState({
      onpurchaseDateErrorText: "",
      selectedDate: event.target.value,
      selectedDate: date1,
      isValid: true,
    });

    let purchaseDateVal = event.target.value;
    if (purchaseDateVal) {
      this.setState({
        onpurchaseDateErrorText: "Choose the correct Purchased Date",
        isValid: false,
      });
    } else {
    }
  }

  onchangePurchasedPrice(event) {
    if (event.target.value !== "") {
      let PurchasedPriceVal = validator.isNumeric(event.target.value);
      if (PurchasedPriceVal != PurchasedPriceVal) {
        this.setState({
          onPurchasedPriceErrorText: "Choose the correct Purchased Price",
          isValid: false,
        });
      } else {
        const purchaseExpensePrice =
          this.state.purchase_expense.length > 0
            ? Number(this.state.purchase_expense)
            : 0;
        let totalCost = Number(event.target.value);
        totalCost = totalCost + purchaseExpensePrice;
        this.setState({
          onPurchasedPriceErrorText: "",
          PurchasedPrice: event.target.value,
          total_cost: totalCost,
          isValid: true,
        });
      }
    } else {
      const purchaseExpensePrice =
        this.state.purchase_expense.length > 0
          ? Number(this.state.purchase_expense)
          : "";
      let totalCost = purchaseExpensePrice;

      this.setState({
        onPurchasedPriceErrorText: "",
        PurchasedPrice: event.target.value,
        total_cost: totalCost,
        isValid: true,
      });
    }
  }

  onchangeSerialNumber(event) {
    if (this.state.type === null || this.state.type === "misc_watch") {
      let SerialNumberVal = event.target.value;
      if (!SerialNumberVal || SerialNumberVal === "") {
        this.setState({
          onSerialNumberErrorText: "Choose the correct Serial Number",
          SerialNumber: event.target.value,
          isValid: false,
        });
      } else {
        this.setState({
          onSerialNumberErrorText: "",
          SerialNumber: event.target.value,
          isValid: true,
        });
      }
    } else {
      this.setState({
        onSerialNumberErrorText: "",
        SerialNumber: event.target.value,
        isValid: true,
      });
    }
  }

  onchangeProductName(event) {
    this.setState({
      onProductNameErrorText: "",
      product_name: event.target.value,
      isValid: true,
    });
  }

  onchangeProductTitle(event) {
    this.setState({
      onProductTitleErrorText: "",
      product_title: event.target.value,
      isValid: true,
    });
    if (event.target.value === "") {
      this.setState({
        isProduct_title_Changed: true,
      });
      setTimeout(() => {
        this.setState({
          isProduct_title_Changed: false,
        });
      }, 3000);
    }
  }

  onchangeDescription(event) {
    this.setState({
      onDescriptionErrorText: "",
      description: event.target.value,
      isValid: true,
    });
  }
  onchangeProductModelNumber(event) {
    this.setState({
      onModelNumberErrorText: "",
      model_number: event.target.value,
      isValid: true,
    });
  }

  onchangeQuantity(event) {
    if (event.target.value !== "") {
      let QuantityVal = validator.isNumeric(event.target.value);
      if (!QuantityVal) {
        this.setState({
          onQuantityErrorText: "Enter Numeric Values Only",
          isValid: false,
        });
      } else if (Number(event.target.value) > 1) {
        this.setState({
          quantity: event.target.value,
          onQuantityErrorText: "",
          disabled: true,
          isValid: true,
          SerialNumber: "",
        });
      } else if (Number(event.target.value) === 1) {
        this.setState({
          quantity: event.target.value,
          onQuantityErrorText: "",
          disabled: false,
          isValid: true,
        });
      }
    } else {
      this.setState({
        onQuantityErrorText: "Quantity should be minumum of 1",
        isValid: false,
        quantity: "",
      });
    }
  }

  onchangeCondition(event, value) {
    let ConditionVal = value;
    if (!ConditionVal) {
      this.setState({
        onConditionErrorText: "Choose the correct Condition",
        isValid: false,
      });
    } else if (ConditionVal === 5) {
      this.setState({
        onCoditionTenSuccessText: "Pre Owned",
        onConditionErrorText: "",
        Condition: value,
        isValid: true,
      });
    } else {
      this.setState({
        onConditionErrorText: "",
        Condition: value,
        isValid: true,
      });
    }
  }

  onchangeBandSize(event) {
    this.setState({
      onBandSizeErrorText: "",
      BandSize: event.target.value.replace(/[^0-9\.]/g, ""),
      isValid: true,
    });
  }

  onchangeDealerNotes(event) {
    this.setState({
      onDealerNotesErrorText: "",
      DealerNotes: event.target.value,
    });
    // }
  }

  onchangePublicDealerNote(event) {
    this.setState({
      public_dealer_note: event.target.value,
      isValid: true,
    });
  }

  onchangeTagPrice(event) {
    this.setState({
      tag_price: event.target.value,
      isValid: true,
    });
  }

  locationAutoComplete = (evt) => {
    let text = evt.target.value;
    this.setState({ locationSearch: text, locationloading: true });
    this.getLocationAutocomplete(text);
  };

  getLocationAutocomplete = (evt) => {
    // if (evt.length > 0) {
    searchLocation({ locationSearch: evt || null }, (error, res) => {
      this.setState({ locationloading: false });
      this.setState({ locationAutoCompleteData: { data: res?.data } });
    });
    // } else {
    //   this.setState({ locationloading: false });
    //   this.setState({ locationAutoCompleteData: { data: [] } });
    // }
  };

  handleLocationFocus = (event) => {
    if (!event.target.value) {
      this.locationAutoComplete(event);
    }
  };

  handleLocationBlur = () => {
    this.setState({
      locationAutoCompleteData: {
        data: [],
      }
    })
  };

  onchangeLocation(product) {
    this.setState({
      locationAutoCompleteData: { data: [] },
      locationSearch: product.locations,
      isValid: true,
    });
  }

  onchangePurchaseExpense(event) {
    if (event.target.value !== "") {
      let PurchaseExpenseVal = validator.isNumeric(event.target.value);
      if (PurchaseExpenseVal != PurchaseExpenseVal) {
        this.setState({
          onTagPriceText: "Enter the correct purchase expenses",
          isValid: false,
        });
      } else {
        let totalCost = Number(event.target.value);
        const purchasePriceCalc =
          this.state.PurchasedPrice.length > 0
            ? Number(this.state.PurchasedPrice)
            : 0;
        totalCost = totalCost + purchasePriceCalc;
        this.setState({
          onPurchaseExpenseErrorText: "",
          purchase_expense: event.target.value,
          isValid: true,
          total_cost: totalCost,
        });
      }
    } else {
      const purchasePriceCalc =
        this.state.PurchasedPrice.length > 0
          ? Number(this.state.PurchasedPrice)
          : "";
      const totalCost = purchasePriceCalc;
      this.setState({
        onPurchaseExpenseErrorText: "",
        purchase_expense: event.target.value,
        isValid: true,
        total_cost: totalCost,
      });
    }
  }

  onchangeInventoryStatus(event) {
    this.setState({
      InventoryStatus: event.target.value,
      isValid: true,
    });
  }

  onchangeInventoryTitle(event) {
    this.setState({
      inventory_title: event.target.value,
    });
    if (event.target.value === "") {
      this.setState({
        isProduct_title_Changed: true,
      });
      setTimeout(() => {
        this.setState({
          isProduct_title_Changed: false,
        });
      }, 3000);
    }
  }

  onchangePurchaseExpenseNote(event) {
    this.setState({
      purchase_expense_note: event.target.value,
      isValid: true,
    });
  }

  onchangeNumberOfLinks(event) {
    this.setState({
      onNumberOfLinksErrorText: "",
      NumberOfLinks: event.target.value,
    });
    // }
  }

  onchangeWholeSalePrice(event) {
    // if (event.target.value !== "") {
    this.setState({
      onWholeSalePriceErrorText: "",
      WholeSalePrice: event.target.value,
    });
    // }
  }

  onchangeRetailPrice(event) {
    this.setState({
      onRetailPriceErrorText: "",
      RetailPrice: event.target.value,
    });
  }

  onchangeRetailWirePrice(event) {
    this.setState({
      onRetailWirePriceErrorText: "",
      RetailWirePrice: event.target.value,
    });
  }

  onchangePaymentDetails(event) {
    this.setState({
      onPaymentDetailsErrorText: "",
      paymentDetailsText: event.target.value,
    });
  }

  onProductChange(e, v, r) {
    switch (e) {
      case "product_selected":
        this.onchangeModelNumber(v.productid);
        break;
      case "input_changed":
        this.state.autocomplete.currentInput = v;
        this.setState({
          ...this.state,
        });

        this.state.autocomplete.loading = true;
        this.setState({
          ...this.state,
        });

        if (v.length >= 3 && r == "input") {
          this.props.searchProducts({ searchText: v }, (error, res) => {
            console.log(error, res);
          });
        }

        break;
    }
  }

  valuetext = (value) => {
    return "mm";
  };
  handleClickOpen = () => {
    this.setState({
      setOpen: "true",
    });
  };

  handleClose = () => {
    this.setState({
      setOpen: "false",
    });
  };

  handleTypeOfArchive = (event) => {
    if (event.target.checked == true) {
      this.setState({
        archive: "1",
      });
      localStorage.setItem("archive", "1");
    } else {
      this.setState({
        archive: "",
      });
      // this.setState({
      //   archiveDate: null,
      // })
      localStorage.setItem("archive", "");
    }
  };
  handleTypeOfWarranty = (event) => {
    if (event.target.checked == true) {
      this.setState({
        warranty_paper: "1",
      });
      localStorage.setItem("warranty_paper", "1");
    } else {
      this.setState({
        warranty_paper: "",
      });
      localStorage.setItem("warranty_paper", "");
    }
  };
  handleTypeOfService = (event) => {
    if (event.target.checked == true) {
      this.setState({
        isService: true,
      });
    } else {
      this.setState({
        isService: false,
      });
    }
  };

  handlePaidStatusCheck = (event) => {
    this.setState({
      paidStatus: event.target.checked,
    });
  };

  handleNotforSaleCheck = (event) => {
    this.setState({
      notforSale: event.target.checked,
    });
  };

  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({
        warrentyExpiryDate: true,
        WarentyPaper: true,
        warranty_paper: "1",
        typeOfPaper: "1",
      });
    } else {
      this.setState({
        warrentyExpiryDate: false,
        WarentyPaper: false,
        warranty_paper: "0",
        typeOfPaper: "0",
      });
    }
  };

  handleCheck1 = (event) => {
    if (event.target.checked == true) {
      this.setState({
        box: true,
      });
      localStorage.setItem("warranty_box", "1");
    } else {
      this.setState({
        box: false,
      });
      localStorage.setItem("warranty_box", "0");
    }
  };

  validateInput() {
    let onModelNumberErrorText = "";
    // let onPurchasedFromErrorText = ''
    // let onpurchaseDateErrorText = '';
    // let onPurchasedPriceErrorText = '';
    let onSerialNumberErrorText = "";
    let onConditionErrorText = "";
    let modificationDetailsError = "";
    // let onBandSizeErrorText = '';
    // let onNumberOfLinksErrorText = '';
    // let onDealerNotesErrorText = '';
    // let onWholeSalePriceErrorText = '';
    // let onRetailPriceErrorText = '';
    // let onRetailWirePriceErrorText = '';

    let isValid = true;

    // if (this.state.ModelNumber.length === 0) {
    //   onModelNumberErrorText = 'Enter search watches';
    //   isValid = false;
    // }
    // if (this.state.PurchasedFrom.length === 0) {
    //   onPurchasedFromErrorText = 'Enter PurchasedFrom';
    //   isValid = false;
    // }
    // if (this.state.purchaseDate.length === 0) {
    //   onpurchaseDateErrorText = 'Enter Purchased Date';
    //   isValid = false;
    // }
    // if (this.state.PurchasedPrice.length === 0) {
    //   onPurchasedPriceErrorText = 'Enter Purchased Price';
    //   isValid = false;
    // }
    if (
      this.state.SerialNumber.length === 0 &&
      this.state.type !== "misc_product"
    ) {
      onSerialNumberErrorText = "Enter Serial Number";
      isValid = false;
    }
    if (this.state.Condition.length === 0) {
      onConditionErrorText = "Enter Condition";
      isValid = false;
    }
    if (
      this.state.ismodified &&
      this.state.modification_deatils &&
      this.state.modification_deatils.length === 0
    ) {
      modificationDetailsError = "Enter Modiification Details";
      isValid = false;
    }
    // if (this.state.BandSize.length === 0) {
    //   onBandSizeErrorText = 'Enter BandSize';
    //   isValid = false;
    // }
    // if (this.state.NumberOfLinks.length === 0) {
    //   onNumberOfLinksErrorText = 'Enter Number Of Links';
    //   isValid = false;
    // }
    // if (this.state.WholeSalePrice.length === 0) {
    //   onWholeSalePriceErrorText = 'Enter WholeSale Price';
    //   isValid = false;
    // }
    // if (this.state.RetailPrice.length === 0) {
    //   onRetailPriceErrorText = 'Enter Retail Price';
    //   isValid = false;
    // }
    // if (this.state.RetailWirePrice.length === 0) {
    //   onRetailWirePriceErrorText = 'Enter Retail Wire Price';
    //   isValid = false;
    // }

    this.setState({
      //   ...this.state,
      // onModelNumberErrorText,
      //   onPurchasedFromErrorText,
      // onpurchaseDateErrorText,
      //   onPurchasedPriceErrorText,
      onSerialNumberErrorText,
      onConditionErrorText,
      modificationDetailsError,
      //   onBandSizeErrorText,
      //   onNumberOfLinksErrorText,
      //   onDealerNotesErrorText,
      //   onWholeSalePriceErrorText,
      //   onRetailPriceErrorText,
      //   onRetailWirePriceErrorText,
    });
    return isValid;
  }

  submitForm(event) {
    if (this.state.PurchasedFrom && !this.state.purchaseFromContactId) {
      this.purchasedFromRef?.current?.focus();
      return;
    } else if (this.state.MemoDetails && !this.state.memoContactId) {
      this.memoDetailsRef.current?.focus();
      return;
    } else if (this.state.PartnershipDetails && !this.state.partnerContactId) {
      this.partnershipDetailsRef.current?.focus();
      return;
    }
    let warrentydate = this.state.warranty_paper
      ? this.state.warranty_paper
      : this.state.inventoryDetailByIdDetails.warrantydate;
    let archive_date = this.state.archive
      ? this.state.archive
      : this.state.inventoryDetailByIdDetails.archive_date;
    let service_date = this.state.isService
      ? this.state.isService
      : this.state.inventoryDetailByIdDetails.service_date;
    let x = localStorage.getItem("warranty_box");
    if (warrentydate === "") {
      this.setState({ purchaseDate1: "" });
    } else {
      this.setState({
        purchaseDate1: this.state.purchaseDate1
          ? this.state.purchaseDate1
          : this.state.inventoryDetailByIdDetails.warrantydate,
      });
    }
    if (archive_date === "") {
      this.setState({ archiveDate: null });
    } else {
      this.setState({
        archiveDate: this.state.archiveDate
          ? this.state.archiveDate
          : this.state.inventoryDetailByIdDetails.archive_date,
      });
    }
    if (service_date === "") {
      this.setState({ serviceDate: null });
    } else {
      this.setState({
        serviceDate: this.state.serviceDate
          ? this.state.serviceDate
          : this.state.inventoryDetailByIdDetails.service_date,
      });
    }
    if (this.state.archive === false) {
      this.setState({ archiveDate: null });
    }
    if (this.state.warranty_paper != "1") {
      this.setState({ purchaseDate1: null });
      this.state.inventoryDetailByIdDetails.warrantydate = "";
    }
    if (!this.state.isService) {
      this.setState({ service_date: null });
    }
    if (this.validateInput()) {
      let technical_details = {};
      let attributes = {};
      const PId = this.props?.match?.params?.inventryId
        ? this.props?.match?.params?.inventryId
        : null;
      if (
        this.state.inventoryDetailByIdDetails.type === "misc_product" ||
        this.state.inventoryDetailByIdDetails.type === "misc_watch"
      ) {
        technical_details = {
          product_name: this.state.product_name ? this.state.product_name : "",
          brand_name: this.state.brand_name ? this.state.brand_name : "",
          description: this.state.description ? this.state.description : "",
          model_number: this.state.model_number ? this.state.model_number : "",
          product_title: this.state.product_title
            ? this.state.product_title
            : this.state.product_name
              ? this.state.product_name
              : "",
          case_material: this.state.case_material
            ? this.state.case_material
            : "",
          case_size: this.state.case_size ? this.state.case_size : "",
          dial_color: this.state.dial_color ? this.state.dial_color : "",
          band_material: this.state.band_material
            ? this.state.band_material
            : "",
        };
      } else {
        attributes = {
          reference: this.state.inventoryDetailByIdDetails.model,
          productname: this.state.inventoryDetailByIdDetails.productname,
          productfamily: this.state.inventoryDetailByIdDetails.productfamily,
          bezel: "",
          gender: "",
          glass: this.state.inventoryDetailByIdDetails?.invattr?.glass,
          shape: this.state.inventoryDetailByIdDetails?.invattr?.shape,
          waterresistant: this.state.inventoryDetailByIdDetails?.waterresistant,
          caliber: this.state.inventoryDetailByIdDetails?.invattr?.caliber,
          movement: this.state.inventoryDetailByIdDetails?.invattr?.movement,
          casesize: this.state.inventoryDetailByIdDetails?.invattr?.casesize,
          powerreserve:
            this.state.inventoryDetailByIdDetails?.invattr?.powerreserve,
          indices: this.state.inventoryDetailByIdDetails?.invattr?.indices,
          casematerial:
            this.state.inventoryDetailByIdDetails?.invattr?.casematerial,
          bandmaterial:
            this.state.inventoryDetailByIdDetails?.invattr?.bandmaterial,
          dialcolor: this.state.inventoryDetailByIdDetails?.invattr?.dialcolor,
          inventory_title:
            this.state.inventoryDetailByIdDetails?.invattr?.model,
          circa: "",
          productcode: "",
          luminous: "",
          bandcolor: "",
          bandtype: "",
          buckletype: "",
        };
      }
      let data = {
        product_id: this.state?.inventoryDetailByIdDetails?.productid || null,
        technical_details: technical_details
          ? JSON.stringify(technical_details)
          : null,
        attributes: attributes ? JSON.stringify(attributes) : null,
        purchase_price: this.state.PurchasedPrice
          ? this.state.PurchasedPrice
          : " ",
        purchase_date: this.state.purchaseDate ? this.state.purchaseDate : "",
        purchase_from: this.state.PurchasedFrom ? this.state.PurchasedFrom : "",
        purchasefromcontactid: this.state.purchaseFromContactId || null,
        memocontactid: this.state.memoContactId || null,
        partnercontactid: this.state.partnerContactId || null,
        serial_no: this.state.SerialNumber ? this.state.SerialNumber : "",
        inventory_type: this.state.InventoryType
          ? this.state.InventoryType
          : "",
        inventory_status: this.state.InventoryStatus
          ? this.state.InventoryStatus
          : "",
        memo_details: this.state.MemoDetails ? this.state.MemoDetails : "",
        partnership_detais: this.state.PartnershipDetails
          ? this.state.PartnershipDetails
          : "",
        condition: this.state.Condition
          ? this.state.Condition
          : this.state.inventoryDetailByIdDetails.condition,
        band_size: this.state.BandSize
          ? this.state.BandSize
          : this.state.inventoryDetailByIdDetails.bandsize,
        links: this.state.NumberOfLinks ? this.state.NumberOfLinks : "",
        note: this.state.DealerNotes ? this.state.DealerNotes : "",
        wholesale_price: this.state.WholeSalePrice
          ? this.state.WholeSalePrice
          : "",
        retail_price: this.state.RetailPrice ? this.state.RetailPrice : "",
        retailwire_price: this.state.RetailWirePrice
          ? this.state.RetailWirePrice
          : "",
        warranty_paper: this.state.warranty_paper
          ? this.state.warranty_paper
          : "",
        archive: this.state.archive ? this.state.archive : "",
        is_service: this.state.isService ? this.state.isService : "",
        service_date: this.state.serviceDate ? this.state.serviceDate : "",
        warranty_box: x ? x : "0",
        created_by: this.props.currentUser.userId,
        warranty_date: this.state.purchaseDate1,
        archive_date: this.state.archiveDate,
        brandname: this.state.brandname ? this.state.brandname : "",
        inventory_id: this.state.inventoryDetailByIdDetails?.inventoryid
          ? this.state.inventoryDetailByIdDetails?.inventoryid
          : PId,
        public_dealer_note: this.state.public_dealer_note
          ? this.state.public_dealer_note
          : "",
        memo_number: this.state.updatememo ? this.state.updatememo : "",
        partnership_number: this.state.updatepartnershipnumber || "",
        total_cost: this.state.total_cost ? this.state.total_cost : "",
        tag_price: this.state.tag_price ? this.state.tag_price : "",
        modification_deatils: this.state.modification_deatils
          ? this.state.modification_deatils
          : "",
        purchase_expense: this.state.purchase_expense
          ? this.state.purchase_expense
          : "",
        purchase_expense_note: this.state.purchase_expense_note
          ? this.state.purchase_expense_note
          : "",
        paperType: this.state.typeOfPaper || "",
        inventory_location: this.state.locationSearch || "",
        inventory_title: this.state.inventory_title
          ? this.state.inventory_title
          : this.state.type === null
            ? this.state.inventoryDetailByIdDetails &&
              this.state.inventoryDetailByIdDetails.invattr &&
              this.state.inventoryDetailByIdDetails.invattr.model
              ? this.state.inventoryDetailByIdDetails.invattr.model
              : ""
            : "",
        quantity: this.state.quantity || "1",
        // "ismodified": this.state.inventory_title.toLowerCase() !== this.state.title_without_edit.toLowerCase() ? true : false,
        ismodified: this.state.ismodified,
        purchase_payment_date: this.state.paymentDate ?? null,
        purchase_payment_duedate: this.state.purchaseDueDate ?? null,
        purchase_payment_details: this.state.paymentDetailsText,
        purchase_invoice_paid: this.state.paidStatus,
        is_include_dealerfeed: this.state.dealerInv,
        // 'is_notsale': this.state.notforSale
      };
      if (data.inventory_id === "undefined") {
        data.inventory_id = PId;
      }
      if (this.state.purchaseInvoice && this.state.purchaseInvoice !== null) {
        data.purchaseInvoiceImages = JSON.stringify(this.state.purchaseInvoice);
      } // if (this.state.inventoryDetailByIdDetails.productid.length === 0) {
      //   delete data.product_id;
      // }
      if (attributes.length === 0) {
        delete data.attributes;
      }
      if (technical_details.length === 0) {
        delete data.technical_details;
      }
      if (this.state.PurchasedPrice.length === 0) {
        delete data.purchase_price;
      }
      if (this.state.InventoryStatus.length === 0) {
        delete data.inventory_status;
      }
      if (this.state.archive.length === 0) {
        delete data.archive;
      }
      if (!this.state.isService) {
        delete data.is_service;
      }
      if (this.state.MemoDetails.length === 0) {
        delete data.memo_details;
      }
      if (this.state.PartnershipDetails.length === 0) {
        delete data.partnership_detais;
      }
      if (
        this.state.purchaseDate === "Invalid date" ||
        this.state.purchaseDate === null
      ) {
        delete data.purchase_date;
      }
      if (this.state.PurchasedFrom.length === 0) {
        delete data.purchase_from;
      }
      if (!this.state.purchaseFromContactId) {
        delete data.purchasefromcontactid;
      }
      if (!this.state.memoContactId) {
        delete data.memocontactid;
      }
      if (!this.state.partnerContactId) {
        delete data.partnercontactid;
      }
      if (this.state.SerialNumber.length === 0) {
        delete data.serialno;
      }
      if (this.state.Condition.length === 0) {
        delete data.condition;
      }
      if (this.state.BandSize.length === 0) {
        delete data.band_size;
      }
      if (this.state.NumberOfLinks.length === 0) {
        delete data.links;
      }
      if (this.state.DealerNotes.length === 0) {
        delete data.note;
      }
      if (this.state.WholeSalePrice.length === 0) {
        delete data.wholesale_price;
      }
      if (this.state.RetailPrice.length === 0) {
        delete data.retail_price;
      }
      if (this.state.RetailWirePrice.length === 0) {
        delete data.retailwire_price;
      }
      if (this.state.warranty_paper.length === 0) {
        delete data.warranty_paper;
        data.warranty_date = null;
      }

      if (
        this.state.purchaseDate1 === "Invalid date" ||
        this.state.purchaseDate1 === null ||
        this.state.purchaseDate1 === ""
      ) {
        delete data.warranty_date;
      }
      if (
        this.state.archiveDate === "Invalid date" ||
        this.state.archiveDate === null
      ) {
        delete data.archive_date;
      }
      if (
        this.state.serviceDate === "Invalid date" ||
        this.state.serviceDate === null
      ) {
        delete data.service_date;
      }
      if (this.props.currentUser.userId) {
        delete data.created_by;
      }

      if (!this.state.warranty_paper) {
        delete data.warranty_paper;
        data.warranty_date = null;
      }

      if (this.state.total_cost.length === 0) {
        delete data.total_cost;
      }
      if (this.state.tag_price.length === 0) {
        delete data.tag_price;
      }
      if (this.state.purchase_expense.length === 0) {
        delete data.purchase_expense;
      }
      if (this.state.purchase_expense_note.length === 0) {
        delete data.purchase_expense_note;
      }
      // if (this.state.locationSearch.length === 0) {
      //   delete data.inventory_location;
      // }
      if (this.state.public_dealer_note.length === 0) {
        delete data.public_dealer_note;
      }
      if (this.state.InventoryStatus.length === 0) {
        delete data.inventory_status;
      }
      if (
        (this.state.MemoDetails && this.state.MemoDetails.length === 0) ||
        !this.state.MemoDetails
      ) {
        delete data.memo_details;
      }
      if (
        !this.state.updatememo ||
        (this.state.updatememo && this.state.updatememo.length === 0)
      ) {
        delete data.memo_number;
      }
      if (
        !this.state.updatepartnershipnumber ||
        (this.state.updatepartnershipnumber &&
          this.state.updatepartnershipnumber.length === 0)
      ) {
        delete data.partnership_number;
      }
      if (
        (this.state.PartnershipDetails &&
          this.state.PartnershipDetails.length === 0) ||
        !this.state.PartnershipDetails
      ) {
        delete data.partnership_detais;
      }
      if (this.state.modification_deatils.length === 0) {
        delete data.modification_deatils;
      }
      if (this.state.InventoryType.length === 0) {
        delete data.inventory_type;
      }
      if (data.warranty_date === null || data.warranty_date === "null") {
        delete data.warranty_date;
      }
      if (
        !data.purchase_payment_date ||
        !this.state.paymentDate ||
        !this.state.paidStatus
      ) {
        delete data.purchase_payment_date;
      }
      if (
        !data.purchase_payment_duedate ||
        !this.state.purchaseDueDate ||
        this.state.paidStatus
      ) {
        delete data.purchase_payment_duedate;
      }

      let formData = new FormData();
      for (const key of Object.keys(this.state.bulkUploadFile)) {
        formData.append("image", this.state.bulkUploadFile[key]);
      }
      for (const key in data) {
        formData.set(key, data[key]);
      }
      searchinventry.updateInventryDetails(formData).then((res) => {
        if (res && res.data && res.data.success === 1) {
          localStorage.removeItem("warranty_box");
          toast(res.data.data, {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
          this.props.history.push(
            "/inventory-detail/" +
            data?.inventory_id +
            "/" +
            this.props.currentUser.userId,
            this.props.history.location.state
          );
        } else {
          toast("Same Model and Serial Number", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
    }
  }

  checloaader() {
    this.setState({ loaded: false });
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 3000);
  }

  handleDateChange = (date, e) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          ["PPD"]: "",
        },
        paymentDate: e,
      });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ["PPD"]: `Invalid Date`,
        },
      });
    }
  };

  handleDateChange1 = (date, e, type) => {
    if (date === null || this.isValidDate(date)) {
      this.setState({
        error: {
          ...this.state.error,
          [type]: "",
        },
      });
      if (type === "Warranty") {
        this.setState({
          purchaseDate1: e,
        });
      } else if (type === "Archive") {
        this.setState({
          archiveDate: e,
        });
      } else if (type === "Service") {
        this.setState({
          serviceDate: e,
        });
      }
    } else {
      this.setState({
        error: {
          ...this.state.error,
          [type]: `Invalid Date`,
        },
      });
    }
  };

  handleTypeOfPaperCheck = (event) => {
    const { value } = event.target;
    if (value === "Warranty") {
      this.setState({
        warrentyExpiryDate: true,
        WarentyPaper: true,
        warranty_paper: "1",
      });
    } else {
      this.setState({
        archivePaperSet: "1",
        archivePaper: true,
      });
    }
  };

  render() {
    const { searchItems, cursor, item } = this.state;
    const handleChange = (event, newValue) => {
      this.setState({
        onBandSizeErrorText: "",
        BandSize: newValue,
        isValid: true,
        value: newValue,
      });
    };
    const TextFieldComponent = (props) => {
      return <TextField {...props} disabled={true} />;
    };
    const marks = [
      {
        value: 0,
        label: "0mm",
      },
    ];

    return (
      <React.Fragment>
        <Paper>
          <Header />
          {this.state.loaded === false ? (
            <div className="d-spinner">
              <SpinnerRoundFilled
                size={51}
                thickness={125}
                speed={80}
                color="rgba(57, 107, 172, 1)"
                className="spp"
                enabled={this.state.loaded === false}
              />
            </div>
          ) : (
            this.state.type === null && (
              <div className="space-1">
                <form
                  //  onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}
                  id="add_inventory_form"
                >
                  <Container>
                    <div className="space-left-50">
                      <Typography
                        variant="h6"
                        component="p"
                        className="primary-text uppercase"
                      ></Typography>
                    </div>
                    <div className="space-left-50">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                          >
                            <Grid>
                              <ArrowBackIcon
                                style={{
                                  color: "action",
                                  paddingRight: "15px",
                                  marginTop: "5px",
                                  fontSize: 40,
                                  cursor: "pointer",
                                }}
                                onClick={this.backNavigation}
                              ></ArrowBackIcon>
                            </Grid>
                            <Grid>
                              <div onClick={this.handleClickBreadCrumbs}>
                                <Stack spacing={1}>
                                  <Breadcrumbs
                                    aria-label="breadcrumb"
                                    separator={
                                      <span className="sperator">›</span>
                                    }
                                  >
                                    <Link
                                      className="link"
                                      style={{
                                        fontSize: "14px",
                                        color: "#CEA05F",
                                        textDecoration: "underline !important",
                                        backgroundColor: "white",
                                      }}
                                      onClick={this.backNavigation}
                                    >
                                      My Inventory
                                    </Link>

                                    <Link
                                      className="link"
                                      style={{
                                        fontSize: "14px",
                                        color: "#CEA05F",
                                        textDecoration: "underline !important",
                                        backgroundColor: "white",
                                      }}
                                      onClick={this.handleGoBack}
                                    >
                                      Inventory Details
                                    </Link>
                                    <Link
                                      style={{
                                        fontSize: "14px",
                                        color: "#9e9e9e",
                                        backgroundColor: "white",
                                      }}
                                      aria-current="page"
                                    >
                                      Edit Inventory Details
                                    </Link>
                                  </Breadcrumbs>
                                </Stack>
                              </div>
                            </Grid>
                          </Grid>

                          <div>
                            <FormControl
                              className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                              style={{ margin: "10px 0px" }}
                            >
                              <FormHelperText className="error-o">
                                {this.state.onModelNumberErrorText}
                              </FormHelperText>
                            </FormControl>
                            <Loader loaded={this.state.loaded}>
                              <Card className="form-width-70 margin-t-b-5 margin-p-b-20">
                                <CardContent>
                                  <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    className="color-BCBCBC"
                                  >
                                    TECHNICAL DETAILS
                                  </Typography>
                                  <Typography>
                                    <b>Model Name :</b>{" "}
                                    {this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                      this.state.inventoryDetailByIdDetails
                                        .invattr.model
                                      ? this.state.inventoryDetailByIdDetails
                                        .invattr.model
                                      : ""}
                                    <br />
                                    <b>Brand Name :</b>{" "}
                                    {this.state.brandname &&
                                      this.state.brandname
                                      ? this.state.brandname
                                      : ""}
                                    <br />
                                    <b>Model Number :</b>{" "}
                                    {this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                      this.state.inventoryDetailByIdDetails
                                        .invattr.reference
                                      ? this.state.inventoryDetailByIdDetails
                                        .invattr.reference
                                      : ""}
                                    <br />
                                    <b>Case Material :</b>{" "}
                                    {this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                      this.state.inventoryDetailByIdDetails
                                        .invattr.casematerial
                                      ? this.state.inventoryDetailByIdDetails
                                        .invattr.casematerial
                                      : ""}
                                    <br />
                                    <b>Case Size :</b>{" "}
                                    {this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                      this.state.inventoryDetailByIdDetails
                                        .invattr.casesize
                                      ? this.state.inventoryDetailByIdDetails
                                        .invattr.casesize
                                      : ""}
                                    <br />
                                    <b>Dial Color :</b>{" "}
                                    {this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                      this.state.inventoryDetailByIdDetails
                                        .invattr.dialcolor
                                      ? this.state.inventoryDetailByIdDetails
                                        .invattr.dialcolor
                                      : ""}
                                    <br />
                                    <b>Band Material :</b>{" "}
                                    {this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                      this.state.inventoryDetailByIdDetails
                                        .invattr.bandmaterial
                                      ? this.state.inventoryDetailByIdDetails
                                        .invattr.bandmaterial
                                      : ""}
                                    <br />
                                    <b>Retail Price :</b>{" "}
                                    {this.state.retailAmount
                                      ? this.state.retailAmount
                                      : "List Price Not Available"}
                                    <br />
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Loader>
                            <br />

                            <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                INVENTORY TITLE ITEM
                              </InputLabel>

                              <Input
                                id="component-simple"
                                onChange={this.onchangeInventoryTitle}
                                value={
                                  this.state.inventory_title
                                    ? this.state.inventory_title
                                    : this.state.isProduct_title_Changed
                                      ? this.state.inventory_title
                                      : this.state.inventoryDetailByIdDetails
                                        .invattr &&
                                        this.state.inventoryDetailByIdDetails
                                          .invattr.model
                                        ? this.state.inventoryDetailByIdDetails
                                          .invattr.model
                                        : ""
                                }
                              ></Input>

                              <FormHelperText className="error-o"></FormHelperText>
                            </FormControl>
                            {/* {isActionAllowed(
                              this.state.viewData,
                              "purchase_from"
                            ) && (
                                <FormControl
                                  className="form-width-70 "
                                  style={{ marginTop: "8px" }}
                                >
                                  <InputLabel
                                    htmlFor="component-simple"
                                    style={{ color: "#424242" }}
                                  >
                                    PURCHASED FROM
                                  </InputLabel>
                                  <Input
                                    id="component-simple"
                                    onChange={this.onchangePurchasedFrom}
                                    style={{ marginBottom: "10px" }}
                                    disabled={this.state.isDisabled}
                                    value={
                                      this.state.PurchasedFrom
                                        ? this.state.PurchasedFrom
                                        : ""
                                    }
                                  />
                                  <FormHelperText className="error-o">
                                    {this.state.onPurchasedFromErrorText}
                                  </FormHelperText>
                                </FormControl>
                              )} */}
                            {isActionAllowed(
                              this.state.viewData,
                              "purchase_from"
                            ) && (
                                <>
                                  <FormControl
                                    className="form-width-70 "
                                    style={{ marginTop: "8px" }}
                                  >
                                    <InputLabel
                                      htmlFor="component-simple"
                                      shrink={this.state.PurchasedFrom ? true : false}
                                      style={{ color: "#424242" }}
                                    >
                                      PURCHASED FROM
                                    </InputLabel>
                                    <AntdInput
                                      id="PurchasedFrom"
                                      type="text"
                                      autoComplete="off"
                                      ref={this.purchasedFromRef}
                                      value={this.state.PurchasedFrom}
                                      style={{
                                        marginBottom: "0px",
                                        marginTop: "10px",
                                        fontSize: "16px",
                                        paddingLeft: 0,
                                      }}
                                      onChange={(e) =>
                                        this.onchangeContactSuggestion(
                                          e,
                                          "PurchasedFrom",
                                          ["supplier", "memo_supplier"]
                                        )
                                      }
                                      bordered={false}
                                      disabled={this.state.isDisabled}
                                    />
                                    {/* <FormHelperText className="error-o">{this.state.onPurchasedFromErrorText}</FormHelperText> */}
                                    <Divider style={{ background: "#ada8a8", marginTop: "5px", }} />
                                    <div className="contactSearchIcon">
                                      <Grid container>
                                        <Grid item xs={6} sm={6} md={6}>
                                          <>
                                            {!this.state.PurchasedFrom ? (
                                              <SearchIcon
                                                style={{ cursor: "pointer" }}
                                              />
                                            ) : (
                                              <>
                                                {this.state.fieldLoader ? (
                                                  <CircularProgress
                                                    style={{
                                                      width: "25px",
                                                      height: "25px",
                                                    }}
                                                  />
                                                ) : (
                                                  <ClearIcon
                                                    id="clearBtn"
                                                    onClick={() =>
                                                      this.clearContactSuggestion(
                                                        "PurchasedFrom"
                                                      )
                                                    }
                                                    style={{ cursor: "pointer" }}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </FormControl>
                                  {this.state.purchasedFromArray &&
                                    this.state.purchasedFromArray.length > 0 &&
                                    this.state.PurchasedFrom?.length > 0 ? (
                                    <div>
                                      <AntdPopover
                                        visible={this.state.isPopOverPurchasedFrom}
                                        content={
                                          <div
                                            style={{
                                              height: "200px",
                                              overflow: "auto",
                                            }}
                                          >
                                            {this.state.dataset
                                              ?.slice(0, 15)
                                              .map((value, key) => {
                                                return value.map((el, index) => {
                                                  if (index < 1) {
                                                    const displayName = `${el?.firstname ?? ""
                                                      } ${el?.lastname ?? ""}`;
                                                    return (
                                                      <>
                                                        {/* <Accordion>
                                                          <AccordionSummary
                                                            aria-controls="panel1d-content"
                                                            id="panel1d-header"
                                                          >
                                                            <Typography
                                                              style={{
                                                                padding: 10,
                                                                width: 300,
                                                                cursor: "pointer",
                                                              }}
                                                            >
                                                              {(el?.businessname ||
                                                                displayName) ??
                                                                ""}
                                                            </Typography>
                                                          </AccordionSummary> */}

                                                        {this.state.purchasedFromArray
                                                          ?.filter(
                                                            (v) =>
                                                              v.email == el.email
                                                          )
                                                          .map((el, index) => {
                                                            return (
                                                              <AccordionDetails
                                                                key={index}
                                                              >
                                                                <Typography
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    this.handleContactSelection(
                                                                      el,
                                                                      "PurchasedFrom"
                                                                    )
                                                                  }
                                                                >
                                                                  {this.handleContact(
                                                                    el
                                                                  )}
                                                                </Typography>
                                                              </AccordionDetails>
                                                            );
                                                          })}
                                                        {/* </Accordion> */}
                                                      </>
                                                    );
                                                  }
                                                });
                                              })}
                                          </div>
                                        }
                                        trigger="click"
                                        placement="bottomRight"
                                        // placement="bottomLeft"
                                        onVisibleChange={this.handleVisibleChange}
                                      >
                                        <div
                                          style={{
                                            width: 0,
                                            height: 0,
                                            position: "absolute",
                                          }}
                                        />
                                      </AntdPopover>
                                    </div>
                                  ) :
                                    (this.state.PurchasedFrom.length > 2 &&
                                      !this.state.purchasedFromArray.length &&
                                      !this.state.fieldLoader && !this.state.purchaseFromContactId) &&
                                    <AntdPopover
                                      // id={column.id}
                                      visible={this.state.PurchasedFrom?.length > 2 && !this.state.purchasedFromArray.length &&
                                        !this.state.fieldLoader && !this.state.purchaseFromContactId}
                                      content={
                                        <div
                                          style={{ overflow: "auto" }}
                                        >
                                          <a
                                            className="dataItem"
                                            href="javascript:void(0)"
                                            onClick={() => {
                                              // this.setState({
                                              //   PurchasedFrom: "",
                                              // });
                                              const typeData = [
                                                { id: "supplier", value: "Supplier" },
                                                { id: "memo_supplier", value: "Memo Supplier" }
                                              ];
                                              const encodedTypeData = btoa(JSON.stringify(typeData));
                                              window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                              // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                            }
                                            }
                                            style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                          >
                                            Click here to Create New Contact
                                          </a>
                                        </div>
                                      }
                                      trigger="click"
                                      placement="bottom"
                                    // overlayStyle={{ marginTop: "5px" }}
                                    >
                                      {/* <div
                                        style={{
                                          width: 0,
                                          height: 0,
                                          position: "absolute",
                                        }}
                                      /> */}
                                    </AntdPopover>
                                  }
                                </>
                              )}
                            {isActionAllowed(
                              this.state.viewData,
                              "purchase_date"
                            ) && (
                                <div>
                                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <KeyboardDatePicker
                                        // className="color-BCBCBC"
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/dd/yyyy"
                                        disableFuture={true}
                                        value={this.state.purchaseDate || null}
                                        onChange={this.handlePurchaseDate}
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        // TextFieldComponent={TextFieldComponent}
                                        placeholder="PURCHASED DATE - MM/DD/YYYY"
                                        helperText={
                                          this.state.error["PD"]
                                            ? "Invalid date"
                                            : ""
                                        }
                                        error={this.state.error["PD"]}
                                      />
                                    </MuiPickersUtilsProvider>
                                    <FormHelperText className="error-o">
                                      {this.state.onpurchaseDateErrorText}
                                    </FormHelperText>
                                  </FormControl>
                                </div>
                              )}
                            <div className="space-top-8">
                              <FormControl
                                className="form-width-70 margin-t-b-20 margin-p-b-20"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "2rem",
                                }}
                              >
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  style={{ color: "#424242" }}
                                >
                                  PURCHASE PAYMENT STATUS
                                </FormLabel>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="checkedB1"
                                      value={this.state.paidStatus}
                                      className="float-right"
                                      checked={this.state.paidStatus}
                                      onChange={this.handlePaidStatusCheck}
                                    />
                                  }
                                  label="Paid"
                                />
                              </FormControl>
                              {this.state.paidStatus ? (
                                <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      margin="normal"
                                      // label='PURCHASE PAYMENT DATE'
                                      id="date-picker-purchase"
                                      format="MM/dd/yyyy"
                                      disableFuture={true}
                                      value={this.state.paymentDate || null}
                                      onChange={this.handleDateChange}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                      InputLabelProps={{
                                        // shrink: this.state.paymentDate ? true : false,
                                        style: { color: "black" },
                                      }}
                                      placeholder="PURCHASE PAYMENT DATE - MM/DD/YYYY"
                                      helperText={
                                        this.state.error["PPD"]
                                          ? "Invalid date"
                                          : ""
                                      }
                                      error={this.state.error["PPD"]}
                                    />
                                  </MuiPickersUtilsProvider>
                                  <FormHelperText className="error-o">
                                    {this.state.onpurchaseDateErrorText}
                                  </FormHelperText>
                                </FormControl>
                              ) : (
                                <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      margin="normal"
                                      // label='PURCHASE PAYMENT DUE DATE'
                                      id="date-picker-dialog"
                                      format="MM/dd/yyyy"
                                      // disableFuture={true}

                                      value={this.state.purchaseDueDate || null}
                                      onChange={this.handleDueDateChange}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                      placeholder="PURCHASE PAYMENT DUE DATE - MM/DD/YYYY"
                                      InputLabelProps={{
                                        // shrink: this.state.purchaseDueDate ? true : false,
                                        style: { color: "black" },
                                      }}
                                      helperText={
                                        this.state.error["PPDD"]
                                          ? "Invalid date"
                                          : ""
                                      }
                                      error={this.state.error["PPDD"]}
                                    />
                                  </MuiPickersUtilsProvider>
                                  <FormHelperText className="error-o">
                                    {this.state.onpurchaseDueDateErrorText}
                                  </FormHelperText>
                                </FormControl>
                              )}
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  PURCHASE PAYMENT DETAILS
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  multiline
                                  onChange={this.onchangePaymentDetails}
                                  value={
                                    this.state.paymentDetailsText
                                      ? this.state.paymentDetailsText
                                      : ""
                                  }
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onPaymentDetailsErrorText}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            {isActionAllowed(
                              this.state.viewData,
                              "purchase_price"
                            ) && (
                                <FormControl
                                  className="form-width-70 "
                                  style={{ marginTop: "8px" }}
                                >
                                  <InputLabel
                                    htmlFor="component-simple"
                                    style={{ color: "#424242" }}
                                  >
                                    PURCHASED PRICE ($)
                                  </InputLabel>
                                  <Input
                                    id="component-simple"
                                    onChange={this.onchangePurchasedPrice}
                                    value={
                                      this.state.PurchasedPrice
                                        ? this.state.PurchasedPrice
                                        : ""
                                    }
                                    style={{ marginBottom: "10px" }}
                                    disabled={this.state.isDisabled}
                                  />
                                  <FormHelperText className="error-o">
                                    {this.state.onPurchasedPriceErrorText}
                                  </FormHelperText>
                                </FormControl>
                              )}
                            {isActionAllowed(
                              this.state.viewData,
                              "purchase_expense"
                            ) && (
                                <>
                                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                    <InputLabel
                                      htmlFor="component-simple"
                                      style={{ color: "#424242" }}
                                    >
                                      PURCHASE EXPENSE ($)
                                    </InputLabel>
                                    <Input
                                      id="component-simple"
                                      onChange={this.onchangePurchaseExpense}
                                      value={
                                        this.state.purchase_expense
                                          ? this.state.purchase_expense
                                          : ""
                                      }
                                    />
                                    <FormHelperText className="error-o"></FormHelperText>
                                  </FormControl>
                                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                    <InputLabel
                                      htmlFor="component-simple"
                                      style={{ color: "#424242" }}
                                    >
                                      {" "}
                                      PURCHASE EXPENSE NOTE
                                    </InputLabel>
                                    <Input
                                      id="component-simple"
                                      onChange={this.onchangePurchaseExpenseNote}
                                      value={
                                        this.state.purchase_expense_note
                                          ? this.state.purchase_expense_note
                                          : ""
                                      }
                                    // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                    <FormHelperText className="error-o"></FormHelperText>
                                  </FormControl>
                                </>
                              )}

                            {isActionAllowed(
                              this.state.viewData,
                              "total_cost"
                            ) && (
                                <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                  <InputLabel
                                    htmlFor="component-simple"
                                    style={{ color: "#424242" }}
                                  >
                                    {" "}
                                    TOTAL COST ($)
                                  </InputLabel>
                                  <Input
                                    id="component-simple"
                                    disabled
                                    value={this.state.total_cost}
                                  // onChange={this.onchangeTotalCost}
                                  // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                  ></Input>
                                  <FormHelperText className="error-o"></FormHelperText>
                                </FormControl>
                              )}

                            <FormControl
                              className="form-width-70 "
                              style={{ marginTop: "8px" }}
                            >
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                SERIAL NUMBER
                              </InputLabel>
                              <Input
                                id="component-simple"
                                value={
                                  this.state.SerialNumber
                                    ? this.state.SerialNumber
                                    : ""
                                }
                                onChange={this.onchangeSerialNumber}
                                style={{ marginBottom: "10px" }}
                                disabled={this.state.isDisabled}
                              />
                              <FormHelperText className="error-o">
                                {this.state.onSerialNumberErrorText}
                              </FormHelperText>
                            </FormControl>

                            <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                INVENTORY TYPE
                              </InputLabel>
                              <Select
                                id="component-simple"
                                defaultValue={this.state.InventoryType}
                                value={this.state.InventoryType}
                                onChange={(e) =>
                                  this.setState({
                                    InventoryType: e.target.value,
                                    MemoDetails: "",
                                    PartnershipDetails: "",
                                    memoContactId: null,
                                    partnerContactId: null,
                                  })
                                }
                              >
                                <MenuItem value="1">Regular Inventory</MenuItem>
                                <MenuItem value="2">Memo Inventory</MenuItem>
                                <MenuItem value="3">Partnership Inventory</MenuItem>
                              </Select>
                            </FormControl>

                            {this.state.InventoryType === "2" && (
                              <>
                                <FormControl
                                  className="form-width-70 "
                                  style={{ marginTop: "8px" }}
                                >
                                  <InputLabel
                                    htmlFor="component-simple"
                                    shrink={this.state.MemoDetails ? true : false}
                                    style={{ color: "#424242" }}
                                  >
                                    Memo Details
                                  </InputLabel>
                                  <AntdInput
                                    id="MemoDetails"
                                    type="text"
                                    ref={this.memoDetailsRef}
                                    autoComplete="off"
                                    value={this.state.MemoDetails}
                                    style={{
                                      marginBottom: "0px",
                                      marginTop: "10px",
                                      fontSize: "16px",
                                      paddingLeft: 0,
                                    }}
                                    onChange={(e) =>
                                      this.onchangeContactSuggestion(
                                        e,
                                        "MemoDetails",
                                        ["memo_supplier"]
                                      )
                                    }
                                    bordered={false}
                                  />
                                  <Divider style={{ background: "#ada8a8", marginTop: "5px" }} />
                                  <div className="contactSearchIcon">
                                    <Grid container>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <>
                                          {!this.state.MemoDetails ? (
                                            <SearchIcon
                                              style={{ cursor: "pointer" }}
                                            />
                                          ) : (
                                            <>
                                              {this.state.fieldLoader ? (
                                                <CircularProgress
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                  }}
                                                />
                                              ) : (
                                                <ClearIcon
                                                  id="clearBtn"
                                                  onClick={() =>
                                                    this.clearContactSuggestion(
                                                      "MemoDetails"
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                />
                                              )}
                                            </>
                                          )}
                                        </>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </FormControl>
                                {this.state.memoDetailsArray &&
                                  this.state.memoDetailsArray.length > 0 &&
                                  this.state.MemoDetails?.length > 0 ? (
                                  <div>
                                    <AntdPopover
                                      visible={this.state.isPopOverMemoDetails}
                                      content={
                                        <div
                                          style={{
                                            height: "200px",
                                            overflow: "auto",
                                          }}
                                        >
                                          {this.state.dataset
                                            ?.slice(0, 15)
                                            .map((value, key) => {
                                              return value.map((el, index) => {
                                                if (index < 1) {
                                                  const displayName = `${el?.firstname ?? ""
                                                    } ${el?.lastname ?? ""}`;
                                                  return (
                                                    <>
                                                      {/* <Accordion>
                                                        <AccordionSummary
                                                          aria-controls="panel1d-content"
                                                          id="panel1d-header"
                                                        >
                                                          <Typography
                                                            style={{
                                                              padding: 10,
                                                              width: 300,
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {(el?.businessname ||
                                                              displayName) ??
                                                              ""}
                                                          </Typography>
                                                        </AccordionSummary> */}

                                                      {this.state.memoDetailsArray
                                                        ?.filter(
                                                          (v) =>
                                                            v.email == el.email
                                                        )
                                                        .map((el, index) => {
                                                          return (
                                                            <AccordionDetails
                                                              key={index}
                                                            >
                                                              <Typography
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() =>
                                                                  this.handleContactSelection(
                                                                    el,
                                                                    "MemoDetails"
                                                                  )
                                                                }
                                                              >
                                                                {this.handleContact(
                                                                  el
                                                                )}
                                                              </Typography>
                                                            </AccordionDetails>
                                                          );
                                                        })}
                                                      {/* </Accordion> */}
                                                    </>
                                                  );
                                                }
                                              });
                                            })}
                                        </div>
                                      }
                                      trigger="click"
                                      placement="bottomRight"
                                      // placement="bottomLeft"
                                      onVisibleChange={this.handleVisibleChange}
                                    >
                                      <div
                                        style={{
                                          width: 0,
                                          height: 0,
                                          position: "absolute",
                                        }}
                                      />
                                    </AntdPopover>
                                  </div>
                                ) :
                                  (this.state.MemoDetails?.length > 2
                                    && !this.state.memoDetailsArray.length
                                    && !this.state.fieldLoader && !this.state.memoContactId) &&
                                  <AntdPopover
                                    // id={column.id}
                                    visible={this.state.MemoDetails?.length > 2 && !this.state.memoDetailsArray.length &&
                                      !this.state.fieldLoader && !this.state.memoContactId}
                                    content={
                                      <div
                                        style={{ overflow: "auto" }}
                                      >
                                        <a
                                          className="dataItem"
                                          onClick={() => {
                                            // this.setState({
                                            //   MemoDetails: "",
                                            // });
                                            const typeData = [{ id: "memo_supplier", value: "Memo Supplier" }];
                                            const encodedTypeData = btoa(JSON.stringify(typeData));
                                            window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                            // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                          }
                                          }
                                          style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                        >
                                          Click here to Create New Contact
                                        </a>
                                      </div>
                                    }
                                    trigger="click"
                                    placement="bottom"
                                  // overlayStyle={{ marginTop: "5px" }}
                                  >
                                    {/* <div
                                      style={{
                                        width: 0,
                                        height: 0,
                                        position: "absolute",
                                      }}
                                    /> */}
                                  </AntdPopover>
                                }
                              </>
                            )}
                            {this.state.InventoryType === "3" && (
                              <>
                                <FormControl
                                  className="form-width-70 "
                                  style={{ marginTop: "8px" }}
                                >
                                  <InputLabel
                                    htmlFor="component-simple"
                                    shrink={
                                      this.state.PartnershipDetails ? true : false
                                    }
                                    style={{ color: "#424242" }}
                                  >
                                    Partnership Details
                                  </InputLabel>
                                  <AntdInput
                                    id="PartnershipDetails"
                                    type="text"
                                    ref={this.partnershipDetailsRef}
                                    autoComplete="off"
                                    value={this.state.PartnershipDetails}
                                    style={{
                                      marginBottom: "0px",
                                      marginTop: "10px",
                                      fontSize: "16px",
                                      paddingLeft: 0,
                                    }}
                                    onChange={(e) =>
                                      this.onchangeContactSuggestion(
                                        e,
                                        "PartnershipDetails",
                                        ["partner"]
                                      )
                                    }
                                    bordered={false}
                                  />
                                  <Divider style={{ background: "#ada8a8", marginTop: "5px" }} />
                                  <div className="contactSearchIcon">
                                    <Grid container>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <>
                                          {!this.state.PartnershipDetails ? (
                                            <SearchIcon
                                              style={{ cursor: "pointer" }}
                                            />
                                          ) : (
                                            <>
                                              {this.state.fieldLoader ? (
                                                <CircularProgress
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                  }}
                                                />
                                              ) : (
                                                <ClearIcon
                                                  id="clearBtn"
                                                  onClick={() =>
                                                    this.clearContactSuggestion(
                                                      "PartnershipDetails"
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                />
                                              )}
                                            </>
                                          )}
                                        </>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </FormControl>
                                {this.state.partnershipDetailsArray &&
                                  this.state.partnershipDetailsArray.length > 0 &&
                                  this.state.PartnershipDetails?.length > 0 ? (
                                  <div>
                                    <AntdPopover
                                      visible={
                                        this.state.isPopOverPartnershipDetails
                                      }
                                      content={
                                        <div
                                          style={{
                                            height: "200px",
                                            overflow: "auto",
                                          }}
                                        >
                                          {this.state.dataset
                                            ?.slice(0, 15)
                                            .map((value, key) => {
                                              return value.map((el, index) => {
                                                if (index < 1) {
                                                  const displayName = `${el?.firstname ?? ""
                                                    } ${el?.lastname ?? ""}`;
                                                  return (
                                                    <>
                                                      {/* <Accordion>
                                                        <AccordionSummary
                                                          aria-controls="panel1d-content"
                                                          id="panel1d-header"
                                                        >
                                                          <Typography
                                                            style={{
                                                              padding: 10,
                                                              width: 300,
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {(el?.businessname ||
                                                              displayName) ??
                                                              ""}
                                                          </Typography>
                                                        </AccordionSummary> */}

                                                      {this.state.partnershipDetailsArray
                                                        ?.filter(
                                                          (v) =>
                                                            v.email == el.email
                                                        )
                                                        .map((el, index) => {
                                                          return (
                                                            <AccordionDetails
                                                              key={index}
                                                            >
                                                              <Typography
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() =>
                                                                  this.handleContactSelection(
                                                                    el,
                                                                    "PartnershipDetails"
                                                                  )
                                                                }
                                                              >
                                                                {this.handleContact(
                                                                  el
                                                                )}
                                                              </Typography>
                                                            </AccordionDetails>
                                                          );
                                                        })}
                                                      {/* </Accordion> */}
                                                    </>
                                                  );
                                                }
                                              });
                                            })}
                                        </div>
                                      }
                                      trigger="click"
                                      placement="bottomRight"
                                      // placement="bottomLeft"
                                      onVisibleChange={this.handleVisibleChange}
                                    >
                                      <div
                                        style={{
                                          width: 0,
                                          height: 0,
                                          position: "absolute",
                                        }}
                                      />
                                    </AntdPopover>
                                  </div>
                                ) :
                                  (this.state.PartnershipDetails.length > 2
                                    && !this.state.partnershipDetailsArray.length
                                    && !this.state.fieldLoader && !this.state.partnerContactId) &&
                                  <AntdPopover
                                    // id={column.id}
                                    visible={this.state.PartnershipDetails?.length > 2 && !this.state.partnershipDetailsArray.length &&
                                      !this.state.fieldLoader && !this.state.partnerContactId}
                                    content={
                                      <div
                                        style={{ overflow: "auto" }}
                                      >
                                        <a
                                          className="dataItem"
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            // this.setState({
                                            //   PartnershipDetails: "",
                                            // });
                                            const typeData = [{ id: "partner", value: "Partner" }];
                                            const encodedTypeData = btoa(JSON.stringify(typeData));
                                            window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                            // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                          }
                                          }
                                          style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                        >
                                          Click here to Create New Contact
                                        </a>
                                      </div>
                                    }
                                    trigger="click"
                                    placement="bottom"
                                  // overlayStyle={{ marginTop: "5px" }}
                                  >
                                    {/* <div
                                      style={{
                                        width: 0,
                                        height: 0,
                                        position: "absolute",
                                      }}
                                    /> */}
                                  </AntdPopover>
                                }
                              </>
                            )}

                            {(this.state.InventoryType === "2" ||
                              this.state.InventoryType === "3") && (
                                <>
                                  {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                    <InputLabel
                                      htmlFor="component-simple"
                                      style={{ color: "#424242" }}
                                    >
                                      {this.state.InventoryType === "2"
                                        ? "Memo Details"
                                        : "Partnership Details"}
                                    </InputLabel>
                                    <Input
                                      id="component-simple"
                                      value={
                                        this.state.InventoryType === "2"
                                          ? this.state.MemoDetails
                                          : this.state.PartnershipDetails
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          MemoDetails:
                                            this.state.InventoryType === "2"
                                              ? e.target.value
                                              : "",
                                          PartnershipDetails:
                                            this.state.InventoryType === "3"
                                              ? e.target.value
                                              : "",
                                        });
                                      }}
                                    />
                                  </FormControl> */}
                                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                    <InputLabel
                                      htmlFor="component-simple"
                                      style={{ color: "#424242" }}
                                    >
                                      {this.state.InventoryType === "2"
                                        ? "Memo Number"
                                        : "Partnership Number"}
                                    </InputLabel>
                                    <Input
                                      id="component-simple"
                                      value={
                                        this.state.InventoryType === "2"
                                          ? this.state.updatememo
                                          : this.state.updatepartnershipnumber
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          updatememo:
                                            this.state.InventoryType === "2"
                                              ? e.target.value
                                              : "",
                                          updatepartnershipnumber:
                                            this.state.InventoryType === "3"
                                              ? e.target.value
                                              : "",
                                        });
                                      }}
                                    />
                                  </FormControl>
                                </>
                              )}
                            <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                INVENTORY STATUS
                              </InputLabel>
                              <Select
                                id="component-simple"
                                defaultValue={this.state.InventoryStatus}
                                value={this.state.InventoryStatus}
                                onChange={(e) =>
                                  this.setState({
                                    InventoryStatus: e.target.value,
                                  })
                                }
                                disabled={
                                  this.state.memoId &&
                                    this.state.InventoryStatus == 3 &&
                                    this.state.sold !== 1 ? true : false}
                              >
                                <MenuItem value="1">Inhand</MenuItem>
                                <MenuItem value="2">Incoming</MenuItem>
                                <MenuItem value="3">Out on Memo</MenuItem>
                                <MenuItem value="4">
                                  On Hold / Reserved
                                </MenuItem>
                                <MenuItem value="5">
                                  Available to Order
                                </MenuItem>
                                <MenuItem value="6">
                                  Needs Service / Polish
                                </MenuItem>
                                <MenuItem value="7">At Service</MenuItem>
                                <MenuItem value="8">Need to Ship</MenuItem>
                                <MenuItem value="9">
                                  Shipped waiting on payment
                                </MenuItem>
                                <MenuItem value="10">
                                  Sold & Cashed Out
                                </MenuItem>
                                <MenuItem value="11">Intake Complete</MenuItem>
                              </Select>
                            </FormControl>

                            <div>
                              <Input
                                type="text"
                                variant="outline"
                                id="autocomplete1"
                                placeholder="INVENTORY LOCATION"
                                onChange={this.locationAutoComplete}
                                onFocus={this.handleLocationFocus}
                                onBlur={this.handleLocationBlur}
                                value={this.state.locationSearch}
                                className="form-width-70  form-control searchoption boxwidth"
                              />

                              {this.state.locationloading ? (
                                <CircularProgress />
                              ) :
                                this.state.locationAutoCompleteData.data?.length > 0 ? (
                                  <List className="searchlist MuiPaper-elevation1 inventrysearch" style={{ listStyle: "none" }}>
                                    {this.state.locationAutoCompleteData.data.map((item, idx) => (
                                      <div>
                                        <ListItem
                                          className={
                                            cursor === idx ? "active" : ""
                                          }
                                          key={idx}
                                          style={{ cursor: "pointer" }}
                                          onMouseDown={() => this.onchangeLocation(item)}
                                        >
                                          <ListItemText
                                            primary={item.locations}
                                            style={{
                                              marginTop: "10px",
                                              marginLeft: "50px",
                                            }}
                                          />
                                        </ListItem>
                                        <Divider
                                          variant="inset"
                                          component="li"
                                          style={{ margin: "5px" }}
                                        />
                                      </div>
                                    )
                                    )}
                                  </List>
                                ) : null}
                            </div>

                            <Box
                              component="fieldset"
                              borderColor="transparent"
                              className="padding-0 "
                              style={{ margin: "20px 0px" }}
                            >
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                                className="update"
                              >
                                CONDITION{" "}
                              </InputLabel>
                              <FormControl
                                className="form-width-70 sliderclass"
                                style={{ marginBottom: "5px" }}
                              >
                                <Typography
                                  style={{
                                    textAlign: "right",
                                    marginTop: "20px",
                                    visibility: "hidden",
                                  }}
                                >
                                  {" "}
                                  {scale(this.state.Condition)}
                                </Typography>

                                <Slider
                                  defaultValue={0}
                                  aria-labelledby="discrete-slider-always"
                                  step={1}
                                  min={1}
                                  marks={followersMarks1}
                                  max={4}
                                  value={
                                    this.state.Condition
                                      ? this.state.Condition
                                      : ""
                                  }
                                  valueLabelDisplay="on"
                                  onChange={this.onchangeCondition}
                                  className="MuiOutlinedInput"
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onConditionErrorText}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                            <FormControl
                              className="form-width-70 "
                              style={{ marginTop: "8px" }}
                            >
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                BAND SIZE
                              </InputLabel>
                              <Typography
                                style={{
                                  textAlign: "right",
                                  marginTop: "8px",
                                  visibility: "hidden",
                                }}
                              >
                                {" "}
                                {scale(this.state.Condition)}
                              </Typography>
                              <Input
                                id="component-simple"
                                value={
                                  this.state.BandSize ? this.state.BandSize : ""
                                }
                                onChange={this.onchangeBandSize}
                                style={{ marginBottom: "10px" }}
                                disabled={this.state.isDisabled}
                              />
                              <FormHelperText className="error-o">
                                {this.state.onBandSizeErrorText}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          {this.state.viewData &&
                            this.state.viewData.length === 0 && (
                              <>
                                <FormControl
                                  className="form-width-70 "
                                  style={{ marginTop: "8px" }}
                                >
                                  <InputLabel
                                    htmlFor="component-simple"
                                    style={{ color: "#424242" }}
                                  >
                                    NUMBER OF LINKS / SCREWS
                                  </InputLabel>
                                  <Input
                                    id="component-simple"
                                    value={
                                      this.state.NumberOfLinks
                                        ? this.state.NumberOfLinks
                                        : ""
                                    }
                                    onChange={this.onchangeNumberOfLinks}
                                    style={{ marginBottom: "10px" }}
                                    disabled={this.state.isDisabled}
                                  />
                                  <FormHelperText className="error-o">
                                    {this.state.onNumberOfLinksErrorText}
                                  </FormHelperText>
                                </FormControl>

                                <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                  <InputLabel
                                    htmlFor="component-simple"
                                    style={{ color: "#424242" }}
                                  >
                                    INTERNAL DEALER NOTE
                                  </InputLabel>
                                  <Input
                                    id="component-simple"
                                    multiline
                                    onChange={this.onchangeDealerNotes}
                                    value={
                                      this.state.DealerNotes
                                        ? this.state.DealerNotes
                                        : ""
                                    }
                                  />
                                  <FormHelperText className="error-o">
                                    {this.state.onDealerNotesErrorText}
                                  </FormHelperText>
                                </FormControl>
                              </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <div
                            className="space-top-20"
                            style={{ marginTop: "50px" }}
                          >
                            {this.state.viewData &&
                              this.state.viewData.length !== 0 && (
                                <>
                                  <FormControl
                                    className="form-width-70 "
                                    style={{ marginTop: "8px" }}
                                  >
                                    <InputLabel
                                      htmlFor="component-simple"
                                      style={{ color: "#424242" }}
                                    >
                                      NUMBER OF LINKS / SCREWS
                                    </InputLabel>
                                    <Input
                                      id="component-simple"
                                      value={
                                        this.state.NumberOfLinks
                                          ? this.state.NumberOfLinks
                                          : ""
                                      }
                                      onChange={this.onchangeNumberOfLinks}
                                      style={{ marginBottom: "10px" }}
                                      disabled={this.state.isDisabled}
                                    />
                                    <FormHelperText className="error-o">
                                      {this.state.onNumberOfLinksErrorText}
                                    </FormHelperText>
                                  </FormControl>

                                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                    <InputLabel
                                      htmlFor="component-simple"
                                      style={{ color: "#424242" }}
                                    >
                                      INTERNAL DEALER NOTE
                                    </InputLabel>
                                    <Input
                                      id="component-simple"
                                      multiline
                                      onChange={this.onchangeDealerNotes}
                                      value={
                                        this.state.DealerNotes
                                          ? this.state.DealerNotes
                                          : ""
                                      }
                                    />
                                    <FormHelperText className="error-o">
                                      {this.state.onDealerNotesErrorText}
                                    </FormHelperText>
                                  </FormControl>
                                </>
                              )}
                            <div>
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  PUBLIC DEALER NOTES
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  onChange={this.onchangePublicDealerNote}
                                  value={
                                    this.state.public_dealer_note
                                      ? this.state.public_dealer_note
                                      : ""
                                  }
                                />
                                <FormHelperText className="error-o"></FormHelperText>
                              </FormControl>
                            </div>
                            <div className="space-top-20">
                              <Typography
                                variant="h5"
                                style={{ fontSize: "16px" }}
                                className="color-232323"
                              >
                                <b>TARGET PRICING DETAILS</b>
                              </Typography>
                            </div>
                            <div className="space-top-20">
                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  TARGET WHOLESALE PRICE ($)
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  style={{ marginBottom: "10px" }}
                                  onChange={this.onchangeWholeSalePrice}
                                  value={
                                    this.state.WholeSalePrice
                                      ? this.state.WholeSalePrice
                                      : ""
                                  }
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onWholeSalePriceErrorText}
                                </FormHelperText>
                              </FormControl>

                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  TARGET END CUSTOMER PRICE ($)
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  style={{ marginBottom: "10px" }}
                                  onChange={this.onchangeRetailPrice}
                                  value={
                                    this.state.RetailPrice
                                      ? this.state.RetailPrice
                                      : ""
                                  }
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onRetailPriceErrorText}
                                </FormHelperText>
                              </FormControl>

                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  TARGET END CUSTOMER WIRE PRICE ($)
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  style={{ marginBottom: "10px" }}
                                  onChange={this.onchangeRetailWirePrice}
                                  value={
                                    this.state.RetailWirePrice
                                      ? this.state.RetailWirePrice
                                      : ""
                                  }
                                />
                                <FormHelperText className="error-o">
                                  {this.state.onRetailWirePriceErrorText}
                                </FormHelperText>
                              </FormControl>

                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  {" "}
                                  TAG PRICE ($)
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  onChange={this.onchangeTagPrice}
                                  value={
                                    this.state.tag_price
                                      ? this.state.tag_price
                                      : ""
                                  }
                                />
                                <FormHelperText
                                  className="error-o"
                                  type="number"
                                  pattern="[0-9]*"
                                ></FormHelperText>
                              </FormControl>
                              {/* <InputLabel htmlFor="component-simple" style={{ color: '#424242', marginBottom: '20px', marginTop: '20px' }} >Purchase Invoice</InputLabel>
                                <input accept="image/*" className="input-upload" id="image" name="image" type="file" onChange={this.bulkUploadFile} multiple />
                                <label htmlFor="image">
                                  <div className="primary-border bg-color-F5F5F5" style={{ boxShadow: '0 10px 6px -6px #777', width: '200px', marginBottom: '30px' }}>
                                    <div className="space-5 text-center1" style={{ width: "100%" }}>
                                      <div className="text-center1">
                                        <img src={AddIcon} alt="Addicon" style={{ cursor: 'pointer' }} />
                                        <Typography variant="body2">Upload Invoice Images</Typography>
                                      </div>
                                    </div>
                                  </div>
                                </label> */}

                              {/* <div className="form-group multi-preview" style={{ display: 'block' }}>
                                  {(this.state.fileArray || []).map(url => (
                                    <img src={url} alt="..." width='200px'
                                      height='150px' />
                                  ))}
                                </div> */}
                              <Grid
                                item
                                md={6.1}
                                xl={6.1}
                                lg={6.1}
                                sm={12}
                                xs={12}
                              >
                                <div>
                                  <InputLabel
                                    htmlFor="component-simple"
                                    style={{
                                      color: "#424242",
                                      marginBottom: "20px",
                                      float: "left",
                                      marginTop: "20px",
                                    }}
                                  >
                                    Upload Invoice Images or File&nbsp;
                                  </InputLabel>
                                  {/* <Typography component="p" sx={{ mb: 1, color: 'black' }} className='xxs-normal'>Upload Invoice Images and/or videos&nbsp; */}
                                  {/* <Tooltip title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/BMP/WEBP and videos MP4/WEBM/OGG/MKV/AVI files, NOT embedded in other document types such as Word or PowerPoint."  style={{position: 'relative', top: 3, cursor: 'pointer'}}> */}
                                  <Tooltip
                                    title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/PDF/DOC"
                                    style={{
                                      position: "relative",
                                      top: 3,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span>
                                      <HelpIcon
                                        fontSize="10px"
                                        style={{
                                          color: "#CEA05F",
                                          marginTop: "14px",
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                  {/* </Typography> */}
                                  <FileUploadGroup
                                    imageDetails={this.state.fileArray}
                                    page={"Edit"}
                                    onFilesChange={this.handleSelectedFiles}
                                  />
                                </div>
                              </Grid>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="checkedB1"
                                      onClick={this.handleCheck1}
                                      className="float-right"
                                      checked={this.state.box == true}
                                    />
                                  }
                                  label="I have a box"
                                />
                                <br />
                              </div>

                              {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">

                                <p><FormLabel id="demo-radio-buttons-group-label" style={{ color: '#424242' }}>SALE STATUS</FormLabel></p>
                                <div  >

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="checkedB1"
                                        value={this.state.notforSale}
                                        checked={this.state.notforSale}
                                        className="float-right"
                                        onChange={this.handleNotforSaleCheck}
                                      />
                                    }
                                    label="Not for sale"
                                  />

                                </div>
                              </FormControl> */}

                              <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                {/* {this.state.typeOfPaper ?  */}
                                <p>
                                  <FormLabel
                                    id="demo-radio-buttons-group-label"
                                    style={{ color: "#424242" }}
                                  >
                                    TYPE OF PAPER
                                  </FormLabel>
                                </p>
                                <div style={{ marginBottom: "8px" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="checkedB1"
                                        value="Warranty"
                                        className="float-right"
                                        checked={
                                          this.state.warranty_paper == "1"
                                        }
                                        onChange={this.handleTypeOfWarranty}
                                      />
                                    }
                                    label="Warranty"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="checkedB1"
                                        className="float-right"
                                        value="Archive"
                                        checked={this.state.archive == "1"}
                                        onChange={this.handleTypeOfArchive}
                                      />
                                    }
                                    label="Archive"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="checkedB1"
                                        className="float-right"
                                        value="isService"
                                        checked={this.state.isService}
                                        onChange={this.handleTypeOfService}
                                      />
                                    }
                                    label="Service Card/Paper"
                                  />
                                  {/* </FormControl> */}
                                </div>
                                {this.state.warranty_paper && (
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      className="color-BCBCBC"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      format="MM/dd/yyyy"
                                      // disablePast={true}
                                      value={this.state.purchaseDate1 || null}
                                      onChange={(date, e) => {
                                        this.handleDateChange1(
                                          date,
                                          e,
                                          "Warranty"
                                        );
                                      }}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                      style={{
                                        marginBottom: "20px",
                                        marginTop: "0px",
                                      }}
                                      // TextFieldComponent={TextFieldComponent}
                                      helperText={
                                        this.state.error["Warranty"]
                                          ? "Invalid date"
                                          : ""
                                      }
                                      error={this.state.error["Warranty"]}
                                      placeholder="Warranty - MM/DD/YYYY"
                                      onPaste={(event) => event.preventDefault()}
                                    />
                                  </MuiPickersUtilsProvider>
                                )}
                                {this.state.archive && (
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      className="color-BCBCBC"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      format="MM/dd/yyyy"
                                      // disablePast={true}
                                      value={this.state.archiveDate || null}
                                      onChange={(date, e) => {
                                        this.handleDateChange1(
                                          date,
                                          e,
                                          "Archive"
                                        );
                                      }}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                      style={{
                                        marginBottom: "20px",
                                        marginTop: "0px",
                                      }}
                                      // TextFieldComponent={TextFieldComponent}
                                      helperText={
                                        this.state.error["Archive"]
                                          ? "Invalid date"
                                          : ""
                                      }
                                      error={this.state.error["Archive"]}
                                      placeholder="Archive - MM/DD/YYYY"
                                    />
                                  </MuiPickersUtilsProvider>
                                )}
                                {this.state.isService && (
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      className="color-BCBCBC"
                                      margin="normal"
                                      id="date-picker-dialog"
                                      format="MM/dd/yyyy"
                                      // disablePast={true}
                                      value={this.state.serviceDate || null}
                                      onChange={(date, e) => {
                                        this.handleDateChange1(
                                          date,
                                          e,
                                          "Service"
                                        );
                                      }}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                      style={{
                                        marginBottom: "20px",
                                        marginTop: "0px",
                                      }}
                                      // TextFieldComponent={TextFieldComponent}
                                      helperText={
                                        this.state.error["Service"]
                                          ? "Invalid date"
                                          : ""
                                      }
                                      error={this.state.error["Service"]}
                                      placeholder="Service Card / Paper - MM/DD/YYYY"
                                    />
                                  </MuiPickersUtilsProvider>
                                )}
                              </FormControl>
                              <FormControl className="form-width-70 ">
                                <p>
                                  <FormLabel
                                    id="demo-radio-buttons-group-label"
                                    style={{ color: "#424242" }}
                                  >
                                    MODIFICATION
                                  </FormLabel>
                                </p>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="checkedB1"
                                        className="float-right"
                                        // value={this.state.ismodified}
                                        checked={this.state.ismodified}
                                        onChange={this.handleCheck2}
                                      />
                                    }
                                    label="Modified"
                                  />
                                </div>
                                {this.state.ismodified && (
                                  <FormControl>
                                    <InputLabel
                                      htmlFor="component-simple"
                                      style={{ color: "#424242" }}
                                    >
                                      MODIFICATION DETAILS
                                    </InputLabel>
                                    <Input
                                      id="component-simple"
                                      value={this.state.modification_deatils}
                                      onChange={
                                        this.onchangeModificationDetails
                                      }
                                      multiline
                                      maxRows={4}
                                    />
                                    <FormHelperText className="error-o">
                                      {this.state.modificationDetailsError}
                                    </FormHelperText>
                                  </FormControl>
                                )}
                              </FormControl>

                              {this.state.userData &&
                                (this.state.userData
                                  ?.dealerInventorySubscription ===
                                  "access_only" ||
                                  this.state.userData
                                    ?.dealerInventorySubscription ===
                                  "no_subscription") ? null : (
                                <FormControl className="form-width-70 ">
                                  <p>
                                    <FormLabel
                                      id="demo-radio-buttons-group-label"
                                      style={{ color: "#424242" }}
                                    >
                                      DEALER FEED
                                    </FormLabel>
                                  </p>
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="checkedB1"
                                          className="float-right"
                                          value={this.state.dealerInv}
                                          checked={this.state.dealerInv}
                                          onChange={this.handleDealerInclude}
                                        />
                                      }
                                      label="Include in the Dealer Feed"
                                    />
                                  </div>
                                </FormControl>
                              )}

                              <div>
                                <Button
                                  className="primary-bg color-white text-center1 form-width-70"
                                  style={{ marginTop: "20px" }}
                                  onClick={this.submitForm}
                                >
                                  Update
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Container>
                </form>
              </div>
            )
          )}
          {this.state.type === "misc_product" ? (
            <form id="add_inventory_form">
              <Container>
                <div>
                  <Typography
                    variant="h6"
                    component="p"
                    className="primary-text uppercase"
                  ></Typography>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                              <div>
                                <Grid
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  {/* <Grid>
                                    <ArrowBackIcon
                                      style={{
                                        color: "action",
                                        paddingRight: "15px",
                                        marginTop: '5px',
                                        fontSize: 40,
                                        cursor: "pointer",
                                      }}
                                      onClick={this.backNavigation}
                                    >

                                    </ArrowBackIcon>
                                  </Grid> */}
                                  <Grid>
                                    <div onClick={this.handleClickBreadCrumbs}>
                                      <Stack spacing={1}>
                                        <Breadcrumbs
                                          aria-label="breadcrumb"
                                          separator={
                                            <span className="sperator">›</span>
                                          }
                                        >
                                          <Link
                                            className="link"
                                            style={{
                                              fontSize: "14px",
                                              color: "#CEA05F",
                                              textDecoration:
                                                "underline !important",
                                              backgroundColor: "white",
                                            }}
                                            onClick={this.backNavigation}
                                          >
                                            My Inventory
                                          </Link>

                                          <Link
                                            className="link"
                                            style={{
                                              fontSize: "14px",
                                              color: "#CEA05F",
                                              textDecoration:
                                                "underline !important",
                                              backgroundColor: "white",
                                            }}
                                            onClick={this.handleGoBack}
                                          >
                                            Inventory Details
                                          </Link>
                                          <Link
                                            style={{
                                              fontSize: "14px",
                                              color: "#9e9e9e",
                                              backgroundColor: "white",
                                            }}
                                            aria-current="page"
                                          >
                                            Edit Inventory Details
                                          </Link>
                                        </Breadcrumbs>
                                      </Stack>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div>
                                  <div
                                    className="bodyloader"
                                    style={{ marginTop: "40px" }}
                                  >
                                    <div style={{ marginBottom: "20px" }}>
                                      <Typography
                                        variant="h5"
                                        style={{ fontSize: "16px" }}
                                        className="color-232323"
                                      >
                                        <b>TECHNICAL DETAILS</b>
                                      </Typography>
                                    </div>

                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        MODEL NAME{" "}
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeProductName}
                                        value={
                                          this.state.product_name
                                            ? this.state.product_name
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onProductNameErrorText}
                                      </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        INVENTORY TITLE{" "}
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeProductTitle}
                                        value={
                                          this.state.product_title
                                            ? this.state.product_title
                                            : this.state.isProduct_title_Changed
                                              ? this.state.product_title
                                              : this.state.product_name
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onProductTitleErrorText}
                                      </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        DESCRIPTION{" "}
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeDescription}
                                        value={
                                          this.state.description
                                            ? this.state.description
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onDescriptionErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <div>
                                      <Input
                                        type="text"
                                        variant="outline"
                                        id="autocomplete1"
                                        placeholder="BRAND"
                                        onChange={this.onchangeBrandName}
                                        value={
                                          this.state.brand_name
                                            ? this.state.brand_name
                                            : ""
                                        }
                                        className="form-width-70  form-control searchoption boxwidth"
                                      />

                                      {this.state.brandloading ? (
                                        <CircularProgress />
                                      ) : this.state.brandAutoCompleteData.data
                                        .length > 0 ? (
                                        <List
                                          className="searchlist MuiPaper-elevation1 inventrysearch"
                                          style={{
                                            listStyle: "none",
                                            height: "auto",
                                          }}
                                        >
                                          {this.state.brandAutoCompleteData.data.map(
                                            (item, idx) => (
                                              <div>
                                                <ListItem
                                                  className={
                                                    cursor === idx
                                                      ? "active"
                                                      : ""
                                                  }
                                                  key={idx}
                                                  onClick={() =>
                                                    this.onchangebrand(item)
                                                  }
                                                >
                                                  <ListItemText
                                                    primary={item.brandname}
                                                    style={{
                                                      marginTop: "10px",
                                                      marginLeft: "50px",
                                                    }}
                                                  />
                                                </ListItem>
                                                <Divider
                                                  variant="inset"
                                                  component="li"
                                                  style={{ margin: "5px" }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </List>
                                      ) : null}
                                    </div>

                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        MODEL NUMBER
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={
                                          this.onchangeProductModelNumber
                                        }
                                        value={
                                          this.state.model_number
                                            ? this.state.model_number
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onModelNumberErrorText}
                                      </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        QUANTITY{" "}
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeQuantity}
                                        value={
                                          this.state.quantity
                                            ? this.state.quantity
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onQuantityErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20" style={{ marginTop: '15px' }} >
                                      <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>SERIAL NUMBER</InputLabel>
                                      <Input id="component-simple"
                                        onChange={this.onchangeSerialNumber}
                                       value={this.state.SerialNumber ? this.state.SerialNumber : ''}
                                       disabled={this.state.disabled}
                                       style={{backgroundColor:(this.state.disabled ? 'lightgray' : 'white')}}
                                      />
                                      <FormHelperText className="error-o">{this.state.onSerialNumberErrorText}</FormHelperText>

                                      </FormControl> */}

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <TextField
                                        id="outlined-number"
                                        label="SERIAL NUMBER"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={this.onchangeSerialNumber}
                                        value={
                                          this.state.SerialNumber
                                            ? this.state.SerialNumber
                                            : ""
                                        }
                                        disabled={this.state.isdisabled}
                                        style={{
                                          backgroundColor: this.state.disabled
                                            ? "lightgray"
                                            : "white",
                                        }}
                                      />
                                      <FormHelperText className="error-o">
                                        {this.state.onSerialNumberErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <div style={{ paddingTop: "20px" }}>
                                      <Typography
                                        variant="h5"
                                        style={{ fontSize: "16px" }}
                                        className="color-232323"
                                      >
                                        <b>PURCHASE DETAILS</b>
                                      </Typography>
                                    </div>
                                    {/* <FormControl
                                      className="form-width-70 "
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASED FROM
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangePurchasedFrom}
                                        style={{ marginBottom: "10px" }}
                                        value={
                                          this.state.PurchasedFrom
                                            ? this.state.PurchasedFrom
                                            : ""
                                        }
                                      />
                                      <FormHelperText className="error-o">
                                        {this.state.onPurchasedFromErrorText}
                                      </FormHelperText>
                                    </FormControl> */}
                                    <FormControl
                                      className="form-width-70 "
                                      style={{ marginTop: "8px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        shrink={this.state.PurchasedFrom ? true : false}
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASED FROM
                                      </InputLabel>
                                      <AntdInput
                                        id="PurchasedFrom"
                                        type="text"
                                        autoComplete="off"
                                        ref={this.purchasedFromRef}
                                        value={this.state.PurchasedFrom}
                                        style={{
                                          marginBottom: "0px",
                                          marginTop: "10px",
                                          fontSize: "16px",
                                          paddingLeft: 0,
                                        }}
                                        onChange={(e) =>
                                          this.onchangeContactSuggestion(
                                            e,
                                            "PurchasedFrom",
                                            ["supplier", "memo_supplier"]
                                          )
                                        }
                                        bordered={false}
                                        disabled={this.state.isDisabled}
                                      />
                                      {/* <FormHelperText className="error-o">{this.state.onPurchasedFromErrorText}</FormHelperText> */}
                                      <Divider style={{ background: "#ada8a8", marginTop: "5px", }} />
                                      <div className="contactSearchIcon">
                                        <Grid container>
                                          <Grid item xs={6} sm={6} md={6}>
                                            <>
                                              {!this.state.PurchasedFrom ? (
                                                <SearchIcon
                                                  style={{ cursor: "pointer" }}
                                                />
                                              ) : (
                                                <>
                                                  {this.state.fieldLoader ? (
                                                    <CircularProgress
                                                      style={{
                                                        width: "25px",
                                                        height: "25px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <ClearIcon
                                                      id="clearBtn"
                                                      onClick={() =>
                                                        this.clearContactSuggestion(
                                                          "PurchasedFrom"
                                                        )
                                                      }
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </FormControl>
                                    {this.state.purchasedFromArray &&
                                      this.state.purchasedFromArray.length > 0 &&
                                      this.state.PurchasedFrom?.length > 0 ? (
                                      <div>
                                        <AntdPopover
                                          visible={this.state.isPopOverPurchasedFrom}
                                          content={
                                            <div
                                              style={{
                                                height: "200px",
                                                overflow: "auto",
                                              }}
                                            >
                                              {this.state.dataset
                                                ?.slice(0, 15)
                                                .map((value, key) => {
                                                  return value.map((el, index) => {
                                                    if (index < 1) {
                                                      const displayName = `${el?.firstname ?? ""
                                                        } ${el?.lastname ?? ""}`;
                                                      return (
                                                        <>
                                                          {/* <Accordion>
                                                            <AccordionSummary
                                                              aria-controls="panel1d-content"
                                                              id="panel1d-header"
                                                            >
                                                              <Typography
                                                                style={{
                                                                  padding: 10,
                                                                  width: 300,
                                                                  cursor: "pointer",
                                                                }}
                                                              >
                                                                {(el?.businessname ||
                                                                  displayName) ??
                                                                  ""}
                                                              </Typography>
                                                            </AccordionSummary> */}

                                                          {this.state.purchasedFromArray
                                                            ?.filter(
                                                              (v) =>
                                                                v.email == el.email
                                                            )
                                                            .map((el, index) => {
                                                              return (
                                                                <AccordionDetails
                                                                  key={index}
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.handleContactSelection(
                                                                        el,
                                                                        "PurchasedFrom"
                                                                      )
                                                                    }
                                                                  >
                                                                    {this.handleContact(
                                                                      el
                                                                    )}
                                                                  </Typography>
                                                                </AccordionDetails>
                                                              );
                                                            })}
                                                          {/* </Accordion> */}
                                                        </>
                                                      );
                                                    }
                                                  });
                                                })}
                                            </div>
                                          }
                                          trigger="click"
                                          placement="bottomRight"
                                          // placement="bottomLeft"
                                          onVisibleChange={this.handleVisibleChange}
                                        >
                                          <div
                                            style={{
                                              width: 0,
                                              height: 0,
                                              position: "absolute",
                                            }}
                                          />
                                        </AntdPopover>
                                      </div>
                                    ) :
                                      (this.state.PurchasedFrom.length > 2 &&
                                        !this.state.purchasedFromArray.length &&
                                        !this.state.fieldLoader && !this.state.purchaseFromContactId) &&
                                      <AntdPopover
                                        // id={column.id}
                                        visible={this.state.PurchasedFrom?.length > 2 && !this.state.purchasedFromArray.length &&
                                          !this.state.fieldLoader && !this.state.purchaseFromContactId}
                                        content={
                                          <div
                                            style={{ overflow: "auto" }}
                                          >
                                            <a
                                              className="dataItem"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                // this.setState({
                                                //   PurchasedFrom: "",
                                                // });
                                                const typeData = [
                                                  { id: "supplier", value: "Supplier" },
                                                  { id: "memo_supplier", value: "Memo Supplier" },
                                                ];
                                                const encodedTypeData = btoa(JSON.stringify(typeData));
                                                window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                                // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                              }
                                              }
                                              style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                            >
                                              Click here to Create New Contact
                                            </a>
                                          </div>
                                        }
                                        trigger="click"
                                        placement="bottom"
                                      // overlayStyle={{ marginTop: "5px" }}
                                      >
                                        {/* <div
                                          style={{
                                            width: 0,
                                            height: 0,
                                            position: "absolute",
                                          }}
                                        /> */}
                                      </AntdPopover>
                                    }

                                    <div>
                                      <FormControl
                                        className="form-width-70 margin-t-b-20 margin-p-b-20"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            // className="color-BCBCBC"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="MM/dd/yyyy"
                                            disableFuture={true}
                                            value={
                                              this.state.purchaseDate || null
                                            }
                                            onChange={this.handlePurchaseDate}
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                            // TextFieldComponent={TextFieldComponent}
                                            placeholder="PURCHASED DATE - MM/DD/YYYY"
                                            helperText={
                                              this.state.error["PD"]
                                                ? "Invalid date"
                                                : ""
                                            }
                                            error={this.state.error["PD"]}
                                          />
                                        </MuiPickersUtilsProvider>
                                        <FormHelperText className="error-o">
                                          {this.state.onpurchaseDateErrorText}
                                        </FormHelperText>
                                      </FormControl>
                                    </div>

                                    <div className="space-top-8">
                                      <FormControl
                                        className="form-width-70 margin-t-b-20 margin-p-b-20"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          gap: "2rem",
                                        }}
                                      >
                                        <FormLabel
                                          id="demo-radio-buttons-group-label"
                                          style={{ color: "#424242" }}
                                        >
                                          PURCHASE PAYMENT STATUS
                                        </FormLabel>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="checkedB1"
                                              value={this.state.paidStatus}
                                              className="float-right"
                                              checked={this.state.paidStatus}
                                              onChange={
                                                this.handlePaidStatusCheck
                                              }
                                            />
                                          }
                                          label="Paid"
                                        />
                                      </FormControl>
                                      {this.state.paidStatus ? (
                                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              margin="normal"
                                              // label='PURCHASE PAYMENT DATE'
                                              id="date-picker-purchase"
                                              format="MM/dd/yyyy"
                                              disableFuture={true}
                                              value={
                                                this.state.paymentDate || null
                                              }
                                              onChange={this.handleDateChange}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              InputLabelProps={{
                                                // shrink: this.state.paymentDate ? true : false,
                                                style: { color: "black" },
                                              }}
                                              placeholder="PURCHASE PAYMENT DATE - MM/DD/YYYY"
                                              helperText={
                                                this.state.error["PPD"]
                                                  ? "Invalid date"
                                                  : ""
                                              }
                                              error={this.state.error["PPD"]}
                                            />
                                          </MuiPickersUtilsProvider>
                                          <FormHelperText className="error-o">
                                            {this.state.onpurchaseDateErrorText}
                                          </FormHelperText>
                                        </FormControl>
                                      ) : (
                                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              margin="normal"
                                              // label='PURCHASE PAYMENT DUE DATE'
                                              id="date-picker-dialog"
                                              format="MM/dd/yyyy"
                                              // disableFuture={true}

                                              value={
                                                this.state.purchaseDueDate ||
                                                null
                                              }
                                              onChange={
                                                this.handleDueDateChange
                                              }
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              placeholder="PURCHASE PAYMENT DUE DATE - MM/DD/YYYY"
                                              InputLabelProps={{
                                                // shrink: this.state.purchaseDueDate ? true : false,
                                                style: { color: "black" },
                                              }}
                                              helperText={
                                                this.state.error["PPDD"]
                                                  ? "Invalid date"
                                                  : ""
                                              }
                                              error={this.state.error["PPDD"]}
                                            />
                                          </MuiPickersUtilsProvider>
                                          <FormHelperText className="error-o">
                                            {
                                              this.state
                                                .onpurchaseDueDateErrorText
                                            }
                                          </FormHelperText>
                                        </FormControl>
                                      )}
                                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                        <InputLabel
                                          htmlFor="component-simple"
                                          style={{ color: "#424242" }}
                                        >
                                          PURCHASE PAYMENT DETAILS
                                        </InputLabel>
                                        <Input
                                          id="component-simple"
                                          multiline
                                          onChange={this.onchangePaymentDetails}
                                          value={
                                            this.state.paymentDetailsText
                                              ? this.state.paymentDetailsText
                                              : ""
                                          }
                                        />
                                        <FormHelperText className="error-o">
                                          {this.state.onPaymentDetailsErrorText}
                                        </FormHelperText>
                                      </FormControl>
                                    </div>

                                    <FormControl
                                      className="form-width-70 "
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASED PRICE ($)
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangePurchasedPrice}
                                        value={
                                          this.state.PurchasedPrice
                                            ? this.state.PurchasedPrice
                                            : ""
                                        }
                                        style={{ marginBottom: "10px" }}
                                      />
                                      <FormHelperText className="error-o">
                                        {this.state.onPurchasedPriceErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASE EXPENSE ($)
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangePurchaseExpense}
                                        value={
                                          this.state.purchase_expense
                                            ? this.state.purchase_expense
                                            : ""
                                        }
                                      />
                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        {" "}
                                        PURCHASE EXPENSE NOTE
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={
                                          this.onchangePurchaseExpenseNote
                                        }
                                        value={
                                          this.state.purchase_expense_note
                                            ? this.state.purchase_expense_note
                                            : ""
                                        }
                                      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      />
                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        {" "}
                                        TOTAL COST ($)
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        disabled
                                        value={this.state.total_cost}
                                      // onChange={this.onchangeTotalCost}
                                      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      ></Input>
                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div>
                        <div
                          style={{ marginTop: "110px", marginBottom: "20px" }}
                        >
                          <Typography
                            variant="h5"
                            style={{ fontSize: "16px" }}
                            className="color-232323"
                          >
                            <b>INVENTORY DETAILS</b>
                          </Typography>
                        </div>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            INVENTORY TYPE
                          </InputLabel>
                          <Select
                            id="component-simple"
                            defaultValue={this.state.InventoryType}
                            value={this.state.InventoryType}
                            onChange={(e) =>
                              this.setState({
                                InventoryType: e.target.value,
                                MemoDetails: "",
                                PartnershipDetails: "",
                                memoContactId: null,
                                partnerContactId: null,
                              })
                            }
                          >
                            <MenuItem value="1">Regular Inventory</MenuItem>
                            <MenuItem value="2">Memo Inventory</MenuItem>
                            <MenuItem value="3">Partnership Inventory</MenuItem>
                          </Select>
                        </FormControl>

                        {this.state.InventoryType === "2" && (
                          <>
                            <FormControl
                              className="form-width-70 "
                              style={{ marginTop: "8px" }}
                            >
                              <InputLabel
                                htmlFor="component-simple"
                                shrink={this.state.MemoDetails ? true : false}
                                style={{ color: "#424242" }}
                              >
                                Memo Details
                              </InputLabel>
                              <AntdInput
                                id="MemoDetails"
                                type="text"
                                ref={this.memoDetailsRef}
                                autoComplete="off"
                                value={this.state.MemoDetails}
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "10px",
                                  fontSize: "16px",
                                  paddingLeft: 0,
                                }}
                                onChange={(e) =>
                                  this.onchangeContactSuggestion(
                                    e,
                                    "MemoDetails",
                                    ["memo_supplier"]
                                  )
                                }
                                bordered={false}
                              />
                              <Divider style={{ background: "#ada8a8", marginTop: "5px" }} />
                              <div className="contactSearchIcon">
                                <Grid container>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <>
                                      {!this.state.MemoDetails ? (
                                        <SearchIcon
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <>
                                          {this.state.fieldLoader ? (
                                            <CircularProgress
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                            />
                                          ) : (
                                            <ClearIcon
                                              id="clearBtn"
                                              onClick={() =>
                                                this.clearContactSuggestion(
                                                  "MemoDetails"
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  </Grid>
                                </Grid>
                              </div>
                            </FormControl>
                            {this.state.memoDetailsArray &&
                              this.state.memoDetailsArray.length > 0 &&
                              this.state.MemoDetails?.length > 0 ? (
                              <div>
                                <AntdPopover
                                  visible={this.state.isPopOverMemoDetails}
                                  content={
                                    <div
                                      style={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      {this.state.dataset
                                        ?.slice(0, 15)
                                        .map((value, key) => {
                                          return value.map((el, index) => {
                                            if (index < 1) {
                                              const displayName = `${el?.firstname ?? ""
                                                } ${el?.lastname ?? ""}`;
                                              return (
                                                <>
                                                  {/* <Accordion>
                                                    <AccordionSummary
                                                      aria-controls="panel1d-content"
                                                      id="panel1d-header"
                                                    >
                                                      <Typography
                                                        style={{
                                                          padding: 10,
                                                          width: 300,
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {(el?.businessname ||
                                                          displayName) ??
                                                          ""}
                                                      </Typography>
                                                    </AccordionSummary> */}

                                                  {this.state.memoDetailsArray
                                                    ?.filter(
                                                      (v) =>
                                                        v.email == el.email
                                                    )
                                                    .map((el, index) => {
                                                      return (
                                                        <AccordionDetails
                                                          key={index}
                                                        >
                                                          <Typography
                                                            style={{
                                                              cursor:
                                                                "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.handleContactSelection(
                                                                el,
                                                                "MemoDetails"
                                                              )
                                                            }
                                                          >
                                                            {this.handleContact(
                                                              el
                                                            )}
                                                          </Typography>
                                                        </AccordionDetails>
                                                      );
                                                    })}
                                                  {/* </Accordion> */}
                                                </>
                                              );
                                            }
                                          });
                                        })}
                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottomRight"
                                  // placement="bottomLeft"
                                  onVisibleChange={this.handleVisibleChange}
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      position: "absolute",
                                    }}
                                  />
                                </AntdPopover>
                              </div>
                            ) :
                              (this.state.MemoDetails?.length > 2
                                && !this.state.memoDetailsArray.length
                                && !this.state.fieldLoader && !this.state.memoContactId) &&
                              <AntdPopover
                                // id={column.id}
                                visible={this.state.MemoDetails?.length > 2 && !this.state.memoDetailsArray.length &&
                                  !this.state.fieldLoader && !this.state.memoContactId}
                                content={
                                  <div
                                    style={{ overflow: "auto" }}
                                  >
                                    <a
                                      className="dataItem"
                                      onClick={() => {
                                        // this.setState({
                                        //   MemoDetails: "",
                                        // });
                                        const typeData = [{ id: "memo_supplier", value: "Memo Supplier" }];
                                        const encodedTypeData = btoa(JSON.stringify(typeData));
                                        window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                        // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                      }
                                      }
                                      style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                    >
                                      Click here to Create New Contact
                                    </a>
                                  </div>
                                }
                                trigger="click"
                                placement="bottom"
                              // overlayStyle={{ marginTop: "5px" }}
                              >
                                {/* <div
                                  style={{
                                    width: 0,
                                    height: 0,
                                    position: "absolute",
                                  }}
                                /> */}
                              </AntdPopover>
                            }
                          </>
                        )}

                        {this.state.InventoryType === "3" && (
                          <>
                            <FormControl
                              className="form-width-70 "
                              style={{ marginTop: "8px" }}
                            >
                              <InputLabel
                                htmlFor="component-simple"
                                shrink={
                                  this.state.PartnershipDetails ? true : false
                                }
                                style={{ color: "#424242" }}
                              >
                                Partnership Details
                              </InputLabel>
                              <AntdInput
                                id="PartnershipDetails"
                                type="text"
                                autoComplete="off"
                                ref={this.partnershipDetailsRef}
                                value={this.state.PartnershipDetails}
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "10px",
                                  fontSize: "16px",
                                  paddingLeft: 0,
                                }}
                                onChange={(e) =>
                                  this.onchangeContactSuggestion(
                                    e,
                                    "PartnershipDetails",
                                    ["partner"]
                                  )
                                }
                                bordered={false}
                              />
                              <Divider style={{ background: "#ada8a8", marginTop: "5px" }} />
                              <div className="contactSearchIcon">
                                <Grid container>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <>
                                      {!this.state.PartnershipDetails ? (
                                        <SearchIcon
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <>
                                          {this.state.fieldLoader ? (
                                            <CircularProgress
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                            />
                                          ) : (
                                            <ClearIcon
                                              id="clearBtn"
                                              onClick={() =>
                                                this.clearContactSuggestion(
                                                  "PartnershipDetails"
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  </Grid>
                                </Grid>
                              </div>
                            </FormControl>
                            {this.state.partnershipDetailsArray &&
                              this.state.partnershipDetailsArray.length > 0 &&
                              this.state.PartnershipDetails?.length > 0 ? (
                              <div>
                                <AntdPopover
                                  visible={
                                    this.state.isPopOverPartnershipDetails
                                  }
                                  content={
                                    <div
                                      style={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      {this.state.dataset
                                        ?.slice(0, 15)
                                        .map((value, key) => {
                                          return value.map((el, index) => {
                                            if (index < 1) {
                                              const displayName = `${el?.firstname ?? ""
                                                } ${el?.lastname ?? ""}`;
                                              return (
                                                <>
                                                  {/* <Accordion>
                                                    <AccordionSummary
                                                      aria-controls="panel1d-content"
                                                      id="panel1d-header"
                                                    >
                                                      <Typography
                                                        style={{
                                                          padding: 10,
                                                          width: 300,
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {(el?.businessname ||
                                                          displayName) ??
                                                          ""}
                                                      </Typography>
                                                    </AccordionSummary> */}

                                                  {this.state.partnershipDetailsArray
                                                    ?.filter(
                                                      (v) =>
                                                        v.email == el.email
                                                    )
                                                    .map((el, index) => {
                                                      return (
                                                        <AccordionDetails
                                                          key={index}
                                                        >
                                                          <Typography
                                                            style={{
                                                              cursor:
                                                                "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.handleContactSelection(
                                                                el,
                                                                "PartnershipDetails"
                                                              )
                                                            }
                                                          >
                                                            {this.handleContact(
                                                              el
                                                            )}
                                                          </Typography>
                                                        </AccordionDetails>
                                                      );
                                                    })}
                                                  {/* </Accordion> */}
                                                </>
                                              );
                                            }
                                          });
                                        })}
                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottomRight"
                                  // placement="bottomLeft"
                                  onVisibleChange={this.handleVisibleChange}
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      position: "absolute",
                                    }}
                                  />
                                </AntdPopover>
                              </div>
                            ) :
                              (this.state.PartnershipDetails.length > 2
                                && !this.state.partnershipDetailsArray.length
                                && !this.state.fieldLoader && !this.state.partnerContactId) &&
                              <AntdPopover
                                // id={column.id}
                                visible={this.state.PartnershipDetails?.length > 2 && !this.state.partnershipDetailsArray.length &&
                                  !this.state.fieldLoader && !this.state.partnerContactId}
                                content={
                                  <div
                                    style={{ overflow: "auto" }}
                                  >
                                    <a
                                      className="dataItem"
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        // this.setState({
                                        //   PartnershipDetails: "",
                                        // });
                                        const typeData = [{ id: "partner", value: "Partner" }];
                                        const encodedTypeData = btoa(JSON.stringify(typeData));
                                        window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                        // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                      }
                                      }
                                      style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                    >
                                      Click here to Create New Contact
                                    </a>
                                  </div>
                                }
                                trigger="click"
                                placement="bottom"
                              // overlayStyle={{ marginTop: "5px" }}
                              >
                                {/* <div
                                  style={{
                                    width: 0,
                                    height: 0,
                                    position: "absolute",
                                  }}
                                /> */}
                              </AntdPopover>
                            }
                          </>
                        )}

                        {(this.state.InventoryType === "2" ||
                          this.state.InventoryType === "3") && (
                            <>
                              {/* <FormControl
                                className="form-width-70 margin-t-b-20 margin-p-b-20"
                                style={{ marginTop: "15px" }}
                              >
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  {this.state.InventoryType === "2"
                                    ? "Memo Details"
                                    : "Partnership Details"}
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  value={
                                    this.state.InventoryType === "2"
                                      ? this.state.MemoDetails
                                      : this.state.PartnershipDetails
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      MemoDetails:
                                        this.state.InventoryType === "2"
                                          ? e.target.value
                                          : "",
                                      PartnershipDetails:
                                        this.state.InventoryType === "3"
                                          ? e.target.value
                                          : "",
                                    });
                                  }}
                                />
                              </FormControl> */}
                              <FormControl
                                className="form-width-70 margin-t-b-20 margin-p-b-20"
                                style={{ marginTop: "15px" }}
                              >
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  {this.state.InventoryType === "2"
                                    ? "Memo Number"
                                    : "Partnership Number"}
                                </InputLabel>
                                <Input
                                  id="component-simple"
                                  value={
                                    this.state.InventoryType === "2"
                                      ? this.state.updatememo
                                      : this.state.updatepartnershipnumber
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      updatememo:
                                        this.state.InventoryType === "2"
                                          ? e.target.value
                                          : "",
                                      updatepartnershipnumber:
                                        this.state.InventoryType === "3"
                                          ? e.target.value
                                          : "",
                                    });
                                  }}
                                />
                              </FormControl>
                            </>
                          )}

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            INVENTORY STATUS
                          </InputLabel>
                          <Select
                            id="component-simple"
                            defaultValue={this.state.InventoryStatus}
                            value={this.state.InventoryStatus}
                            onChange={(e) =>
                              this.setState({ InventoryStatus: e.target.value })
                            }
                            disabled={
                              this.state.memoId &&
                                this.state.InventoryStatus == 3 &&
                                this.state.sold !== 1 ? true : false}
                          >
                            <MenuItem value="1">Inhand</MenuItem>
                            <MenuItem value="2">Incoming</MenuItem>
                            <MenuItem value="3">Out on Memo</MenuItem>
                            <MenuItem value="4">On Hold / Reserved</MenuItem>
                            <MenuItem value="5">Available to Order</MenuItem>
                            <MenuItem value="6">
                              Needs Service / Polish
                            </MenuItem>
                            <MenuItem value="7">At Service</MenuItem>
                            <MenuItem value="8">Need to Ship</MenuItem>
                            <MenuItem value="9">
                              Shipped waiting on payment
                            </MenuItem>
                            <MenuItem value="10">Sold & Cashed Out</MenuItem>
                            <MenuItem value="11">Intake Complete</MenuItem>
                          </Select>
                        </FormControl>

                        <div>
                          <Input
                            type="text"
                            variant="outline"
                            id="autocomplete1"
                            placeholder="INVENTORY LOCATION"
                            onChange={this.locationAutoComplete}
                            onFocus={this.handleLocationFocus}
                            onBlur={this.handleLocationBlur}
                            value={this.state.locationSearch}
                            className="form-width-70  form-control searchoption boxwidth"
                          />

                          {this.state.locationloading ? (
                            <CircularProgress />
                          ) :
                            this.state.locationAutoCompleteData.data?.length > 0 ? (
                              <List className="searchlist MuiPaper-elevation1 inventrysearch" style={{ listStyle: "none" }}>
                                {this.state.locationAutoCompleteData.data.map((item, idx) => (
                                  <div>
                                    <ListItem
                                      className={cursor === idx ? "active" : ""}
                                      key={idx}
                                      style={{ cursor: "pointer" }}
                                      onMouseDown={() => this.onchangeLocation(item)}
                                    >
                                      <ListItemText
                                        primary={item.locations}
                                        style={{
                                          marginTop: "10px",
                                          marginLeft: "50px",
                                        }}
                                      />
                                    </ListItem>
                                    <Divider
                                      variant="inset"
                                      component="li"
                                      style={{ margin: "5px" }}
                                    />
                                  </div>
                                )
                                )}
                              </List>
                            ) : null}
                        </div>

                        <Box
                          component="fieldset"
                          borderColor="transparent"
                          className="padding-0 "
                          style={{ margin: "35px 0px 3px 0px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242", marginBottom: "10px" }}
                            className="update"
                          >
                            CONDITION{" "}
                          </InputLabel>
                          <FormControl className="form-width-70 sliderclass">
                            <Typography
                              style={{
                                textAlign: "right",
                                marginTop: "10px",
                                visibility: "hidden",
                              }}
                            >
                              {" "}
                              {scale(this.state.Condition)}
                            </Typography>

                            <Slider
                              defaultValue={0}
                              aria-labelledby="discrete-slider-always"
                              step={1}
                              min={1}
                              marks={followersMarks2}
                              max={2}
                              value={
                                this.state.Condition ? this.state.Condition : ""
                              }
                              valueLabelDisplay="on"
                              onChange={this.onchangeCondition}
                              className="MuiOutlinedInput"
                            />
                            <FormHelperText className="error-o">
                              {this.state.onConditionErrorText}
                            </FormHelperText>
                          </FormControl>
                        </Box>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            INTERNAL DEALER NOTE
                          </InputLabel>
                          <Input
                            id="component-simple"
                            multiline
                            onChange={this.onchangeDealerNotes}
                            value={
                              this.state.DealerNotes
                                ? this.state.DealerNotes
                                : ""
                            }
                          />
                          <FormHelperText className="error-o">
                            {this.state.onDealerNotesErrorText}
                          </FormHelperText>
                        </FormControl>
                      </div>

                      <FormControl
                        className="form-width-70 margin-t-b-20 margin-p-b-20"
                        style={{ marginBottom: "35px", marginTop: "15px" }}
                      >
                        <InputLabel
                          htmlFor="component-simple"
                          style={{ color: "#424242" }}
                        >
                          PUBLIC DEALER NOTES
                        </InputLabel>
                        <Input
                          id="component-simple"
                          onChange={this.onchangePublicDealerNote}
                          value={
                            this.state.public_dealer_note
                              ? this.state.public_dealer_note
                              : ""
                          }
                        />
                        <FormHelperText className="error-o"></FormHelperText>
                      </FormControl>

                      <div>
                        <div>
                          <Typography
                            variant="h5"
                            className="color-232323"
                            style={{ fontSize: "16px" }}
                          >
                            <b>TARGET PRICING DETAILS</b>
                          </Typography>
                        </div>
                        <div className="space-top-8">
                          <FormControl
                            className="form-width-70 margin-t-b-20 margin-p-b-20"
                            style={{ marginTop: "20px" }}
                          >
                            <InputLabel
                              htmlFor="component-simple"
                              style={{ color: "#424242" }}
                            >
                              TARGET WHOLESALE PRICE ($)
                            </InputLabel>
                            <Input
                              id="component-simple"
                              style={{ marginBottom: "10px" }}
                              onChange={this.onchangeWholeSalePrice}
                              value={
                                this.state.WholeSalePrice
                                  ? this.state.WholeSalePrice
                                  : ""
                              }
                            />
                            <FormHelperText className="error-o">
                              {this.state.onWholeSalePriceErrorText}
                            </FormHelperText>
                          </FormControl>
                        </div>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            TARGET END CUSTOMER PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            onChange={this.onchangeRetailPrice}
                            value={
                              this.state.RetailPrice
                                ? this.state.RetailPrice
                                : ""
                            }
                          />
                          <FormHelperText className="error-o">
                            {this.state.onRetailPriceErrorText}
                          </FormHelperText>
                        </FormControl>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            TARGET END CUSTOMER WIRE PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            onChange={this.onchangeRetailWirePrice}
                            value={
                              this.state.RetailWirePrice
                                ? this.state.RetailWirePrice
                                : ""
                            }
                          />
                          <FormHelperText className="error-o">
                            {this.state.onRetailWirePriceErrorText}
                          </FormHelperText>
                        </FormControl>

                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            {" "}
                            TAG PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeTagPrice}
                            value={
                              this.state.tag_price ? this.state.tag_price : ""
                            }
                          />
                          <FormHelperText
                            className="error-o"
                            type="number"
                            pattern="[0-9]*"
                          ></FormHelperText>
                        </FormControl>
                        {/* <InputLabel htmlFor="component-simple" style={{ color: '#424242', marginBottom: '20px', marginTop: '20px' }} >Purchase Invoice</InputLabel>
                                <input accept="image/*" className="input-upload" id="image" name="image" type="file" onChange={this.bulkUploadFile} multiple />
                                <label htmlFor="image">
                                  <div className="primary-border bg-color-F5F5F5" style={{ boxShadow: '0 10px 6px -6px #777', width: '200px', marginBottom: '30px' }}>
                                    <div className="space-5 text-center1" style={{ width: "100%" }}>
                                      <div className="text-center1">
                                        <img src={AddIcon} alt="Addicon" style={{ cursor: 'pointer' }} />
                                        <Typography variant="body2">Upload Invoice Images</Typography>
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <div className="form-group multi-preview" style={{ display: 'block' }}>
                                  {(this.state.fileArray || []).map(url => (
                                    <img src={url} alt="..." width='200px'
                                      height='150px' />
                                  ))}
                                </div> */}
                        <Grid item md={6.1} xl={6.1} lg={6.1} sm={12} xs={12}>
                          <div>
                            <InputLabel
                              htmlFor="component-simple"
                              style={{
                                color: "#424242",
                                marginBottom: "20px",
                                float: "left",
                                marginTop: "20px",
                              }}
                            >
                              Upload Invoice Images or File&nbsp;
                            </InputLabel>
                            {/* <Typography component="p" sx={{ mb: 1, color: 'black' }} className='xxs-normal'>Upload Invoice Images and/or videos&nbsp; */}
                            {/* <Tooltip title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/BMP/WEBP and videos MP4/WEBM/OGG/MKV/AVI files, NOT embedded in other document types such as Word or PowerPoint."  style={{position: 'relative', top: 3, cursor: 'pointer'}}> */}
                            <Tooltip
                              title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/PDF/DOC"
                              style={{
                                position: "relative",
                                top: 3,
                                cursor: "pointer",
                              }}
                            >
                              <span>
                                <HelpIcon
                                  fontSize="10px"
                                  style={{
                                    color: "#CEA05F",
                                    marginTop: "14px",
                                  }}
                                />
                              </span>
                            </Tooltip>
                            {/* </Typography> */}
                            <FileUploadGroup
                              page={"Edit"}
                              imageDetails={this.state.fileArray}
                              onFilesChange={this.handleSelectedFiles}
                            />
                          </div>
                        </Grid>
                        {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">

                          <p><FormLabel id="demo-radio-buttons-group-label" style={{ color: '#424242' }}>SALE STATUS</FormLabel></p>
                          <div  >

                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  value={this.state.notforSale}
                                  checked={this.state.notforSale}
                                  className="float-right"
                                  onChange={this.handleNotforSaleCheck}
                                />
                              }
                              label="Not for sale"
                            />

                          </div>
                        </FormControl> */}
                        {this.state.userData &&
                          (this.state.userData?.dealerInventorySubscription !==
                            "access_only" ||
                            this.state.userData?.dealerInventorySubscription !==
                            "no_subscription") ? (
                          <FormControl className="form-width-70 ">
                            <p>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                style={{ color: "#424242" }}
                              >
                                DEALER FEED
                              </FormLabel>
                            </p>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB1"
                                    className="float-right"
                                    value={this.state.dealerInv}
                                    checked={this.state.dealerInv}
                                    onChange={this.handleDealerInclude}
                                  />
                                }
                                label="Include in the Dealer Feed"
                              />
                            </div>
                          </FormControl>
                        ) : null}

                        <div>
                          <Button
                            className="primary-bg color-white text-center1 form-width-70"
                            style={{ marginBottom: "30px", marginTop: "30px" }}
                            onClick={this.submitForm}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </form>
          ) : null}

          {this.state.type === "misc_watch" ? (
            <form id="add_inventory_form">
              <Container>
                <div>
                  <Typography
                    variant="h5"
                    component="p"
                    className="primary-text uppercase"
                  ></Typography>
                </div>
                <div>
                  <div
                    style={{
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  ></div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                              <div>
                                <Grid
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Grid>
                                    <ArrowBackIcon
                                      style={{
                                        color: "action",
                                        paddingRight: "15px",
                                        marginTop: "5px",
                                        fontSize: 40,
                                        cursor: "pointer",
                                      }}
                                      onClick={this.backNavigation}
                                    ></ArrowBackIcon>
                                  </Grid>
                                  <Grid>
                                    <div onClick={this.handleClickBreadCrumbs}>
                                      <Stack spacing={1}>
                                        <Breadcrumbs
                                          aria-label="breadcrumb"
                                          separator={
                                            <span className="sperator">›</span>
                                          }
                                        >
                                          <Link
                                            className="link"
                                            style={{
                                              fontSize: "14px",
                                              color: "#CEA05F",
                                              textDecoration:
                                                "underline !important",
                                              backgroundColor: "white",
                                            }}
                                            onClick={this.backNavigation}
                                          >
                                            My Inventory
                                          </Link>

                                          <Link
                                            className="link"
                                            style={{
                                              fontSize: "14px",
                                              color: "#CEA05F",
                                              textDecoration:
                                                "underline !important",
                                              backgroundColor: "white",
                                            }}
                                            onClick={this.handleGoBack}
                                          >
                                            Inventory Details
                                          </Link>
                                          <Link
                                            style={{
                                              fontSize: "14px",
                                              color: "#9e9e9e",
                                              backgroundColor: "white",
                                            }}
                                            aria-current="page"
                                          >
                                            Edit Inventory Details
                                          </Link>
                                        </Breadcrumbs>
                                      </Stack>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div>
                                  <div
                                    className="bodyloader"
                                    style={{ marginTop: "30px" }}
                                  >
                                    <div>
                                      <Typography
                                        variant="h6"
                                        style={{ fontSize: "16px" }}
                                        className="color-232323"
                                      >
                                        <b>TECHNICAL DETAILS</b>
                                      </Typography>
                                    </div>

                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        MODEL NAME{" "}
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeProductName}
                                        value={
                                          this.state.product_name
                                            ? this.state.product_name
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onProductNameErrorText}
                                      </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        INVENTORY TITLE
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeProductTitle}
                                        value={
                                          this.state.product_title
                                            ? this.state.product_title
                                            : this.state.isProduct_title_Changed
                                              ? this.state.product_title
                                              : this.state.product_name
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl>

                                    <div>
                                      <Input
                                        type="text"
                                        variant="outline"
                                        id="autocomplete1"
                                        placeholder="BRAND"
                                        onChange={this.onchangeBrandName}
                                        value={
                                          this.state.brand_name
                                            ? this.state.brand_name
                                            : ""
                                        }
                                        className="form-width-70  form-control searchoption boxwidth"
                                      />

                                      {this.state.brandloading ? (
                                        <CircularProgress />
                                      ) : this.state.brandAutoCompleteData.data
                                        .length > 0 ? (
                                        <List
                                          className="searchlist MuiPaper-elevation1 inventrysearch"
                                          style={{
                                            listStyle: "none",
                                            height: "auto",
                                          }}
                                        >
                                          {this.state.brandAutoCompleteData.data.map(
                                            (item, idx) => (
                                              <div>
                                                <ListItem
                                                  className={
                                                    cursor === idx
                                                      ? "active"
                                                      : ""
                                                  }
                                                  key={idx}
                                                  onClick={() =>
                                                    this.onchangebrand(item)
                                                  }
                                                >
                                                  <ListItemText
                                                    primary={item.brandname}
                                                    style={{
                                                      marginTop: "10px",
                                                      marginLeft: "50px",
                                                    }}
                                                  />
                                                </ListItem>
                                                <Divider
                                                  variant="inset"
                                                  component="li"
                                                  style={{ margin: "5px" }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </List>
                                      ) : null}
                                    </div>

                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "25px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        MODEL NUMBER
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={
                                          this.onchangeProductModelNumber
                                        }
                                        value={
                                          this.state.model_number
                                            ? this.state.model_number
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onModelNumberErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <div>
                                      <Input
                                        type="text"
                                        variant="outline"
                                        id="autocomplete1"
                                        placeholder="CASE MATERIAL"
                                        onChange={this.onchangeCaseMaterial}
                                        value={
                                          this.state.case_material
                                            ? this.state.case_material
                                            : ""
                                        }
                                        className="form-width-70  form-control searchoption boxwidth"
                                      />

                                      {this.state.caseMaterialloading ? (
                                        <CircularProgress />
                                      ) : this.state
                                        .caseMaterialAutoCompleteData.data
                                        .length > 1 ? (
                                        <List
                                          className="searchlist MuiPaper-elevation1 inventrysearch"
                                          style={{ listStyle: "none" }}
                                        >
                                          {this.state.caseMaterialAutoCompleteData.data.map(
                                            (item, idx) => (
                                              <div>
                                                <ListItem
                                                  className={
                                                    cursor === idx
                                                      ? "active"
                                                      : ""
                                                  }
                                                  key={idx}
                                                  onClick={() =>
                                                    this.onchangecasematerial(
                                                      item
                                                    )
                                                  }
                                                >
                                                  <ListItemText
                                                    primary={item.casematerial}
                                                    style={{
                                                      marginTop: "10px",
                                                      marginLeft: "50px",
                                                    }}
                                                  />
                                                </ListItem>
                                                <Divider
                                                  variant="inset"
                                                  component="li"
                                                  style={{ margin: "5px" }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </List>
                                      ) : null}
                                    </div>

                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        CASE SIZE
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeCaseSize}
                                        value={
                                          this.state.case_size
                                            ? this.state.case_size
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onCaseSizeErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-5 margin-p-b-20 color-BCBCBC"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        DIAL COLOR
                                      </InputLabel>

                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeDialColor}
                                        value={
                                          this.state.dial_color
                                            ? this.state.dial_color
                                            : ""
                                        }
                                      ></Input>

                                      <FormHelperText className="error-o">
                                        {this.state.onDialColorErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <div>
                                      <Input
                                        type="text"
                                        variant="outline"
                                        id="autocomplete1"
                                        placeholder="BAND MATERIAL"
                                        onChange={this.onchangeBandMaterial}
                                        value={
                                          this.state.band_material
                                            ? this.state.band_material
                                            : ""
                                        }
                                        className="form-width-70  form-control searchoption boxwidth"
                                      />

                                      {this.state.bandMaterialloading ? (
                                        <CircularProgress />
                                      ) : this.state
                                        .bandMaterialAutoCompleteData.data
                                        .length > 1 ? (
                                        <List
                                          className="searchlist MuiPaper-elevation1 inventrysearch"
                                          style={{ listStyle: "none" }}
                                        >
                                          {this.state.bandMaterialAutoCompleteData.data.map(
                                            (item, idx) => (
                                              <div>
                                                <ListItem
                                                  className={
                                                    cursor === idx
                                                      ? "active"
                                                      : ""
                                                  }
                                                  key={idx}
                                                  onClick={() =>
                                                    this.onchangebandmaterial(
                                                      item
                                                    )
                                                  }
                                                >
                                                  <ListItemText
                                                    primary={item.bandmaterial}
                                                    style={{
                                                      marginTop: "10px",
                                                      marginLeft: "50px",
                                                    }}
                                                  />
                                                </ListItem>
                                                <Divider
                                                  variant="inset"
                                                  component="li"
                                                  style={{ margin: "5px" }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </List>
                                      ) : null}
                                    </div>

                                    <div style={{ marginTop: "25px" }}>
                                      <Typography
                                        variant="h6"
                                        style={{ fontSize: "16px" }}
                                        className="color-232323"
                                      >
                                        <b>PURCHASE DETAILS</b>
                                      </Typography>
                                    </div>

                                    {/* <FormControl
                                      className="form-width-70 "
                                      style={{ marginTop: "8px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASED FROM
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangePurchasedFrom}
                                        value={
                                          this.state.PurchasedFrom
                                            ? this.state.PurchasedFrom
                                            : ""
                                        }
                                      />
                                      <FormHelperText className="error-o">
                                        {this.state.onPurchasedFromErrorText}
                                      </FormHelperText>
                                    </FormControl> */}
                                    <FormControl
                                      className="form-width-70 "
                                      style={{ marginTop: "8px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        shrink={this.state.PurchasedFrom ? true : false}
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASED FROM
                                      </InputLabel>
                                      <AntdInput
                                        id="PurchasedFrom"
                                        type="text"
                                        autoComplete="off"
                                        ref={this.purchasedFromRef}
                                        value={this.state.PurchasedFrom}
                                        style={{
                                          marginBottom: "0px",
                                          marginTop: "10px",
                                          fontSize: "16px",
                                          paddingLeft: 0,
                                        }}
                                        onChange={(e) =>
                                          this.onchangeContactSuggestion(
                                            e,
                                            "PurchasedFrom",
                                            ["supplier", "memo_supplier"]
                                          )
                                        }
                                        bordered={false}
                                        disabled={this.state.isDisabled}
                                      />
                                      {/* <FormHelperText className="error-o">{this.state.onPurchasedFromErrorText}</FormHelperText> */}
                                      <Divider style={{ background: "#ada8a8", marginTop: "5px", }} />
                                      <div className="contactSearchIcon">
                                        <Grid container>
                                          <Grid item xs={6} sm={6} md={6}>
                                            <>
                                              {!this.state.PurchasedFrom ? (
                                                <SearchIcon
                                                  style={{ cursor: "pointer" }}
                                                />
                                              ) : (
                                                <>
                                                  {this.state.fieldLoader ? (
                                                    <CircularProgress
                                                      style={{
                                                        width: "25px",
                                                        height: "25px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <ClearIcon
                                                      id="clearBtn"
                                                      onClick={() =>
                                                        this.clearContactSuggestion(
                                                          "PurchasedFrom"
                                                        )
                                                      }
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </FormControl>
                                    {this.state.purchasedFromArray &&
                                      this.state.purchasedFromArray.length > 0 &&
                                      this.state.PurchasedFrom?.length > 0 ? (
                                      <div>
                                        <AntdPopover
                                          visible={this.state.isPopOverPurchasedFrom}
                                          content={
                                            <div
                                              style={{
                                                height: "200px",
                                                overflow: "auto",
                                              }}
                                            >
                                              {this.state.dataset
                                                ?.slice(0, 15)
                                                .map((value, key) => {
                                                  return value.map((el, index) => {
                                                    if (index < 1) {
                                                      const displayName = `${el?.firstname ?? ""
                                                        } ${el?.lastname ?? ""}`;
                                                      return (
                                                        <>
                                                          {/* <Accordion>
                                                            <AccordionSummary
                                                              aria-controls="panel1d-content"
                                                              id="panel1d-header"
                                                            >
                                                              <Typography
                                                                style={{
                                                                  padding: 10,
                                                                  width: 300,
                                                                  cursor: "pointer",
                                                                }}
                                                              >
                                                                {(el?.businessname ||
                                                                  displayName) ??
                                                                  ""}
                                                              </Typography>
                                                            </AccordionSummary> */}

                                                          {this.state.purchasedFromArray
                                                            ?.filter(
                                                              (v) =>
                                                                v.email == el.email
                                                            )
                                                            .map((el, index) => {
                                                              return (
                                                                <AccordionDetails
                                                                  key={index}
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.handleContactSelection(
                                                                        el,
                                                                        "PurchasedFrom"
                                                                      )
                                                                    }
                                                                  >
                                                                    {this.handleContact(
                                                                      el
                                                                    )}
                                                                  </Typography>
                                                                </AccordionDetails>
                                                              );
                                                            })}
                                                          {/* </Accordion> */}
                                                        </>
                                                      );
                                                    }
                                                  });
                                                })}
                                            </div>
                                          }
                                          trigger="click"
                                          placement="bottomRight"
                                          // placement="bottomLeft"
                                          onVisibleChange={this.handleVisibleChange}
                                        >
                                          <div
                                            style={{
                                              width: 0,
                                              height: 0,
                                              position: "absolute",
                                            }}
                                          />
                                        </AntdPopover>
                                      </div>
                                    ) :
                                      (this.state.PurchasedFrom.length > 2 &&
                                        !this.state.purchasedFromArray.length &&
                                        !this.state.fieldLoader && !this.state.purchaseFromContactId) &&
                                      <AntdPopover
                                        // id={column.id}
                                        visible={this.state.PurchasedFrom?.length > 2 && !this.state.purchasedFromArray.length &&
                                          !this.state.fieldLoader && !this.state.purchaseFromContactId}
                                        content={
                                          <div
                                            style={{ overflow: "auto" }}
                                          >
                                            <a
                                              className="dataItem"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                // this.setState({
                                                //   PurchasedFrom: "",
                                                // });
                                                const typeData = [
                                                  { id: "supplier", value: "Supplier" },
                                                  { id: "memo_supplier", value: "Memo Supplier" }
                                                ];
                                                const encodedTypeData = btoa(JSON.stringify(typeData));
                                                window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                                // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                              }
                                              }
                                              style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                            >
                                              Click here to Create New Contact
                                            </a>
                                          </div>
                                        }
                                        trigger="click"
                                        placement="bottom"
                                      // overlayStyle={{ marginTop: "5px" }}
                                      >
                                        {/* <div
                                          style={{
                                            width: 0,
                                            height: 0,
                                            position: "absolute",
                                          }}
                                        /> */}
                                      </AntdPopover>
                                    }

                                    <div>
                                      <FormControl
                                        className="form-width-70 margin-t-b-20 margin-p-b-20"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            // className="color-BCBCBC"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="MM/dd/yyyy"
                                            disableFuture={true}
                                            value={
                                              this.state.purchaseDate || null
                                            }
                                            onChange={this.handlePurchaseDate}
                                            isClearable
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                            // TextFieldComponent={TextFieldComponent}
                                            placeholder="PURCHASED DATE - MM/DD/YYYY"
                                            helperText={
                                              this.state.error["PD"]
                                                ? "Invalid date"
                                                : ""
                                            }
                                            error={this.state.error["PD"]}
                                          />
                                        </MuiPickersUtilsProvider>
                                        <FormHelperText className="error-o">
                                          {this.state.onpurchaseDateErrorText}
                                        </FormHelperText>
                                      </FormControl>
                                    </div>

                                    <div className="space-top-8">
                                      <FormControl
                                        className="form-width-70 margin-t-b-20 margin-p-b-20"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          gap: "2rem",
                                        }}
                                      >
                                        <FormLabel
                                          id="demo-radio-buttons-group-label"
                                          style={{ color: "#424242" }}
                                        >
                                          PURCHASE PAYMENT STATUS
                                        </FormLabel>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="checkedB1"
                                              value={this.state.paidStatus}
                                              className="float-right"
                                              checked={this.state.paidStatus}
                                              onChange={
                                                this.handlePaidStatusCheck
                                              }
                                            />
                                          }
                                          label="Paid"
                                        />
                                      </FormControl>
                                      {this.state.paidStatus ? (
                                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              margin="normal"
                                              // label='PURCHASE PAYMENT DATE'
                                              id="date-picker-purchase"
                                              format="MM/dd/yyyy"
                                              disableFuture={true}
                                              value={
                                                this.state.paymentDate || null
                                              }
                                              onChange={this.handleDateChange}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              InputLabelProps={{
                                                // shrink: this.state.paymentDate ? true : false,
                                                style: { color: "black" },
                                              }}
                                              placeholder="PURCHASE PAYMENT DATE - MM/DD/YYYY"
                                              helperText={
                                                this.state.error["PPD"]
                                                  ? "Invalid date"
                                                  : ""
                                              }
                                              error={this.state.error["PPD"]}
                                            />
                                          </MuiPickersUtilsProvider>
                                          <FormHelperText className="error-o">
                                            {this.state.onpurchaseDateErrorText}
                                          </FormHelperText>
                                        </FormControl>
                                      ) : (
                                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              margin="normal"
                                              // label='PURCHASE PAYMENT DUE DATE'
                                              id="date-picker-dialog"
                                              format="MM/dd/yyyy"
                                              // disableFuture={true}

                                              value={
                                                this.state.purchaseDueDate ||
                                                null
                                              }
                                              onChange={
                                                this.handleDueDateChange
                                              }
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              placeholder="PURCHASE PAYMENT DUE DATE - MM/DD/YYYY"
                                              InputLabelProps={{
                                                // shrink: this.state.purchaseDueDate ? true : false,
                                                style: { color: "black" },
                                              }}
                                              helperText={
                                                this.state.error["PPDD"]
                                                  ? "Invalid date"
                                                  : ""
                                              }
                                              error={this.state.error["PPDD"]}
                                            />
                                          </MuiPickersUtilsProvider>
                                          <FormHelperText className="error-o">
                                            {
                                              this.state
                                                .onpurchaseDueDateErrorText
                                            }
                                          </FormHelperText>
                                        </FormControl>
                                      )}
                                      <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                        <InputLabel
                                          htmlFor="component-simple"
                                          style={{ color: "#424242" }}
                                        >
                                          PURCHASE PAYMENT DETAILS
                                        </InputLabel>
                                        <Input
                                          id="component-simple"
                                          multiline
                                          onChange={this.onchangePaymentDetails}
                                          value={
                                            this.state.paymentDetailsText
                                              ? this.state.paymentDetailsText
                                              : ""
                                          }
                                        />
                                        <FormHelperText className="error-o">
                                          {this.state.onPaymentDetailsErrorText}
                                        </FormHelperText>
                                      </FormControl>
                                    </div>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASED PRICE ($){" "}
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangePurchasedPrice}
                                        value={
                                          this.state.PurchasedPrice
                                            ? this.state.PurchasedPrice
                                            : ""
                                        }

                                      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      />
                                      <FormHelperText className="error-o">
                                        {this.state.onPurchasedPriceErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        PURCHASE EXPENSE ($)
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangePurchaseExpense}
                                        value={
                                          this.state.purchase_expense
                                            ? this.state.purchase_expense
                                            : ""
                                        }
                                      />
                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        {" "}
                                        PURCHASE EXPENSE NOTE
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={
                                          this.onchangePurchaseExpenseNote
                                        }
                                        value={
                                          this.state.purchase_expense_note
                                            ? this.state.purchase_expense_note
                                            : ""
                                        }
                                      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      />
                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        {" "}
                                        TOTAL COST ($)
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        disabled
                                        value={this.state.total_cost}
                                      // onChange={this.onchangeTotalCost}
                                      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      ></Input>
                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl>
                                    <div style={{ marginTop: "25px" }}>
                                      <Typography
                                        variant="h6"
                                        style={{ fontSize: "16px" }}
                                        className="color-232323"
                                      >
                                        <b>INVENTORY DETAILS</b>
                                      </Typography>
                                    </div>
                                    {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20" style={{ marginTop: '8px' }} >
                                      <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>INVENTORY TITLE ITEM</InputLabel>

                                      <Input id="component-simple"
                                        onChange={this.onchangeInventoryTitle}
                                        value={this.state.inventory_title ? this.state.inventory_title : ''}
                                      >

                                      </Input>

                                      <FormHelperText className="error-o"></FormHelperText>
                                    </FormControl> */}

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        SERIAL NUMBER
                                      </InputLabel>
                                      <Input
                                        id="component-simple"
                                        onChange={this.onchangeSerialNumber}
                                        value={
                                          this.state.SerialNumber
                                            ? this.state.SerialNumber
                                            : ""
                                        }
                                        disabled={this.state.disabled}
                                      />
                                      <FormHelperText className="error-o">
                                        {this.state.onSerialNumberErrorText}
                                      </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        INVENTORY TYPE
                                      </InputLabel>

                                      <Select
                                        id="component-simple"
                                        defaultValue={this.state.InventoryType}
                                        value={this.state.InventoryType}
                                        onChange={(e) =>
                                          this.setState({
                                            InventoryType: e.target.value,
                                            MemoDetails: "",
                                            PartnershipDetails: "",
                                            memoContactId: null,
                                            partnerContactId: null,
                                          })
                                        }
                                      >
                                        <MenuItem value="1">
                                          Regular Inventory
                                        </MenuItem>
                                        <MenuItem value="2">
                                          Memo Inventory
                                        </MenuItem>
                                        <MenuItem value="3">
                                          Partnership Inventory
                                        </MenuItem>
                                      </Select>
                                    </FormControl>

                                    {this.state.InventoryType === "2" && (
                                      <>
                                        <FormControl
                                          className="form-width-70 "
                                          style={{ marginTop: "8px" }}
                                        >
                                          <InputLabel
                                            htmlFor="component-simple"
                                            shrink={this.state.MemoDetails ? true : false}
                                            style={{ color: "#424242" }}
                                          >
                                            Memo Details
                                          </InputLabel>
                                          <AntdInput
                                            id="MemoDetails"
                                            type="text"
                                            ref={this.memoDetailsRef}
                                            autoComplete="off"
                                            value={this.state.MemoDetails}
                                            style={{
                                              marginBottom: "0px",
                                              marginTop: "10px",
                                              fontSize: "16px",
                                              paddingLeft: 0,
                                            }}
                                            onChange={(e) =>
                                              this.onchangeContactSuggestion(
                                                e,
                                                "MemoDetails",
                                                ["memo_supplier"]
                                              )
                                            }
                                            bordered={false}
                                          />
                                          <Divider style={{ background: "#ada8a8", marginTop: "5px" }} />
                                          <div className="contactSearchIcon">
                                            <Grid container>
                                              <Grid item xs={6} sm={6} md={6}>
                                                <>
                                                  {!this.state.MemoDetails ? (
                                                    <SearchIcon
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  ) : (
                                                    <>
                                                      {this.state.fieldLoader ? (
                                                        <CircularProgress
                                                          style={{
                                                            width: "25px",
                                                            height: "25px",
                                                          }}
                                                        />
                                                      ) : (
                                                        <ClearIcon
                                                          id="clearBtn"
                                                          onClick={() =>
                                                            this.clearContactSuggestion(
                                                              "MemoDetails"
                                                            )
                                                          }
                                                          style={{ cursor: "pointer" }}
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </FormControl>
                                        {this.state.memoDetailsArray &&
                                          this.state.memoDetailsArray.length > 0 &&
                                          this.state.MemoDetails?.length > 0 ? (
                                          <div>
                                            <AntdPopover
                                              visible={this.state.isPopOverMemoDetails}
                                              content={
                                                <div
                                                  style={{
                                                    height: "200px",
                                                    overflow: "auto",
                                                  }}
                                                >
                                                  {this.state.dataset
                                                    ?.slice(0, 15)
                                                    .map((value, key) => {
                                                      return value.map((el, index) => {
                                                        if (index < 1) {
                                                          const displayName = `${el?.firstname ?? ""
                                                            } ${el?.lastname ?? ""}`;
                                                          return (
                                                            <>
                                                              {/* <Accordion>
                                                                <AccordionSummary
                                                                  aria-controls="panel1d-content"
                                                                  id="panel1d-header"
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      padding: 10,
                                                                      width: 300,
                                                                      cursor: "pointer",
                                                                    }}
                                                                  >
                                                                    {(el?.businessname ||
                                                                      displayName) ??
                                                                      ""}
                                                                  </Typography>
                                                                </AccordionSummary> */}

                                                              {this.state.memoDetailsArray
                                                                ?.filter(
                                                                  (v) =>
                                                                    v.email == el.email
                                                                )
                                                                .map((el, index) => {
                                                                  return (
                                                                    <AccordionDetails
                                                                      key={index}
                                                                    >
                                                                      <Typography
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                          this.handleContactSelection(
                                                                            el,
                                                                            "MemoDetails"
                                                                          )
                                                                        }
                                                                      >
                                                                        {this.handleContact(
                                                                          el
                                                                        )}
                                                                      </Typography>
                                                                    </AccordionDetails>
                                                                  );
                                                                })}
                                                              {/* </Accordion> */}
                                                            </>
                                                          );
                                                        }
                                                      });
                                                    })}
                                                </div>
                                              }
                                              trigger="click"
                                              placement="bottomRight"
                                              // placement="bottomLeft"
                                              onVisibleChange={this.handleVisibleChange}
                                            >
                                              <div
                                                style={{
                                                  width: 0,
                                                  height: 0,
                                                  position: "absolute",
                                                }}
                                              />
                                            </AntdPopover>
                                          </div>
                                        ) :
                                          (this.state.MemoDetails?.length > 2
                                            && !this.state.memoDetailsArray.length
                                            && !this.state.fieldLoader && !this.state.memoContactId) &&
                                          <AntdPopover
                                            // id={column.id}
                                            visible={this.state.MemoDetails?.length > 2 && !this.state.memoDetailsArray.length &&
                                              !this.state.fieldLoader && !this.state.memoContactId}
                                            content={
                                              <div
                                                style={{ overflow: "auto" }}
                                              >
                                                <a
                                                  className="dataItem"
                                                  onClick={() => {
                                                    // this.setState({
                                                    //   MemoDetails: "",
                                                    // });
                                                    const typeData = [{ id: "memo_supplier", value: "Memo Supplier" }];
                                                    const encodedTypeData = btoa(JSON.stringify(typeData));
                                                    window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                                    // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                                  }
                                                  }
                                                  style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                                >
                                                  Click here to Create New Contact
                                                </a>
                                              </div>
                                            }
                                            trigger="click"
                                            placement="bottom"
                                          // overlayStyle={{ marginTop: "5px" }}
                                          >
                                            {/* <div
                                              style={{
                                                width: 0,
                                                height: 0,
                                                position: "absolute",
                                              }}
                                            /> */}
                                          </AntdPopover>
                                        }
                                      </>
                                    )}

                                    {this.state.InventoryType === "3" && (
                                      <>
                                        <FormControl
                                          className="form-width-70 "
                                          style={{ marginTop: "8px" }}
                                        >
                                          <InputLabel
                                            htmlFor="component-simple"
                                            shrink={
                                              this.state.PartnershipDetails ? true : false
                                            }
                                            style={{ color: "#424242" }}
                                          >
                                            Partnership Details
                                          </InputLabel>
                                          <AntdInput
                                            id="PartnershipDetails"
                                            type="text"
                                            autoComplete="off"
                                            ref={this.partnershipDetailsRef}
                                            value={this.state.PartnershipDetails}
                                            style={{
                                              marginBottom: "0px",
                                              marginTop: "10px",
                                              fontSize: "16px",
                                              paddingLeft: 0,
                                            }}
                                            onChange={(e) =>
                                              this.onchangeContactSuggestion(
                                                e,
                                                "PartnershipDetails",
                                                ["partner"]
                                              )
                                            }
                                            bordered={false}
                                          />
                                          <Divider style={{ background: "#ada8a8", marginTop: "5px" }} />
                                          <div className="contactSearchIcon">
                                            <Grid container>
                                              <Grid item xs={6} sm={6} md={6}>
                                                <>
                                                  {!this.state.PartnershipDetails ? (
                                                    <SearchIcon
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  ) : (
                                                    <>
                                                      {this.state.fieldLoader ? (
                                                        <CircularProgress
                                                          style={{
                                                            width: "25px",
                                                            height: "25px",
                                                          }}
                                                        />
                                                      ) : (
                                                        <ClearIcon
                                                          id="clearBtn"
                                                          onClick={() =>
                                                            this.clearContactSuggestion(
                                                              "PartnershipDetails"
                                                            )
                                                          }
                                                          style={{ cursor: "pointer" }}
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </FormControl>
                                        {this.state.partnershipDetailsArray &&
                                          this.state.partnershipDetailsArray.length > 0 &&
                                          this.state.PartnershipDetails?.length > 0 ? (
                                          <div>
                                            <AntdPopover
                                              visible={
                                                this.state.isPopOverPartnershipDetails
                                              }
                                              content={
                                                <div
                                                  style={{
                                                    height: "200px",
                                                    overflow: "auto",
                                                  }}
                                                >
                                                  {this.state.dataset
                                                    ?.slice(0, 15)
                                                    .map((value, key) => {
                                                      return value.map((el, index) => {
                                                        if (index < 1) {
                                                          const displayName = `${el?.firstname ?? ""
                                                            } ${el?.lastname ?? ""}`;
                                                          return (
                                                            <>
                                                              {/* <Accordion>
                                                                <AccordionSummary
                                                                  aria-controls="panel1d-content"
                                                                  id="panel1d-header"
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      padding: 10,
                                                                      width: 300,
                                                                      cursor: "pointer",
                                                                    }}
                                                                  >
                                                                    {(el?.businessname ||
                                                                      displayName) ??
                                                                      ""}
                                                                  </Typography>
                                                                </AccordionSummary> */}

                                                              {this.state.partnershipDetailsArray
                                                                ?.filter(
                                                                  (v) =>
                                                                    v.email == el.email
                                                                )
                                                                .map((el, index) => {
                                                                  return (
                                                                    <AccordionDetails
                                                                      key={index}
                                                                    >
                                                                      <Typography
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                          this.handleContactSelection(
                                                                            el,
                                                                            "PartnershipDetails"
                                                                          )
                                                                        }
                                                                      >
                                                                        {this.handleContact(
                                                                          el
                                                                        )}
                                                                      </Typography>
                                                                    </AccordionDetails>
                                                                  );
                                                                })}
                                                              {/* </Accordion> */}
                                                            </>
                                                          );
                                                        }
                                                      });
                                                    })}
                                                </div>
                                              }
                                              trigger="click"
                                              placement="bottomRight"
                                              // placement="bottomLeft"
                                              onVisibleChange={this.handleVisibleChange}
                                            >
                                              <div
                                                style={{
                                                  width: 0,
                                                  height: 0,
                                                  position: "absolute",
                                                }}
                                              />
                                            </AntdPopover>
                                          </div>
                                        ) :
                                          (this.state.PartnershipDetails.length > 2
                                            && !this.state.partnershipDetailsArray.length
                                            && !this.state.fieldLoader && !this.state.partnerContactId) &&
                                          <AntdPopover
                                            // id={column.id}
                                            visible={this.state.PartnershipDetails?.length > 2 && !this.state.partnershipDetailsArray.length &&
                                              !this.state.fieldLoader && !this.state.partnerContactId}
                                            content={
                                              <div
                                                style={{ overflow: "auto" }}
                                              >
                                                <a
                                                  className="dataItem"
                                                  href="javascript:void(0)"
                                                  onClick={() => {
                                                    // this.setState({
                                                    //   PartnershipDetails: "",
                                                    // });
                                                    const typeData = [{ id: "partner", value: "Partner" }];
                                                    const encodedTypeData = btoa(JSON.stringify(typeData));
                                                    window.open(`/contactmanagement/add?type=${encodedTypeData}`, "_blank");
                                                    // window.open(`${window.location.origin}/contactmanagement/add`, "_blank");
                                                  }
                                                  }
                                                  style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                                >
                                                  Click here to Create New Contact
                                                </a>
                                              </div>
                                            }
                                            trigger="click"
                                            placement="bottom"
                                          // overlayStyle={{ marginTop: "5px" }}
                                          >
                                            {/* <div
                                              style={{
                                                width: 0,
                                                height: 0,
                                                position: "absolute",
                                              }}
                                            /> */}
                                          </AntdPopover>
                                        }
                                      </>
                                    )}

                                    {(this.state.InventoryType === "2" ||
                                      this.state.InventoryType === "3") && (
                                        <>
                                          {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                                            <InputLabel
                                              htmlFor="component-simple"
                                              style={{ color: "#424242" }}
                                            >
                                              {this.state.InventoryType === "2"
                                                ? "Memo Details"
                                                : "Partnership Details"}
                                            </InputLabel>
                                            <Input
                                              id="component-simple"
                                              value={this.state.InventoryType === "2" ? this.state.MemoDetails : this.state.PartnershipDetails}
                                              onChange={(e) => {
                                                this.setState({
                                                  MemoDetails:
                                                    this.state.InventoryType ===
                                                      "2"
                                                      ? e.target.value
                                                      : "",
                                                  PartnershipDetails:
                                                    this.state.InventoryType ===
                                                      "3"
                                                      ? e.target.value
                                                      : "",
                                                });
                                              }}
                                            />
                                          </FormControl> */}
                                          <FormControl
                                            className="form-width-70 margin-t-b-20 margin-p-b-20"
                                            style={{ marginTop: "15px" }}
                                          >
                                            <InputLabel
                                              htmlFor="component-simple"
                                              style={{ color: "#424242" }}
                                            >
                                              {this.state.InventoryType === "2"
                                                ? "Memo Number"
                                                : "Partnership Number"}
                                            </InputLabel>
                                            <Input
                                              id="component-simple"
                                              value={
                                                this.state.InventoryType === "2"
                                                  ? this.state.updatememo
                                                  : this.state
                                                    .updatepartnershipnumber
                                              }
                                              onChange={(e) => {
                                                this.setState({
                                                  updatememo:
                                                    this.state.InventoryType ===
                                                      "2"
                                                      ? e.target.value
                                                      : "",
                                                  updatepartnershipnumber:
                                                    this.state.InventoryType ===
                                                      "3"
                                                      ? e.target.value
                                                      : "",
                                                });
                                              }}
                                            />
                                          </FormControl>
                                        </>
                                      )}

                                    <FormControl
                                      className="form-width-70 margin-t-b-20 margin-p-b-20"
                                      style={{
                                        marginTop: "15px",
                                        marginBottom: "30px",
                                      }}
                                    >
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        INVENTORY STATUS
                                      </InputLabel>

                                      <Select
                                        id="component-simple"
                                        defaultValue={
                                          this.state.InventoryStatus
                                        }
                                        value={this.state.InventoryStatus}
                                        onChange={(e) =>
                                          this.setState({
                                            InventoryStatus: e.target.value,
                                          })
                                        }
                                        disabled={
                                          this.state.memoId &&
                                            this.state.InventoryStatus == 3 &&
                                            this.state.sold !== 1 ? true : false}
                                      >
                                        <MenuItem value="1">Inhand</MenuItem>
                                        <MenuItem value="2">Incoming</MenuItem>
                                        <MenuItem value="3">
                                          Out on Memo
                                        </MenuItem>
                                        <MenuItem value="4">
                                          On Hold / Reserved
                                        </MenuItem>
                                        <MenuItem value="5">
                                          Available to Order
                                        </MenuItem>
                                        <MenuItem value="6">
                                          Needs Service / Polish
                                        </MenuItem>
                                        <MenuItem value="7">
                                          At Service
                                        </MenuItem>
                                        <MenuItem value="8">
                                          Need to Ship
                                        </MenuItem>
                                        <MenuItem value="9">
                                          Shipped waiting on payment
                                        </MenuItem>
                                        <MenuItem value="10">
                                          Sold & Cashed Out
                                        </MenuItem>
                                        <MenuItem value="11">
                                          Intake Complete
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    {/* <div>
                                      <Input
                                        type="text"
                                        variant="outline"
                                        id="autocomplete1"
                                        placeholder="INVENTORY LOCATION"
                                        onChange={this.locationAutoComplete}
                                        value={this.state.locationSearch}
                                        className="form-width-70  form-control searchoption boxwidth"
                                      />

                                      {this.state.locationloading ? (
                                        <CircularProgress />
                                      ) : this.state.locationAutoCompleteData
                                        .data.length > 1 ? (
                                        <List
                                          className="searchlist MuiPaper-elevation1 inventrysearch"
                                          style={{ listStyle: "none" }}
                                        >
                                          {this.state.locationAutoCompleteData.data.map(
                                            (item, idx) => (
                                              <div>
                                                <ListItem
                                                  className={
                                                    cursor === idx
                                                      ? "active"
                                                      : ""
                                                  }
                                                  key={idx}
                                                  onClick={() =>
                                                    this.onchangeLocation(item)
                                                  }
                                                >
                                                  <ListItemText
                                                    primary={item.locations}
                                                    style={{
                                                      marginTop: "10px",
                                                      marginLeft: "50px",
                                                    }}
                                                  />
                                                </ListItem>
                                                <Divider
                                                  variant="inset"
                                                  component="li"
                                                  style={{ margin: "5px" }}
                                                />
                                              </div>
                                            ))}
                                          </List>
                                        ) : null
                                      )}
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div>
                        <div>
                          <Input
                            type="text"
                            variant="outline"
                            id="autocomplete1"
                            placeholder="INVENTORY LOCATION"
                            onChange={this.locationAutoComplete}
                            onFocus={this.handleLocationFocus}
                            onBlur={this.handleLocationBlur}
                            value={this.state.locationSearch}
                            className="form-width-70  form-control searchoption boxwidth"
                            style={{ marginTop: "120px" }}
                          />

                          {this.state.locationloading ? (
                            <CircularProgress />
                          ) :
                            this.state.locationAutoCompleteData.data?.length > 0 ? (
                              <List className="searchlist MuiPaper-elevation1 inventrysearch" style={{ listStyle: "none" }}>
                                {this.state.locationAutoCompleteData.data.map((item, idx) => (
                                  <div>
                                    <ListItem
                                      className={cursor === idx ? "active" : ""}
                                      key={idx}
                                      style={{ cursor: "pointer" }}
                                      onMouseDown={() => this.onchangeLocation(item)}
                                    >
                                      <ListItemText
                                        primary={item.locations}
                                        style={{
                                          marginTop: "10px",
                                          marginLeft: "50px",
                                        }}
                                      />
                                    </ListItem>
                                    <Divider
                                      variant="inset"
                                      component="li"
                                      style={{ margin: "5px" }}
                                    />
                                  </div>
                                )
                                )}
                              </List>
                            ) : null}
                        </div>

                        <Box
                          component="fieldset"
                          borderColor="transparent"
                          className="padding-0 "
                          style={{ margin: "35px 0px 3px 0px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242", marginBottom: "20px" }}
                          >
                            CONDITION
                          </InputLabel>
                          <FormControl className="form-width-70 sliderclass">
                            <Typography
                              style={{
                                textAlign: "right",
                                marginTop: "-20px",
                                visibility: "hidden",
                              }}
                            >
                              {" "}
                              {scale(this.state.Condition)}
                            </Typography>

                            <Slider
                              defaultValue={1}
                              aria-labelledby="discrete-slider-always"
                              valueLabelDisplay="auto"
                              step={1}
                              min={1}
                              marks={followersMarks1}
                              max={4}
                              onChange={this.onchangeCondition}
                              value={
                                this.state.Condition ? this.state.Condition : ""
                              }
                              className="MuiOutlinedInput"
                              ariaLabelledBy="non-linear-slider"
                            />
                            <FormHelperText className="error-o"></FormHelperText>
                          </FormControl>
                        </Box>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            style={{ color: "#424242", marginBottom: "10px" }}
                          >
                            BAND SIZE
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeBandSize}
                            value={
                              this.state.BandSize ? this.state.BandSize : ""
                            }
                          />

                          <FormHelperText className="error-o">
                            {this.state.onBandSizeErrorText}
                          </FormHelperText>
                        </FormControl>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel style={{ color: "#424242" }}>
                            NUMBER OF LINKS / SCREWS
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeNumberOfLinks}
                            value={
                              this.state.NumberOfLinks
                                ? this.state.NumberOfLinks
                                : ""
                            }
                          />
                          <FormHelperText className="error-o">
                            {this.state.onNumberOfLinksErrorText}
                          </FormHelperText>
                        </FormControl>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            INTERNAL DEALER NOTE
                          </InputLabel>
                          <Input
                            id="component-simple"
                            multiline
                            // rows={3}
                            onChange={this.onchangeDealerNotes}
                            value={
                              this.state.DealerNotes
                                ? this.state.DealerNotes
                                : ""
                            }
                          />
                          <FormHelperText className="error-o">
                            {this.state.onDealerNotesErrorText}
                          </FormHelperText>
                        </FormControl>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginBottom: "30px", marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            PUBLIC DEALER NOTES
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangePublicDealerNote}
                            value={
                              this.state.public_dealer_note
                                ? this.state.public_dealer_note
                                : ""
                            }
                          />
                          <FormHelperText className="error-o"></FormHelperText>
                        </FormControl>

                        <div>
                          <Typography
                            variant="h6"
                            style={{ fontSize: "16px" }}
                            className="color-232323"
                          >
                            <b>TARGET PRICING DETAILS</b>
                          </Typography>
                        </div>
                        <div className="space-top-8">
                          <FormControl
                            className="form-width-70 margin-t-b-20 margin-p-b-20"
                            style={{ marginTop: "15px" }}
                          >
                            <InputLabel
                              htmlFor="component-simple"
                              style={{ color: "#424242" }}
                            >
                              TARGET WHOLESALE PRICE ($)
                            </InputLabel>
                            <Input
                              id="component-simple"
                              onChange={this.onchangeWholeSalePrice}
                              value={
                                this.state.WholeSalePrice
                                  ? this.state.WholeSalePrice
                                  : ""
                              }
                            />
                            <FormHelperText className="error-o">
                              {this.state.onWholeSalePriceErrorText}
                            </FormHelperText>
                          </FormControl>
                        </div>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            TARGET END CUSTOMER PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeRetailPrice}
                            value={
                              this.state.RetailPrice
                                ? this.state.RetailPrice
                                : ""
                            }
                          />
                          <FormHelperText className="error-o">
                            {this.state.onRetailPriceErrorText}
                          </FormHelperText>
                        </FormControl>

                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            TARGET END CUSTOMER WIRE PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeRetailWirePrice}
                            value={
                              this.state.RetailWirePrice
                                ? this.state.RetailWirePrice
                                : ""
                            }
                          // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          />
                          <FormHelperText className="error-o">
                            {this.state.onRetailWirePriceErrorText}
                          </FormHelperText>
                        </FormControl>

                        <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            {" "}
                            TAG PRICE ($)
                          </InputLabel>
                          <Input
                            id="component-simple"
                            onChange={this.onchangeTagPrice}
                            value={
                              this.state.tag_price ? this.state.tag_price : ""
                            }
                          />
                          <FormHelperText
                            className="error-o"
                            type="number"
                            pattern="[0-9]*"
                          ></FormHelperText>
                        </FormControl>

                        {/* <InputLabel htmlFor="component-simple" style={{ color: '#424242', marginBottom: '20px', marginTop: '30px' }} >Purchase Invoice</InputLabel>
                        <input accept="image/*" className="input-upload" id="image" name="image" type="file" onChange={this.bulkUploadFile} multiple />
                        <label htmlFor="image">
                          <div className="primary-border bg-color-F5F5F5" style={{ boxShadow: '0 10px 6px -6px #777', width: '200px', marginBottom: '30px' }}>
                            <div className="space-5 text-center1" style={{ width: "100%" }}>
                              <div className="text-center1">
                                <img src={AddIcon} alt="Addicon" style={{ cursor: 'pointer' }} />
                                <Typography variant="body2">Upload Invoice Images</Typography>
                              </div>
                            </div>
                          </div>
                        </label>
                        <div className="form-group multi-preview" style={{ display: 'block' }}>
                          {(this.state.fileArray || []).map(url => (
                            <img src={url} alt="..." width='200px'
                              height='150px' />
                          ))}
                        </div> */}
                        <Grid item md={6.1} xl={6.1} lg={6.1} sm={12} xs={12}>
                          <div>
                            <InputLabel
                              htmlFor="component-simple"
                              style={{
                                color: "#424242",
                                marginBottom: "20px",
                                float: "left",
                                marginTop: "20px",
                              }}
                            >
                              Upload Invoice Images or File&nbsp;
                            </InputLabel>
                            {/* <Typography component="p" sx={{ mb: 1, color: 'black' }} className='xxs-normal'>Upload Invoice Images and/or videos&nbsp; */}
                            {/* <Tooltip title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/BMP/WEBP and videos MP4/WEBM/OGG/MKV/AVI files, NOT embedded in other document types such as Word or PowerPoint."  style={{position: 'relative', top: 3, cursor: 'pointer'}}> */}
                            <Tooltip
                              title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/PDF/DOC"
                              style={{
                                position: "relative",
                                top: 3,
                                cursor: "pointer",
                              }}
                            >
                              <span>
                                <HelpIcon
                                  fontSize="10px"
                                  style={{
                                    color: "#CEA05F",
                                    marginTop: "14px",
                                  }}
                                />
                              </span>
                            </Tooltip>
                            {/* </Typography> */}
                            <FileUploadGroup
                              page="Edit"
                              imageDetails={this.state.fileArray}
                              onFilesChange={this.handleSelectedFiles}
                            />
                          </div>
                        </Grid>
                        <FormControlLabel
                          className="MuiFormControlLabel-labelPlacement"
                          style={{ color: "#424242", marginTop: "8px" }}
                          control={
                            <Checkbox
                              name="checkedB"
                              className="float-right"
                              onClick={this.handleCheck1}
                              checked={this.state.box == true}
                            />
                          }
                          label="I have a box"
                        />
                        <br />

                        {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">

                          <p><FormLabel id="demo-radio-buttons-group-label" style={{ color: '#424242' }}>SALE STATUS</FormLabel></p>
                          <div  >

                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  value={this.state.notforSale}
                                  checked={this.state.notforSale}
                                  className="float-right"
                                  onChange={this.handleNotforSaleCheck}
                                />
                              }
                              label="Not for sale"
                            />

                          </div>
                        </FormControl> */}
                        <FormControl
                          className="form-width-70 margin-t-b-20 margin-p-b-20"
                          style={{ marginTop: "15px" }}
                        >
                          <p>
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{ color: "#424242" }}
                            >
                              TYPE OF PAPER
                            </FormLabel>
                          </p>
                          <div
                            style={{ marginBottom: "8px", marginTop: "8px" }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  value="Warranty"
                                  className="float-right"
                                  checked={this.state.warranty_paper == "1"}
                                  onChange={this.handleTypeOfWarranty}
                                />
                              }
                              label="Warranty"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  className="float-right"
                                  value="Archive"
                                  checked={this.state.archive == "1"}
                                  onChange={this.handleTypeOfArchive}
                                />
                              }
                              label="Archive"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  className="float-right"
                                  value="isService"
                                  checked={this.state.isService}
                                  onChange={this.handleTypeOfService}
                                />
                              }
                              label="Service Card/Paper"
                            />
                            {/* </FormControl> */}
                          </div>
                          {this.state.warranty_paper && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="color-BCBCBC"
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                // disablePast={true}
                                value={this.state.purchaseDate1 || null}
                                onChange={(date, e) => {
                                  this.handleDateChange1(date, e, "Warranty");
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "8px",
                                }}
                                // TextFieldComponent={TextFieldComponent}
                                helperText={
                                  this.state.error["Warranty"]
                                    ? "Invalid date"
                                    : ""
                                }
                                error={this.state.error["Warranty"]}
                                placeholder="Warranty - MM/DD/YYYY"
                                onPaste={(event) => event.preventDefault()}
                              />
                            </MuiPickersUtilsProvider>
                          )}
                          {this.state.archive && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="color-BCBCBC"
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                // disablePast={true}
                                value={this.state.archiveDate || null}
                                onChange={(date, e) => {
                                  this.handleDateChange1(date, e, "Archive");
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "8px",
                                }}
                                // TextFieldComponent={TextFieldComponent}
                                helperText={
                                  this.state.error["Archive"]
                                    ? "Invalid date"
                                    : ""
                                }
                                error={this.state.error["Archive"]}
                                placeholder="Archive - MM/DD/YYYY"
                              />
                            </MuiPickersUtilsProvider>
                          )}
                          {this.state.isService && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="color-BCBCBC"
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                value={this.state.serviceDate || null}
                                onChange={(date, e) => {
                                  this.handleDateChange1(date, e, "Service");
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "0px",
                                }}
                                // TextFieldComponent={TextFieldComponent}
                                helperText={
                                  this.state.error["Service"]
                                    ? "Invalid date"
                                    : ""
                                }
                                error={this.state.error["Service"]}
                                placeholder="Service Card / Paper - MM/DD/YYYY"
                              />
                            </MuiPickersUtilsProvider>
                          )}
                        </FormControl>

                        <FormControl
                          className="form-width-70 "
                          style={{ marginTop: "15px" }}
                        >
                          <p>
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{ color: "#424242" }}
                            >
                              MODIFICATION
                            </FormLabel>
                          </p>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB1"
                                  className="float-right"
                                  // value={this.state.ismodified}
                                  checked={this.state.ismodified}
                                  onChange={this.handleCheck2}
                                />
                              }
                              label="Modified"
                            />
                          </div>
                          {this.state.ismodified && (
                            <FormControl style={{ marginTop: "8px" }}>
                              <InputLabel
                                htmlFor="component-simple"
                                style={{ color: "#424242" }}
                              >
                                MODIFICATION DETAILS
                              </InputLabel>
                              <Input
                                id="component-simple"
                                value={this.state.modification_deatils}
                                onChange={this.onchangeModificationDetails}
                                multiline
                                maxRows={4}
                              />
                              <FormHelperText className="error-o"></FormHelperText>
                            </FormControl>
                          )}
                        </FormControl>

                        {this.state.userData &&
                          (this.state.userData?.dealerInventorySubscription ===
                            "access_only" ||
                            this.state.userData?.dealerInventorySubscription ===
                            "no_subscription") ? null : (
                          <FormControl className="form-width-70 ">
                            <p>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                style={{ color: "#424242" }}
                              >
                                DEALER FEED
                              </FormLabel>
                            </p>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB1"
                                    className="float-right"
                                    value={this.state.dealerInv}
                                    checked={this.state.dealerInv}
                                    onChange={this.handleDealerInclude}
                                  />
                                }
                                label="Include in the Dealer Feed"
                              />
                            </div>
                          </FormControl>
                        )}

                        <div>
                          <Button
                            className="primary-bg color-white text-center1 form-width-70"
                            style={{ marginBottom: "20px", marginTop: "30px" }}
                            onClick={this.submitForm}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </form>
          ) : null}
        </Paper>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps({
  individualBrowseWatchesInfo,
  auth,
  dealer,
  browseWatchesInfo,
}) {
  localStorage.setItem("menuTitle", "ADD INVENTORY");
  return {
    individualBrowseWatchesInfo:
      individualBrowseWatchesInfo.individualBrowseWatchesInfo,
    currentUser: auth.currentUser,
    autocompleteProducts: dealer.autoCompleteProducts,
    brandInfo: browseWatchesInfo.brandInfo,
    caseMaterialInfo: browseWatchesInfo.caseMaterialInfo,
    brandMaterialInfo: browseWatchesInfo.brandMaterialInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      individualBrowseWatch,
      searchProducts,
      brandList,
      caseMaterial,
      brandMaterial,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(inventryupdateComponent);
