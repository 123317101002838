import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Card,
  Button,
  FormHelperText,
  capitalize,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import upload1 from "../../../../assests/images/new7.png";
import upload2 from "../../../../assests/images/new6.png";
import upload3 from "../../../../assests/images/27.png";
import upload4 from "../../../../assests/images/22.png";
import upload5 from "../../../../assests/images/new1.png";
import upload6 from "../../../../assests/images/new9.png";
import upload7 from "../../../../assests/images/new8.png";
import upload8 from "../../../../assests/images/new5.png";
import AddIcon from "../../../../assests/images/add.png";
import deleteIcon from "../../../../assests/images/delete.png";
import { toast } from "react-toastify";
import "../../dashboard/component/Drag.css";
import VideoPlayer from "simple-react-video-thumbnail";
import { addInventoryImage } from "../actions/dealerAction";
import searchinventry from "../../dashboard/actions/dashboardaapi";
var Loader = require("react-loader");

const ConfirmationDialog = ({ open, handleClose, handleConfirm, message }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle >
        Delete
        <IconButton onClick={() => { handleClose() }} style={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div> Are you sure you want to <b>Delete</b>? </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleConfirm}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
class DealerProductUploadImagesComponent extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.inputRef2 = React.createRef();
    this.inputRef3 = React.createRef();
    this.inputRef4 = React.createRef();
    this.inputRef5 = React.createRef();
    this.inputRef6 = React.createRef();
    this.inputRef7 = React.createRef();
    this.inputRef8 = React.createRef();
    this.inputRef9 = React.createRef();
    this.inputRef10 = React.createRef();
    this.inputRef11 = React.createRef();
    this.inputRef12 = React.createRef();
    this.state = {
      UploadPic: "",
      UploadPic2: "",
      UploadPic3: "",
      UploadPic4: "",
      UploadPic5: "",
      UploadPic6: "",
      UploadPic7: "",
      UploadPic8: "",
      UploadPic9: "",
      UploadPic10: "",
      UploadPic11: "",
      UploadPic12: "",
      Goback: '/create-inventory',
      isUploaded: false,
      file: null,
      loaded: true,
      videoTitledisplay: "",
      inventory_id: null,
      inventoryDetailByIdDetails: [],
      dragActive: false,
      dragActive2: false,
      dragActive3: false,
      dragActive4: false,
      dragActive5: false,
      dragActive6: false,
      dragActive7: false,
      dragActive8: false,
      dragActive9: false,
      dragActive10: false,
      dragActive11: false,
      dragActive12: false,
      changeState: false,
      title: '',
      isDialogOpen: false,
      imageToDelete: null
    };
    this.UploadPhoto1 = this.UploadPhoto1.bind(this);
    this.UploadPhoto2 = this.UploadPhoto2.bind(this);
    this.UploadPhoto3 = this.UploadPhoto3.bind(this);
    this.UploadPhoto4 = this.UploadPhoto4.bind(this);
    this.UploadPhoto5 = this.UploadPhoto5.bind(this);
    this.UploadPhoto6 = this.UploadPhoto6.bind(this);
    this.UploadPhoto7 = this.UploadPhoto7.bind(this);
    this.UploadPhoto8 = this.UploadPhoto8.bind(this);
    this.UploadPhoto9 = this.UploadPhoto9.bind(this);
    this.UploadPhoto10 = this.UploadPhoto10.bind(this);
    this.UploadPhoto11 = this.UploadPhoto11.bind(this);
    this.UploadPhoto12 = this.UploadPhoto12.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDrag2 = this.handleDrag2.bind(this);
    this.handleDrag3 = this.handleDrag3.bind(this);
    this.handleDrag4 = this.handleDrag4.bind(this);
    this.handleDrag5 = this.handleDrag5.bind(this);
    this.handleDrag6 = this.handleDrag6.bind(this);
    this.handleDrag7 = this.handleDrag7.bind(this);
    this.handleDrag8 = this.handleDrag8.bind(this);
    this.handleDrag9 = this.handleDrag9.bind(this);
    this.handleDrag10 = this.handleDrag10.bind(this);
    this.handleDrag11 = this.handleDrag11.bind(this);
    this.handleDrag12 = this.handleDrag12.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDrop2 = this.handleDrop2.bind(this);
    this.handleDrop3 = this.handleDrop3.bind(this);
    this.handleDrop4 = this.handleDrop4.bind(this);
    this.handleDrop5 = this.handleDrop5.bind(this);
    this.handleDrop6 = this.handleDrop6.bind(this);
    this.handleDrop7 = this.handleDrop7.bind(this);
    this.handleDrop8 = this.handleDrop8.bind(this);
    this.handleDrop9 = this.handleDrop9.bind(this);
    this.handleDrop10 = this.handleDrop10.bind(this);
    this.handleDrop11 = this.handleDrop11.bind(this);
    this.handleDrop12 = this.handleDrop12.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleClearImage1 = this.handleClearImage1.bind(this);
    this.handleClearImage2 = this.handleClearImage2.bind(this);
    this.handleClearImage3 = this.handleClearImage3.bind(this);
    this.handleClearImage4 = this.handleClearImage4.bind(this);
    this.handleClearImage5 = this.handleClearImage5.bind(this);
    this.handleClearImage6 = this.handleClearImage6.bind(this);
    this.handleClearImage7 = this.handleClearImage7.bind(this);
    this.handleClearImage8 = this.handleClearImage8.bind(this);
    this.handleClearImage12 = this.handleClearImage12.bind(this);
    this.openDeleteConfirmation = this.openDeleteConfirmation.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
  }
  componentDidMount() {
    if (this.props.match.path === '/misc-watch' || this.props.match.path === '/dealer') {
      let id = this.props.match.params.inventryId || this.props.inventoryId;
      let inventory_id = id;
      this.setState({
        inventory_id: id,
        changeState: true,

      });
      searchinventry.getinventryDetails(inventory_id).then((res) => {
        if (res.data.success === 1) {
          this.setState({
            inventoryDetailByIdDetails: res.data.data,
            title: 'Upload Watch Images',
          });
          if (this.state?.inventoryDetailByIdDetails?.imagepaths) {
            let imagepaths = this.state?.inventoryDetailByIdDetails?.imagepaths;
            this.setState({
              title: 'Upload Watch Images',
              UploadPic: imagepaths?.front ? imagepaths.front : "",
              UploadPic2: imagepaths?.back ? imagepaths.back : "",
              UploadPic3: imagepaths?.left ? imagepaths.left : "",
              UploadPic4: imagepaths?.right ? imagepaths.right : "",
              UploadPic5: imagepaths?.free_form ? imagepaths.free_form : "",
              UploadPic6: imagepaths?.buckle_opened ? imagepaths.buckle_opened : "",
              UploadPic7: imagepaths?.buckle_closed ? imagepaths.buckle_closed : "",
              UploadPic8: imagepaths?.wrist_shot ? imagepaths.wrist_shot : "",
              UploadPic9: imagepaths?.video ? imagepaths.video : "",
            });
          }
        }
      });
    }

    else if (this.props.match.path === '/misc-product') {
      let id = this.props.match.params.inventryId || this.props.inventoryId;
      let inventory_id = id;
      this.setState({
        inventory_id: id,
        changeState: false,
      });
      searchinventry.getinventryDetails(inventory_id).then((res) => {
        if (res.data.success === 1) {
          this.setState({
            inventoryDetailByIdDetails: res.data.data,
            title: res.data.data.category,
          });
          if (this.state?.inventoryDetailByIdDetails?.imagepaths) {
            let imagepaths = this.state?.inventoryDetailByIdDetails?.imagepaths;
            this.setState({
              title: res.data.data.category,
              UploadPic: imagepaths?.front ? imagepaths.front : "",
              UploadPic2: imagepaths?.back ? imagepaths.back : "",
              UploadPic12: imagepaths?.free_form ? imagepaths.free_form : "",
              UploadPic9: imagepaths?.video ? imagepaths.video : "",
            });
          }
        }
      });
    } else {
      let id = this.props.match.params.inventryId || this.props.inventoryId;
      let inventory_id = id;
      this.setState({
        inventory_id: id,
      });
      searchinventry.getinventryDetails(inventory_id).then((res) => {
        if (res.data.success === 1) {
          this.setState({
            inventoryDetailByIdDetails: res.data.data,
            Goback: null
          });
          if (this.state?.inventoryDetailByIdDetails?.type === null || this.state?.inventoryDetailByIdDetails?.type === 'misc_watch') {
            let imagepaths = this.state?.inventoryDetailByIdDetails?.imagepaths;
            this.setState({
              title: 'Upload Watch Images ',
              changeState: true,
              UploadPic: imagepaths?.front ? imagepaths.front : "",
              UploadPic2: imagepaths?.back ? imagepaths.back : "",
              UploadPic3: imagepaths?.left ? imagepaths.left : "",
              UploadPic4: imagepaths?.right ? imagepaths.right : "",
              UploadPic5: imagepaths?.free_form ? imagepaths.free_form : "",
              UploadPic6: imagepaths?.buckle_opened ? imagepaths.buckle_opened : "",
              UploadPic7: imagepaths?.buckle_closed ? imagepaths.buckle_closed : "",
              UploadPic8: imagepaths?.wrist_shot ? imagepaths.wrist_shot : "",
              UploadPic9: imagepaths?.video ? imagepaths.video : "",
            });
          } else if (this.state?.inventoryDetailByIdDetails?.type === 'misc_product') {
            let imagepaths = this.state?.inventoryDetailByIdDetails?.imagepaths;
            this.setState({
              changeState: false,
              title: res.data.data.category,
              UploadPic: imagepaths?.front ? imagepaths.front : "",
              UploadPic2: imagepaths?.back ? imagepaths.back : "",
              UploadPic12: imagepaths?.free_form ? imagepaths.free_form : "",
              UploadPic9: imagepaths?.video ? imagepaths.video : "",
            });
          }
        }
      });
    }
  }

  handleSkip() {
    const { history } = this.props;
    if (this.state.Goback === null) {
      history.goBack();
    }
    else {
      history.push('/myinventory')
    }
  }
  // ref
  // handle drag events
  handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };

  // triggers when file is dropped
  handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto1(data);
    }
  };

  UploadPhoto1 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({
      onUploadPic1ErrorText: "",
      UploadPic: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic1ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "front");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic: URL.createObjectURL(file),
    });
  };

  handleClearImage1 = () => {
    let imageUrl = this.state.UploadPic;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "front");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  handleDrag2 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive2: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive2: false });
    }
  };

  // triggers when file is dropped
  handleDrop2 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive2: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto2(data);
    }
  };

  UploadPhoto2 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic2ErrorText: "",
      UploadPic2: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic2ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "back");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic2: URL.createObjectURL(file),
    });
  };

  handleClearImage2 = () => {
    let imageUrl = this.state.UploadPic2;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "back");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic2: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  handleDrag3 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive3: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive3: false });
    }
  };

  // triggers when file is dropped
  handleDrop3 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive3: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto3(data);
    }
  };

  UploadPhoto3 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic3ErrorText: "",
      UploadPic3: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic3ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "left");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic3: URL.createObjectURL(file),
    });
  };

  handleClearImage3 = () => {
    let imageUrl = this.state.UploadPic3;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "left");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic3: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  handleDrag4 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive4: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive4: false });
    }
  };

  // triggers when file is dropped
  handleDrop4 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive4: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto4(data);
    }
  };
  UploadPhoto4 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic4ErrorText: "",
      UploadPic4: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic4ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "right");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic4: URL.createObjectURL(file),
    });
  };

  handleClearImage4 = () => {
    let imageUrl = this.state.UploadPic4;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "right");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic4: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  handleDrag5 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive5: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive5: false });
    }
  };

  // triggers when file is dropped
  handleDrop5 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive5: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto5(data);
    }
  };

  UploadPhoto5 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic5ErrorText: "",
      UploadPic5: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic5ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "free_form");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic5: URL.createObjectURL(file),
    });
  };

  handleClearImage5 = () => {
    let imageUrl = this.state.UploadPic5;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "free_form");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic5: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  handleDrag6 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive6: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive6: false });
    }
  };

  // triggers when file is dropped
  handleDrop6 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive6: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto6(data);
    }
  };

  UploadPhoto6 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic6ErrorText: "",
      UploadPic6: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic6ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "buckle_opened");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic6: URL.createObjectURL(file),
    });
  };

  handleClearImage6 = () => {
    let imageUrl = this.state.UploadPic6;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "buckle_opened");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic6: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  handleDrag7 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive7: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive7: false });
    }
  };

  // triggers when file is dropped
  handleDrop7 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive7: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto7(data);
    }
  };

  UploadPhoto7 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic7ErrorText: "",
      UploadPic7: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic7ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "buckle_closed");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic7: URL.createObjectURL(file),
    });
  };

  handleDrag8 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive8: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive8: false });
    }
  };

  handleClearImage7 = () => {
    let imageUrl = this.state.UploadPic7;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "buckle_closed");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic7: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  // triggers when file is dropped
  handleDrop8 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive8: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto8(data);
    }
  };

  UploadPhoto8 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic8ErrorText: "",
      UploadPic8: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic8ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "wrist_shot");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic8: URL.createObjectURL(file),
    });
  };

  handleClearImage8 = () => {
    let imageUrl = this.state.UploadPic8;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "wrist_shot");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic8: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };

  handleDrag9 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive9: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive9: false });
    }
  };

  // triggers when file is dropped
  handleDrop9 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive9: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto9(data);
    }
  };

  UploadPhoto9 = ({ target }) => {
    this.setState({ videoTitledisplay: "Please wait video is uploading...." });
    var file = target.files[0];
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "video");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data.success === 1) {
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({ videoTitledisplay: "", isUploaded: true });
      } else {
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({ videoTitledisplay: "Something went wrong", isUploaded: false });
      }
    });
    this.setState({
      UploadPic9: URL.createObjectURL(file),
    });
  };

  handleDrag10 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive10: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive5: false });
    }
  };

  handleDrop10 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive10: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto10(data);
    }
  };

  UploadPhoto10 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic10ErrorText: "",
      UploadPic10: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic10ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "free_form");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic10: URL.createObjectURL(file),
    });
  };


  handleDrag11 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive11: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive11: false });
    }
  };

  handleDrop11 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive11: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto11(data);
    }
  };

  UploadPhoto11 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic11ErrorText: "",
      UploadPic11: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic11ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "free_form");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic11: URL.createObjectURL(file),
    });
  };

  handleDrag12 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive12: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive12: false });
    }
  };

  handleDrop12 = function (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive12: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = {
        target: {
          files: e.dataTransfer.files,
        },
      };
      this.UploadPhoto12(data);
    }
  };

  UploadPhoto12 = ({ target }) => {
    let file = target.files[0];
    let fd = new FormData();
    const imageFile = target.files[0];
    this.setState({ onUploadPic1ErrorText: "" });
    this.setState({
      onUploadPic12ErrorText: "",
      UploadPic12: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({ isUploaded: false });
      return false;
    } else {
      this.setState({
        isUploaded: true,
      });
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPic11ErrorText:
          "Please select only jpg,png or jpeg format images",
        isUploaded: false,
        isValid: false,
      });
      return false;
    }
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "free_form");
    fd.set("image", file);
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
    });
    this.setState({
      UploadPic12: URL.createObjectURL(file),
    });
  };

  handleClearImage12 = () => {
    let imageUrl = this.state.UploadPic12;
    let fd = new FormData();
    fd.set(
      "inventory_id",
      this.props.inventoryId ? this.props.inventoryId : this.state.inventory_id
    );
    fd.set("position", "free_form");
    fd.set("image", imageUrl);
    fd.set("imageUrl", imageUrl ? (imageUrl.includes('/') ? imageUrl.split('/').pop() : imageUrl) : null);
    fd.set("type", "delete");
    fd.set("modified_by", this.props.currentUser.userId);
    addInventoryImage(fd, (error, res) => {
      if (res.data?.success === 1) {
        this.setState({ UploadPic12: false, isUploaded: false, isDialogOpen: false });
      }
    });
  };


  validateInput = () => {
    this.setState({ loaded: false });
    let onUploadPic1ErrorText = "";
    let onUploadPic2ErrorText = "";
    let onUploadPic3ErrorText = "";
    let onUploadPic4ErrorText = "";
    let onUploadPic5ErrorText = "";
    let onUploadPic6ErrorText = "";
    let onUploadPic7ErrorText = "";
    let onUploadPic8ErrorText = "";
    let onUploadPic10ErrorText = "";
    let onUploadPic11ErrorText = "";
    let onUploadPic12ErrorText = "";

    let isValid = false;
    onUploadPic1ErrorText = "Please Upload Atleast 1 Inventory Image";
    const { history } = this.props;
    if (
      this.state.UploadPic ||
      this.state.UploadPic2 ||
      this.state.UploadPic3 ||
      this.state.UploadPic4 ||
      this.state.UploadPic5 ||
      this.state.UploadPic6 ||
      this.state.UploadPic7 ||
      this.state.UploadPic8 ||
      this.state.UploadPic9 ||
      this.state.UploadPic10 ||
      this.state.UploadPic11 ||
      this.state.UploadPic12
    ) {
      isValid = true;
      onUploadPic1ErrorText = "";
    }

    if (isValid) {
      const { history, location } = this.props;
      const { inventoryDetailPage = false } = location?.state || {};
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 3000);
      let id = this.props.inventoryId
        ? this.props.inventoryId
        : this.state.inventory_id;
      history.push({
        pathname: `/add-to-inventory-successful/${id}/${this.props.currentUser.userId}`,
        state: { inventoryDetailPage }
      });
    }
    //   if (this.state.UploadPic === '') {
    //     onUploadPic1ErrorText = 'Please Upload Pic';
    //     isValid = false;
    //   }
    //   if (this.state.UploadPic === '') {
    //     onUploadPic3ErrorText = 'Please Upload Pic';
    //     isValid = false;
    //   }
    //   if (this.state.UploadPic === '') {
    //     onUploadPic4ErrorText = 'Please Upload Pic';
    //     isValid = false;
    //   }
    //   if (this.state.UploadPic === '') {
    //     onUploadPic5ErrorText = 'Please Upload Pic';
    //     isValid = false;
    //   }
    //   if (this.state.UploadPic === '') {
    //     onUploadPic6ErrorText = 'Please Upload Pic';
    //     isValid = false;
    //   }
    //   if (this.state.UploadPic === '') {
    //     onUploadPic7ErrorText = 'Please Upload Pic';
    //     isValid = false;
    //   }
    //   if (this.state.UploadPic === '') {
    //     onUploadPic8ErrorText = 'Please Upload Pic';
    //     isValid = false;
    //   }

    this.setState({
      ...this.state,
      onUploadPic1ErrorText,
      onUploadPic2ErrorText,
      onUploadPic3ErrorText,
      onUploadPic4ErrorText,
      onUploadPic5ErrorText,
      onUploadPic6ErrorText,
      onUploadPic7ErrorText,
      onUploadPic8ErrorText,
      onUploadPic10ErrorText,
      onUploadPic11ErrorText,
      onUploadPic12ErrorText,
    });
    return isValid;
  };

  openDeleteConfirmation = (imageIdentifier) => {
    this.setState({ isDialogOpen: true, imageToDelete: imageIdentifier });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false, imageToDelete: null });
  };

  handleConfirmDelete = () => {
    const { imageToDelete } = this.state;
    if (imageToDelete === 'UploadPic') {
      this.handleClearImage1();
    } else if (imageToDelete === 'UploadPic2') {
      this.handleClearImage2();
    } else if (imageToDelete === 'UploadPic3') {
      this.handleClearImage3();
    } else if (imageToDelete === 'UploadPic4') {
      this.handleClearImage4();
    } else if (imageToDelete === 'UploadPic5') {
      this.handleClearImage5();
    } else if (imageToDelete === 'UploadPic6') {
      this.handleClearImage6();
    } else if (imageToDelete === 'UploadPic7') {
      this.handleClearImage7();
    } else if (imageToDelete === 'UploadPic8') {
      this.handleClearImage8();
    } else if (imageToDelete === 'UploadPic12') {
      this.handleClearImage12();
    }
  };

  render() {
    return (
      <>
        {this.state.changeState === true ? (
          <div className="space-3">
            <Container>
              <div className="space-top-30" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h4" className="font-size-24 color-232323">
                  <b>Inventory Upload Media</b>
                </Typography>
                <div >
                  <Button className="text-capitalize primary-bg color-white" id="demo-customized-button" variant="text" style={{ width: '100px' }} onClick={this.handleSkip}>SKIP</Button>
                </div>

              </div>
              <div className="space-top-15 space-bottom-15">
                <Typography variant="h5" className="font-size-24 color-454545">
                  <b>{this.state.title ? capitalize(this.state.title) : ""} </b>
                </Typography>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={6} sm={3} md={3}>
                  <form
                    id="form-file-upload"
                    onDragEnter={this.handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={this.inputRef}
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file"
                      type="file"
                      onChange={this.UploadPhoto1}
                    />
                    <label
                      id="label-file-upload"
                      htmlFor="icon-button-file"
                      className={this.state.dragActive ? "drag-active" : ""}
                    >
                      {this.state.UploadPic ? (
                        <>
                          <img
                            src={this.state.UploadPic}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                          <IconButton
                            style={{
                              position: 'absolute', top: "-10px", right: '-10px',
                              background: "#fafafa", padding: "6px"
                            }}
                            onClick={() => this.openDeleteConfirmation('UploadPic')}
                          >
                            <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </>
                      ) : (
                        <img
                          src={upload4}
                          alt="Please select only jpg,png or jpeg format images"
                          className="img-responsive11"
                        />
                      )}
                    </label>
                    {this.state.dragActive && (
                      <div
                        className="drag-file-element"
                        onDragEnter={this.handleDrag}
                        onDragLeave={this.handleDrag}
                        onDragOver={this.handleDrag}
                        onDrop={this.handleDrop}
                      ></div>
                    )}
                  </form>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <div>
                    <form
                      id="form-file-upload2"
                      onDragEnter={this.handleDrag2}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        ref={this.inputRef2}
                        accept="image/*"
                        className="input-upload"
                        id="icon-button-file2"
                        type="file"
                        onChange={this.UploadPhoto2}
                      />
                      <label
                        id="label-file-upload2"
                        htmlFor="icon-button-file2"
                        className={this.state.dragActive2 ? "drag-active" : ""}
                      >
                        {this.state.UploadPic2 ? (
                          <>
                            <img
                              src={this.state.UploadPic2}
                              alt="uploadimage"
                              className="img-responsive11"
                            />
                            <IconButton
                              style={{
                                position: 'absolute', top: "-10px", right: '-10px',
                                background: "#fafafa", padding: "6px"
                              }}
                              onClick={() => this.openDeleteConfirmation('UploadPic2')}
                            >
                              <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                            </IconButton>
                          </>
                        ) : (
                          <img
                            src={upload3}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                        )}
                      </label>
                      {this.state.dragActive2 && (
                        <div
                          className="drag-file-element"
                          onDragEnter={this.handleDrag2}
                          onDragLeave={this.handleDrag2}
                          onDragOver={this.handleDrag2}
                          onDrop={this.handleDrop2}
                        ></div>
                      )}
                    </form>
                    <FormHelperText className="error-o">
                      {this.state.onUploadPic2ErrorText}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <form
                    id="form-file-upload3"
                    onDragEnter={this.handleDrag3}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={this.inputRef3}
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file3"
                      type="file"
                      onChange={this.UploadPhoto3}
                    />
                    <label
                      id="label-file-upload3"
                      htmlFor="icon-button-file3"
                      className={this.state.dragActive3 ? "drag-active" : ""}
                    >
                      {this.state.UploadPic3 ? (
                        <>
                          <img
                            src={this.state.UploadPic3}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                          <IconButton
                            style={{
                              position: 'absolute', top: "-10px", right: '-10px',
                              background: "#fafafa", padding: "6px"
                            }}
                            onClick={() => this.openDeleteConfirmation('UploadPic3')}
                          >
                            <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </>
                      ) : (
                        <img
                          src={upload2}
                          alt="uploadimage"
                          className="img-responsive11"
                        />
                      )}
                    </label>
                    {this.state.dragActive3 && (
                      <div
                        className="drag-file-element"
                        onDragEnter={this.handleDrag3}
                        onDragLeave={this.handleDrag3}
                        onDragOver={this.handleDrag3}
                        onDrop={this.handleDrop3}
                      ></div>
                    )}
                  </form>
                  <FormHelperText className="error-o">
                    {this.state.onUploadPic3ErrorText}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <form
                    id="form-file-upload4"
                    onDragEnter={this.handleDrag4}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={this.inputRef4}
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file4"
                      type="file"
                      onChange={this.UploadPhoto4}
                    />
                    <label
                      id="label-file-upload4"
                      htmlFor="icon-button-file4"
                      className={this.state.dragActive4 ? "drag-active" : ""}
                    >
                      {this.state.UploadPic4 ? (
                        <>
                          <img
                            src={this.state.UploadPic4}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                          <IconButton
                            style={{
                              position: 'absolute', top: "-10px", right: '-10px',
                              background: "#fafafa", padding: "6px"
                            }}
                            onClick={() => this.openDeleteConfirmation('UploadPic4')}
                          >
                            <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </>
                      ) : (
                        <img
                          src={upload1}
                          alt="uploadimage"
                          className="img-responsive11"
                        />
                      )}
                    </label>
                    {this.state.dragActive4 && (
                      <div
                        className="drag-file-element"
                        onDragEnter={this.handleDrag4}
                        onDragLeave={this.handleDrag4}
                        onDragOver={this.handleDrag4}
                        onDrop={this.handleDrop4}
                      ></div>
                    )}
                  </form>
                  <FormHelperText className="error-o">
                    {this.state.onUploadPic4ErrorText}
                  </FormHelperText>
                </Grid>

                <Grid item xs={6} sm={3} md={3}>
                  <form
                    id="form-file-upload5"
                    onDragEnter={this.handleDrag5}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={this.inputRef5}
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file5"
                      type="file"
                      onChange={this.UploadPhoto5}
                    />
                    <label
                      id="label-file-upload5"
                      htmlFor="icon-button-file5"
                      className={this.state.dragActive5 ? "drag-active" : ""}
                    >
                      {this.state.UploadPic5 ? (
                        <>
                          <img
                            src={this.state.UploadPic5}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                          <IconButton
                            style={{
                              position: 'absolute', top: "-10px", right: '-10px',
                              background: "#fafafa", padding: "6px"
                            }}
                            onClick={() => this.openDeleteConfirmation('UploadPic5')}
                          >
                            <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </>
                      ) : (
                        <img
                          src={upload5}
                          alt="uploadimage"
                          className="img-responsive11"
                        />
                      )}
                    </label>
                    {this.state.dragActive5 && (
                      <div
                        className="drag-file-element"
                        onDragEnter={this.handleDrag5}
                        onDragLeave={this.handleDrag5}
                        onDragOver={this.handleDrag5}
                        onDrop={this.handleDrop5}
                      ></div>
                    )}
                  </form>
                  <div>
                    <FormHelperText className="error-o">
                      {this.state.onUploadPic5ErrorText}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <form
                    id="form-file-upload6"
                    onDragEnter={this.handleDrag6}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={this.inputRef6}
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file6"
                      type="file"
                      onChange={this.UploadPhoto6}
                    />
                    <label
                      id="label-file-upload6"
                      htmlFor="icon-button-file6"
                      className={this.state.dragActive6 ? "drag-active" : ""}
                    >
                      {this.state.UploadPic6 ? (
                        <>
                          <img
                            src={this.state.UploadPic6}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                          <IconButton
                            style={{
                              position: 'absolute', top: "-10px", right: '-10px',
                              background: "#fafafa", padding: "6px"
                            }}
                            onClick={() => this.openDeleteConfirmation('UploadPic6')}
                          >
                            <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </>
                      ) : (
                        <img
                          src={upload6}
                          alt="uploadimage"
                          className="img-responsive11"
                        />
                      )}
                    </label>
                    {this.state.dragActive6 && (
                      <div
                        className="drag-file-element"
                        onDragEnter={this.handleDrag6}
                        onDragLeave={this.handleDrag6}
                        onDragOver={this.handleDrag6}
                        onDrop={this.handleDrop6}
                      ></div>
                    )}
                  </form>
                  <div>
                    <FormHelperText className="error-o">
                      {this.state.onUploadPic6ErrorText}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <div>
                    <form
                      id="form-file-upload7"
                      onDragEnter={this.handleDrag7}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        ref={this.inputRef7}
                        accept="image/*"
                        className="input-upload"
                        id="icon-button-file7"
                        type="file"
                        onChange={this.UploadPhoto7}
                      />
                      <label
                        id="label-file-upload7"
                        htmlFor="icon-button-file7"
                        className={this.state.dragActive7 ? "drag-active" : ""}
                      >
                        {this.state.UploadPic7 ? (
                          <>
                            <img
                              src={this.state.UploadPic7}
                              alt="uploadimage"
                              className="img-responsive11"
                            />
                            <IconButton
                              style={{
                                position: 'absolute', top: "-10px", right: '-10px',
                                background: "#fafafa", padding: "6px"
                              }}
                              onClick={() => this.openDeleteConfirmation('UploadPic7')}
                            >
                              <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                            </IconButton>
                          </>
                        ) : (
                          <img
                            src={upload7}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                        )}
                      </label>
                      {this.state.dragActive7 && (
                        <div
                          className="drag-file-element"
                          onDragEnter={this.handleDrag7}
                          onDragLeave={this.handleDrag7}
                          onDragOver={this.handleDrag7}
                          onDrop={this.handleDrop7}
                        ></div>
                      )}
                    </form>
                    <FormHelperText className="error-o">
                      {this.state.onUploadPic7ErrorText}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <div>
                    <form
                      id="form-file-upload8"
                      onDragEnter={this.handleDrag8}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        ref={this.inputRef8}
                        accept="image/*"
                        className="input-upload"
                        id="icon-button-file8"
                        type="file"
                        onChange={this.UploadPhoto8}
                      />
                      <label
                        id="label-file-upload8"
                        htmlFor="icon-button-file8"
                        className={this.state.dragActive8 ? "drag-active" : ""}
                      >
                        {this.state.UploadPic8 ? (
                          <>
                            <img
                              src={this.state.UploadPic8}
                              alt="uploadimage"
                              className="img-responsive11"
                            />
                            <IconButton
                              style={{
                                position: 'absolute', top: "-10px", right: '-10px',
                                background: "#fafafa", padding: "6px"
                              }}
                              onClick={() => this.openDeleteConfirmation('UploadPic8')}
                            >
                              <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                            </IconButton>
                          </>
                        ) : (
                          <img
                            src={upload8}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                        )}
                      </label>
                      {this.state.dragActive8 && (
                        <div
                          className="drag-file-element"
                          onDragEnter={this.handleDrag8}
                          onDragLeave={this.handleDrag8}
                          onDragOver={this.handleDrag8}
                          onDrop={this.handleDrop8}
                        ></div>
                      )}
                    </form>
                    <FormHelperText className="error-o">
                      {this.state.onUploadPic8ErrorText}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>

              <FormHelperText className="error-o" style={{ textAlign: "center" }}>
                {this.state.onUploadPic1ErrorText}
              </FormHelperText>
            </Container>
            <div className="space-3">
              <Container>
                <Typography variant="h6" className="color-232323">
                  {/* <b>Upload Watch Video {this.state.UploadPic9}</b> */}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    {/* <div className="space-top-30"> */}
                    {/* <div> */}
                    <form
                      id="form-file-upload9"
                      onDragEnter={this.handleDrag9}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        ref={this.inputRef9}
                        accept="video/*"
                        className="input-upload"
                        id="icon-button-file9"
                        type="file"
                        onChange={this.UploadPhoto9}
                      />
                      <label
                        id="label-file-upload9"
                        htmlFor="icon-button-file9"
                        className={this.state.dragActive9 ? "drag-active" : ""}
                      >
                        <Card className="Primary-Border">
                          <div className="space-3 primary-border">
                            <div
                              className="space-5 primary-border-dashed text-center1"
                              style={{ width: "100%" }}
                            >
                              {this.state.UploadPic9 === "" ? (
                                <div className="text-center1">
                                  <img src={AddIcon} alt="Addicon" />
                                  <Typography variant="body1">
                                    Upload Watch Video
                                  </Typography>
                                </div>
                              ) : (
                                <VideoPlayer
                                  videoUrl={this.state.UploadPic9 + "?autoplay=1"}
                                  snapshotAt={10}
                                />
                              )}
                            </div>
                          </div>
                        </Card>
                      </label>
                      {this.state.dragActive9 && (
                        <div
                          className="drag-file-element"
                          onDragEnter={this.handleDrag9}
                          onDragLeave={this.handleDrag9}
                          onDragOver={this.handleDrag9}
                          onDrop={this.handleDrop9}
                        ></div>
                      )}
                    </form>
                    {/* <form
                  id="video-drag"
                  onDragEnter={this.handleDrag9}
                  onSubmit={(e) => e.preventDefault()}
                >
                <input
                  ref={this.inputRef9}
                  accept="video/*"
                  className="input-upload"
                  id="icon-button-file9"
                  type="file"
                  onChange={this.UploadPhoto9}
                />
                <label
                  id="label-file-upload9"
                  htmlFor="icon-button-file9"
                  className={this.state.dragActive9 ? "drag-active" : ""}
                >
                 <Card className="Primary-Border">
                      <div className="space-3 primary-border">
                        <div
                          className="space-5 primary-border-dashed text-center1"
                          style={{ width: "100%" }}
                        >
                          {this.state.UploadPic9 === "" ? (
                            <div className="text-center1">
                              <img src={AddIcon} alt="Addicon" />
                              <Typography variant="body1">
                                Upload Watch Video
                              </Typography>
                            </div>
                          ) : (
                            <VideoPlayer
                              videoUrl={this.state.UploadPic9 + "?autoplay=1"}
                              snapshotAt={10}
                            />
                          )}
                        </div>
                      </div>
                    </Card>
                </label>
                {this.state.dragActive9 && (
                  <div
                    id="drag-file-element"
                    onDragEnter={this.handleDrag9}
                    onDragLeave={this.handleDrag9}
                    onDragOver={this.handleDrag9}
                    onDrop={this.handleDrop9}
                  ></div>
                )}
              </form> */}
                    {/* </div> */}
                    <Typography variant="h6">
                      {this.state.videoTitledisplay}
                    </Typography>
                  </Grid>
                  <Loader loaded={this.state.loaded}></Loader>{" "}
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="space-top-20">
                      {((!this.state.isUploaded) || (this.state.videoTitledisplay !== "")) && (
                        <Button
                          variant="contained"
                          disabled
                          className="text-center1 form-width-100"
                        // style={{marginRight:"13px",marginTop: "4px",padding: "4px 6px",fontSize: "12px"}}
                        >
                          Finish
                        </Button>

                      )}
                      {(
                        this.state.UploadPic ||
                        this.state.UploadPic2 ||
                        this.state.UploadPic3 ||
                        this.state.UploadPic4 ||
                        this.state.UploadPic5 ||
                        this.state.UploadPic6 ||
                        this.state.UploadPic7 ||
                        this.state.UploadPic8 ||
                        this.state.UploadPic9
                      ) && this.state.isUploaded && this.state.videoTitledisplay === "" && (
                          <Button
                            className="primary-bg color-white text-center1 form-width-100"
                            onClick={this.validateInput}
                          >
                            Finish
                          </Button>
                        )}

                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Grid>
              </Container>
            </div>
          </div>
        ) :

          <div >
            <Container>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h4" className=" color-232323">
                  <b>Inventory Upload Media</b>
                </Typography>
              </div>
              <div className="space-top-15 space-bottom-15">
                <Typography variant="h5" className="font-size-24 color-454545">
                  <b>Upload {this.state.title ? capitalize(this.state.title) : ""} Images</b>
                </Typography>
              </div>
              <Grid container spacing={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                <Grid item xs={6} sm={3} md={3}>
                  <form
                    id="form-file-upload"
                    onDragEnter={this.handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={this.inputRef}
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file"
                      type="file"
                      onChange={this.UploadPhoto1}
                    />
                    <label
                      id="label-file-upload"
                      htmlFor="icon-button-file"
                      className={this.state.dragActive ? "drag-active" : ""}
                    >
                      {this.state.UploadPic ? (
                        <>
                          <img
                            src={this.state.UploadPic}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                          <IconButton
                            style={{
                              position: 'absolute', top: "-10px", right: '-10px',
                              background: "#fafafa", padding: "6px"
                            }}
                            onClick={() => this.openDeleteConfirmation('UploadPic')}
                          >
                            <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </>
                      ) : (
                        <img
                          src={upload5}
                          alt="Please select only jpg,png or jpeg format images"
                          className="img-responsive11"
                        />
                      )}
                    </label>
                    {this.state.dragActive && (
                      <div
                        className="drag-file-element"
                        onDragEnter={this.handleDrag}
                        onDragLeave={this.handleDrag}
                        onDragOver={this.handleDrag}
                        onDrop={this.handleDrop}
                      ></div>
                    )}
                  </form>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <div>
                    <form
                      id="form-file-upload2"
                      onDragEnter={this.handleDrag2}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        ref={this.inputRef2}
                        accept="image/*"
                        className="input-upload"
                        id="icon-button-file2"
                        type="file"
                        onChange={this.UploadPhoto2}
                      />
                      <label
                        id="label-file-upload2"
                        htmlFor="icon-button-file2"
                        className={this.state.dragActive2 ? "drag-active" : ""}
                      >
                        {this.state.UploadPic2 ? (
                          <>
                            <img
                              src={this.state.UploadPic2}
                              alt="uploadimage"
                              className="img-responsive11"
                            />
                            <IconButton
                              style={{
                                position: 'absolute', top: "-10px", right: '-10px',
                                background: "#fafafa", padding: "6px"
                              }}
                              onClick={() => this.openDeleteConfirmation('UploadPic2')}
                            >
                              <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                            </IconButton>
                          </>
                        ) : (
                          <img
                            src={upload5}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                        )}
                      </label>
                      {this.state.dragActive2 && (
                        <div
                          className="drag-file-element"
                          onDragEnter={this.handleDrag2}
                          onDragLeave={this.handleDrag2}
                          onDragOver={this.handleDrag2}
                          onDrop={this.handleDrop2}
                        ></div>
                      )}
                    </form>
                    <FormHelperText className="error-o">
                      {this.state.onUploadPic2ErrorText}
                    </FormHelperText>
                  </div>
                </Grid>


                <Grid item xs={6} sm={3} md={3}>
                  <form
                    id="form-file-upload5"
                    onDragEnter={this.handleDrag12}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={this.inputRef12}
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file5"
                      type="file"
                      onChange={this.UploadPhoto12}
                    />
                    <label
                      id="label-file-upload5"
                      htmlFor="icon-button-file5"
                      className={this.state.dragActive12 ? "drag-active" : ""}
                    >
                      {this.state.UploadPic12 ? (
                        <>
                          <img
                            src={this.state.UploadPic12}
                            alt="uploadimage"
                            className="img-responsive11"
                          />
                          <IconButton
                            style={{
                              position: 'absolute', top: "-10px", right: '-10px',
                              background: "#fafafa", padding: "6px"
                            }}
                            onClick={() => this.openDeleteConfirmation('UploadPic12')}
                          >
                            <img src={deleteIcon} alt="delete" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        </>
                      ) : (
                        <img
                          src={upload5}
                          alt="uploadimage"
                          className="img-responsive11"
                        />
                      )}
                    </label>
                    {this.state.dragActive12 && (
                      <div
                        className="drag-file-element"
                        onDragEnter={this.handleDrag12}
                        onDragLeave={this.handleDrag12}
                        onDragOver={this.handleDrag12}
                        onDrop={this.handleDrop12}
                      ></div>
                    )}
                  </form>
                  <div>
                    <FormHelperText className="error-o">
                      {this.state.onUploadPic12ErrorText}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>

              <FormHelperText className="error-o" style={{ textAlign: "center" }}>
                {this.state.onUploadPic1ErrorText}
              </FormHelperText>
            </Container>
            <div className="space-3">
              <Container>
                <Typography variant="h6" className="color-232323">
                  {/* <b>Upload Watch Video {this.state.UploadPic9}</b> */}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    {/* <div className="space-top-30"> */}
                    {/* <div> */}
                    <form
                      id="form-file-upload9"
                      onDragEnter={this.handleDrag9}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        ref={this.inputRef9}
                        accept="video/*"
                        className="input-upload"
                        id="icon-button-file9"
                        type="file"
                        onChange={this.UploadPhoto9}
                      />
                      <label
                        id="label-file-upload9"
                        htmlFor="icon-button-file9"
                        className={this.state.dragActive9 ? "drag-active" : ""}
                      >
                        <Card className="Primary-Border">
                          <div className="space-3 primary-border">
                            <div
                              className="space-5 primary-border-dashed text-center1"
                              style={{ width: "100%" }}
                            >
                              {this.state.UploadPic9 === "" ? (
                                <div className="text-center1">
                                  <img src={AddIcon} alt="Addicon" />
                                  <Typography variant="body1">
                                    Upload Watch Video
                                  </Typography>
                                </div>
                              ) : (
                                <VideoPlayer
                                  videoUrl={this.state.UploadPic9 + "?autoplay=1"}
                                  snapshotAt={10}
                                />
                              )}
                            </div>
                          </div>
                        </Card>
                      </label>
                      {this.state.dragActive9 && (
                        <div
                          className="drag-file-element"
                          onDragEnter={this.handleDrag9}
                          onDragLeave={this.handleDrag9}
                          onDragOver={this.handleDrag9}
                          onDrop={this.handleDrop9}
                        ></div>
                      )}
                    </form>
                    {/* <form
                id="video-drag"
                onDragEnter={this.handleDrag9}
                onSubmit={(e) => e.preventDefault()}
              >
              <input
                ref={this.inputRef9}
                accept="video/*"
                className="input-upload"
                id="icon-button-file9"
                type="file"
                onChange={this.UploadPhoto9}
              />
              <label
                id="label-file-upload9"
                htmlFor="icon-button-file9"
                className={this.state.dragActive9 ? "drag-active" : ""}
              >
               <Card className="Primary-Border">
                    <div className="space-3 primary-border">
                      <div
                        className="space-5 primary-border-dashed text-center1"
                        style={{ width: "100%" }}
                      >
                        {this.state.UploadPic9 === "" ? (
                          <div className="text-center1">
                            <img src={AddIcon} alt="Addicon" />
                            <Typography variant="body1">
                              Upload Watch Video
                            </Typography>
                          </div>
                        ) : (
                          <VideoPlayer
                            videoUrl={this.state.UploadPic9 + "?autoplay=1"}
                            snapshotAt={10}
                          />
                        )}
                      </div>
                    </div>
                  </Card>
              </label>
              {this.state.dragActive9 && (
                <div
                  id="drag-file-element"
                  onDragEnter={this.handleDrag9}
                  onDragLeave={this.handleDrag9}
                  onDragOver={this.handleDrag9}
                  onDrop={this.handleDrop9}
                ></div>
              )}
            </form> */}
                    {/* </div> */}
                    <Typography variant="h6">
                      {this.state.videoTitledisplay}
                    </Typography>
                  </Grid>
                  <Loader loaded={this.state.loaded}></Loader>{" "}
                  <Grid item xs={12} sm={4}>
                    <div style={{ marginTop: '20px', display: "flex", justifyContent: "flex-end" }}>
                      <Button className="text-capitalize primary-bg color-white" id="demo-customized-button" variant="text" style={{ width: '300px' }} onClick={this.handleSkip}>SKIP</Button>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="space-top-20">
                      {((!this.state.isUploaded) || (this.state.videoTitledisplay !== "")) && (
                        <Button
                          variant="contained"
                          disabled
                          className="text-center1 form-width-100"
                        // style={{marginRight:"13px",marginTop: "4px",padding: "4px 6px",fontSize: "12px"}}
                        >
                          Finish
                        </Button>

                      )}
                      {(

                        this.state.UploadPic ||
                        this.state.UploadPic2 ||
                        this.state.UploadPic9 ||
                        this.state.UploadPic12
                      ) && this.state.isUploaded && this.state.videoTitledisplay === "" && (
                          <Button
                            className="primary-bg color-white text-center1 form-width-100"
                            onClick={this.validateInput}
                          >
                            Finish
                          </Button>
                        )}

                    </div>
                  </Grid>
                  <Grid item xs={12} sm={1}></Grid>
                </Grid>
              </Container>
            </div>

          </div>}

        <ConfirmationDialog
          open={this.state.isDialogOpen}
          handleClose={this.handleCloseDialog}
          handleConfirm={this.handleConfirmDelete}
          message="Are you sure you want to <b>Delete</b>?"
        />
      </>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    currentUser: auth.currentUser,
  };
}

export default connect(
  mapStateToProps,
  null
)(DealerProductUploadImagesComponent);
