import React, { Component } from "react";
// import thunk from "redux-thunk";
import { Provider } from "react-redux";
// import { applyMiddleware, createStore } from "redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Routes from "./Routes";
import { isLoggedIn } from "./utils/Authentication";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import store from "./redux/store";
import "spinners-react/lib/SpinnerCircular.css";
import "spinners-react/lib/index.css";
// import reducers from "./components/pages/login/reducers/loginComponent";

// const middleware = applyMiddleware(thunk);
// const initialState = window.INITIAL_STATE;
// const store = createStore(reducers, initialState, middleware);

class App extends Component {
  componentDidMount() {
    function verifyUser(userInfo) {
      return {
        type: 'TOKEN_VERIFIED',
        payload: userInfo
      }
    }
    store.dispatch(verifyUser(isLoggedIn()))
  }
  render() {
    //store.dispatch(()=>{return {type:'TOKEN_VERIFIED',payload:{abc:'abc'}}});
    return (
      <div>
        <Provider store={store}>
          <Router>{Routes}</Router>
        </Provider>
        <ToastContainer hideProgressBar />
      </div>
    );
  }
}

export default App;
