import React, { Component } from "react";
import VideoPlayer from "simple-react-video-thumbnail";
import VerticalTabs from "./dashboardTabsComponent";
import {
  Container,
  Grid,
  Typography,
  Button,
  Card,
  Paper,
  CardContent,
  FormControlLabel,
  Switch,
  FormGroup,
  List,
  ListItem,
  Tooltip,
  Modal,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import Header from "../../../../common/components/Header";
import Footer from "../../../../common/components/Footer";
import { inventoryDetailById } from "../actions/dashboardAction";
import searchinventry from "../actions/dashboardaapi";
import getActivepackage from "../actions/dashboardaapi";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import listingImg from "../../../../assests/images/Group-1863@2x.png";
import NewContentWrapper from "../../../../common/components/newContentWrapper";
import chrono from "../../../../assests/chrono24.png";
import ebay from "../../../../assests/ebay.png";
import pricelistImg from "../../../../assests/listprice.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { addChornoListing } from "../../dealer/actions/dealerAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FbIcon from "../../../../assests/images/fb.png";
import { Alert, AlertTitle } from "@material-ui/lab";
import AddIcon from "../../../../../src/assests/images/add.png";
import defaulutImg from "../../../../assests/images/Bitmap.png";
import { AudioCard, VideoCard } from "material-ui-player";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { SpinnerRoundFilled } from "spinners-react";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getComponentActionList,
  isActionAllowed,
  getComponentViewsList,
  formatDate,
} from "../../../../common/components/Helper/Utils";
import imageNotAvailable from '../../../../assests/images/img-soon.png';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import ViewInvoicemodel from "./ViewInvoicemodel";




// const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
// const theme = useTheme();
var Loader = require("react-loader");
const FB = window.FB;

const InventoryStatusLookup = {
  1: "Inhand",
  2: "Incoming",
  3: "Out on Memo",
  4: "On Hold / Reserved",
  5: "Available to Order",
  6: "Needs Service / Polish",
  7: "At Service",
  8: "Need to Ship",
  9: "Shipped waiting on payment",
  10: "Sold & Cashed Out",
  11: "Intake Complete",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: 600,
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class InventoryDetailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseDate: "NewDate",
      purchaseDate1: "NewDate1",
      fullScreen: true,
      open: false,
      setOpen: false,
      inventoryDetailByIdDetails: [],
      productDetails: [],
      prodId: "",
      displayUrl: false,
      manageResult: false,
      ChronoUrl: "",
      inventory_id: "",
      listed_on: "chrono",
      sold_status: "",
      enbleAltert: false,
      displaysharingImg: false,
      inventryPricelist: [],
      loaded: false,
      soldbtn: false,
      ebayAmout: "",
      croono21Ammount: "",
      retailAmount: "",
      listing_addon: false,
      accessData: [],
      viewData: [],
      viewInvoiceModal: false,
      invoiceId: !isNaN(localStorage.getItem('invoiceId')) ? localStorage.getItem('invoiceId') : null,
      userData: sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null,
      openModal: false,
      invoiceType: "regular_invoice"
      // uploadimages: ''
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onsocialsearch1 = this.onsocialsearch1.bind(this);
    this.onsocialsearch2 = this.onsocialsearch2.bind(this);
    this.onsocialsearch3 = this.onsocialsearch3.bind(this);
    this.onsocialsearch4 = this.onsocialsearch4.bind(this);
    this.onsocialsearch5 = this.onsocialsearch5.bind(this);
    this.onsocialsearch6 = this.onsocialsearch6.bind(this);
    this.onsocialsearch7 = this.onsocialsearch7.bind(this);
    this.onsocialsearch8 = this.onsocialsearch8.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onsocialsearch = this.onsocialsearch.bind(this);
    this.addChoonoListing = this.addChoonoListing.bind(this);
    this.changeRangeName = this.changeRangeName.bind(this);
    this.onclickfacebookshare = this.onclickfacebookshare.bind(this);
    // this.getproductDetails = this.getproductDetails.bind(this)
    this.editinvnetrydeatils = this.editinvnetrydeatils.bind(this);
    this.boxcheckboxselection = this.boxcheckboxselection.bind(this);
    this.editIconClick = this.editIconClick.bind(this);
    this.backNavigation = this.backNavigation.bind(this);
    this.handleClickBreadCrumbs = this.handleClickBreadCrumbs.bind(this);
    this.handleMyInventoryClick = this.handleMyInventoryClick.bind(this);
    this.handleClosecheck = this.handleClosecheck.bind(this);
  }

  componentDidMount() {
    const linkStyle = {
      fontSize: '14px',
      color: 'black',
      backgroundColor: 'white',
      textDecoration: 'none',
      transition: 'color 0.3s',
      cursor: 'pointer',
    };

    const hoverStyle = {
      color: '#007bff',
      textDecoration: 'underline',
    };
    const accessDataObject = getComponentActionList("inventory_details");
    const viewDataObject = getComponentViewsList("inventory_details");
    this.setState({ accessData: accessDataObject, viewData: viewDataObject });
    let productId = this.props.match.params.productId;
    let id = this.props.match.params.id;
    this.setState({
      inventory_id: id,
    });

    this.setState({
      ChronoUrl:
        "(Your Chrone24 xml url is URL(https://api.watchops.com/inventories/" +
        this.props.match.params.userId +
        "/c24-importer.xml))",
    });
    // this.props.inventoryDetailById(id);
    // const datagg = this.props.inventoryDetailById(id);
    searchinventry.getinventryDetails(id).then((res) => {
      if (res.data.success === 1) {
        if (res.data.data && res.data.data.technical_details && typeof res.data.data.technical_details === 'string') {
          const technical_details = JSON.parse(res.data.data.technical_details)
          res.data.data.technical_details = technical_details
        }
        this.setState({
          inventoryDetailByIdDetails: res.data.data,
          prodId: res.data.data.productid,
        });
        // this.getproductDetails(this.state.prodId)
        if (this.state.prodId) {
          this.getpriceLsting(this.state.prodId);
        } else {
          this.setState({
            loaded: true,
          });
        }
        this.boxcheckboxselection();
        // this.getActivePackages();
        searchinventry.getActivepackage().then((res) => {
          if (res.data.data && res.data.data.listing_addon) {
            this.setState({
              listing_addon: res.data.data.listing_addon,
            });
          }
        });
      } else {
        this.setState({
          inventoryDetailByIdDetails: res.data,
          manageResult: true,
          loaded: true,
        });
      }
    });
  }
  handleClosecheck() {
    this.setState({ viewInvoiceModal: false })
  }
  getpriceLsting(id) {
    searchinventry.getinventrypriceList(id).then((res) => {
      this.setState({
        inventryPricelist: res.data.data,
        loaded: true,
      });
      for (let i = 0; i < this.state.inventryPricelist.length; i++) {
        if (this.state.inventryPricelist[i].watch_source == "Chrono24") {
          this.setState({
            croono21Ammount: this.state.inventryPricelist[i].avg,
          });
        } else if (this.state.inventryPricelist[i].watch_source == "Retail") {
          this.setState({ retailAmount: this.state.inventryPricelist[i].avg });
        } else if (this.state.inventryPricelist[i].watch_source == "EBay") {
          this.setState({ ebayAmout: this.state.inventryPricelist[i].avg });
        }
      }
    });
  }

  getActivePackages() {
  }

  handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  handleMyInventoryClick() {
    const { history } = this.props;
    history.push('/myinventory');
  };

  boxcheckboxselection() {
    if (this.state.inventoryDetailByIdDetails.sold == 1) {
      this.setState({
        soldbtn: true,
      });
    } else {
      this.setState({
        soldbtn: false,
      });
    }
  }

  onclickfacebookshare() {
    this.setState({
      displaysharingImg: true,
    });
  }
  onsocialsearch1(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch2(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch3(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch4(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch5(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch6(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch7(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch8(e) {
    this.setState({
      uploadimages: e,
      displaysharingImg: false,
    });
    this.onsocialsearch(e);
  }
  onsocialsearch(uploadImages) {
    this.setState({
      uploadimages: uploadImages,
    });
    window.fbAsyncInit = function () {
      window.FB.getLoginStatus(({ authResponse }) => { });
    };
    FB.ui(
      {
        display: "popup",
        method: "feed",
        picture: uploadImages,
        link: uploadImages,
      },
      function (response) { }
    );
  }
  backNavigation() {
    const { history } = this.props;
    history.push("/myinventory");
  }

  addChoonoListing() {
    this.setState({ loaded: false });
    let data = {
      inventory_id: this.state.inventory_id,
      listed_on: "chrono",
    };
    addChornoListing(data, (error, res) => {
      if (res.data.success === 1) {
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        Swal.fire(
          this.state.ChronoUrl,
          " <br>Use this Xml link for further Usage"
        );
        this.setState({
          displayUrl: true,
          loaded: true,
        });
      } else {
        this.setState({
          displayUrl: true,
          loaded: true,
        });
        Swal.fire(
          this.state.ChronoUrl,
          " <br>Use this Xml link for further Usage"
        );
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  }
  // getproductDetails (id){
  //     searchinventry.getProductsDetails(id).then((res) => {
  //         this.setState({
  //             productDetails: res.data.data
  //         })
  //     })
  // }
  // socialshare(e){
  //     console.log(e)
  // }
  editinvnetrydeatils() {
    this.props.history.push(
      "/update-inventry-details/" +
      this.state.inventoryDetailByIdDetails.inventoryid,
      this.props.history.location.state
    );
  }

  changeRangeName = (event) => {
    const checkstaus = event.target.checked;
    if (checkstaus === true) {
      this.setState({
        inventory_id: this.state.inventoryDetailByIdDetails.inventoryid,
        sold_status: "1",
        soldbtn: true,
      });
      localStorage.setItem("sold_status", "1");
    } else {
      this.setState({
        inventory_id: this.state.inventoryDetailByIdDetails.inventoryid,
        sold_status: "0",
        soldbtn: false,
      });
      localStorage.setItem("sold_status", "0");
    }
    let data = {
      inventory_id: this.state.inventory_id,
      sold_status: localStorage.getItem("sold_status"),
      listed_on: this.state.listed_on,
    };
    searchinventry.productsold(data).then((res) => {
      if (res.data.success === 1) {
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        localStorage.removeItem("sold_status");

        this.setState({
          enbleAltert: true,
        });
      } else {
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        localStorage.removeItem("sold_status");
        this.setState({
          enbleAltert: true,
        });
      }
    });
  };

  handleClickOpen() {
    this.setState({
      open: true,
      setOpen: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      setOpen: false,
    });
  }

  editIconClick() {
    const { inventoryid } = this.state.inventoryDetailByIdDetails;
    this.props.history.push({
      pathname: "/update-inventry-images-details/" + inventoryid,
      state: { inventoryDetailPage: true }
    });
  }

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  getInvoiceDetails = (inventoryDetailByIdDetails) => {
    const {
      type,
      serialno,
      purchaseprice,
      quantity,
      inventoryid,
      technical_details,
      invattr,
      imagepaths,
      product_imagepaths,
    } = inventoryDetailByIdDetails ?? {};

    const isMiscProductOrWatch = type === "misc_product" || type === "misc_watch";

    const model = isMiscProductOrWatch
      ? technical_details?.product_title ||
      technical_details?.product_name ||
      ""
      : invattr?.reference;

    const productid = isMiscProductOrWatch
      ? technical_details?.productid
      : inventoryDetailByIdDetails?.productid;

    const productName = isMiscProductOrWatch
      ? technical_details?.product_title ||
      technical_details?.product_name ||
      ""
      : null;

    const desc = isMiscProductOrWatch
      ? technical_details?.product_title ||
      technical_details?.product_name ||
      ""
      : inventoryDetailByIdDetails?.inventory_title ||
      invattr?.model ||
      "---";

    const image =
      imagepaths?.front ||
      imagepaths?.back ||
      imagepaths?.left ||
      imagepaths?.right ||
      imagepaths?.free_form ||
      imagepaths?.buckle_opened ||
      imagepaths?.buckle_closed ||
      imagepaths?.wrist_shot ||
      product_imagepaths?.images?.[0] ||
      imageNotAvailable;

    return {
      model,
      serialno,
      productid,
      productName,
      purchaseprice,
      desc,
      type,
      quantity,
      inventoryid,
      image,
    };
  };

  newContentView() {
    const { openModal, invoiceType } = this.state;
    let inventoryDetailByIdInfo = "";
    let id = "";
    let inventoryDetailByIdDetails = "";
    // if(this.props.inventoryDetailByIdInfo.success == 0){
    //     inventoryDetailByIdInfo = this.props.inventoryDetailByIdInfo;
    // }else{
    //     inventoryDetailByIdInfo = this.props.inventoryDetailByIdInfo.data;
    //      id = inventoryDetailByIdInfo.id;
    //      inventoryDetailByIdDetails = (inventoryDetailByIdInfo && inventoryDetailByIdInfo.prodattr) ? inventoryDetailByIdInfo.prodattr : "";
    // }
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { warrantypaper, archive, is_service } = this.state.inventoryDetailByIdDetails;
    if (this.state.manageResult) {
      return (
        <div
          style={{
            marginLeft: "400px",
            marginTop: "50px",
            textAlign: "center",
          }}
        >
          <Card style={{ width: "600px", textAlign: "center" }}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                <div> {this.state.inventoryDetailByIdDetails.message}</div>
              </Typography>
            </CardContent>
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          <link rel="icon" href="./faviconw.png" />

          {this.state.loaded == false ? (
            <div class="d-spinner">
              <SpinnerRoundFilled
                size={51}
                thickness={125}
                speed={80}
                color="rgba(57, 107, 172, 1)"
                className="spp"
                enabled={this.state.loaded == false}
              />
            </div>
          ) : (
            <div>
              <div>
                <Container style={{ marginBottom: "10px" }}>
                  <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {/* <Grid>
                      <ArrowBackIcon
                        style={{
                          color: "action",
                          paddingRight: "15px",
                          marginTop: '5px',
                          fontSize: 40,
                          cursor: "pointer",
                        }}
                        onClick={this.backNavigation}
                      >

                      </ArrowBackIcon>
                    </Grid> */}
                    <Grid style={{ marginTop: '10px' }}>
                      {/* <Typography  variant="h5"
                        className="font-size-18"><b>Go Back</b></Typography> */}

                      <div onClick={this.handleClickBreadCrumbs}>
                        <Stack spacing={1}>
                          <Breadcrumbs aria-label="breadcrumb" separator={<span className="sperator">›</span>} >
                            <Link
                              className="link"
                              style={{ fontSize: '14px', color: '#CEA05F', textDecoration: 'underline !important', backgroundColor: 'white' }}
                              to={{
                                pathname: '/myinventory',
                                state: { ...this.props.history.location.state }
                              }}
                            >
                              My Inventory
                            </Link>

                            <Link
                              style={{ fontSize: '14px', color: '#9e9e9e', backgroundColor: 'white' }}
                              aria-current="page"
                            >
                              Inventory Details
                            </Link>

                          </Breadcrumbs>
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                  <Card style={{ marginTop: "20px" }}>
                    <Grid container sacing={2} style={{ padding: "1rem" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}

                      >

                        {this.state.inventoryDetailByIdDetails &&
                          this.state.inventoryDetailByIdDetails.imagepaths ? (
                          <>
                            {isActionAllowed(
                              this.state.accessData,
                              "upload_inventory_images"
                            ) && (
                                <div onClick={this.editIconClick}>
                                  <EditIcon
                                    style={{
                                      backgroundColor: "#ccc",
                                      position: "relative",
                                      float: "right",
                                      bottom: "-5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                          </>
                        ) : (
                          <>
                            {isActionAllowed(
                              this.state.accessData,
                              "upload_inventory_images"
                            ) && (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="primary-bg color-white text-center form-width-30"
                                    onClick={this.editIconClick}
                                  >
                                    upload Images
                                  </Button>
                                </>
                              )}
                          </>
                        )}
                        <div
                          className="space-1"
                          style={{ textAlign: "center" }}
                        >
                          <Slider {...settings} className="slide-invdetail">
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.front ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.front || defaulutImg}
                                  alt="rolex"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "-5px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.left ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.left || defaulutImg}
                                  alt="client-logo-1"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "0px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.right ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.right || defaulutImg}
                                  alt="client-logo-1"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "0px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.back ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.back || defaulutImg}
                                  alt="client-logo-1"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "0px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.buckle_closed ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.buckle_closed || defaulutImg}
                                  alt="client-logo-1"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "0px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.buckle_opened ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.buckle_opened || defaulutImg}
                                  alt="client-logo-1"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "0px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.free_form ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.free_form || defaulutImg}
                                  alt="client-logo-1"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "0px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.inventoryDetailByIdDetails?.imagepaths?.wrist_shot ? (
                              <div
                                className="space-3"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={this.state.inventoryDetailByIdDetails?.imagepaths?.wrist_shot || defaulutImg}
                                  alt="client-logo-1"
                                  className="img-width-23 img-responsive1"
                                  style={{
                                    display: "initial",
                                    width: "auto",
                                    marginLeft: "0px",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </Slider>
                          {/* <div className="text-center1 space-top-20">
                                                        <Link href="/" style={{ color: '#292C2F' }}></Link>
                                                    </div> */}
                          <div>
                            <div>
                              {this.state.inventoryDetailByIdDetails?.imagepaths?.video ? (
                                <div>
                                  {
                                    this.state.inventoryDetailByIdDetails?.imagepaths?.video ? (
                                      <VideoPlayer
                                        videoUrl={this.state.inventoryDetailByIdDetails?.imagepaths?.video + "?autoplay=1"}
                                        snapshotAt={10}
                                      />
                                    ) : (
                                      ""
                                    )
                                    // <Typography variant="h6" className="color-232323"><b>No video Found {this.state.UploadPic9}</b></Typography>
                                  }
                                </div>
                              ) : (
                                // <div className="space-3">
                                //     <Grid container spacing={2}>
                                //         <Grid>
                                //             <h2>Inventory Video</h2>
                                //         </Grid>
                                //         <Grid>
                                //             <Button style={{ marginTop: '15px' }}>
                                //                 <SlowMotionVideoIcon onClick={this.handleClickOpen} />
                                //             </Button>
                                //         </Grid>
                                //     </Grid>
                                // </div>
                                ""
                              )
                                // <Typography variant="h6" className="color-232323"><b>No Video Found {this.state.UploadPic9}</b></Typography>
                              }
                              {/* <h3>Inventory Video</h3>
                                            <Button>
                                            <SlowMotionVideoIcon  onClick={this.handleClickOpen}/>
                                            </Button> */}
                            </div>
                            <Dialog
                              // fullScreen={this.state.fullScreen}
                              open={this.state.open}
                              onClose={this.handleClose}
                              aria-labelledby="responsive-dialog-title"
                            >
                              {/* <DialogTitle id="responsive-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
                              <DialogContent style={{ paddingLeft: "0px" }}>
                                <DialogContentText>
                                  <div style={{ marginLeft: "10px" }}>
                                    <Typography variant="h6">
                                      <b>Watch Video {this.state.UploadPic9}</b>
                                    </Typography>
                                    <Grid container spacing={1}>
                                      <Grid item xs={6} sm={6} md={12} lg={12}>
                                        <div style={{ width: "580px" }}>
                                          {
                                            this.state
                                              ?.inventoryDetailByIdDetails
                                              ?.imagepaths?.video && (
                                              <VideoPlayer
                                                videoUrl={
                                                  this.state
                                                    ?.inventoryDetailByIdDetails
                                                    ?.imagepaths?.video +
                                                  "?autoplay=1"
                                                }
                                                snapshotAt={10}
                                              />
                                            )
                                            // <Typography variant="h6" className="color-232323"><b>No video Found {this.state.UploadPic9}</b></Typography>
                                          }
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  autoFocus
                                  onClick={this.handleClose}
                                  color="primary"
                                >
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={7}>
                        {/* <div className="space-3">
                                                </div> */}
                        <div>
                          <Grid container>
                            <Grid item xs={12} sm={9} md={9}>
                              <div style={{ padding: "0px" }}>
                                {/* <Button variant="contained" color="primary" className=" float-right primary-bg color-white text-center1" style={{padding:"5px 15px", fontSize:"0.8rem"}} onClick={()=>{this.props.history.push({pathname: "/create/invoice",state: { inventoryDetails: {model: this.state?.inventoryDetailByIdDetails?.invattr?.reference, serialno: this.state?.inventoryDetailByIdDetails?.serialno, desc: this.state?.inventoryDetailByIdDetails?.description} }})}}>CREATE INVOICE</Button> */}
                                <Typography
                                  variant="h5"
                                  className="color-292C2F"
                                  style={{ fontSize: "1.2rem" }}
                                >
                                  <b>Inventory product details</b>
                                </Typography>
                              </div>
                              <div style={{ padding: "10px 0 10px" }}>
                                {/* <Button variant="contained" color="primary" className=" float-right primary-bg color-white text-center1" style={{padding:"5px 15px", fontSize:"0.8rem", top: '13px'}} onClick={this.editinvnetrydeatils}>EDIT</Button> */}
                                <Typography
                                  variant="h5"
                                  style={{ fontSize: "1rem" }}
                                >
                                  {this.state.inventoryDetailByIdDetails &&
                                    this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                    this.state.inventoryDetailByIdDetails.invattr
                                      .productname &&
                                    this.state.inventoryDetailByIdDetails.invattr
                                      .productname
                                    ? this.state.inventoryDetailByIdDetails
                                      .invattr.productname
                                    : ""}{" "}
                                  {this.state.inventoryDetailByIdDetails &&
                                    this.state.inventoryDetailByIdDetails
                                      .invattr &&
                                    this.state.inventoryDetailByIdDetails.invattr
                                      .model &&
                                    this.state.inventoryDetailByIdDetails.invattr
                                      .model
                                    ? this.state.inventoryDetailByIdDetails
                                      .invattr.model
                                    : ""}
                                </Typography>
                              </div>
                              <div className="MuiGrid-spacing-xs-4">
                                <Grid container spacing={4}>
                                  <React.Fragment>
                                    {/* <Grid item xs={12} sm={12} md={4} style={{padding:"0px"}}>
                                                                            <img src={ebay} alt="" width="103px" style={{ padding: "5px 0px" }} />
                                                                            {this.state.ebayAmout ?
                                                                                <div style={{ marginLeft: '25px' }} className="eby">
                                                                                    <Typography variant="small">
                                                                                        ${(this.state.ebayAmout && this.state.ebayAmout.slice(0, 8))
                                                                                            ?
                                                                                            (this.state.ebayAmout && this.state.ebayAmout.slice(0, 8))
                                                                                            :
                                                                                            " ---"}
                                                                                    </Typography>
                                                                                </div>
                                                                                :
                                                                                <div style={{ marginLeft: '20px' }}>
                                                                                    <Typography variant="small">
                                                                                        {"---"}
                                                                                    </Typography>
                                                                                </div>

                                                                            }

                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={4} style={{padding:"0px 16px 0px"}}>
                                                                            <img src={chrono} alt="" width="103px" style={{ padding: "5px 0px" }} onClick={this.addChoonoListing} />
                                                                            <div>
                                                                                {this.state.croono21Ammount ? <Typography variant="small">${(this.state.croono21Ammount && this.state.croono21Ammount.slice(0, 8)) ? (this.state.croono21Ammount && this.state.croono21Ammount.slice(0, 8)) : " ---"}</Typography> : <Typography variant="small"> {"---"}</Typography>}
                                                                            </div>
                                                                        </Grid> */}
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      style={{ padding: "0px 16px 0px" }}
                                    >
                                      <img
                                        src={listingImg}
                                        alt=""
                                        width="103px"
                                        style={{ padding: "5px 0px" }}
                                      />
                                      <div>
                                        {this.state.retailAmount ? (
                                          <Typography variant="small">
                                            $
                                            {this.state.retailAmount &&
                                              this.state.retailAmount.slice(0, 8)
                                              ? this.state.retailAmount &&
                                              this.state.retailAmount.slice(
                                                0,
                                                8
                                              )
                                              : " List Price Not Available"}
                                          </Typography>
                                        ) : (
                                          <Typography variant="small">
                                            {" "}
                                            List Price Not Available
                                          </Typography>
                                        )}
                                      </div>
                                    </Grid>
                                  </React.Fragment>
                                </Grid>
                              </div>
                            </Grid>
                            <Grid container item xs={12} sm={3} md={3}>
                              {this.state.userData && (!this.state.userData?.planDetails || this.state.userData?.planDetails === "Noplan") ?
                                null :
                                <>
                                  {isActionAllowed(
                                    this.state.accessData,
                                    "create_invoice"
                                  ) && (
                                      <Grid item xs={12} sm={12} md={12}>
                                        <div style={{ padding: "0px" }}>
                                          {(this.state.inventoryDetailByIdDetails
                                            .sold === 1 && this.state.invoiceId && this.state.inventoryDetailByIdDetails
                                              .status !== "Void") && (
                                              <Button
                                                variant="contained"
                                                className=" float-right primary-bg color-white text-center1"
                                                color="primary"
                                                disabled={this.state.invoiceId ? false : true}
                                                style={{
                                                  // marginRight: "13px",
                                                  marginTop: "4px",
                                                  padding: "5px 15px",
                                                  fontSize: "12px",
                                                }}
                                                onClick={() => this.setState({ viewInvoiceModal: true })}
                                              >
                                                View Invoice
                                              </Button>
                                            )}
                                          {this.state.inventoryDetailByIdDetails
                                            .sold !== 1 && this.state.inventoryDetailByIdDetails
                                              .status !== "Void" && this.state.inventoryDetailByIdDetails?.inventory_status != 3 &&
                                            (
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                className=" float-right primary-bg color-white text-center1"
                                                style={{
                                                  padding: "5px 15px",
                                                  fontSize: "0.8rem",
                                                }}
                                                onClick={this.handleOpenModal}
                                              >CREATE INVOICE
                                              </Button>
                                            )}

                                        </div>
                                      </Grid>
                                    )}
                                </>
                              }

                              {isActionAllowed(
                                this.state.accessData,
                                "edit_inventory"
                              ) && (
                                  <>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <div style={{ padding: "0px 0 10px" }}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          className=" float-right primary-bg color-white text-center1"
                                          style={{
                                            padding: "5px 15px",
                                            fontSize: "0.8rem",
                                            top: "13px",
                                            // marginBottom:'20px',
                                          }}
                                          onClick={this.editinvnetrydeatils}
                                        >
                                          EDIT
                                        </Button>
                                      </div>
                                      {/* <div><Button
                                              variant="contained"
                                              color="primary"
                                              className=" float-right primary-bg color-white text-center1"
                                              onClick={()=>this.setState({viewInvoiceModal:true})}
                                              style={{
                                                marginTop: "4px",
                                                fontSize: "12px",
                                              }}
                                            >
                                              View Invoice
                                            </Button></div> */}

                                    </Grid>
                                  </>
                                )}
                            </Grid>
                          </Grid>

                          {/* <div className="space-bottom-10" style={{ padding: "20px 0px" }}>
                                                        <Typography variant="h5" className="color-292C2F"><b>List Prices</b></Typography>
                                                    </div> */}

                          {/* <Grid container spacing={4}>
                                            {this.state.inventryPricelist &&
                                                this.state.inventryPricelist.map((price) => (
                                                    <Grid item xs={4} sm={3} md={3}>
                                                        <div>
                                                            <div style={{ marginTop: '6px', }}>
                                                                {price.watch_source == "EBay" ? <img src={ebay} alt="" width="110px" style={{ padding: "20px 0px" }} /> : ''}
                                                                {price.watch_source == "Chrono24" ? <img src={chrono} alt="" width="110px" style={{ padding: "20px 0px" }} onClick={this.addChoonoListing} /> : ''}
                                                                {price.watch_source == "Retail" ? <img src={pricelistImg} alt="" width="110px" style={{ padding: "20px 0px" }} />:"" }
                                                            </div>
                                                            
                                                            <div>
                                                            <div className="color-848484" style={{ marginTop: '2px' }} >
                                                                {price.watch_source == "EBay" ? <Typography variant="small">${(price && price.avg.slice(0, 8)) ? (price && price.avg.slice(0, 8)) : "---"}</Typography> : ''}
                                                                {price.watch_source == "Chrono24" ? <Typography variant="small">${(price && price.avg.slice(0, 8)) ? (price && price.avg.slice(0, 8)) : "---"}</Typography> : ""}
                                                                {price.watch_source == "Retail" ? <Typography variant="small">${(price && price.avg.slice(0, 8)) ? (price && price.avg.slice(0, 8)) : "---"}</Typography> : ''}
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </Grid>))}
                                        </Grid>   */}
                          {this.state.listing_addon ? (
                            <div>
                              {/* 
                                                                // removed based on hemal request
                                                            <div className="space-bottom-10" style={{ padding: "20px 0px" }}>
                                                                <Typography variant="h5" className="color-292C2F"><b>List Item On</b></Typography>
                                                            </div> */}
                              {/* <Loader loaded={this.state.loaded}> */}

                              {/* hemal request
                                                            <Grid container spacing={4}> */}
                              {/* {this.state.inventryPricelist &&
                                                    this.state.inventryPricelist.map((price) => ( */}
                              {/* hemal request
                                                                <Grid item xs={4} sm={3} md={3}>

                                                                    <div>
                                                                        <div style={{ marginTop: '6px'}}> */}
                              {/* {price.watch_source == "EBay" ? <img src={ebay} alt="" width="110px" style={{ padding: "12px 0px" }} /> : ''} */}
                              {/*  hemal request
                                                                            <img src={chrono} alt="" width="110px" style={{ padding: "20px 0px" }} onClick={this.addChoonoListing} /> */}
                              {/* {price.watch_source == "Retail" ? <img src={pricelistImg} alt="" width="110px" style={{ padding: "20px 0px" }} />:"" } */}
                              {/* </div> */}
                              {/* <div className="color-848484" style={{ marginTop: '2px' }} >
                                                                    {price.watch_source == "EBay" ? <Typography variant="small">${(price && price.avg.slice(0, 8)) ? (price && price.avg.slice(0, 8)) : "---"}</Typography> : ''}
                                                                    {price.watch_source == "Chrono24" ? <Typography variant="small">${(price && price.avg.slice(0, 8)) ? (price && price.avg.slice(0, 8)) : "---"}</Typography> : ""}
                                                                    {price.watch_source == "Retail" ? <Typography variant="small">${(price && price.avg.slice(0, 8)) ? (price && price.avg.slice(0, 8)) : "---"}</Typography> : ''}
                                                                </div> */}
                              {/* hemal request </div>

                                                                </Grid> */}

                              {/* ))} */}
                              {/* </Grid>

                                                            <Grid container spacing={4}>
                                                                <Grid item xs={4} sm={3} md={2}>
                                                                    <div className="fl-left">
                                                                        <FormGroup>
                                                                            <Typography variant="h6" className="color-292C2F"><b>status : </b></Typography>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4} sm={3} md={3}>
                                                                    <div className="fl-left">
                                                                        <FormGroup>
                                                                            <FormControlLabel
                                                                                control={<Switch onChange={this.changeRangeName}
                                                                                    checked={this.state.soldbtn == true} name="hdsdsdbjb" />}
                                                                                label="sold"
                                                                            />
                                                                        </FormGroup>

                                                                    </div>
                                                                </Grid>

                                                            </Grid> */}
                              {/* </Loader> */}
                              {/* <div className="space-bottom-5 space-top-5">
                                                                <Typography variant="h5" className="color-292C2F"><b>Promote The Item On </b></Typography>
                                                            </div> */}
                              {/* <Grid container spacing={4}>
                                                                <Grid item xs={4} sm={3} md={3}>
                                                                    <div className="image-shopping-right" style={{ marginTop: '6px', padding: "10px 0px" }}>
                                                                        <img src={FbIcon} alt="right-Icon" style={{ width: "70%" }} className="b" onClick={this.onclickfacebookshare} />
                                                                    </div>

                                                                </Grid>

                                                            </Grid> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Container>
                {/* <br /> */}
                {/* mm---------------------------------------------------------- */}

                {this.state.displaysharingImg ? (
                  <Container style={{ marginBottom: "10px" }}>
                    <div className="space-top-30">
                      <Typography
                        variant="h4"
                        className="font-size-24 color-232323 center-text"
                      >
                        <b>Select Images To Share</b>
                      </Typography>
                    </div>

                    <Grid container spacing={4}>
                      {this.state.inventoryDetailByIdDetails.imagepaths
                        .front ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                Front
                              </Typography>
                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.front
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch4(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.front
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}

                      {this.state.inventoryDetailByIdDetails.imagepaths.back ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                Back
                              </Typography>
                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.back
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch1(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.back
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {this.state.inventoryDetailByIdDetails.imagepaths
                        .right ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                Right
                              </Typography>
                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.right
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch6(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.right
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}

                      {this.state.inventoryDetailByIdDetails.imagepaths.left ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                left
                              </Typography>
                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.left
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch5(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.left
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}

                      {this.state.inventoryDetailByIdDetails.imagepaths.top ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                Top
                              </Typography>

                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.top
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch2(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.top
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {this.state.inventoryDetailByIdDetails.imagepaths
                        .bottom ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                Bottom
                              </Typography>

                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.bottom
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch3(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.bottom
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {this.state.inventoryDetailByIdDetails.imagepaths
                        .buckle_opened ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                Buckle Opened
                              </Typography>

                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.buckle_opened
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch7(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.buckle_opened
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {this.state.inventoryDetailByIdDetails.imagepaths
                        .buckle_closed ? (
                        <Grid item xs={6} sm={3} md={3}>
                          <div>
                            <div className="boxshodow">
                              <Typography variant="h5" className="boxtitle">
                                Buckle Closed
                              </Typography>
                              <img
                                src={
                                  this.state.inventoryDetailByIdDetails &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths &&
                                  this.state.inventoryDetailByIdDetails
                                    .imagepaths.buckle_closed
                                }
                                alt="client-logo-1"
                                className="img-width-23 img-responsive2"
                                onClick={() => {
                                  this.onsocialsearch8(
                                    this.state.inventoryDetailByIdDetails
                                      .imagepaths.buckle_closed
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Container>
                ) : (
                  <Container style={{ marginBottom: "10px" }}>
                    <Card
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "20px",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4}>
                          <div style={{ textAlign: "center" }}>
                            <Typography
                              variant="h5"
                              className="color-292C2F"
                              style={{ fontSize: "1rem" }}
                            >
                              <b>Product Specifications</b>
                            </Typography>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    List Price
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.retailAmount ? (
                                        <Typography variant="small">
                                          $
                                          {this.state.retailAmount &&
                                            this.state.retailAmount.slice(0, 8)
                                            ? this.state.retailAmount &&
                                            this.state.retailAmount.slice(
                                              0,
                                              8
                                            )
                                            : " List Price Not Available"}
                                        </Typography>
                                      ) : (
                                        <Typography variant="small">
                                          {" "}
                                          List Price Not Available
                                        </Typography>
                                      )}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {/* <hr style={{ width: '330px' }} /> */}
                          {((this.state.inventoryDetailByIdDetails.type &&
                            this.state.inventoryDetailByIdDetails.type !==
                            "misc_watch" &&
                            this.state.inventoryDetailByIdDetails.type !==
                            "misc_product") ||
                            this.state.inventoryDetailByIdDetails.type ===
                            null) && (
                              <>
                                <div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Brand Name
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.brandname
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Model Number
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {
                                                this.state
                                                  ?.inventoryDetailByIdDetails
                                                  ?.invattr?.reference
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Model Name
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.model
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Case Material
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.casematerial
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Case Size
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.casesize
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Case Back
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                .inventoryDetailByIdDetails &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr.caseback &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr.caseback
                                                ? this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr.caseback
                                                : "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div> */}
                                  {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Case Shape
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                .inventoryDetailByIdDetails &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr.shape &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr.shape
                                                ? this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr.shape
                                                : "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div> */}
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Dial Color
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.dialcolor
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Band Type
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.bandtype
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Band Material
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.bandmaterial
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Band Size
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.bandsize
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Caliber
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.caliber
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                  <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={6}>
                                      <div>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="color-00000061"
                                        >
                                          Power Reserve
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6}>
                                      <div>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state
                                              .inventoryDetailByIdDetails &&
                                            this.state
                                              .inventoryDetailByIdDetails
                                              .invattr &&
                                            this.state
                                              .inventoryDetailByIdDetails
                                              .invattr.powerreserve &&
                                            this.state
                                              .inventoryDetailByIdDetails
                                              .invattr.powerreserve
                                              ? this.state
                                                  .inventoryDetailByIdDetails
                                                  .invattr.powerreserve
                                              : "---"}
                                          </b>
                                        </Typography>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div> */}
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Water Resistent
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.invattr?.waterresistant
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Purchase Date</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right">
                                                                            <b>{this.state.inventoryDetailByIdDetails.purchasedate ? moment(this.state.inventoryDetailByIdDetails.purchasedate).format('MM/DD/YYYY') : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Inventory Type</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>
                                                                        {this.state.inventoryDetailByIdDetails.inventorytype === "1" ? "regularinventory" : (this.state.inventoryDetailByIdDetails.inventorytype === "2" ? "memodetails" : (this.state.inventoryDetailByIdDetails.inventorytype === "3" ? "partnershipdetais" : "---" ))}</b></Typography>
                 
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Condition</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.condition ? this.state.inventoryDetailByIdDetails.condition : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Band Size1</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.bandsize ? this.state.inventoryDetailByIdDetails.bandsize : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>

                                                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Links</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.links ? this.state.inventoryDetailByIdDetails.links : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Purchase Price</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>$ {this.state.inventoryDetailByIdDetails.purchaseprice ? this.state.inventoryDetailByIdDetails.purchaseprice : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>

                                                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Retail Price</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>$ {this.state.inventoryDetailByIdDetails.retailprice ? this.state.inventoryDetailByIdDetails.retailprice : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div> */}
                                </div>
                              </>
                            )}

                          {this.state.inventoryDetailByIdDetails.type &&
                            this.state.inventoryDetailByIdDetails.type ===
                            "misc_watch" && (
                              <>
                                <div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Product Name
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.product_title
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Product Title
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                .inventoryDetailByIdDetails &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .technical_details &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .technical_details.product_title
                                                ? this.state
                                                  .inventoryDetailByIdDetails
                                                  .technical_details
                                                  .product_title
                                                : "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div> */}
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Brand Name
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.brand_name
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Model Number
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.model_number
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Case Material
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.case_material
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Case Size
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.case_size
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Dial Color
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.dial_color
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Band Material
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.band_material
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Band Size
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.bandsize
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </>
                            )}
                          {this.state.inventoryDetailByIdDetails.type &&
                            this.state.inventoryDetailByIdDetails.type ===
                            "misc_product" && (
                              <>
                                <div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Product Name
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.product_title
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Product Category
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right text-capitalize"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.category
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Product Title
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                .inventoryDetailByIdDetails &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .technical_details &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .technical_details.product_title
                                                ? this.state
                                                  .inventoryDetailByIdDetails
                                                  .technical_details
                                                  .product_title
                                                : "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div> */}
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Description
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.description
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Brand Name
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.brand_name
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="color-00000061"
                                          >
                                            Model Number
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6}>
                                        <div>
                                          <Typography
                                            variant="p"
                                            component="p"
                                            className="float-right"
                                          >
                                            <b>
                                              {this.state
                                                ?.inventoryDetailByIdDetails
                                                ?.technical_details
                                                ?.model_number
                                                || "---"}
                                            </b>
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </>
                            )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          style={{ marginTop: "1px" }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Typography
                              variant="h5"
                              className="color-292C2F"
                              style={{ fontSize: "1rem" }}
                            >
                              <b>Inventory Details</b>
                            </Typography>
                          </div>
                          {/* <hr style={{ width: '330px' }} /> */}
                          {this.state.inventoryDetailByIdDetails.type !== 'misc_product' && (<>
                            <div className="space-bottom-5 space-top-5 border-bottom-1">
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography variant="p" component="p" className="color-00000061">Inventory Title</Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography variant="p" component="p" className="float-right"><b>
                                      {/* {} */}
                                      {this.state.inventoryDetailByIdDetails.inventory_title ? this.state.inventoryDetailByIdDetails.inventory_title : (this.state
                                        .inventoryDetailByIdDetails &&
                                        this.state
                                          .inventoryDetailByIdDetails
                                          .technical_details &&
                                        this.state
                                          .inventoryDetailByIdDetails
                                          .technical_details.product_title
                                        ? this.state
                                          .inventoryDetailByIdDetails
                                          .technical_details
                                          .product_title
                                        : "---")}
                                    </b></Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </>)}
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Serial Number
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails
                                        .serialno
                                        ? this.state.inventoryDetailByIdDetails
                                          .serialno
                                        : "---"}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Inventory ID
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails
                                        .inventoryid
                                        ? this.state.inventoryDetailByIdDetails
                                          .inventoryid
                                        : "---"}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {this.state.inventoryDetailByIdDetails.type ===
                            "misc_product" && (
                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="color-00000061"
                                      >
                                        Quantity
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="float-right"
                                      >
                                        <b>
                                          {this.state.inventoryDetailByIdDetails
                                            .quantity
                                            ? this.state
                                              .inventoryDetailByIdDetails
                                              .quantity
                                            : "---"}
                                        </b>
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Inventory Type
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Tooltip title={this.state.inventoryDetailByIdDetails.inventorytype === "2" || this.state.inventoryDetailByIdDetails.inventorytype === "2" ?
                                    (this.state.inventoryDetailByIdDetails
                                      .inventorytype === "2" && (
                                        <b>
                                          {/* {this.state.inventoryDetailByIdDetails.serialno ? this.state.inventoryDetailByIdDetails.serialno : '---'} */}
                                          {this.state.inventoryDetailByIdDetails
                                            .memodetails
                                            ? this.state
                                              .inventoryDetailByIdDetails
                                              .memodetails
                                            : "---"}
                                        </b>
                                      )) : (
                                      this.state.inventoryDetailByIdDetails
                                        .inventorytype === "3" && (
                                        <b>
                                          {/* {this.state.inventoryDetailByIdDetails.serialno ? this.state.inventoryDetailByIdDetails.serialno : '---'} */}
                                          {this.state.inventoryDetailByIdDetails
                                            .partnershipdetais
                                            ? this.state
                                              .inventoryDetailByIdDetails
                                              .partnershipdetais
                                            : "---"}
                                        </b>
                                      ))}
                                  >
                                    <Typography
                                      variant="p"
                                      component="p"
                                      className="float-right"
                                    >
                                      <b>
                                        {/* {this.state.inventoryDetailByIdDetails.serialno ? this.state.inventoryDetailByIdDetails.serialno : '---'} */}
                                        {this.state.inventoryDetailByIdDetails
                                          .inventorytype === "1"
                                          ? "Regular Inventory"
                                          : this.state.inventoryDetailByIdDetails
                                            .inventorytype === "2"
                                            ? "Memo Inventory"
                                            : this.state.inventoryDetailByIdDetails
                                              .inventorytype === "3"
                                              ? "Partnership Inventory"
                                              : "---"}
                                      </b>
                                    </Typography>
                                  </Tooltip>
                                </div>
                              </Grid>
                            </Grid>
                          </div>


                          {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Inventory Type Details
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    {this.state.inventoryDetailByIdDetails.inventorytype === "2" && (
                                    <b>
                                      {this.state.inventoryDetailByIdDetails
                                          .memodetails
                                          ? this.state
                                              .inventoryDetailByIdDetails
                                              .memodetails
                                          : "---"}
                                    </b>
                                    )}
                                     {this.state.inventoryDetailByIdDetails.inventorytype === "3" && (
                                    <b>
                                      {this.state.inventoryDetailByIdDetails
                                          .partnershipdetais
                                          ? this.state
                                              .inventoryDetailByIdDetails
                                              .partnershipdetais
                                          : "---"}
                                    </b>
                                    )}

                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div> */}

                          {isActionAllowed(
                            this.state.viewData,
                            "purchase_from"
                          ) && (
                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="color-00000061"
                                      >
                                        Purchase From
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="float-right"
                                      >
                                        <b>
                                          {this.state.inventoryDetailByIdDetails
                                            .purchasefrom
                                            ? this.state
                                              .inventoryDetailByIdDetails
                                              .purchasefrom
                                            : "---"}
                                        </b>
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            )}

                          {isActionAllowed(
                            this.state.viewData,
                            "purchase_price"
                          ) && (
                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="color-00000061"
                                      >
                                        Purchase Price
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="float-right"
                                      >
                                        <b>
                                          {this.state.inventoryDetailByIdDetails?.purchaseprice
                                            ? `$ ${this.state.inventoryDetailByIdDetails.purchaseprice}`
                                            : "---"}
                                        </b>
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Purchase Payment Status
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails
                                        .purchase_invoice_paid
                                        ? 'Paid' : 'Not Paid'
                                      }
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {this.state.inventoryDetailByIdDetails
                            .purchase_invoice_paid ?
                            <div className="space-bottom-5 space-top-5 border-bottom-1">
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography
                                      variant="p"
                                      component="p"
                                      className="color-00000061"
                                    >
                                      Purchase Payment Date
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography
                                      variant="p"
                                      component="p"
                                      className="float-right"
                                    >
                                      <b>
                                        {this.state.inventoryDetailByIdDetails.purchase_payment_date
                                          // ? moment(this.state
                                          //   .inventoryDetailByIdDetails
                                          //   .purchase_payment_date).format('MM/DD/YYYY')
                                          ? formatDate(this.state.inventoryDetailByIdDetails.purchase_payment_date, 'MM/DD/YYYY')
                                          : "---"}
                                      </b>
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </div> :
                            <div className="space-bottom-5 space-top-5 border-bottom-1">
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography
                                      variant="p"
                                      component="p"
                                      className="color-00000061"
                                    >
                                      Purchase Payment Due Date
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography
                                      variant="p"
                                      component="p"
                                      className="float-right"
                                    >
                                      <b>
                                        {this.state.inventoryDetailByIdDetails.purchase_payment_duedate
                                          // ? moment(this.state
                                          //   .inventoryDetailByIdDetails
                                          //   .purchase_payment_duedate).format('MM/DD/YYYY')
                                          ? formatDate(this.state.inventoryDetailByIdDetails.purchase_payment_duedate, 'MM/DD/YYYY')
                                          : "---"}
                                      </b>
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>}
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Purchase Payment Details
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails
                                        .purchase_payment_details
                                        ? this.state
                                          .inventoryDetailByIdDetails
                                          .purchase_payment_details
                                        : "---"}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Date Added
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails.createdon
                                        // ? moment(
                                        //   this.state
                                        //     .inventoryDetailByIdDetails
                                        //     .createdon
                                        // ).format("MM/DD/YYYY")
                                        ? formatDate(this.state.inventoryDetailByIdDetails.createdon, 'MM/DD/YYYY')
                                        : "---"}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>

                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Condition
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  {this.state.inventoryDetailByIdDetails
                                    .type !== "misc_product" && (
                                      <>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state.inventoryDetailByIdDetails
                                              .condition &&
                                              this.state.inventoryDetailByIdDetails
                                                .condition === 4
                                              ? "New"
                                              : this.state
                                                .inventoryDetailByIdDetails
                                                .condition &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .condition === 3
                                                ? "Retail Ready"
                                                : this.state
                                                  .inventoryDetailByIdDetails
                                                  .condition &&
                                                  this.state
                                                    .inventoryDetailByIdDetails
                                                    .condition === 2
                                                  ? "Mint"
                                                  : "Pre-owned"}
                                          </b>
                                        </Typography>
                                      </>
                                    )}
                                  {this.state.inventoryDetailByIdDetails
                                    .type === "misc_product" && (
                                      <>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state.inventoryDetailByIdDetails
                                              .condition &&
                                              this.state.inventoryDetailByIdDetails
                                                .condition === 1
                                              ? "New"
                                              : this.state
                                                .inventoryDetailByIdDetails
                                                .condition &&
                                                this.state
                                                  .inventoryDetailByIdDetails
                                                  .condition === 2
                                                ? "Used"
                                                : "New"}
                                          </b>
                                        </Typography>
                                      </>
                                    )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>

                          {this.state.inventoryDetailByIdDetails.type !==
                            "misc_product" && (
                              <>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                  <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={6}>
                                      <div>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="color-00000061"
                                        >
                                          Box
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6}>
                                      <div>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state.inventoryDetailByIdDetails
                                              .warrantybox &&
                                              this.state.inventoryDetailByIdDetails
                                                .warrantybox === "1"
                                              ? "Yes"
                                              : "No"}
                                          </b>
                                        </Typography>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            )}



                          {this.state.inventoryDetailByIdDetails.type !== "misc_product" && (
                            <>
                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography variant="p" component="p" className="color-00000061">
                                        Papers
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="float-right"
                                        id={
                                          warrantypaper === "1"
                                            ? "warranty"
                                            : archive === 1
                                              ? "archive"
                                              : undefined
                                        }
                                      >
                                        <b>
                                          {warrantypaper === "1" && archive === 1 && is_service
                                            ? "Warranty, Archive and Service Card/Paper"
                                            : warrantypaper === "1" && archive === 1
                                              ? "Warranty and Archive"
                                              : warrantypaper === "1" && is_service
                                                ? "Warranty and Service Card/Paper"
                                                : archive === 1 && is_service
                                                  ? "Archive and Service Card/Paper"
                                                  : warrantypaper === "1"
                                                    ? "Warranty"
                                                    : archive === 1
                                                      ? "Archive"
                                                      : is_service
                                                        ? "Service Card/Paper"
                                                        : "---"}
                                        </b>
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          )}

                          {/* {this.state.inventoryDetailByIdDetails.type !==
                            "misc_product" && (
                            <>
                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="color-00000061"
                                      >
                                        Warranty
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      {this.state.inventoryDetailByIdDetails
                                        .warrantypaper == 1 ? (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state
                                              .inventoryDetailByIdDetails
                                              .warrantydate
                                              ? moment(
                                                  this.state
                                                    .inventoryDetailByIdDetails
                                                    .warrantydate
                                                ).format("MM/DD/YYYY")
                                              : "No"}
                                          </b>
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>{"---"}</b>
                                        </Typography>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          )} */}

                          {(this.state.inventoryDetailByIdDetails.type !== "misc_product" && this.state.inventoryDetailByIdDetails.warrantypaper === "1") && (
                            <>
                              <div className="space-bottom-5 space-top-5 border-bottom-1" id="warranty_date">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    {this.state.inventoryDetailByIdDetails.warrantypaper === "1" && (
                                      <div>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="color-00000061"
                                        >
                                          Warranty Date
                                        </Typography>
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      {this.state.inventoryDetailByIdDetails
                                        .warrantypaper === "1" ? (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state.inventoryDetailByIdDetails.warrantydate
                                              // ? moment(
                                              //   this.state
                                              //     .inventoryDetailByIdDetails
                                              //     .warrantydate
                                              // ).format("MM/DD/YYYY")
                                              ? formatDate(this.state.inventoryDetailByIdDetails.warrantydate, "MM/DD/YYYY")
                                              : "No"}
                                          </b>
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>{"---"}</b>
                                        </Typography>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          )}

                          {(this.state.inventoryDetailByIdDetails.type !== "misc_product" && this.state.inventoryDetailByIdDetails.archive === 1) && (
                            <>
                              <div className="space-bottom-5 space-top-5 border-bottom-1" id="archive_date">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    {this.state.inventoryDetailByIdDetails.archive === 1 && (
                                      <div>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="color-00000061"
                                        >
                                          Archive Date
                                        </Typography>
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      {this.state.inventoryDetailByIdDetails
                                        .archive === 1 ? (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state.inventoryDetailByIdDetails.archive_date
                                              // ? moment(
                                              //   this.state
                                              //     .inventoryDetailByIdDetails
                                              //     .archive_date
                                              // ).format("MM/DD/YYYY")
                                              ? formatDate(this.state.inventoryDetailByIdDetails.archive_date, "MM/DD/YYYY")
                                              : "No"}
                                          </b>
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>{"---"}</b>
                                        </Typography>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          )}

                          {(this.state.inventoryDetailByIdDetails.type !== "misc_product" && this.state.inventoryDetailByIdDetails.is_service) && (
                            <>
                              <div className="space-bottom-5 space-top-5 border-bottom-1" id="service_date">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    {this.state.inventoryDetailByIdDetails.is_service && (
                                      <div>
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="color-00000061"
                                        >
                                          Service Date
                                        </Typography>
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      {this.state.inventoryDetailByIdDetails
                                        .is_service ? (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>
                                            {this.state.inventoryDetailByIdDetails.service_date
                                              // ? moment(
                                              //   this.state
                                              //     .inventoryDetailByIdDetails
                                              //     .service_date
                                              // ).format("MM/DD/YYYY")
                                              ? formatDate(this.state.inventoryDetailByIdDetails.service_date, "MM/DD/YYYY")
                                              : "No"}
                                          </b>
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="p"
                                          component="p"
                                          className="float-right"
                                        >
                                          <b>{"---"}</b>
                                        </Typography>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          )}


                          {/* {this.state.inventoryDetailByIdDetails.type !==
                            "misc_product" && (
                            <>
                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="color-00000061"
                                      >
                                        Band Size
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="float-right"
                                      >
                                        <b>
                                          {this.state.inventoryDetailByIdDetails
                                            .bandsize
                                            ? this.state
                                                .inventoryDetailByIdDetails
                                                .bandsize
                                            : "---"}
                                        </b>
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="color-00000061"
                                      >
                                        Links
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography
                                        variant="p"
                                        component="p"
                                        className="float-right"
                                      >
                                        <b>
                                          {this.state.inventoryDetailByIdDetails
                                            .links
                                            ? this.state
                                                .inventoryDetailByIdDetails
                                                .links
                                            : "---"}
                                        </b>
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          )} */}

                          {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                     <Typography variant="p" component="p" className="color-00000061">Warranty Paper</Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                     <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.warrantypaper ? this.state.inventoryDetailByIdDetails.warrantypaper : '---'}</b></Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <div>
                     <Typography variant="p" component="p" className="color-00000061">Purchase Invoice</Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                     <Slider {...settings} className="slide-invdetail">
                         {((this.state.inventoryDetailByIdDetails &&
                             this.state.inventoryDetailByIdDetails.purchaseinvoiceimages &&
                             this.state.inventoryDetailByIdDetails.purchaseinvoiceimages.images) || []).map(url => (
                                 <img src={url || defaulutImg} alt="rolex" className="img-width-23 img-responsive1" style={{ display: 'initial', width: 'auto', maxHeight: '300px', marginLeft: '-5px' }} />

                             ))}
                     </Slider>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div> */}
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} style={{ marginTop: '1px', }}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography variant="h5" className="color-292C2F" style={{ fontSize: "1rem" }}><b>Inventory Details</b></Typography>
                          </div>
                          {/* <hr style={{ width: '330px' }} /> */}
                          {/* {this.state.inventoryDetailByIdDetails.type !== 'misc_product' && (<>
                                                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                     <Typography variant="p" component="p" className="color-00000061">Inventory Title</Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                     <Typography variant="p" component="p" className="float-right"><b>
                                                                        {this.state.inventoryDetailByIdDetails.inventory_title ? this.state.inventoryDetailByIdDetails.inventory_title : '---'}
                                                                        </b></Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    </>)} */}
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography variant="p" component="p" className="color-00000061">Inventory Status</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.inventory_status ? InventoryStatusLookup[this.state.inventoryDetailByIdDetails.inventory_status] : '---'}</b></Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {((this.state.userData?.roleId == "2" || this.state.userData?.roleId == "3") && isActionAllowed(this.state.viewData, 'total_cost')) ? (
                            <div className="space-bottom-5 space-top-5 border-bottom-1">
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography variant="p" component="p" className="color-00000061">Total Cost</Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <div>
                                    <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.total_cost ? this.state.inventoryDetailByIdDetails.total_cost : '---'}</b></Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          ) : null}
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Target Wholesale Price
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails?.wholesaleprice
                                        ? `$ ${this.state.inventoryDetailByIdDetails.wholesaleprice}`
                                        : "---"}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Target End Customer Price
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails?.retailprice
                                        ? `$ ${this.state.inventoryDetailByIdDetails.retailprice}`
                                        : "---"}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Target End Customer Wire Price
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {this.state.inventoryDetailByIdDetails?.retailwireprice
                                        ? `$ ${this.state.inventoryDetailByIdDetails.retailwireprice}`
                                        : "---"}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography variant="p" component="p" className="color-00000061">Tag Price</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.tag_price ? '$ ' + this.state.inventoryDetailByIdDetails.tag_price : '---'}</b></Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography variant="p" component="p" className="color-00000061">Public Dealer Note</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.public_dealer_note ? this.state.inventoryDetailByIdDetails.public_dealer_note : '---'}</b></Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="space-bottom-5 space-top-5 border-bottom-1">
                            <Grid container spacing={2}>
                              <Grid item xs={5} sm={4} md={3}>
                                <div>
                                  <Typography variant="p" component="p" className="color-00000061">Private Dealer Note</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={7} sm={8} md={9}>
                                <div>
                                  <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.note ? this.state.inventoryDetailByIdDetails.note : '---'}</b></Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {this.state.inventoryDetailByIdDetails.type !== 'misc_product' && (
                            <>

                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography variant="p" component="p" className="color-00000061">Inventory Location</Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <div>
                                      <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.inventory_location ? this.state.inventoryDetailByIdDetails.inventory_location : '---'}</b></Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>

                              <div className="space-bottom-5 space-top-5 border-bottom-1">
                                <Grid container spacing={2}>
                                  <Grid item xs={5} sm={4} md={3}>
                                    <div>
                                      <Typography variant="p" component="p" className="color-00000061">Modified</Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs={7} sm={8} md={9}>
                                    <div>
                                      <Tooltip arrow title={this.state.inventoryDetailByIdDetails.ismodified === true && (
                                        <b>{this.state.inventoryDetailByIdDetails.modification_deatils ? this.state.inventoryDetailByIdDetails.modification_deatils : '- - -'}</b>
                                      )} >
                                        <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.ismodified === true ? "Yes" : 'No'}</b></Typography>
                                      </Tooltip>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>

                              {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                 <Grid container spacing={2}>
                                     <Grid item xs={6} sm={6} md={6}>
                                      <div>
                                        <Typography variant="p" component="p" className="color-00000061">Modification Details</Typography>
                                        </div>
                                     </Grid>
                                     <Grid item xs={6} sm={6} md={6}>
                                      <div>
                                        <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.modification_deatils ? this.state.inventoryDetailByIdDetails.modification_deatils : '---'}</b></Typography>
                                        </div>
                                     </Grid>
                                 </Grid>
                              </div> */}
                            </>)}
                          {this.state.viewInvoiceModal && (
                            <ViewInvoicemodel
                              open={this.state.viewInvoiceModal}
                              handleClose={this.handleClosecheck}
                              invoiceid={{
                                id: this.state.invoiceId,
                              }}
                            />
                          )}

                          {openModal && (
                            <Modal
                              open={openModal}
                              onClose={this.handleCloseModal}
                              aria-labelledby="modal-modal-title"
                            >
                              <Box sx={style}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  Invoice Type
                                </Typography>
                                <FormControl fullWidth>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={(e) => this.setState({
                                      invoiceType: e.target.value
                                    }
                                    )}
                                    defaultValue={invoiceType}
                                    label="Payment Status"
                                  >
                                    <MenuItem value="regular_invoice">Regular Invoice</MenuItem>
                                    <MenuItem value="memo_invoice">Memo Invoice</MenuItem>
                                  </Select>
                                </FormControl>
                                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <Button
                                    type="submit"
                                    variant="text"
                                    style={{
                                      background: "#000",
                                      color: "#fff",
                                      marginTop: "20px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => {
                                      const invoiceDetails = this.getInvoiceDetails(this.state.inventoryDetailByIdDetails);
                                      const path = invoiceType === "regular_invoice" ? "/create/invoice" : "/create/memoinvoice";
                                      this.props.history.push({
                                        pathname: path,
                                        state: {
                                          inventoryDetails: invoiceDetails,
                                        },
                                      });
                                    }}
                                  >
                                    Create
                                  </Button>
                                  &nbsp;
                                  <Button
                                    variant="outlined"
                                    onClick={this.handleCloseModal}
                                    style={{
                                      background: "#fff",
                                      color: "#000",
                                      marginTop: "20px",
                                      paddingTop: "5 !important",
                                      paddingBottom: "5 !important",
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                              </Box>
                            </Modal>
                          )}
                          {/* {isActionAllowed(this.state.viewData,'purchase_expense') && (
                                                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Purchase Expense</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>$ {this.state.inventoryDetailByIdDetails.purchase_expense ? this.state.inventoryDetailByIdDetails.purchase_expense : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                    </div>
                                                    )} */}
                          {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="color-00000061">Purchase Expense Note</Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <div>
                                                                        <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.purchase_expense_note ? this.state.inventoryDetailByIdDetails.purchase_expense_note : '---'}</b></Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                    </div>
                                                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                                                                    <Typography variant="p" component="p" className="color-00000061">Tag Price</Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                                                                    <Typography variant="p" component="p" className="float-right"><b>{this.state.inventoryDetailByIdDetails.tag_price ? this.state.inventoryDetailByIdDetails.tag_price : '---'}</b></Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div> */}
                        </Grid>
                      </Grid>
                    </Card>
                  </Container>
                )}
              </div>
            </div>

          )
          }
        </div>
      )
    }
  }
  render() {

    localStorage.setItem("menuTitle", "");
    return <NewContentWrapper contentView={this.newContentView()} />;
  }
}
function mapStateToProps({ inventoryDetailByIdInfo }) {
  return {
    // inventoryDetailByIdInfo: inventoryDetailByIdInfo.inventoryDetailByIdInfo
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      inventoryDetailById,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryDetailComponent);
