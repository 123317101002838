import React, { useRef, useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Grid,
  Popover,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
  Input,
  Typography,
  FormControlLabel,
  Checkbox,
  Modal,
  Box,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  List,
  IconButton,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { toast } from "react-toastify";
import {
  createInvoiceDetails,
  getModelSerialList,
  getBillShipContactList,
  checkContactInDB,
  getNewModelSerialList,
  getInvoiceNewContactList,
  getMemoList,
} from "../actions/dashboardAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import imageNotAvailable from "../../../../assests/images/img-soon.png";
import "../style/style.css";
import Header from "../../../../common/components/Header";
import FooterComponent from "../../../../common/components/Footer";
import { useHistory, useLocation } from "react-router-dom";
import {
  Popover as AntdPopover,
  Input as AntdInput,
  Modal as AntdModal,
} from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import "../../../../common/style/SearchBar.css";
// import {formatPhoneNumber, unformatPhoneNumber} from "../../../../common/components/Helper/Utils";
import { SpinnerRoundFilled } from "spinners-react";
import getUserdetailsforsubscrption from "../../dashboard/actions/dashboardaapi";
import ContactListModal from "./ContactListModal";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Pricewarining from "./Pricewarining";
import _, { set } from "lodash";
import { event } from "jquery";
import { isValid } from "date-fns";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import InvoiceValidation from "../../common/components/InvoiceValidation";
import ClearIcon from "@material-ui/icons/Clear";
import { searchProductsAPI } from "../../dealer/actions/dealerAction";
import {
  formatDate,
  showErrorToast,
  isTwoAddressSame,
} from "../../../../common/components/Helper/Utils";

const { innerWidth: width, innerHeight: height } = window;

const invoiceObj = [
  {
    id: "modelAndSerial",
    label: "Search Model | Serial No | Inventory ID",
    minWidth: 100,
    type: "search",
    required: true,
    errMsg: "Please Enter Model | Serial No | Inventory ID",
  },
  {
    id: "modelNumber",
    label: "Model",
    minWidth: 100,
    type: "search",
    required: false,
    errMsg: "Please Enter Model",
  },
  { id: "image", label: "Image", minWidth: 80, type: "image", required: false },
  {
    id: "serialNumber",
    label: "Serial No",
    minWidth: 80,
    type: "search",
    required: false,
    errMsg: "Please Enter Serial No",
  },
  {
    id: "productName",
    label: "Product Name",
    minWidth: 140,
    type: "text",
    required: false,
  },
  {
    id: "quantity",
    label: "Qty",
    minWidth: 80,
    type: "number",
    required: true,
    errMsg: "Please enter quantity",
  },
  {
    id: "currency",
    label: "Currency",
    minWidth: 30,
    type: "select",
    required: true,
    errMsg: "Please enter rate",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 65,
    type: "amount",
    required: true,
    errMsg: "Please Enter Amount",
  },
  { id: "action", label: "Action", minWidth: 70, align: "center" },
];

const billingAddress = [
  {
    id: "billingToName",
    label: "Contact Name",
    minWidth: 80,
    type: "text_search",
    required: true,
    errMsg: "Please enter contact name",
  },
  {
    id: "billingWalkIn",
    label: "Include Walk-In customers in the search",
    minWidth: 80,
    type: "checkbox",
    required: true,
    errMsg: "",
  },
  {
    id: "billingToBusinessName",
    label: "Business Name",
    minWidth: 80,
    type: "text",
    required: true,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "billingToPhoneNo",
    label: "",
    minWidth: 80,
    type: "phone",
    required: true,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "billingToEmail",
    label: "Email",
    minWidth: 80,
    type: "email",
    required: true,
    errMsg: "Please enter valid email",
    isDisabled: true
  },
  {
    id: "billingToAddressLine1",
    label: "Address Line1",
    minWidth: 80,
    type: "text",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "billingToAddressLine2",
    label: "Address Line2",
    minWidth: 80,
    type: "text",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "billingToCity",
    label: "City",
    minWidth: 80,
    type: "text",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "billingToCountry",
    label: "Country",
    minWidth: 80,
    type: "select",
    value: "United States",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "billingToState",
    label: "State",
    minWidth: 80,
    type: "select",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "billingToZip",
    label: "Zipcode",
    minWidth: 80,
    type: "number",
    required: false,
    errMsg: "",
    isDisabled: true
  },
];

const shippingAddress = [
  {
    id: "shippingToName",
    label: "Contact Name",
    minWidth: 80,
    type: "text_search",
    required: true,
    errMsg: "Please enter contact name",
  },
  {
    id: "shippingWalkIn",
    label: "Include Walk-In customers in the search",
    minWidth: 80,
    type: "checkbox",
    required: true,
    errMsg: "",
  },
  {
    id: "shippingToBusinessName",
    label: "Business Name",
    minWidth: 80,
    type: "text",
    required: true,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "shippingToPhoneNo",
    label: "",
    minWidth: 80,
    type: "phone",
    required: true,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "shippingToEmail",
    label: "Email",
    minWidth: 80,
    type: "email",
    required: true,
    errMsg: "Please enter valid email",
    isDisabled: true
  },
  {
    id: "shippingToAddressLine1",
    label: "Address Line1",
    minWidth: 80,
    type: "text",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "shippingToAddressLine2",
    label: "Address Line2",
    minWidth: 80,
    type: "text",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "shippingToCity",
    label: "City",
    minWidth: 80,
    type: "text",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "shippingToCountry",
    label: "Country",
    minWidth: 80,
    type: "select",
    value: "United States",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "shippingToState",
    label: "State",
    minWidth: 80,
    type: "select",
    required: false,
    errMsg: "",
    isDisabled: true
  },
  {
    id: "shippingToZip",
    label: "Zipcode",
    minWidth: 80,
    type: "number",
    required: false,
    errMsg: "",
    isDisabled: true
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CreateInvoiceComponent(props) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    control,
    clearErrors,
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    getValues: getValues1,
    formState: { errors: errors1 },
    watch: watch1,
    reset: reset1,
    setFocus: setFocus1
  } = useForm({
    defaultValues: {
      terms: "Immediate",
    },
  });

  const location = useLocation();
  const { data, isConvertToSale = false, invoiceFilter } = location.state || {};
  const { id } = data || {};
  // const addressForm = watch1();
  const [dataset, setdataset] = useState([]);
  const [paymentContactBased, setPaymentContactBased] = useState([]);
  const [shippingdataset, setshippingdataset] = useState([]);
  const [rows, setRows] = useState([]);
  const [openModal, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isSameAddress, setSameAddress] = useState(false);
  const [amount, setAmount] = useState(null);
  const [shippingDate, setShippingDate] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [notes, setNotes] = useState(null);
  const [wireDetails, setWireDetails] = useState(true);
  const [invoiceId, setInvoiceId] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [tax, setTax] = useState(null);
  const [fees, setFees] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const [paymentmethod, setPaymentMethod] = useState(null);
  const [qtyShrink, setQtyShrink] = useState(true);
  const [descShrink, setDescShrink] = useState(false);
  const [editIdx, setEditIdx] = useState(null);
  const [model, setModelList] = useState(props?.modelList);
  const [serialList, setSerialList] = useState([]);
  const [searchObj, setSearchObj] = useState({});
  const [modelNo, setModel] = useState(null);
  const [serial, setSerial] = useState(null);
  const [image, setImage] = useState(null);
  const [desc, setDesc] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [inventoryid, setInventoryId] = useState(null);
  const [productid, setProductId] = useState(null);
  const [noData, setNoData] = useState(false);
  const [noBillData, setNoBillData] = useState(false);
  const [noShipData, setNoShipData] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [isSubmitInvoice, setIsSubmitInvoice] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorTarget, setAnchorTarget] = useState(null);
  const [billCntctNm, setBillCntctNm] = useState(null);
  const [shipCntctNm, setShipCntctNm] = useState(null);
  const [billCntctSearchTxt, setBillCntctSearchTxt] = useState(null);
  const [shipCntctSearchTxt, setShipCntctSearchTxt] = useState(null);
  const [isBillTextInput, setIsBillTextInput] = useState(false);
  const [isShipTextInput, setIsShipTextInput] = useState(false);
  const [billCntcts, setBillCntctLst] = useState([]);
  const [shipCntcts, setShipCntcts] = useState([]);
  const [countryListData, setCountryListData] = useState([]);
  const [statesListData, setStatesListData] = useState([]);
  const [shippingStatesListData, setShippingStatesListData] = useState([]);
  const [contactListConfirmationModal, SetContactListConfirmationModal] =
    useState(false);
  const [finalInvoiceData, setFinalInvoiceData] = useState(null);
  const [shippingCountryCode, setShippingCountryCode] = useState("+1");
  const [billingCountryCode, setBillingCountryCode] = useState("+1");
  const [modalText, setModalText] = useState(
    "This is not in your contacts, Do you want to save it?"
  );
  const [UniqueCountryCode, setUniqueCountryCode] = useState([]);
  const [currency, setCurrency] = useState("USD");
  // const[openpricewarning,setopenpricewarning]=useState(false);
  const [onsubmitdata, setonsubmitdata] = useState("");
  const [purchaseprice, setpurchaseprice] = useState("");
  const [price, setprice] = useState(null);
  const [isPopoverVisiblebill, setIsPopoverVisiblebill] = useState(false);
  const [isPopoverVisibleship, setIsPopoverVisibleship] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputship, setSearchInputship] = useState("");
  const [isPopoverVisiblemodelno, setIsPopoverVisiblemodelno] = useState(false);
  const [isPopoverVisibleserialno, setIsPopoverVisibleserialno] =
    useState(false);
  const [searchInputmodelno, setSearchInputmodelno] = useState("");
  const [searchInputserialno, setSearchInputserialno] = useState("");
  const [isSerialNumberRequired, setISSerialNumberRequired] = useState(false);
  const [paymentdetails, setPaymentDetails] = useState(null);
  const [type, setType] = useState(null);
  const userId = props?.currentUser?.userId ? props?.currentUser?.userId : null;
  // const accountId = props?.currentUser?.accountId ? props?.currentUser?.accountId : null;
  const accountId = window.sessionStorage.getItem("accountId");
  const history = useHistory();
  const inputRef = useRef(null);
  const shipinputRef = useRef(null);
  const modelinputRef = useRef(null);
  const serialinputRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [invoiceListData, setInvoiceListData] = useState([]);
  const [saletaxOpen, setSaletaxOpen] = useState(false);
  const [billContactId, setBillContactId] = useState(null);
  const [shipContactId, setShipContactId] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reason, setReason] = useState(null);
  const [fieldLoaderBill, setFieldLoaderBill] = useState(null);
  const [fieldLoaderShip, setFieldLoaderShip] = useState(null);
  const [contactTypeBill, setContactTypeBill] = useState(['business_client', 'end_customer']);
  const [contactTypeShip, setContactTypeShip] = useState(['business_client', 'end_customer']);
  const [classificationTypeBill, setClassificationTypeBill] = useState(["supplier", "memo_supplier", "partner"]);
  const [classificationTypeShip, setClassificationTypeShip] = useState(["supplier", "memo_supplier", "partner"]);
  const [watchType, setWatchType] = useState("standard_watch");
  const [tradeModel, setTradeModel] = useState(null);
  const [tradeModelName, setTradeModelName] = useState(null);
  const [tradeSerialNumber, setTradeSerialNumber] = useState(null);
  const [tradeModelNumber, setTradeModelNumber] = useState(null);
  const [tradeMiscCategory, setTradeMiscCategory] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTradeObj, setSearchTradeObj] = useState({});
  const [tradeProductId, setTradeProductId] = useState("");
  const [propsModelList, setPropsModelList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        Object.keys(searchTradeObj).length &&
        searchTradeObj?.searchVal?.length > 2
      ) {
        setSearchLoading(true);
        searchProductsAPI(searchTradeObj, (error, res) => {
          setSearchLoading(false);
          if (error) {
            console.log(error, res);
          }
          if (res && res.data) {
            setPropsModelList(res.data);
          }
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchTradeObj]);

  useEffect(() => {
    if (watchType === "misc_watch") {
      const serialNumber = "Not Available-" + new Date().getTime();
      setTradeSerialNumber(serialNumber);
      setValue1("tradeSerialNumber", serialNumber);
    } else {
      setTradeSerialNumber(null);
      setValue1("tradeSerialNumber", "");
    }
  }, [watchType]);

  useEffect(() => {
    if (data && Object.keys(data) && Object.keys(data)?.length > 0) {
      const { id } = data || {};
      getMemoDetails(id);
    }
  }, [data]);

  const getMemoDetails = (memoId) => {
    setIsLoading(true);
    props.getMemoList(
      memoId,
      (success) => {
        if (success.status === 1) {
          memoDetailsMapping(success?.data?.[0]);
          setIsLoading(false);
        } else {
          showErrorToast(success.message);
          setIsLoading(false);
        }
      },
      (error) => {
        setDisabled(false);
        showErrorToast(error);
        setIsLoading(false);
      }
    );
  };

  const memoDetailsMapping = (memoData) => {
    const {
      memoproductdetails,
      shippingprice,
      discount,
      tax,
      fees,
      deposit,
      memoterm,
      shippingdate,
      shippingmethod,
      trackingnumber,
      wiredetails,
      notes,
      billingcontactname,
      bilingcontactid,
      billingbusinessname,
      billingphone,
      billingemail,
      billingaddress1,
      billingaddress2,
      billingcity,
      billingstates,
      billingcountry,
      billingzipcode,
      issameaddress,
      shippingcontactname,
      shippingbusinessname,
      shippingcontactid,
      shippingphone,
      shippingemail,
      shippingaddress1,
      shippingaddress2,
      shippingcity,
      shippingstates,
      shippingcountry,
      shippingzipcode,
    } = memoData || {};
    const { memo } = memoproductdetails || {};
    const updatedRows = memo
      ?.filter((item) => item.status?.toLowerCase() === "out on memo")
      ?.map((item) => {
        const {
          amount,
          currency,
          quantity,
          serialno,
          productid,
          miscimages,
          description,
          inventoryid,
          modelnumber,
          technical_details,
          product_imagepaths,
        } = item;
        const { images } = product_imagepaths || {};
        const { model_number, product_title } = technical_details || {};
        const {
          front,
          back,
          left,
          right,
          free_form,
          buckle_opened,
          buckle_closed,
          wrist_shot,
        } = miscimages ?? {};
        return {
          modelNumber: modelnumber || model_number || "",
          serialNumber: serialno || "",
          productName: description || product_title || "",
          image:
            images?.[0] ||
            front ||
            back ||
            left ||
            right ||
            free_form ||
            buckle_opened ||
            buckle_closed ||
            wrist_shot ||
            imageNotAvailable,
          inventoryId: inventoryid || null,
          productId: productid || null,
          quantity: quantity || 1,
          amount: amount || null,
          currency: currency || null,
          checked: true,
        };
      });
    setRows(updatedRows || []);
    setValue1("shippingPrice", shippingprice || null);
    setShipContactId(isSameAddress ? bilingcontactid : shippingcontactid);
    setBillContactId(bilingcontactid)
    setValue1("discount", discount || null);
    setValue1("tax", tax || null);
    setValue1("fees", fees || null);
    setValue1("deposit", deposit || null);
    setValue1(
      "terms",
      memoterm && memoterm !== "Immediate" ? `Net ${memoterm}` : "Immediate"
    );
    setShippingDate(
      shippingdate ? formatDate(shippingdate, "MM/DD/YYYY") : null
    );
    setValue1("shippingMethod", shippingmethod || null);
    setValue1("trackingNumber", trackingnumber || null);
    setValue1("notes", notes || null);
    setNotes(notes);
    setWireDetails(wiredetails || true);
    setValue1("billingToName", billingcontactname || null);
    setBillCntctNm(billingcontactname || null);
    setValue1("billingToBusinessName", billingbusinessname);
    setValue1("billingToPhoneNo", billingphone || null);
    setValue1("billingToEmail", billingemail || null);
    setValue1("billingToAddressLine1", billingaddress1 || null);
    setValue1("billingToAddressLine2", billingaddress2 || null);
    setValue1("billingToCity", billingcity || null);
    setValue1("billingToState", billingstates || null);
    setValue1("billingToCountry", billingcountry || "United States");
    setValue1("billingToZip", billingzipcode || null);
    setShipCntctNm(shippingcontactname || null);
    setValue1("shippingToName", shippingcontactname || null);
    setValue1("shippingToBusinessName", shippingbusinessname || null);
    setValue1("shippingToPhoneNo", shippingphone || null);
    setValue1("shippingToEmail", shippingemail || null);
    setValue1("shippingToAddressLine1", shippingaddress1 || null);
    setValue1("shippingToAddressLine2", shippingaddress2 || null);
    setValue1("shippingToCity", shippingcity || null);
    setValue1("shippingToState", shippingstates || null);
    setValue1("shippingToCountry", shippingcountry || "United States");
    setValue1("shippingToZip", shippingzipcode || null);
    setSameAddress(issameaddress || false);
    setDisabled(true);
  };

  useEffect(() => {
    if (props?.location?.state?.inventoryDetails) {
      setInventoryId(props?.location?.state?.inventoryDetails?.inventoryid);
      setRows([
        {
          modelNumber: props?.location?.state?.inventoryDetails?.model,
          serialNumber: props?.location?.state?.inventoryDetails?.serialno,
          productName: props?.location?.state?.inventoryDetails?.desc,
          image: props?.location?.state?.inventoryDetails?.image,
          inventoryId: props?.location?.state?.inventoryDetails?.inventoryid,
          productId: props?.location?.state?.inventoryDetails?.productid,
          quantity: props?.location?.state?.inventoryDetails?.quantity
            ? props?.location?.state?.inventoryDetails?.quantity
            : 1,
          amount: "",
          currency: currency,
          type: props?.location?.state?.inventoryDetails?.type,
        },
      ]);
      const currentRow = {
        amount: "",
        image: props?.location?.state?.inventoryDetails?.image,
        modelNumber: props?.location?.state?.inventoryDetails?.model,
        productName: props?.location?.state?.inventoryDetails?.desc,
        quantity: props?.location?.state?.inventoryDetails?.quantity
          ? props?.location?.state?.inventoryDetails?.quantity
          : 1,
        serialNumber: props?.location?.state?.inventoryDetails?.serialno,
        inventoryId: props?.location?.state?.inventoryDetails?.inventoryid,
        productId: props?.location?.state?.inventoryDetails?.productid,
        from: "inventory",
        currency: currency,
        type: props?.location?.state?.inventoryDetails?.type,
        inventoryid: props?.location?.state?.inventoryDetails?.inventoryid,
      };
      setAnchorEl(null);
      setCurrentItem(currentRow);
      reset(currentRow);
      setEditIdx(0);
      setModel(props?.location?.state?.inventoryDetails?.model);
      setSerial(props?.location?.state?.inventoryDetails?.serialno);
      setProductId(props?.location?.state?.inventoryDetails?.productid);
      setprice(props?.location?.state?.inventoryDetails?.purchaseprice);
      setInventoryId(props?.location?.state?.inventoryDetails?.inventoryid);
      setQuantity(
        props?.location?.state?.inventoryDetails?.quantity
          ? props?.location?.state?.inventoryDetails?.quantity
          : 1
      );
      setType(
        props?.location?.state?.inventoryDetails?.type
          ? props?.location?.state?.inventoryDetails?.type
          : null
      );
      if (props?.location?.state?.inventoryDetails?.desc) {
        setDescShrink(true);
      }
      setModalOpen(!openModal);
      setDisabled(true);
    } else {
      setInventoryId(null);
    }
    getcountryList();
  }, [props?.location?.state?.inventoryDetails]);

  const isStateChanged = watch1("billingToState") || watch1("shippingToState")
    || watch1("billingToZip") || watch1("shippingToZip");
  useEffect(() => {
    const updatedValues = getValues1();
  }, [isStateChanged]);

  const getcountryList = () => {
    getUserdetailsforsubscrption.getAllCountryList().then((res) => {
      setCountryListData(res?.data?.data);
      let countries = res?.data?.data;
      let countryCode = [];
      countries.filter(({ phonecode }) => countryCode.push(phonecode));
      const UniqueCountryCode = [...new Set(countryCode)];
      setUniqueCountryCode(UniqueCountryCode.sort());
    });
    // us States List 231
    getUserdetailsforsubscrption.getAllStateList(231).then((res) => {
      setStatesListData(res.data.data);
      setShippingStatesListData(res.data.data);
    });
  };

  // const isTwoAddressSame = (input, dbData, type) => {
  //   let isChanged = false;
  //   if (type === "shipping") {
  //     if (
  //       (dbData.businessname && dbData.businessname.toLowerCase()) !==
  //       (input.shippingToBusinessName &&
  //         input.shippingToBusinessName.toLowerCase()) ||
  //       (dbData.address1 && dbData.address1.toLowerCase()) !==
  //       (input.shippingToAddressLine1 &&
  //         input.shippingToAddressLine1.toLowerCase()) ||
  //       (dbData.address2 && dbData.address2.toLowerCase()) !==
  //       (input.shippingToAddressLine2
  //         ? input.shippingToAddressLine2 &&
  //         input.shippingToAddressLine2.toLowerCase()
  //         : "") ||
  //       (dbData.city && dbData.city.toLowerCase()) !==
  //       (input.shippingToCity && input.shippingToCity.toLowerCase()) ||
  //       (dbData.states && dbData.states.toLowerCase()) !==
  //       (input.shippingToState && input.shippingToState.toLowerCase()) ||
  //       (dbData.country && dbData.country.toLowerCase()) !==
  //       (input.shippingToCountry && input.shippingToCountry.toLowerCase()) ||
  //       (dbData.zipcode && dbData.zipcode.toLowerCase()) !==
  //       (input.shippingToZip && input.shippingToZip.toLowerCase()) ||
  //       (dbData.countrycode && dbData.countrycode.toLowerCase()) !==
  //       (shippingCountryCode && shippingCountryCode.toLowerCase())
  //     ) {
  //       isChanged = true;
  //     }
  //     return isChanged;
  //   } else {
  //     if (
  //       (dbData.businessname && dbData.businessname.toLowerCase()) !==
  //       (input.billingToBusinessName &&
  //         input.billingToBusinessName.toLowerCase()) ||
  //       (dbData.address1 && dbData.address1.toLowerCase()) !==
  //       (input.billingToAddressLine1 &&
  //         input.billingToAddressLine1.toLowerCase()) ||
  //       (dbData.address2 && dbData.address2.toLowerCase()) !==
  //       (input.billingToAddressLine2 &&
  //         input.billingToAddressLine2.toLowerCase()) ||
  //       (dbData.city && dbData.city.toLowerCase()) !==
  //       (input.billingToCity && input.billingToCity.toLowerCase()) ||
  //       (dbData.states && dbData.states.toLowerCase()) !==
  //       (input.billingToState && input.billingToState.toLowerCase()) ||
  //       (dbData.country && dbData.country.toLowerCase()) !==
  //       (input.billingToCountry && input.billingToCountry.toLowerCase()) ||
  //       (dbData.zipcode && dbData.zipcode.toLowerCase()) !==
  //       (input.billingToZip && input.billingToZip.toLowerCase()) ||
  //       (dbData.countrycode && dbData.countrycode.toLowerCase()) !==
  //       (billingCountryCode && billingCountryCode.toLowerCase())
  //     ) {
  //       isChanged = true;
  //     }
  //     return isChanged;
  //   }
  // };

  const onCreateInvoiceSubmit = (formData, salesTaxId = true, invoiceAmount = true) => {
    if (!formData?.billingToName || !formData?.shippingToName || !billContactId || !shipContactId) {
      return false;
    }
    setIsSubmitInvoice(true);
    setSaletaxOpen(false);
    let isTaxCheck = salesTaxId ? true : false;
    let isTotalinvoiceCheck = invoiceAmount ? true : false
    const data = {
      userId: userId,
      accountid: accountId,
      billingcontactid: billContactId,
      shippingcontactid: isSameAddress ? billContactId : shipContactId,
      isTaxCheck: isTaxCheck,
      isTotalinvoiceCheck: isTotalinvoiceCheck,
      amountExceedreason: reason,
      billingToBusinessName: formData?.billingToBusinessName,
      billingToName: formData?.billingToName,
      billingToAddressLine1: formData?.billingToAddressLine1,
      billingToAddressLine2: formData?.billingToAddressLine2
        ? formData?.billingToAddressLine2
        : "",
      billingToPhoneNo: formData?.billingToPhoneNo
        ? formData?.billingToPhoneNo
        : "",
      billingToCountryCode: billingCountryCode,
      billingToEmail: formData?.billingToEmail || "",
      billingToCity: formData?.billingToCity || "",
      billingToState: formData?.billingToState || "",
      billingToCountry: formData?.billingToCountry || "",
      billingToZip: formData?.billingToZip || "",
      shippingToBusinessName: formData?.shippingToBusinessName || "",
      shippingToName: formData?.shippingToName || "",
      shippingToAddressLine1: formData?.shippingToAddressLine1 || "",
      shippingToAddressLine2: formData?.shippingToAddressLine2
        ? formData?.shippingToAddressLine2
        : "",
      shippingToPhoneNo: formData?.shippingToPhoneNo
        ? formData?.shippingToPhoneNo
        : "",
      shippingToCountryCode: shippingCountryCode,
      shippingToEmail: formData?.shippingToEmail || "",
      shippingToCity: formData?.shippingToCity || "",
      shippingToState: formData?.shippingToState || "",
      shippingToCountry: formData?.shippingToCountry || "",
      shippingToZip: formData?.shippingToZip || "",
      productDetails: JSON.stringify(isConvertToSale ? rows?.filter(row => row.checked) : rows),
      shippingPrice: formData?.shippingPrice
        ? formatPrice(formData?.shippingPrice)
        : "0.00",
      discount: formData?.discount ? formatPrice(formData?.discount) : "0.00",
      tax: formData?.tax ? formatPrice(formData?.tax) : "0.00",
      fees: formData?.fees ? formatPrice(formData?.fees) : "0.00",
      // 'taxId': formData?.taxId ? formData?.taxId : "0.00",
      deposit: formData?.deposit ? formatPrice(formData?.deposit) : "0.00",
      terms: formData?.terms,
      paymentMethod: paymentmethod,
      paymentmethod_details: paymentdetails,
      shippingMethod: formData?.shippingMethod,
      shippingDate: shippingDate,
      trackingNumber: formData?.trackingNumber,
      notes: notes,
      wireDetails: wireDetails,
      isSameAddress: isSameAddress,
      invoiceType: watchType,
      productId: tradeProductId,
      model: tradeModel,
      serialNo: tradeSerialNumber,
      modelNo: tradeModelNumber,
      modelName: tradeModelName,
      miscCategory: tradeMiscCategory,
      ...(isConvertToSale && { memoId: id }),
    };
    createNewInvoiceAPI(data);
    // let contactValidation = [
    //   {
    //     userId: userId,
    //     billingToBusinessName: formData?.billingToBusinessName,
    //     billingToName: formData?.billingToName,
    //     billingToAddressLine1: formData?.billingToAddressLine1,
    //     billingToAddressLine2: formData?.billingToAddressLine2,
    //     billingToPhoneNo: formData?.billingToPhoneNo
    //       ? formData?.billingToPhoneNo
    //       : null,
    //     billingToCountryCode: billingCountryCode,
    //     billingToEmail: formData?.billingToEmail,
    //     billingToCity: formData?.billingToCity,
    //     billingToState: formData?.billingToState,
    //     billingToCountry: formData?.billingToCountry,
    //     billingToZip: formData?.billingToZip,
    //     type: "billing",
    //   },
    //   {
    //     userId: userId,
    //     shippingToBusinessName: formData?.shippingToBusinessName,
    //     shippingToName: formData?.shippingToName,
    //     shippingToAddressLine1: formData?.shippingToAddressLine1,
    //     shippingToAddressLine2: formData?.shippingToAddressLine2,
    //     shippingToPhoneNo: formData?.shippingToPhoneNo
    //       ? formData?.shippingToPhoneNo
    //       : null,
    //     shippingToCountryCode: shippingCountryCode,
    //     shippingToEmail: formData?.shippingToEmail,
    //     shippingToCity: formData?.shippingToCity,
    //     shippingToState: formData?.shippingToState,
    //     shippingToCountry: formData?.shippingToCountry,
    //     shippingToZip: formData?.shippingToZip,
    //     type: "shipping",
    //   },
    // ];
    // props.checkContactInDB(contactValidation, (success, error) => {
    //   if (error) {
    //     console.log(error);
    //     setIsSubmitInvoice(false);
    //     toast("Invoice creation failed", {
    //       autoClose: 2000,
    //       type: toast.TYPE.ERROR,
    //       position: toast.POSITION.BOTTOM_CENTER,
    //     });
    //   } else {
    //     const filterNewData = success.data.filter(
    //       (item) => item.isPresent === false
    //     );
    //     const filterExistingData = success.data.filter(
    //       (item) => item.isPresent === true
    //     );
    //     let isNeedConfirmationModal = false;
    //     filterExistingData.map((input) => {
    //       const isChanged = isTwoAddressSame(
    //         data,
    //         input.contactData ? input.contactData[0] : null,
    //         input.type
    //       );
    //       input.isChanged = isChanged;
    //       if (isChanged) {
    //         isNeedConfirmationModal = true;
    //       }
    //     });
    //     if (!isSameAddress) {
    //       if (filterNewData.length > 0 && filterExistingData.length <= 0) {
    //         data["filterData"] = filterNewData;
    //         setModalText(
    //           "This is not in your contacts, Do you want to save it?"
    //         );
    //         setFinalInvoiceData(data);
    //         SetContactListConfirmationModal(!contactListConfirmationModal);
    //       } else if (
    //         filterExistingData.length > 0 &&
    //         filterNewData.length <= 0 &&
    //         isNeedConfirmationModal
    //       ) {
    //         data["filterData"] = filterExistingData;
    //         setModalText("Contact data is changed. Do you want to update it?");
    //         setFinalInvoiceData(data);
    //         SetContactListConfirmationModal(!contactListConfirmationModal);
    //       } else if (
    //         filterNewData.length > 0 &&
    //         filterExistingData.length > 0
    //       ) {
    //         const isChanged = isTwoAddressSame(
    //           data,
    //           filterExistingData[0].contactData[0],
    //           filterExistingData[0].type
    //         );
    //         filterExistingData[0].isChanged = isChanged;
    //         data["filterData"] = filterNewData.concat(filterExistingData);
    //         setModalText(
    //           "This is not in your contacts, Do you want to save it?"
    //         );
    //         setFinalInvoiceData(data);
    //         SetContactListConfirmationModal(!contactListConfirmationModal);
    //       } else {
    //         createNewInvoiceAPI(data);
    //       }
    //     } else if (isSameAddress) {
    //       let isChanged = false;
    //       if (filterExistingData && filterExistingData.length > 0) {
    //         isChanged = isTwoAddressSame(
    //           data,
    //           filterExistingData[0].contactData[0],
    //           filterExistingData[0].type
    //         );
    //       }

    //       if (filterNewData.length > 0) {
    //         data["filterData"] = filterNewData;
    //         setModalText(
    //           "This is not in your contacts, Do you want to save it?"
    //         );
    //         setFinalInvoiceData(data);
    //         SetContactListConfirmationModal(!contactListConfirmationModal);
    //       } else if (filterExistingData.length > 0 && isChanged) {
    //         filterExistingData[0].isChanged = true;
    //         filterExistingData[1].isChanged = true;
    //         data["filterData"] = filterExistingData;
    //         setModalText("Contact data is changed. Do you want to update it?");
    //         setFinalInvoiceData(data);
    //         SetContactListConfirmationModal(!contactListConfirmationModal);
    //       } else {
    //         createNewInvoiceAPI(data);
    //       }
    //     } else {
    //       createNewInvoiceAPI(data);
    //     }
    //   }
    // });
  };

  const handleCloseContactConfirmation = () => {
    setFinalInvoiceData(null);
    SetContactListConfirmationModal(!contactListConfirmationModal);
    createNewInvoiceAPI(finalInvoiceData);
  };

  const handleSuccessContactConfirmation = () => {
    const data = finalInvoiceData;
    const filterObject = data.filterData;
    setFinalInvoiceData(null);
    SetContactListConfirmationModal(!contactListConfirmationModal);
    filterObject.map((item) => {
      if (!item.isPresent) {
        if (item.type === "billing") {
          data["isBillNew"] = 1;
        } else {
          data["isShipNew"] = 1;
        }
      } else {
        if (item.isChanged && isSameAddress) {
          data["isBillChanged"] = 1;
          data["isShipChanged"] = 1;
        } else if (item.isChanged) {
          if (item.type === "billing") {
            data["isBillChanged"] = 1;
          } else {
            data["isShipChanged"] = 1;
          }
        } else {
          data.isChanged = 0;
        }
      }
    });
    createNewInvoiceAPI(data);
  };

  useEffect(() => {
    if (invoiceId !== null) {
      localStorage.setItem("tabInvoiceDashboard", "yes");
      props.history.push({
        pathname: "/view/invoice",
        state: { tab: "invoice", invoiceId, invoiceFilter },
      });
      localStorage.removeItem("tabInvoiceDashboard", true);
    }
  }, [invoiceId, props.history]);

  const createNewInvoiceAPI = (data) => {
    if (data && data.filterData) delete data.filterData;
    props.createInvoiceDetails(
      data,
      (success) => {
        if (success?.status == 0 && success?.errorcode == 'STNE') {
          setIsSubmitInvoice(false);
          setSaletaxOpen(true);
          setErrorMessage(success?.message ?? "Sales Tax ID does not exist for the selected contact");
          setErrorCode("STNE");
        }
        else if (success?.status == 0 && success?.errorcode == 'TILE') {
          setIsSubmitInvoice(false);
          setSaletaxOpen(true);
          setErrorMessage(success?.message ?? "Total Invoice amount limit is exceeded for the selected contact");
          setErrorCode("TILE");
        }
        else if (success?.status === 1) {
          setIsSubmitInvoice(false);
          localStorage.setItem("menuTitle", "INVOICES");
          toast("Invoice created successfully", {
            autoClose: 1000,
            type: success.status === 1 ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
          setSaletaxOpen(false);
          setTimeout(() => {
            setInvoiceId(success?.data?.id);
          }, 1000);
        } else {
          setIsSubmitInvoice(false);
          setSaletaxOpen(false);
          toast(success.message, {
            autoClose: 3000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      },
      (error) => {
        setIsSubmitInvoice(false);
        setSaletaxOpen(false);
        toast("Invoice creation failed", {
          autoClose: 2000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    );
  };
  const formatPrice = (val) => {
    if (val.includes(".")) {
      if (val.split(".")[1].length > 0) {
        const num = parseFloat(val);
        const cleanNum = num.toFixed(2);
        return cleanNum.toString();
      }
    }
    return `${val}.00`;
  };

  const handleAddRow = (invoiceObj) => {
    if (currentItem) {
      let oldRows = rows;
      oldRows[editIdx] = invoiceObj;
      setRows(oldRows);
    } else {
      setRows((prevArray) => [...prevArray, invoiceObj]);
    }
  };

  const onSubmit = (data) => {
    if (type !== "misc_product" && isSerialNumberRequired) {
      errors["serialNumber"] = {
        message: "Please Enter Serial No",
        type: "required",
      };
      return;
    }
    if ((price && Number(price) < amount) || !price) {
      setModalOpen(false);
      // setopenpricewarning(false);
    } else {
      toast("This sale is under cost", {
        autoClose: 2000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setModalOpen(false);
    }

    const amt = parseFloat(data.amount)?.toFixed(2);
    const rowData = data;
    rowData.amount = amt;
    rowData.inventoryId = inventoryid;
    rowData.productId = productid;
    rowData.currency = currency;
    rowData.purchaseprice = price;
    rowData.type = type;
    if (rowData && rowData.from) {
      delete rowData.from;
    }
    // rowData.inventoryid = inventoryid;
    // rowData.shippingDate = shippingDate;
    handleAddRow({
      ...rowData,
      ...{ modelNumber: modelNo },
      ...{ serialNumber: serial },
      ...{ productName: desc || getValues("productName") },
    }); // , ...{dueDate}, ...{isInvoiced: invd}, ...{isShipped: shpd}
    setpurchaseprice(null);
    setprice(null);
    // else{
    //   setonsubmitdata(data)
    //   setopenpricewarning(true)
    // }
  };
  const onSubmitone = () => {
    setModalOpen(false);
    // setopenpricewarning(false);
    const amt = parseFloat(onsubmitdata.amount)?.toFixed(2);
    const rowData = onsubmitdata;
    rowData.amount = amt;
    rowData.inventoryId = inventoryid;
    rowData.productId = productid;
    rowData.currency = currency;
    rowData.purchaseprice = price;
    if (rowData && rowData.from) {
      delete rowData.from;
    }
    // rowData.inventoryid = inventoryid;
    // rowData.shippingDate = shippingDate;
    handleAddRow({
      ...rowData,
      ...{ modelNumber: modelNo },
      ...{ serialNumber: serial },
      ...{ productName: desc || getValues("productName") },
    }); // , ...{dueDate}, ...{isInvoiced: invd}, ...{isShipped: shpd}
    setpurchaseprice(null);
    setprice(null);
  };

  const handleSameaddressCheck = (event) => {
    setSameAddress(!isSameAddress);
    if (event.target.checked) {
      // setShipCntctNm(getValues1()['billingToName']);
      shippingAddress.map((item, i) => {
        setValue1(item.id, getValues1()[billingAddress[i].id]);
      });
      setValue1("shippingToName", billCntctNm);
      setShippingCountryCode(billingCountryCode);
      setShipCntctNm(billCntctNm);
      setShipContactId(billContactId);
    } else {
      setNoShipData(false);
      setShipCntctSearchTxt(null);
      setShipCntctNm(null);
      setShipContactId(null);
      shippingAddress.map((item, i) => {
        setValue1(item.id, "");
      });
    }
  };

  const destructureData = (data) => {
    const groupedByEmail = {};
    data.forEach((value) => {
      const Email = value.email;
      groupedByEmail[Email] = groupedByEmail[Email] || [];
      groupedByEmail[Email].push(value);
    });
    const resultArray = Object.values(groupedByEmail);
    return resultArray;
  };

  useEffect(() => {
    if (props.billShipCntctLst && props.billShipCntctLst?.length > 0) {
      // setAnchorEl(anchorTarget);
      if (billCntctSearchTxt) {
        const filteredData = destructureData(props.billShipCntctLst);
        setdataset(filteredData);
        setBillCntctLst(props.billShipCntctLst);
        setShipCntcts(null);
        setIsPopoverVisiblebill(!false);
        setNoBillData(false);
      }
      if (shipCntctSearchTxt) {
        const filteredData = destructureData(props.billShipCntctLst);
        setshippingdataset(filteredData);
        setShipCntcts(props.billShipCntctLst);
        setIsPopoverVisibleship(!false);
        setNoShipData(false);
      }
    } else if (props.billShipCntctLst?.length === 0) {
      if (billCntctSearchTxt) {
        setBillCntctLst([]);
        setNoBillData(true);
        setdataset([]);
      }
      if (shipCntctSearchTxt) {
        setShipCntcts([]);
        setNoShipData(true);
        setshippingdataset([]);
      }
    }
  }, [props.billShipCntctLst]);

  useEffect(() => {
    if (props.modelList && props.modelList?.length > 0) {
      if (modelNo) {
        // setInventoryId(props.inventoryid)
        setModelList(props.modelList);
        setIsPopoverVisiblemodelno(true);
        setNoData(false);
      }
      if (serial) {
        setSerialList(props.modelList);
        setIsPopoverVisibleserialno(true);
        setNoData(false);
      }
    } else {
      if (searchObj?.searchVal?.length > 2) {
        setNoData(true);
      }
    }
  }, [props.modelList, modelNo, serial]);
  // useEffect(()=>{
  //     const timer = setTimeout(() => {
  //         if (Object.keys(searchObj).length && searchObj?.searchVal?.length > 2) {
  //             props.getModelSerialList(searchObj,userId);
  //         }
  //     }, 500);
  //     return () => clearTimeout(timer);
  // },[searchObj]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (Object.keys(searchObj).length && searchObj?.searchVal?.length > 2) {
        props.getNewModelSerialList(searchObj, accountId);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchObj]);

  const handleAddItem = () => {
    setModalOpen(!openModal);
    setCurrentItem(null);
    // setDueDate(null);
    setShippingDate(null);
    setAmount(null);
    setInventoryId(null);
    setProductId(null);
    setCurrency("USD");
    setQtyShrink(true);
    setDescShrink(false);
    setEditIdx(null);
    setModel(null);
    setSerial(null);
    setDesc(null);
    setNoData(false);
    setQuantity(1);
    setType(null);
    // setDisabled(false);
    reset({
      modelNumber: "",
      serialNumber: "",
      image: "",
      productName: "",
      quantity: 1,
      // rate:"",
      amount: "",
      inventoryId: "",
      productId: "",
      currency: "USD",
      modelAndSerial: null,
      // shippingDate:"",
      //   shippingMethod:"",
      //   trackingNumber:""
      // isShipped:"",
      // isInvoiced:"",
      // dueDate:""
    });
  };

  const handleClosepriceModal = () => {
    // setopenpricewarning(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    if (currentItem && currentItem.from) {
      handleRemoveSpecificRow(0);
    }
  };

  const handleRemoveSpecificRow = (idx) => {
    setEditIdx(null);
    const temp = [...rows];
    temp.splice(idx, 1);
    if (temp.length === 0) {
      setShipping(null);
      setDeposit(null);
      setTax(null);
      setDeposit(null);
      setQuantity(1);
      setType(null);
      setISSerialNumberRequired(false);
    }
    setRows(temp);
  };

  const onChangeOfCheckBox = (event) => {
    if (event.target.checked === true) {
      setWireDetails(true);
    } else {
      setWireDetails(false);
    }
  };

  const handleSearch = (e, column) => {
    setNoData(false);
    const modelNmTrim = e.target.value.trimStart();
    // setAnchorTarget(e.currentTarget);
    setCurrentSearch(column);
    if (column === "modelNumber") {
      setModel(e.target.value);
      setSearchInputmodelno(modelNmTrim);
    } else {
      setSerial(modelNmTrim.length === 0 ? null : e.target.value);
      setSearchInputserialno(modelNmTrim);
      if (type !== "misc_product" && modelNmTrim.length === 0) {
        setISSerialNumberRequired(true);
      } else {
        setISSerialNumberRequired(false);
      }
    }
    setSearchObj({ column, searchVal: e.target.value });
  };

  const handleTradeSearch = (e, column) => {
    const { value } = e.target;
    setTradeModel(value);
    setTradeProductId(null);
    const string = value
      ? value.replace(/[&\/\\#,+!@^&()$~%.'":;*?<>{}]/g, "")
      : "";
    setSearchTradeObj({
      column,
      searchVal: value,
      searchText: string,
      from: "trade",
    });
  };

  const handleTradeSelection = (val, columnId) => {
    setTradeModel(val.model);
    setValue("tradeModel", val.model);
    setTradeProductId(val.productid);
    setPropsModelList([]);
    setSearchTradeObj((prevState) => ({
      ...prevState,
      searchVal: "",
      searchText: "",
      from: "trade",
    }));
  };

  const handleVisibleChange = (visible) => {
    setIsPopoverVisiblebill(visible);
  };
  const handleVisibleChangeship = (visible) => {
    setIsPopoverVisibleship(visible);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (shipinputRef.current) {
      shipinputRef.current.focus();
    }
    if (modelinputRef.current) {
      modelinputRef.current.focus();
    }
    if (serialinputRef.current) {
      serialinputRef.current.focus();
    }
    getInvoiceList();
  }, []);

  const getInvoiceList = () => {
    getUserdetailsforsubscrption.getAllInvoiceList(
      accountId,
      (error, response) => {
        if (response && response.status == 1) {
          setInvoiceListData(response.data);
        }
      }
    );
  };

  const fetchBillContacts = useCallback(
    _.debounce((searchText) => {
      if (!isBillTextInput && searchText && searchText.length > 2) {
        setFieldLoaderBill(true);
        let payload = {
          key: searchText,
          // key: searchText?.replace(/\s/g, ""),
          contacttype: contactTypeBill,
          type: null,
          accountids: [accountId],
        };
        props
          .getInvoiceNewContactList(payload)
          .then(() => setFieldLoaderBill(false))
          .catch(() => setFieldLoaderBill(false));
      }
    }, 500),
    [isBillTextInput, contactTypeBill, accountId, props]
  );

  useEffect(() => {
    fetchBillContacts(billCntctSearchTxt);
  }, [billCntctSearchTxt]);

  const fetchShipContacts = useCallback(
    _.debounce((searchText) => {
      if (!isShipTextInput && searchText && searchText.length > 2) {
        setFieldLoaderShip(true);
        let payload = {
          key: searchText,
          // key: searchText?.replace(/\s/g, ""),
          contacttype: contactTypeShip,
          type: null,
          accountids: [accountId],
        };
        props
          .getInvoiceNewContactList(payload)
          .then(() => setFieldLoaderShip(false))
          .catch(() => setFieldLoaderShip(false));
      }
    }, 500),
    [isShipTextInput, contactTypeShip, accountId, props]
  );

  useEffect(() => {
    fetchShipContacts(shipCntctSearchTxt);
  }, [shipCntctSearchTxt]);

  // useEffect(() => {
  //   // const timer = setTimeout(() => {
  //   if (
  //     !isBillTextInput &&
  //     billCntctSearchTxt &&
  //     billCntctSearchTxt?.length > 2
  //   ) {
  //     setFieldLoaderBill(true);
  //     // const search = { type: "bill", searchVal: billCntctSearchTxt };
  //     let payload = {
  //       key: billCntctSearchTxt,
  //       contacttype: contactTypeBill,
  //       type: null,
  //       accountids: [accountId],
  //     };
  //     // props.getBillShipContactList(search, userId);      
  //     props.getInvoiceNewContactList(payload)
  //       .then(() => {
  //         setFieldLoaderBill(false);
  //       })
  //       .catch((error) => {
  //         setFieldLoaderBill(false);
  //       });
  //   }
  //   // }, 500);
  //   // return () => clearTimeout(timer);
  // }, [billCntctSearchTxt]);

  // useEffect(() => {
  //   // const timer = setTimeout(() => {
  //   if (
  //     !isShipTextInput &&
  //     shipCntctSearchTxt &&
  //     shipCntctSearchTxt?.length > 2
  //   ) {
  //     setFieldLoaderShip(true);
  //     // const search = { type: "ship", searchVal: shipCntctSearchTxt };
  //     let payload = {
  //       key: shipCntctSearchTxt,
  //       contacttype: contactTypeShip,
  //       type: null,
  //       accountids: [accountId],
  //     };
  //     // props.getBillShipContactList(search, userId);      
  //     props.getInvoiceNewContactList(payload)
  //       .then(() => {
  //         setFieldLoaderShip(false);
  //       })
  //       .catch((error) => {
  //         setFieldLoaderShip(false);
  //       });
  //   }
  //   // }, 500);
  //   // return () => clearTimeout(timer);
  // }, [shipCntctSearchTxt]);

  const handleContactSearch = (e, column, type) => {
    // setAnchorTarget(e.currentTarget);
    const CntctNmTrim = e.target.value.trimStart();
    if (type === "bill" && column === "billingToName") {
      setBillCntctNm(CntctNmTrim);
      setSearchInput(CntctNmTrim.trim());
      setBillCntctSearchTxt(CntctNmTrim);
      setShipCntctSearchTxt(null);
      setValue1("billingToName", CntctNmTrim);
    } else if (type === "ship" && column === "shippingToName") {
      setShipCntctNm(CntctNmTrim);
      setSearchInputship(CntctNmTrim.trim());
      setShipCntctSearchTxt(CntctNmTrim);
      setBillCntctSearchTxt(null);
      setValue1("shippingToName", CntctNmTrim);
    }
    if (type === "bill" && isSameAddress) {
      const changeColum = column.split("billing")[1];
      setValue1(`shipping${changeColum}`, CntctNmTrim);
    }
  };

  const handleContactTypeChange = (event, type) => {
    const { value, checked } = event.target;
    if (type === "bill") {
      setContactTypeBill((prev) =>
        checked ? [...prev, value] : prev.filter((type) => type !== value)
      );
      setClassificationTypeBill((prev) => checked ? null : prev);
    }
    else if (type === "ship") {
      setContactTypeShip((prev) =>
        checked ? [...prev, value] : prev.filter((type) => type !== value)
      );
      setClassificationTypeShip((prev) => checked ? null : prev);
    }
  };

  const clearSearchInput = () => {
    setModel(null);
    setSerial(null);
    setDesc(null);
    setAnchorEl(null);
    setSearchInputmodelno("");
    setSearchInputserialno("");
    setAnchorTarget(null);
    setModelList([]);
    setSerialList([]);
    setCurrentItem(null);
    setCurrency("USD");
    setDescShrink(false);
    setNoData(false);
    setType(null);
    setISSerialNumberRequired(false);
    setSearchObj((prevState) => ({ ...prevState, searchVal: "" }));
    reset({ modelNumber: null, serialNumber: null, productName: null });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSalesTaxModalClose = () => {
    setSaletaxOpen(false);
    setReason(null);
    setErrorCode(null);
    setErrorMessage(null);
  };

  const handleNavigate = () => {
    window.open(`${window.location.origin}/contactmanagement/edit/${billContactId}`, "_blank");
  }

  const handleFocus = () => {
    setSaletaxOpen(false);
    setTimeout(() => {
      const taxInput = document.querySelector("#taxInput");
      if (taxInput) {
        taxInput.scrollIntoView({ behavior: "smooth", block: "center" });
        taxInput.focus();
      }
    }, 100);
  }

  const handleSelection = (val, columnId) => {
    const model =
      val?.model && val?.model !== null
        ? val?.model
        : val?.technical_details?.product_title
          ? val?.technical_details?.product_title
          : val?.technical_details?.product_name;
    const product_name =
      val?.model && val?.model !== null
        ? val?.inventory_title
          ? val?.inventory_title
          : val?.modelname
            ? val?.modelname
            : ""
        : val?.technical_details?.product_title
          ? val?.technical_details?.product_title
          : val?.technical_details?.product_name;
    setModel(model);
    setSerial(val?.serialno);
    setpurchaseprice(val?.purchaseprice);
    setprice(val?.purchaseprice);
    // setDesc(val.description); // todo while fixing productname strink issue we changed this
    setDesc(product_name);
    setImage(
      val?.imagepaths?.front ||
      val?.imagepaths?.back ||
      val?.imagepaths?.left ||
      val?.imagepaths?.right ||
      val?.imagepaths?.free_form ||
      val?.imagepaths?.buckle_opened ||
      val?.imagepaths?.buckle_closed ||
      val?.imagepaths?.wrist_shot ||
      imageNotAvailable
    );
    setQuantity(val?.quantity);
    setType(val?.type);
    setISSerialNumberRequired(false);
    setProductId(val?.productid);
    setAnchorEl(null);
    setAnchorTarget(null);
    setValue("modelNumber", model);
    setValue("serialNumber", val?.serialno);
    setInventoryId(val?.inventoryid);
    setTimeout(() => {
      setValue("productName", product_name);
    }, 100);
    setValue(
      "image",
      val?.imagepaths?.front ||
      val?.imagepaths?.back ||
      val?.imagepaths?.left ||
      val?.imagepaths?.right ||
      val?.imagepaths?.free_form ||
      val?.imagepaths?.buckle_opened ||
      val?.imagepaths?.buckle_closed ||
      val?.imagepaths?.wrist_shot ||
      val?.product_imagepaths?.images ||
      imageNotAvailable
    );
    setDescShrink(product_name ? true : false);
    setSearchObj((prevState) => ({ ...prevState, searchVal: "" }));
    setModelList([]);
    setSerialList([]);
    setSearchInputmodelno("");
    setSearchInputserialno("");
  };

  const clearCntctSearchInput = (type) => {
    if (type === "bill") {
      setNoBillData(false);
      setBillCntctLst([]);
      setBillCntctSearchTxt(null);
      setSearchInput("");
      setBillCntctNm(null);
      setBillContactId(null);
      if (isSameAddress) {
        setNoShipData(false);
        setShipCntctSearchTxt(null);
        setSearchInputship("");
        setShipCntctNm(null);
        reset1();
        setShipContactId(null);
      } else {
        setValue1("billingToName", null);
        setValue1("billingToBusinessName", null);
        setValue1("billingToPhoneNo", null);
        setValue1("billingToEmail", null);
        setValue1("billingToAddressLine1", null);
        setValue1("billingToAddressLine2", null);
        setValue1("billingToCity", null);
        setValue1("billingToState", null);
        setValue1("billingToCountry", "United States");
        setValue1("billingToZip", null);
      }
    } else {
      setNoShipData(false);
      setShipCntcts([]);
      setSearchInputship("");
      setShipCntctSearchTxt(null);
      setShipCntctNm(null);
      setValue1("shippingToName", null);
      setValue1("shippingToBusinessName", null);
      setValue1("shippingToPhoneNo", null);
      setValue1("shippingToEmail", null);
      setValue1("shippingToAddressLine1", null);
      setValue1("shippingToAddressLine2", null);
      setValue1("shippingToCity", null);
      setValue1("shippingToState", null);
      setValue1("shippingToCountry", "United States");
      setValue1("shippingToZip", null);
      setSameAddress(false);
      setShipContactId(null);
    }
  };

  //   const changeTextMode = (type)=>{
  //       if(type ==="bill") {
  //         setIsBillTextInput(!isBillTextInput);
  //         setNoBillData(false);
  //       } else {
  //         setIsShipTextInput(!isShipTextInput);
  //         setNoShipData(false);
  //       }
  //   }
  const onchangeDeposit = (e) => {
    const value = e.target.value.replace(/[^0-9\.]/g, "");
    setDeposit(value);
  };

  const handlePaymentMethod = (event) => {
    const { value } = event.target;
    setPaymentMethod(value);
    if (value !== "Trade") {
      handleNonTrade();
    }
  };

  const handleNonTrade = () => {
    setTradeModel(null);
    setTradeSerialNumber(null);
    setTradeModelName(null);
    setTradeModelNumber(null);
    setTradeMiscCategory(null);
  };

  const handleContactSelection = async (val, columnId) => {
    const displayName = `${`${val?.firstname ?? ""} ${val?.lastname ?? ""}`}`;
    if (billCntctSearchTxt && columnId === "bill") {
      setNoBillData(false);
      setBillCntctNm(displayName);
      setBillCntctSearchTxt("");
      setBillCntctLst([]);
      setSearchInput("");
      setSearchInputship("");
      setPaymentContactBased(val.acceptable_payment && val.acceptable_payment.length > 0 ? val.acceptable_payment : [])
      setValue1("terms", val.paymentterm ? val.paymentterm : "Immediate");
      setBillingCountryCode(val?.billingcountrycode);
      if (val.billingcountry) {
        const selectedCountry = val.billingcountry;
        const filterCountry = countryListData.find(
          (country) => country.name === selectedCountry
        );
        if (filterCountry && filterCountry.id) {
          getUserdetailsforsubscrption.getAllStateList(filterCountry.id)
            .then((res) => {
              setStatesListData(res?.data?.data);
            })
            .catch((error) => {
              showErrorToast(error || "Something went wrong")
            });
        }
      }
      let phoneNumber = val ? val.phoneno : null;
      if (phoneNumber && val.billingcountry) {
        phoneNumber = phoneNumber.replace(/\W+/g, "");
      }
      setBillContactId(val?.contactid);
      setValue1("billingToName", displayName);
      setValue1("billingToBusinessName", val?.businessname);
      setValue1("billingToPhoneNo", phoneNumber);
      setValue1("billingToEmail", val?.emailid);
      setValue1("billingToAddressLine1", val?.billingaddress1);
      setValue1("billingToAddressLine2", val?.billingaddress2);
      setValue1("billingToCity", val?.billingcity);
      setValue1("billingToState", val?.billingstate);
      setValue1("billingToCountry", val?.billingcountry);
      setValue1("billingToZip", val?.billingzipcode);
      if (isSameAddress) {
        shippingFormPatch(val);
      }
    } else {
      setNoShipData(false);
      setShipCntctSearchTxt("");
      setShipCntcts([]);
      shippingFormPatch(val);
    }
  };

  const shippingFormPatch = (val) => {
    const displayName = `${`${val?.firstname ?? ""} ${val?.lastname ?? ""}`}`;
    setShipCntctNm(displayName);
    setShippingCountryCode(val?.shippingcountrycode);
    if (val.shippingcountry) {
      const selectedCountry = val.shippingcountry;
      const filterCountry = countryListData.find(
        (country) => country.name === selectedCountry
      );
      if (filterCountry && filterCountry.id) {
        getUserdetailsforsubscrption
          .getAllStateList(filterCountry.id)
          .then((res) => {
            setShippingStatesListData(res?.data?.data);
          })
          .catch((error) => {
            showErrorToast(error || "Something went wrong")
          });
      }
    }
    let phoneNumber = val ? val.phoneno : null;
    phoneNumber = phoneNumber ? phoneNumber.replace(/\W+/g, "") : null;
    setShipContactId(val?.contactid);
    setValue1("shippingToName", displayName);
    setValue1("shippingToBusinessName", val?.businessname);
    setValue1("shippingToPhoneNo", phoneNumber);
    setValue1("shippingToEmail", val?.emailid);
    setValue1("shippingToAddressLine1", val?.shippingaddress1);
    setValue1("shippingToAddressLine2", val?.shippingaddress2);
    setValue1("shippingToCity", val?.shippingcity);
    setValue1("shippingToState", val?.shippingstate);
    setValue1("shippingToCountry", val?.shippingcountry);
    setValue1("shippingToZip", val?.shippingzipcode);
  };

  const onChangeCounry = (e, id, type = null) => {
    const selectedCountry = e.target.value;
    const filterCountry = countryListData.find(
      (country) => country.name === selectedCountry
    );
    if (type) {
      handleContactSearch(e, id, "bill");
      setValue1(id, selectedCountry);
      if (filterCountry && filterCountry.id) {
        getUserdetailsforsubscrption
          .getAllStateList(filterCountry.id)
          .then((res) => {
            setStatesListData(res?.data?.data);
            // setBillingCountryCode(filterCountry.phonecode)
          });
      }
    } else {
      setValue1(id, selectedCountry);
      if (filterCountry && filterCountry.id) {
        getUserdetailsforsubscrption
          .getAllStateList(filterCountry.id)
          .then((res) => {
            setShippingStatesListData(res?.data?.data);
            // setShippingCountryCode(filterCountry.phonecode)
          });
      }
    }
  };

  // const handleContactAddress = (val) => {
  //   let address = "";
  //   if (val?.contactname) {
  //     address += val?.contactname;
  //   }
  //   if (val?.businessname) {
  //     address += address ? ", " + val?.businessname : val?.businessname;
  //   }
  //   if (val?.address1) {
  //     address += address ? ", " + val?.address1 : val?.address1;
  //   }
  //   if (val?.address2) {
  //     address += address ? ", " + val?.address2 : val?.address2;
  //   }
  //   if (val?.city) {
  //     address += address ? ", " + val?.city : val?.city;
  //   }
  //   if (val?.states) {
  //     address += address ? ", " + val?.states : val?.states;
  //   }
  //   if (val?.country) {
  //     address += address ? ", " + val?.country : val?.country;
  //   }
  //   if (val?.zipcode) {
  //     address += address ? ", " + val?.zipcode : val?.zipcode;
  //   }
  //   return address;
  // };

  const handleContactAddress = (val, type) => {
    if (!val || !type) return "";

    const addressFields = type === "bill"
      ? [
        "billingaddress1",
        "billingaddress2",
        "billingcity",
        "billingstate",
        "billingcountry",
        "billingzipcode",
      ]
      : [
        "shippingaddress1",
        "shippingaddress2",
        "shippingcity",
        "shippingstate",
        "shippingcountry",
        "shippingzipcode",
      ];

    const name = `${val?.firstname ?? ""} ${val?.lastname ?? ""}`.trim();
    const fields = [
      name,
      val?.businessname,
      ...addressFields.map((field) => val?.[field]),
    ];

    // Filter out undefined or empty values and join with ", "
    return fields.filter(Boolean).join(", ");
  };

  const handleDescription = (el) => {
    if (el.target.value) {
      setDescShrink(true);
    } else {
      setDescShrink(false);
    }
  };

  const handleGoBack = (invoiceFilter) => {
    history.push({
      pathname: "/invoices",
      state: { invoiceFilter },
    });
  };

  const handleCheckboxChange = (idx) => {
    setRows((prevRows) =>
      prevRows.map((row, index) =>
        index === idx ? { ...row, checked: !row.checked } : row
      )
    );
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <Container
        maxWidth="md"
        style={{ backgroundColor: "white", minHeight: height - 114 + "px" }}
      >
        {isLoading ? (
          <div class="d-spinner">
            <SpinnerRoundFilled
              size={51}
              thickness={125}
              speed={80}
              color="rgba(57, 107, 172, 1)"
              className="spp"
              enabled={isLoading}
            />
          </div>
        ) : (
          <form key={1} onSubmit={handleSubmit1(onCreateInvoiceSubmit)}>
            <Grid container xs={12} sm={12}>
              <h1 style={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon
                  style={{
                    color: "action",
                    paddingRight: "15px",
                    fontSize: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    isConvertToSale
                      ? handleGoBack(invoiceFilter)
                      : history.goBack();
                  }}
                >
                  {" "}
                </ArrowBackIcon>
                {isConvertToSale ? "Convert to Sale" : "Create an invoice"}
              </h1>
            </Grid>
            {!isConvertToSale && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="text"
                  style={{
                    background: "#000",
                    color: "#fff",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  onClick={handleAddItem}
                >
                  Add Item
                </Button>
              </Grid>
            )}
            <TableContainer
              sx={{ maxHeight: 440, marginTop: "20px", marginBottom: "20px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {isConvertToSale && <StyledTableCell></StyledTableCell>}
                    {invoiceObj
                      .filter(
                        (item) =>
                          item.id !== "currency" && item.id !== "modelAndSerial"
                      )
                      .map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length === 0 && (
                    <StyledTableRow>
                      <TableCell colspan={11} style={{ textAlign: "center" }}>
                        No record found
                      </TableCell>
                    </StyledTableRow>
                  )}
                  {rows?.map((row, idx) => {
                    return (
                      <>
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {isConvertToSale && (
                            <TableCell>
                              <Checkbox
                                checked={row.checked}
                                onChange={() => handleCheckboxChange(idx)}
                              />
                            </TableCell>
                          )}
                          {invoiceObj
                            ?.filter(
                              (item) =>
                                item.id !== "currency" &&
                                item.id !== "modelAndSerial"
                            )
                            .map((column) => {
                              return (
                                <>
                                  {column.id !== "action" && (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{
                                        color:
                                          isConvertToSale &&
                                          (row.checked ? "inherit" : "gray"),
                                      }}
                                    >
                                      {
                                        // (column.id === "isShipped" || column.id === "isInvoiced") ? (row[column.id] ? "Yes" : "No") :
                                        column.type === "image" ? (
                                          <img
                                            src={row[column.id]}
                                            width="35"
                                            height="55"
                                          />
                                        ) : column.id === "rate" ||
                                          column.id === "amount" ? (
                                          `${rows[idx].currency}${row[column.id]
                                          }`
                                        ) : column.id === "productName" &&
                                          row[column.id]?.length > 50 ? (
                                          row[column.id]?.slice(0, 50) + "..."
                                        ) : (
                                          row[column.id]
                                        )
                                      }
                                    </TableCell>
                                  )}
                                  {column.id === "action" && (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ padding: 10 }}
                                    >
                                      <Box
                                        style={{
                                          pointerEvents:
                                            isConvertToSale &&
                                            (row.checked ? "auto" : "none"),
                                          opacity:
                                            isConvertToSale &&
                                            (row.checked ? 1 : 0.5),
                                        }}
                                      >
                                        <EditIcon
                                          style={{
                                            cursor: "pointer",
                                            width: "19px",
                                          }}
                                          onClick={() => {
                                            setModalOpen(!openModal);
                                            setAnchorEl(null);
                                            const currentItem = rows.at(idx);
                                            const {
                                              amount,
                                              currency,
                                              modelNumber,
                                              serialNumber,
                                              type,
                                              purchaseprice,
                                              productId,
                                              productName,
                                              inventoryId,
                                            } = currentItem;
                                            setCurrentItem(currentItem);
                                            setCurrency(currency);
                                            reset(currentItem);
                                            setEditIdx(idx);
                                            setModel(modelNumber);
                                            setSerial(serialNumber);
                                            setType(type);
                                            setprice(purchaseprice);
                                            setAmount(amount);
                                            setInventoryId(inventoryId);
                                            setProductId(productId)
                                            setDesc(productName);
                                            if (productName) {
                                              setDescShrink(true);
                                            }
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        {isConvertToSale ? null : (
                                          <DeleteIcon
                                            style={{
                                              cursor: "pointer",
                                              width: "19px",
                                            }}
                                            onClick={() => {
                                              handleRemoveSpecificRow(idx);
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </TableCell>
                                  )}
                                </>
                              );
                            })}
                        </StyledTableRow>
                      </>
                    );
                  })}
                </TableBody >
              </Table >
            </TableContainer >
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div>
                  <div>
                    <Typography className="color-232323">
                      <Grid
                        container
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          height: "42px",
                        }}
                      >
                        <Grid item xs={6} sm={6} md={6}>
                          <b>BILL TO</b>
                        </Grid>
                        {/* <Grid item xs={6} sm={6} md={6}>
                                { !isBillTextInput ?
                                   <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('bill')}}>Click to Add Contact</Button>
                                   :
                                   <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('bill')}}>Click to Search Contact</Button>
                                }
                            </Grid>
                            */}
                      </Grid>
                    </Typography>
                  </div>
                  {billingAddress.map((column) => (
                    <>
                      {column.type === "text_search" && (
                        <>
                          <div
                            className="searchInputs"
                            style={{ marginTop: "30px" }}
                          >
                            <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                              {billCntctNm ? (
                                <InputLabel
                                  htmlFor="billCntctNm"
                                  shrink={true}
                                  style={{ color: "#424242" }}
                                >
                                  {column.label}
                                </InputLabel>
                              ) : (
                                <InputLabel
                                  htmlFor="billCntctNm"
                                  shrink={false}
                                  style={{ color: "#424242" }}
                                >
                                  {column.label}
                                </InputLabel>
                              )}

                              <AntdInput
                                ref={inputRef}
                                id="billCntctNm"
                                type="text"
                                autoComplete="off"
                                value={billCntctNm}
                                {...register1(column.id, {
                                  required: column.required
                                    ? column.errMsg
                                    : false,
                                })}
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "10px",
                                  fontSize: "16px",
                                }}
                                onChange={(e) => {
                                  handleContactSearch(e, column.id, "bill");
                                }}
                                bordered={false}
                              />
                              {errors[column.id] && (
                                <span style={{ color: "red" }}>
                                  {column.errMsg}
                                </span>
                              )}
                              <Divider style={{ marginTop: "5px" }} />
                              <div className="contactSearchIcon">
                                <Grid container>
                                  <Grid item xs={6} sm={6} md={6}>
                                    {!isBillTextInput && (
                                      <>
                                        {!billCntctNm ? (
                                          <SearchIcon
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) :
                                          (
                                            <>
                                              {fieldLoaderBill ? (
                                                <CircularProgress
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                  }}
                                                />
                                              ) : (
                                                <CloseIcon
                                                  id="clearBtn"
                                                  onClick={() => {
                                                    clearCntctSearchInput("bill");
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                />
                                              )}
                                            </>
                                          )}
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </FormControl>
                            {billCntcts &&
                              billCntcts.length > 0 &&
                              searchInput.length > 0 ? (
                              <div
                                style={{
                                  marginLeft: "-70px",
                                  marginTop: "40px",
                                }}
                              >
                                <AntdPopover
                                  id={column.id}
                                  visible={isPopoverVisiblebill}
                                  content={
                                    <div
                                      style={{
                                        height: billCntcts.length < 4 ? "auto" : "200px",
                                        overflow: "auto",
                                      }}
                                    >

                                      {billCntcts
                                        .map((el, index) => {
                                          return (
                                            <AccordionDetails
                                              key={index}
                                            >
                                              <Typography
                                                style={{
                                                  cursor:
                                                    "pointer",
                                                }}
                                                onClick={() =>
                                                  handleContactSelection(
                                                    el,
                                                    "bill"
                                                  )
                                                }
                                              >
                                                {handleContactAddress(
                                                  el, "bill"
                                                )}
                                              </Typography>
                                            </AccordionDetails>
                                          );
                                        })}

                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottomRight"
                                  // placement="bottomLeft"
                                  onVisibleChange={handleVisibleChange}
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      position: "absolute",
                                    }}
                                  />
                                </AntdPopover>
                              </div>
                            )
                              :
                              (billCntctSearchTxt?.length > 2
                                && billCntcts?.length == 0
                                && !fieldLoaderBill
                                && (dataset == 0 || !dataset.length)) ?

                                <>
                                  <AntdPopover
                                    id={column.id}
                                    visible={billCntctSearchTxt?.length > 2
                                      && billCntcts?.length == 0
                                      && (dataset == 0 || !dataset.length)}
                                    content={
                                      <div
                                        style={{ overflow: "auto" }}
                                      >
                                        <a
                                          className="dataItem"
                                          href='javascript:void(0)'
                                          onClick={() =>
                                            window.open(`${window.location.origin}/contactmanagement/add`, "_blank")
                                          }
                                          style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                        >
                                          Click here to Create New Contact
                                        </a>
                                      </div>
                                    }
                                    trigger="click"
                                    placement="bottomRight"
                                    overlayStyle={{ marginTop: "5px" }}
                                  >
                                    <div
                                      style={{ position: "relative" }}
                                    />
                                  </AntdPopover>
                                </>
                                : null
                            }
                          </div>
                          {/* {noBillData && (
                          <div className="dataResult">
                            <a className="dataItem">No data found</a>
                          </div>
                        )} */}
                        </>
                      )}
                      {column.type !== "text_search" && (
                        <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                          {column.id !== "billingWalkIn" &&
                            <>
                              {getValues1()[column.id] ? (
                                <InputLabel
                                  shrink={true}
                                  htmlFor="component-simple"
                                  style={{ color: "#818080" }}
                                >
                                  {column.label}
                                </InputLabel>
                              ) : (
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  {column.label}
                                </InputLabel>
                              )}
                            </>}
                          {column.type === "select" &&
                            column.id === "billingToState" && (
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={
                                  getValues1()[column.id]
                                    ? getValues1()[column.id]
                                    : ""
                                }
                                {...register1(column.id)}
                                onChange={(e) => {
                                  handleContactSearch(e, column.id, "bill");
                                  setValue1(column.id, e.target.value);
                                }}
                                disabled={column.isDisabled}
                              >
                                {statesListData &&
                                  statesListData.map((state) => (
                                    <MenuItem key={state.id} value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          {column.type === "select" &&
                            column.id === "billingToCountry" && (
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={
                                  getValues1()[column.id]
                                    ? getValues1()[column.id]
                                    : "United States"
                                }
                                {...register1(column.id)}
                                onChange={(e) => {
                                  onChangeCounry(e, column.id, "bill");
                                }}
                                disabled={column.isDisabled}
                              >
                                {countryListData &&
                                  countryListData.map((country) => (
                                    <MenuItem
                                      key={country.id}
                                      value={country.name}
                                    >
                                      {country.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          {column.type === "phone" && (
                            <>
                              <Grid container>
                                <Grid item xs={2}>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    key={column.id}
                                    value={
                                      billingCountryCode
                                        ? billingCountryCode
                                        : "+1"
                                    }
                                    onChange={(e) => {
                                      setBillingCountryCode(e.target.value);
                                      if (isSameAddress) {
                                        setShippingCountryCode(e.target.value);
                                      }
                                    }}
                                    disabled={column.isDisabled}
                                  >
                                    {UniqueCountryCode &&
                                      UniqueCountryCode.map((country) => (
                                        <MenuItem key={country} value={country}>
                                          {`${country}`}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </Grid>
                                <Grid item xs={10}>
                                  <Input
                                    id="component-simple"
                                    style={{
                                      marginBottom: "10px",
                                      width: "100%",
                                      marginLeft: "10px",
                                    }}
                                    {...register1(column.id, {
                                      // required: "Please enter phone number",
                                      pattern: {
                                        value: /^(?!0+$)[0-9]{1,14}$/,
                                        message: "Invalid phone number",
                                      },
                                    })}
                                    placeholder="Enter your phone number"
                                    inputProps={{
                                      type: column.type,
                                      maxLength: 14,
                                      autoComplete: "off",
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/^\d+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      handleContactSearch(e, column.id, "bill");
                                    }}
                                    disabled={column.isDisabled}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {column.type === "email" && (
                            <Input
                              id="component-simple"
                              key={column.id}
                              style={{ marginBottom: "10px" }}
                              {...register1(column.id, {
                                // required: "Please enter email",
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: "Invalid email address",
                                },
                              })}
                              inputProps={{
                                type: column.type,
                                autoComplete: "off",
                              }}
                              onChange={(e) => {
                                handleContactSearch(e, column.id, "bill");
                              }}
                              disabled={column.isDisabled}
                            />
                          )}
                          {column.type === "number" && (
                            <Input
                              id="component-simple"
                              key={column.id}
                              style={{ marginBottom: "10px" }}
                              {...register1(column.id, {
                                // required: "Please enter zipcode",
                              })}
                              onKeyPress={(event) => {
                                if (!/^[A-Za-z\d\-_\s]$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                handleContactSearch(e, column.id, "bill");
                              }}
                              disabled={column.isDisabled}
                            />
                          )}
                          {column.type === "checkbox" && (
                            <FormControlLabel
                              label={column.label}
                              control={
                                <Checkbox
                                  id={column.id}
                                  name="checkedB1"
                                  value="walk_in"
                                  onChange={(e) => handleContactTypeChange(e, "bill")}
                                />
                              }
                            />
                          )}
                          {column.type === "text" && (
                            <Input
                              id="component-simple"
                              key={column.id}
                              style={{ marginBottom: "10px" }}
                              {...register1(column.id, {
                                // required: column.errMsg,
                              })}
                              inputProps={{
                                type: column.type,
                                autoComplete: "off",
                              }}
                              onChange={(e) => {
                                handleContactSearch(e, column.id, "bill");
                              }}
                              disabled={column.isDisabled}
                            />
                          )}
                          {errors1[column.id] &&
                            errors1[column.id]?.message && (
                              <span style={{ color: "red" }}>
                                {errors1[column.id].message}
                              </span>
                            )}
                        </FormControl>
                      )}
                    </>
                  ))}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <div>
                    <Typography className="color-232323">
                      <Grid container>
                        <Grid item xs={6} sm={6} md={6}>
                          <b>SHIP TO</b>
                        </Grid>
                        {/* <Grid item xs={6} sm={6} md={6}>
                            { !isShipTextInput ?
                                <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('ship')}}>Click to Add Contact</Button>
                                :
                                <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('ship')}}>Click to Search Contact</Button>
                        }
                        </Grid>   */}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          justifyContent="flex-end"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB1"
                                className="float-right"
                                onChange={handleSameaddressCheck}
                                checked={isSameAddress === true}
                              />
                            }
                            label="Same as bill address"
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  </div>
                  {shippingAddress.map((column, i) => (
                    <>
                      {column.type === "text_search" && (
                        <>
                          <div className="searchInputs">
                            <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                              {shipCntctNm ? (
                                <InputLabel
                                  htmlFor="shipCntctNm"
                                  shrink={true}
                                  style={{ color: "#424242" }}
                                >
                                  {column.label}
                                </InputLabel>
                              ) : (
                                <InputLabel
                                  htmlFor="shipCntctNm"
                                  shrink={false}
                                  style={{ color: "#424242" }}
                                >
                                  {column.label}
                                </InputLabel>
                              )}

                              <AntdInput
                                ref={shipinputRef}
                                id="shipCntctNm"
                                type="text"
                                autoComplete="off"
                                value={shipCntctNm}
                                disabled={isSameAddress}
                                {...register1(column.id, {
                                  required: column.required
                                    ? column.errMsg
                                    : false,
                                })}
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "10px",
                                  fontSize: "16px",
                                }}
                                onChange={(e) => {
                                  handleContactSearch(e, column.id, "ship");
                                }}
                                bordered={false}
                              />
                              {errors[column.id] && (
                                <span style={{ color: "red" }}>
                                  {column.errMsg}
                                </span>
                              )}
                              <Divider style={{ marginTop: "5px" }} />
                              <div className="contactSearchIcon">
                                <Grid container>
                                  <Grid item xs={6} sm={6} md={6}>
                                    {!isShipTextInput && (
                                      <>
                                        {!shipCntctNm ? (
                                          <SearchIcon
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) :
                                          (
                                            <>
                                              {fieldLoaderShip ? (
                                                <CircularProgress
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                  }}
                                                />
                                              ) : (
                                                <CloseIcon
                                                  id="clearBtn"
                                                  onClick={() => {
                                                    clearCntctSearchInput("ship");
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                />
                                              )}
                                            </>
                                          )}
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </FormControl>
                            {shipCntcts &&
                              shipCntcts.length > 0 &&
                              searchInputship.length > 0 ? (
                              <div
                                style={{
                                  marginLeft: "-70px",
                                  marginTop: "40px",
                                }}
                              >
                                <AntdPopover
                                  id={column.id}
                                  visible={isPopoverVisibleship}
                                  content={
                                    <div
                                      style={{
                                        height: shipCntcts.length < 4 ? "auto" : "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      {shipCntcts
                                        .map((el, index) => {
                                          return (
                                            <AccordionDetails
                                              key={index}
                                            >
                                              <Typography
                                                style={{
                                                  cursor:
                                                    "pointer",
                                                }}
                                                onClick={() =>
                                                  handleContactSelection(
                                                    el,
                                                    "ship"
                                                  )
                                                }
                                              >
                                                {handleContactAddress(
                                                  el, "ship"
                                                )}
                                              </Typography>
                                            </AccordionDetails>
                                          );
                                        })}
                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottomRight"
                                  onVisibleChange={handleVisibleChangeship}
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      position: "absolute",
                                    }}
                                  />
                                </AntdPopover>
                              </div>)
                              :
                              (searchInputship?.length > 2
                                && shipCntcts?.length == 0
                                && !fieldLoaderShip
                                && (shippingdataset == 0 || !shippingdataset.length)) ?
                                <AntdPopover
                                  id={column.id}
                                  visible={searchInputship?.length > 2
                                    && shipCntcts?.length == 0
                                    && (shippingdataset == 0 || !shippingdataset.length)}
                                  content={
                                    <div
                                      style={{ overflow: "auto" }}
                                    >
                                      <a
                                        className="dataItem"
                                        onClick={() =>
                                          window.open(`${window.location.origin}/contactmanagement/add`, "_blank")
                                        }
                                        style={{ cursor: "pointer", textDecoration: 'underline', fontWeight: 'bold' }}
                                      >
                                        Click here to Create New Contact
                                      </a>
                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottomRight"
                                  overlayStyle={{ marginTop: "5px" }}
                                >
                                  <div
                                    style={{ position: "relative" }}
                                  />
                                </AntdPopover>
                                : null
                            }
                          </div>
                          {/* {noShipData && (
                          <div className="dataResult">
                            <a className="dataItem">No data found</a>
                          </div>
                        )} */}
                        </>
                      )}
                      {column.type !== "text_search" && (
                        <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                          {column.id !== "shippingWalkIn" &&
                            <>
                              {getValues1()[column.id] ? (
                                <InputLabel
                                  shrink={true}
                                  htmlFor="component-simple"
                                  style={{ color: "#818080" }}
                                >
                                  {column.label}
                                </InputLabel>
                              ) : (
                                <InputLabel
                                  htmlFor="component-simple"
                                  style={{ color: "#424242" }}
                                >
                                  {column.label}
                                </InputLabel>
                              )}
                            </>}
                          {column.type === "select" &&
                            column.id === "shippingToCountry" && (
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                // value={country}
                                value={
                                  getValues1()[column.id]
                                    ? getValues1()[column.id]
                                    : "United States"
                                }
                                {...register1(column.id)}
                                onChange={(e) => {
                                  setValue1(column.id, e.target.value);
                                  onChangeCounry(e, column.id);
                                }}
                                disabled={isSameAddress || column.isDisabled}
                              >
                                {countryListData &&
                                  countryListData.map((country) => (
                                    <MenuItem
                                      key={country.id}
                                      value={country.name}
                                    >
                                      {country.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          {column.type === "select" &&
                            column.id === "shippingToState" && (
                              <Select
                                labelId="demo-simple-select-outlined-label-1"
                                id="demo-simple-select-outlined-1"
                                // value={country}
                                value={
                                  getValues1()[column.id]
                                    ? getValues1()[column.id]
                                    : ""
                                }
                                {...register1(column.id)}
                                onChange={(e) => {
                                  setValue1(column.id, e.target.value);
                                }}
                                disabled={isSameAddress || column.isDisabled}
                              >
                                {!isSameAddress &&
                                  shippingStatesListData &&
                                  shippingStatesListData.map((state) => (
                                    <MenuItem key={state.id} value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  ))}
                                {isSameAddress &&
                                  statesListData &&
                                  statesListData.map((state) => (
                                    <MenuItem key={state.id} value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          {column.type === "phone" && (
                            <>
                              <Grid container>
                                <Grid item xs={2}>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={
                                      shippingCountryCode
                                        ? shippingCountryCode
                                        : "+1"
                                    }
                                    onChange={(e) => {
                                      setShippingCountryCode(e.target.value);
                                    }}
                                    disabled={isSameAddress || column.isDisabled}
                                  >
                                    {UniqueCountryCode &&
                                      UniqueCountryCode.map((country) => (
                                        <MenuItem key={country} value={country}>
                                          {`${country}`}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </Grid>
                                <Grid item xs={10}>
                                  <>
                                    <Input
                                      id="component-simple"
                                      style={{
                                        marginBottom: "10px",
                                        width: "100%",
                                        marginLeft: "10px",
                                      }}
                                      {...register1(column.id, {
                                        // required: "Please enter phone number",
                                        pattern: {
                                          value: /^(?!0+$)[0-9]{1,14}$/,
                                          message: "Invalid phone number",
                                        },
                                      })}
                                      placeholder="Enter your phone number"
                                      inputProps={{
                                        type: column.type,
                                        maxLength: 14,
                                        minlength: 1,
                                        autoComplete: "off",
                                      }}
                                      onKeyPress={(event) => {
                                        if (!/^\d+$/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      disabled={isSameAddress || column.isDisabled}
                                    />
                                  </>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {column.type === "email" && (
                            <Input
                              id="component-simple"
                              key={column.id}
                              style={{ marginBottom: "10px" }}
                              {...register1(column.id, {
                                // required: "Please enter email",
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: "Invalid email address",
                                },
                              })}
                              inputProps={{
                                type: column.type,
                                autoComplete: "off",
                              }}
                              disabled={isSameAddress || column.isDisabled}
                            />
                          )}
                          {column.type === "number" && (
                            <Input
                              id="component-simple"
                              key={column.id}
                              style={{ marginBottom: "10px" }}
                              {...register1(column.id, {
                                // required: "Please enter zipcode",
                              })}
                              onKeyPress={(event) => {
                                if (!/^[A-Za-z\d\-_\s]$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={isSameAddress || column.isDisabled}
                            />
                          )}
                          {column.type === "checkbox" && (
                            <FormControlLabel
                              label={column.label}
                              control={
                                <Checkbox
                                  id={column.id}
                                  name="checkedB1"
                                  value="walk_in"
                                  onChange={(e) => handleContactTypeChange(e, "ship")}
                                />
                              }
                            />
                          )}
                          {column.type === "text" && (
                            <Input
                              id="component-simple"
                              key={column.id}
                              style={{ marginBottom: "10px" }}
                              {...register1(column.id, {
                                // required: column.errMsg,
                              })}
                              inputProps={{
                                type: column.type,
                                autoComplete: "off",
                              }}
                              disabled={isSameAddress || column.isDisabled}
                            />
                          )}
                          {errors1[column.id] &&
                            errors1[column.id].message &&
                            !isSameAddress && (
                              <span style={{ color: "red" }}>
                                {errors1[column.id].message}
                              </span>
                            )}
                        </FormControl>
                      )}
                    </>
                  ))}
                </div>
              </Grid>
            </Grid>
            {
              rows.length > 0 ?
                <Typography variant='h6'>Details</Typography> : null
            }
            <TableContainer
              sx={{ maxHeight: 440, marginTop: "20px", marginBottom: "20px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                {/* <TableHead>
                  <TableRow>
                    {isConvertToSale && <StyledTableCell></StyledTableCell>}
                    {invoiceObj
                      .filter(
                        (item) =>
                          item.id !== "currency" && item.id !== "modelAndSerial"
                      )
                      .map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {rows.length > 0 && (
                    <>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          SHIPPING
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            type="text"
                            className="form-width-100"
                            {...register1("shippingPrice")}
                            placeholder="$0.00"
                            style={{ height: "30px" }}
                            value={shipping}
                            onChange={(e) =>
                              setShipping(
                                e.target.value.replace(/[^0-9\.]/g, "")
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          DISCOUNT
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            type="text"
                            className="form-width-100"
                            {...register1("discount")}
                            placeholder="$0.00"
                            style={{ height: "30px" }}
                            value={discount}
                            onChange={(e) =>
                              setDiscount(
                                e.target.value.replace(/[^0-9\.]/g, "")
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          TAX
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            className="form-width-100"
                            type="text"
                            {...register1("tax")}
                            placeholder="$0.00"
                            style={{ height: "30px" }}
                            value={tax}
                            onChange={(e) =>
                              setTax(e.target.value.replace(/[^0-9\.]/g, ""))
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          FEES
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            className="form-width-100"
                            type="text"
                            {...register1("fees")}
                            placeholder="$0.00"
                            style={{ height: "30px" }}
                            value={fees}
                            onChange={(e) =>
                              setFees(e.target.value.replace(/[^0-9\.]/g, ""))
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          DEPOSIT
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            type="text"
                            className="form-width-100"
                            {...register1("deposit")}
                            placeholder="$0.00"
                            style={{ height: "30px" }}
                            value={deposit}
                            // onChange={(e) =>
                            //   setDeposit(e.target.value.replace(/[^0-9\.]/g, ""))
                            // }
                            onChange={onchangeDeposit}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          PAYMENT METHOD
                        </TableCell>
                        <TableCell colspan={3}>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            fullWidth
                            {...register1("payment_method")}
                            onChange={(e) => handlePaymentMethod(e)}
                            value={paymentmethod}
                            inputProps={{
                              autoComplete: "off",
                            }}
                          >
                            {paymentContactBased?.length > 0 ? (
                              paymentContactBased.map((value, index) => (
                                <MenuItem key={index} value={value}>
                                  {value}
                                </MenuItem>
                              ))
                            ) : ["Wire", "Check", "Cash", "Credit Card", "Trade", "Zelle", "Venmo", "Paypal", "Affirm", "Scrap Gold"].map((value, index) => (
                              <MenuItem key={index} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                          {parseFloat(deposit) > 0 && paymentmethod == null && (
                            <FormHelperText className="error-o">
                              Please Select Payment Method
                            </FormHelperText>
                          )}
                        </TableCell>
                      </TableRow>

                      {paymentmethod && paymentmethod === "Trade" && (
                        <>
                          <TableRow>
                            <TableCell
                              colspan={4}
                              style={{ textAlign: "left" }}
                            >
                              TYPE
                            </TableCell>
                            <TableCell colspan={3}>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                fullWidth
                                {...register1("watchType", {
                                  required:
                                    paymentmethod === "Trade"
                                      ? "Please select Type"
                                      : false,
                                })}
                                value={watchType}
                                onChange={(e) => {
                                  setWatchType(e.target.value);
                                  handleNonTrade();
                                }}
                                inputProps={{
                                  autoComplete: "off",
                                }}
                              >
                                <MenuItem value="standard_watch">
                                  Standard Watch
                                </MenuItem>
                                <MenuItem value="misc_watch">
                                  Misc Watch
                                </MenuItem>
                                <MenuItem value="misc_product">
                                  Misc Product
                                </MenuItem>
                              </Select>
                              {errors1?.watchType && (
                                <FormHelperText className="error-o">
                                  {errors1?.watchType?.message}
                                </FormHelperText>
                              )}
                            </TableCell>
                          </TableRow>

                          {watchType === "standard_watch" && (
                            <TableRow>
                              <TableCell
                                colspan={4}
                                style={{ textAlign: "left" }}
                              >
                                MODEL
                              </TableCell>
                              <TableCell colspan={3}>
                                <Input
                                  id="model"
                                  inputProps={{ type: "text" }}
                                  autoComplete={"off"}
                                  value={tradeModel}
                                  {...register1("tradeModel", {
                                    required:
                                      paymentmethod === "Trade" &&
                                        watchType === "standard_watch"
                                        ? "Please select Model"
                                        : false,
                                    onChange: (e) => {
                                      handleTradeSearch(e, "tradeModel");
                                    },
                                  })}
                                  variant="outline"
                                  placeholder="Model"
                                  className="form-width-100 form-control searchoption boxwidth"
                                  style={{ margin: 0, padding: 0 }}
                                  endAdornment={
                                    searchTradeObj?.searchVal?.length > 0 ? (
                                      <>
                                        <InputAdornment position="end">
                                          {searchLoading ? (
                                            <CircularProgress
                                              style={{ width: 25, height: 25 }}
                                            />
                                          ) : (
                                            <IconButton
                                              onClick={() => {
                                                setPropsModelList([]);
                                                setTradeModel("");
                                                setSearchLoading(false);
                                                setSearchTradeObj(
                                                  (prevState) => ({
                                                    ...prevState,
                                                    searchVal: "",
                                                    searchText: "",
                                                    from: "trade",
                                                  })
                                                );
                                              }}
                                              color="primary"
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          )}
                                        </InputAdornment>
                                      </>
                                    ) : null
                                  }
                                />
                                {errors1?.tradeModel && (
                                  <FormHelperText className="error-o">
                                    {errors1?.tradeModel?.message}
                                  </FormHelperText>
                                )}
                                {searchLoading ? null : searchTradeObj
                                  ?.searchVal?.length > 0 &&
                                  searchTradeObj?.column === "tradeModel" &&
                                  propsModelList.length > 0 ? (
                                  <List
                                    className="form-width-100 searchlist MuiPaper-elevation1 inventrysearch"
                                    style={{ listStyle: "none" }}
                                  >
                                    {propsModelList.map((item, idx) => (
                                      <div>
                                        <ListItem
                                          key={idx}
                                          onClick={() =>
                                            handleTradeSelection(item, "model")
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <ListItemIcon>
                                            <IconButton color="primary">
                                              <img
                                                width="45"
                                                src={
                                                  item.imagepaths?.images
                                                    ?.toString()
                                                    .split(",")[0]
                                                }
                                                alt="Product"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={
                                              item.brandname + " " + item.model
                                            }
                                            secondary={item.family}
                                            style={{
                                              marginTop: "25px",
                                              marginLeft: "80px",
                                            }}
                                          />
                                        </ListItem>
                                        <Divider
                                          variant="inset"
                                          component="li"
                                        />
                                      </div>
                                    ))}
                                  </List>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          )}

                          {watchType === "misc_product" && (
                            <TableRow>
                              <TableCell
                                colspan={4}
                                style={{ textAlign: "left" }}
                              >
                                MISC CATEGORY
                              </TableCell>
                              <TableCell colspan={3}>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  fullWidth
                                  {...register1("tradeMiscCategory", {
                                    required:
                                      paymentmethod === "Trade" &&
                                        watchType === "misc_product"
                                        ? "Please select Misc Category"
                                        : false,
                                  })}
                                  value={tradeMiscCategory}
                                  onChange={(e) =>
                                    setTradeMiscCategory(e.target.value)
                                  }
                                  inputProps={{
                                    autoComplete: "off",
                                  }}
                                >
                                  <MenuItem value="watch related accessories">
                                    Watch Related Accessories
                                  </MenuItem>
                                  <MenuItem value="apparel">Apparel</MenuItem>
                                  <MenuItem value="watch parts">
                                    Watch Parts
                                  </MenuItem>
                                  <MenuItem value="jewelry">Jewelry</MenuItem>
                                  <MenuItem value="stones">Stones</MenuItem>
                                  <MenuItem value="hand bags">
                                    Hand Bags
                                  </MenuItem>
                                  <MenuItem value="wallets">Wallets</MenuItem>
                                  <MenuItem value="bag related accessories">
                                    Bag Related Accessories
                                  </MenuItem>
                                  <MenuItem value="service watches">
                                    Service Watches
                                  </MenuItem>
                                  <MenuItem value="others">Others</MenuItem>
                                </Select>
                                {errors1?.tradeMiscCategory && (
                                  <FormHelperText className="error-o">
                                    {errors1?.tradeMiscCategory?.message}
                                  </FormHelperText>
                                )}
                              </TableCell>
                            </TableRow>
                          )}

                          {(watchType === "misc_watch" ||
                            watchType === "misc_product") && (
                              <TableRow>
                                <TableCell
                                  colspan={4}
                                  style={{ textAlign: "left" }}
                                >
                                  MODEL NAME
                                </TableCell>
                                <TableCell colspan={3}>
                                  <input
                                    className="form-width-100"
                                    type="text"
                                    {...register1("tradeModelName", {
                                      required:
                                        paymentmethod === "Trade" &&
                                          watchType === "misc_product"
                                          ? "Please enter Model Name"
                                          : false,
                                    })}
                                    placeholder="Model Name"
                                    style={{ height: "30px" }}
                                    value={tradeModelName}
                                    onChange={(e) =>
                                      setTradeModelName(e.target.value)
                                    }
                                  />
                                  {errors1?.tradeModelName && (
                                    <FormHelperText className="error-o">
                                      {errors1?.tradeModelName?.message}
                                    </FormHelperText>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}

                          {/* {(watchType === "standard_watch" || watchType === "misc_watch") && */}
                          <TableRow>
                            <TableCell
                              colspan={4}
                              style={{ textAlign: "left" }}
                            >
                              SERIAL NUMBER
                            </TableCell>
                            <TableCell colspan={3}>
                              <input
                                className="form-width-100"
                                type="text"
                                {...register1(
                                  "tradeSerialNumber"
                                  //    ,{
                                  //   required: paymentmethod === "Trade" &&
                                  //     (watchType === "standard_watch" || watchType === "misc_watch")
                                  //     ? "Please enter Serial Number" : false,
                                  // }
                                )}
                                placeholder="Serial Number"
                                style={{ height: "30px" }}
                                value={tradeSerialNumber}
                                onChange={(e) =>
                                  setTradeSerialNumber(e.target.value)
                                }
                              />
                              {errors1?.tradeSerialNumber && (
                                <FormHelperText className="error-o">
                                  {errors1?.tradeSerialNumber?.message}
                                </FormHelperText>
                              )}
                            </TableCell>
                          </TableRow>
                          {/* } */}

                          {watchType === "misc_product" && (
                            <TableRow>
                              <TableCell
                                colspan={4}
                                style={{ textAlign: "left" }}
                              >
                                MODEL NUMBER
                              </TableCell>
                              <TableCell colspan={3}>
                                <input
                                  className="form-width-100"
                                  type="text"
                                  {...register1(
                                    "tradeModelNumber"
                                    //    ,{
                                    //   required: (paymentmethod === "Trade" && watchType === "misc_product")
                                    //     ? "Please enter Model Number" : false,
                                    // }
                                  )}
                                  placeholder="Model Number"
                                  style={{ height: "30px" }}
                                  value={tradeModelNumber}
                                  onChange={(e) =>
                                    setTradeModelNumber(e.target.value)
                                  }
                                />
                                {errors1?.tradeModelNumber && (
                                  <FormHelperText className="error-o">
                                    {errors1?.tradeModelNumber?.message}
                                  </FormHelperText>
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          PAYMENT DETAILS
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            className="form-width-100"
                            type="text"
                            {...register1("paymentDetails")}
                            placeholder="Payment Details"
                            style={{ height: "30px" }}
                            value={paymentdetails}
                            onChange={(e) => setPaymentDetails(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          PAYMENT TERM
                        </TableCell>
                        <TableCell colspan={3}>
                          <Select
                            {...register1("terms")}
                            value={watch1("terms")}
                            // defaultValue="Immediate"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            fullWidth
                          >
                            <MenuItem value="Immediate">Immediate</MenuItem>
                            <MenuItem value="Net 7 Days">Net 7 Days</MenuItem>
                            <MenuItem value="Net 14 Days">Net 14 Days</MenuItem>
                            <MenuItem value="Net 21 Days">Net 21 Days</MenuItem>
                            <MenuItem value="Net 30 Days">Net 30 Days</MenuItem>
                            <MenuItem value="Net 60 Days">Net 60 Days</MenuItem>
                            <MenuItem value="Net 90 Days">Net 90 Days</MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          SHIPPING DATE
                        </TableCell>
                        <TableCell colspan={3}>
                          <div>
                            <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  // className="color-BCBCBC"
                                  margin="normal"
                                  id="date-picker-dialog"
                                  format="MM/dd/yyyy"
                                  // disableFuture={true}
                                  value={shippingDate}
                                  onChange={(date, e) => {
                                    setShippingDate(e);
                                  }}
                                  isClearable
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  placeholder="MM/DD/YYYY"
                                  helperText={""}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          SHIPPING METHOD
                        </TableCell>
                        <TableCell colspan={3}>
                          <Select
                            {...register1("shippingMethod")}
                            value={watch1("shippingMethod")}
                            // onChange={(e) => setShippingMethod(e.target.value)}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            fullWidth
                          >
                            <MenuItem value="USPS">USPS</MenuItem>
                            <MenuItem value="Fedex">Fedex</MenuItem>
                            <MenuItem value="UPS">UPS</MenuItem>
                            <MenuItem value="Ferrary">Ferrary</MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          TRACKING NUMBER
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            className="form-width-100"
                            type="text"
                            {...register1("trackingNumber")}
                            placeholder="Tracking Number"
                            style={{ height: "30px" }}
                            value={trackingNumber}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        TAX ID
                      </TableCell>
                      <TableCell colspan={3}>
                        <input
                          className="form-width-100"
                          type="text"
                          {...register1("taxId")}
                          placeholder="Tax Id"
                          style={{ height: "30px" }}
                          value={Taxid}
                          onChange={(e) => {
                            setTaxid(e.target.value); 
                     
                          }}
                        />
                        {Number(tax) > 0 && ( 
                                  <FormHelperText className="error-o">Please provide Tax Id</FormHelperText>
                                   )} 
                      </TableCell>
                      
                    </TableRow> */}
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          NOTES
                        </TableCell>
                        <TableCell colspan={3}>
                          <input
                            className="form-width-100"
                            type="text"
                            {...register1("notes")}
                            placeholder="Notes"
                            style={{ height: "30px" }}
                            value={notes}
                            onChange={(e) => {
                              setNotes(e.target.value);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colspan={4} style={{ textAlign: "left" }}>
                          Include Wire Details
                        </TableCell>
                        <TableCell colspan={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB1"
                                className="float-right"
                                checked={wireDetails}
                                value={wireDetails}
                                onChange={onChangeOfCheckBox}
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {
              isSubmitInvoice && (
                <div class="d-spinner">
                  <SpinnerRoundFilled
                    size={51}
                    thickness={125}
                    speed={80}
                    color="rgba(57, 107, 172, 1)"
                    className="spp"
                    enabled={isSubmitInvoice}
                  />
                </div>
              )
            }
            {
              !isSubmitInvoice && rows.length > 0 && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="text"
                      style={{
                        background: "#000",
                        color: "#fff",
                        float: "right",
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                      disabled={isConvertToSale && rows?.filter((row) => row.checked)?.length == 0}
                    >
                      Create Invoice
                    </Button>
                  </Grid>
                  {isConvertToSale && (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 20,
                      }}
                    >
                      <Typography variant="inherit" className="color-454545">
                        <i>
                          Note: Clicking the "Create Invoice" button will generate
                          an invoice for the selected{" "}
                          {rows?.filter((row) => row.checked)?.length || 0}{" "}
                          item(s).
                        </i>
                      </Typography>
                    </Grid>
                  )}
                </>
              )
            }
          </form >
        )
        }
        <AntdModal
          open={openModal}
          footer={null}
          onCancel={handleCloseModal}
        // onClose={handleCloseModal}
        // aria-labelledby="modal-modal-title"
        >
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {currentItem ? "Edit Item" : "Add Item"}
            </Typography>
            <form key={3} onSubmit={handleSubmit(onSubmit)}>
              {invoiceObj.map((column) => {
                return (
                  <>
                    {column.id === "modelAndSerial" && (
                      <>
                        <div className="searchInputs">
                          <FormControl className="form-width-100 space-marign-bottom-10">
                            <Controller
                              name={column.id}
                              control={control}
                              rules={{
                                required:
                                  currentItem &&
                                    Object.keys(currentItem).length > 0
                                    ? false
                                    : true,
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <Autocomplete
                                  disabled={currentItem}
                                  options={invoiceListData}
                                  getOptionDisabled={(option) => {
                                    return rows.some(
                                      (item) =>
                                        item.inventoryId === option.inventoryid
                                    );
                                  }}
                                  getOptionLabel={(option) => {
                                    const model = option?.model || "";
                                    const productTitle =
                                      option?.technical_details
                                        ?.product_title || "";
                                    const productName =
                                      option?.technical_details?.product_name ||
                                      "";
                                    const productLabel =
                                      model ||
                                      productTitle ||
                                      productName ||
                                      "---";
                                    const serialNo =
                                      option?.serialno ||
                                      option?.technical_details?.model_number ||
                                      "---";
                                    const inventoryId = option?.inventoryid || "---";
                                    return `${productLabel} | ${serialNo} | ${inventoryId}`;
                                  }}
                                  value={value || null}
                                  onChange={(event, newValue) => {
                                    onChange(newValue);
                                    handleSelection(newValue);
                                    if (newValue) {
                                      clearErrors(column.id);
                                    }
                                  }}
                                  filterOptions={(options, state) => {
                                    if (state.inputValue === "") {
                                      return options;
                                    }
                                    const filtered = options.filter(
                                      (option) =>
                                        option?.model
                                          ?.toLowerCase()
                                          .includes(
                                            state.inputValue.toLowerCase()
                                          ) ||
                                        option?.technical_details?.product_title
                                          ?.toLowerCase()
                                          .includes(
                                            state.inputValue.toLowerCase()
                                          ) ||
                                        option?.technical_details?.product_name
                                          ?.toLowerCase()
                                          .includes(
                                            state.inputValue.toLowerCase()
                                          ) ||
                                        option?.serialno
                                          ?.toLowerCase()
                                          .includes(
                                            state.inputValue.toLowerCase()
                                          ) ||
                                        option?.technical_details?.model_number
                                          ?.toLowerCase()
                                          .includes(
                                            state.inputValue.toLowerCase()
                                          ) ||
                                        String(option?.inventoryid)
                                          ?.toLowerCase()
                                          .includes(
                                            state.inputValue.toLowerCase()
                                          )
                                    );
                                    if (filtered.length === 0) {
                                      filtered.push({
                                        model:
                                          "Click here to create new Inventory item",
                                        isNew: true,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  renderOption={(option) => {
                                    if (option.isNew) {
                                      return (
                                        <ListItem
                                          onClick={() =>
                                            history.push("/create-inventory")
                                          }
                                          component="a"
                                        >
                                          Click here to create new Inventory item
                                        </ListItem>
                                      );
                                    }

                                    const model = option?.model || "";
                                    const productTitle =
                                      option?.technical_details
                                        ?.product_title || "";
                                    const productName =
                                      option?.technical_details?.product_name ||
                                      "";
                                    const productLabel =
                                      model ||
                                      productTitle ||
                                      productName ||
                                      "---";
                                    const serialNo =
                                      option?.serialno ||
                                      option?.technical_details?.model_number ||
                                      "---";
                                    const inventoryId = option?.inventoryid || "---";
                                    return `${productLabel} | ${serialNo} | ${inventoryId}`;
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Search Model | Serial No | Inventory ID"
                                      InputLabelProps={{
                                        className:
                                          "MuiFormControlLabel-root contact-add-field",
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {params.InputProps.endAdornment}
                                            <InputAdornment position="end">
                                              <SearchIcon />
                                            </InputAdornment>
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                        {errors[column.id] && (
                          <span style={{ color: "red" }}>{column.errMsg}</span>
                        )}
                      </>
                    )}
                    {column.id === "modelNumber" && (
                      <>
                        <div className="searchInputs">
                          <FormControl className="form-width-100">
                            {modelNo ? (
                              <InputLabel
                                htmlFor="model"
                                shrink={true}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            ) : (
                              <InputLabel
                                htmlFor="model"
                                shrink={false}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            )}

                            <AntdInput
                              readOnly
                              id="model"
                              type="text"
                              autoComplete="off"
                              ref={modelinputRef}
                              value={modelNo}
                              {...register(column.id, {
                                required: column.required
                                  ? column.errMsg
                                  : false,
                              })}
                              onChange={(e) => {
                                handleSearch(e, column.id);
                              }}
                              style={{
                                marginBottom: "0px",
                                marginTop: "10px",
                                fontSize: "16px",
                              }}
                              bordered={false}
                            />
                            <Divider style={{ marginTop: "5px" }} />
                          </FormControl>
                          {/* <div className="searchIcon">
                            {!modelNo ? (
                              <SearchIcon />
                            ) : (
                              <CloseIcon
                                id="clearBtn"
                                onClick={clearSearchInput}
                              />
                            )}
                          </div> */}
                          {model &&
                            model.length > 0 &&
                            searchInputmodelno.length > 0 && (
                              <div
                                style={{
                                  marginLeft: "-360px",
                                  marginTop: "50px",
                                }}
                              >
                                <AntdPopover
                                  id="model"
                                  visible={isPopoverVisiblemodelno}
                                  onVisibleChange={setIsPopoverVisiblemodelno}
                                  content={
                                    <div
                                      style={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      {model?.slice(0, 15).map((value) => (
                                        <Typography
                                          key={
                                            value.model && value.model !== null
                                              ? value.model
                                              : value.miscmodal
                                          }
                                          style={{ padding: 10, width: 200 }}
                                          onClick={() =>
                                            handleSelection(
                                              value,
                                              value.model &&
                                                value.model !== null
                                                ? "model"
                                                : "miscmodal"
                                            )
                                          }
                                        >
                                          {value.model && value.model !== null
                                            ? value.model
                                            : value?.technical_details
                                              ?.product_title
                                              ? value?.technical_details
                                                ?.product_title
                                              : value?.technical_details
                                                ?.product_name}
                                        </Typography>
                                      ))}
                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottom"
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      position: "absolute",
                                    }}
                                  />
                                </AntdPopover>
                              </div>
                            )}
                        </div>
                        {errors[column.id] && (
                          <span style={{ color: "red" }}>{column.errMsg}</span>
                        )}
                        {noData && currentSearch === "modelNumber" && (
                          <div className="dataResult">
                            <a
                              className="dataItem"
                              onClick={() => {
                                props.history.push("/create-inventory");
                              }}
                            >
                              Click here to create new Inventory item
                            </a>
                          </div>
                        )}
                      </>
                    )}
                    {column.id === "serialNumber" && (
                      <>
                        <div className="searchInputs">
                          <FormControl className="form-width-100">
                            {serial ? (
                              <InputLabel
                                htmlFor="model"
                                shrink={true}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            ) : (
                              <InputLabel
                                htmlFor="model"
                                shrink={false}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            )}

                            <AntdInput
                              readOnly
                              id="model"
                              type="text"
                              autoComplete="off"
                              value={serial}
                              ref={serialinputRef}
                              {...register(column.id, {
                                required:
                                  type !== "misc_product"
                                    ? column.required
                                      ? column.errMsg
                                      : false
                                    : false,
                              })}
                              onChange={(e) => {
                                handleSearch(e, column.id);
                              }}
                              style={{
                                marginBottom: "0px",
                                marginTop: "10px",
                                fontSize: "16px",
                              }}
                              bordered={false}
                            />
                            <Divider style={{ marginTop: "5px" }} />
                          </FormControl>
                          {/* <div className="searchIcon">
                            {!serial ? (
                              <SearchIcon />
                            ) : (
                              <CloseIcon
                                id="clearBtn"
                                onClick={clearSearchInput}
                              />
                            )}
                          </div> */}
                          {serialList &&
                            serialList.length > 0 &&
                            searchInputserialno.length > 0 && (
                              <div
                                style={{
                                  marginLeft: "-360px",
                                  marginTop: "50px",
                                }}
                              >
                                <AntdPopover
                                  id={column.id}
                                  visible={isPopoverVisibleserialno}
                                  onVisibleChange={setIsPopoverVisibleserialno}
                                  content={
                                    <div
                                      style={{
                                        height: "180px",
                                        overflow: "auto",
                                      }}
                                    >
                                      {serialList?.slice(0, 15).map((value) => {
                                        return (
                                          <Typography
                                            key={value}
                                            style={{ padding: 10, width: 200 }}
                                            onClick={() => {
                                              handleSelection(value, "serial");
                                            }}
                                          >
                                            {value["serialno"]}
                                          </Typography>
                                        );
                                      })}
                                    </div>
                                  }
                                  trigger="click"
                                  placement="bottom"
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      position: "absolute",
                                    }}
                                  />
                                </AntdPopover>
                              </div>
                            )}
                        </div>
                        {errors[column.id] ? (
                          <span style={{ color: "red" }}>{column.errMsg}</span>
                        ) : isSerialNumberRequired ? (
                          <span style={{ color: "red" }}>
                            {"Please Enter Serial No"}
                          </span>
                        ) : (
                          <></>
                        )}
                        {noData && currentSearch === "serialNumber" && (
                          <div className="dataResult">
                            <a
                              className="dataItem"
                              onClick={() => {
                                props.history.push("/create-inventory");
                              }}
                            >
                              Click here to create new Inventory item
                            </a>
                          </div>
                        )}
                      </>
                    )}
                    {column.id !== "action" &&
                      (column.type === "text" || column.type === "number") && (
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          {column.id === "quantity" ? (
                            <InputLabel
                              shrink={qtyShrink}
                              htmlFor="component-simple"
                              className="color-292C2F"
                            >
                              {column.label}
                            </InputLabel>
                          ) : column.id === "productName" ? (
                            <InputLabel
                              shrink={descShrink}
                              htmlFor="component-simple"
                              className="color-292C2F"
                            >
                              {column.label}
                            </InputLabel>
                          ) : (
                            <InputLabel
                              htmlFor="component-simple"
                              className="color-292C2F"
                            >
                              {column.label}
                            </InputLabel>
                          )}
                          <Input
                            id={column.id}
                            value={
                              column.id === "productName"
                                ? desc
                                : column.id === "quantity"
                                  ? quantity
                                  : 1
                            }
                            {...register(column.id, {
                              required: column.required ? column.errMsg : false,
                            })}
                            onChange={handleDescription}
                            inputProps={{
                              type: column.type,
                              autoComplete: "off",
                            }}
                            readOnly={column.id === "quantity" ? true : false}
                            disableUnderline={false}
                            disabled={
                              column.id === "productName" ? disabled : false
                            }
                          />
                          {errors[column.id] && (
                            <span style={{ color: "red" }}>
                              {column.errMsg}
                            </span>
                          )}
                        </FormControl>
                      )}
                    {(column.type === "select" || column.type === "amount") && (
                      <FormControl
                        className={`form-width-${column.minWidth} margin-t-b-20 margin-p-b-20`}
                        style={{
                          marginRight: column.type === "select" ? "16px" : 0,
                        }}
                      >
                        <InputLabel
                          htmlFor="component-simple"
                          className="color-292C2F"
                        >
                          {column.label}
                        </InputLabel>
                        {column.type === "select" ? (
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // defaultValue={"USD"}
                            value={currency}
                            onChange={(e) => {
                              setCurrency(e.target.value);
                            }}
                          >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="HK$">HK$</MenuItem>
                            <MenuItem value="Euro">Euro</MenuItem>
                            <MenuItem value="AED">AED</MenuItem>
                          </Select>
                        ) : (
                          <Input
                            id="component-simple"
                            {...register(column.id, { required: true })}
                            inputProps={{
                              type: column.type,
                              maxLength: "15",
                              autoComplete: "off",
                            }}
                            disableUnderline={false}
                            value={amount}
                            onChange={(e) => {
                              const val = e.target.value.replace(
                                /[^0-9\.]/g,
                                ""
                              );
                              setAmount(val);
                            }}
                          />
                        )}
                        {errors[column.id] && (
                          <span style={{ color: "red" }}>{column.errMsg}</span>
                        )}
                      </FormControl>
                    )}
                  </>
                );
              })}
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="text"
                  style={{
                    background: !noData ? "#000" : "#cccc",
                    color: "#fff",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                  disabled={noData}
                >
                  Submit
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  onClick={handleCloseModal}
                  style={{
                    background: "#fff",
                    color: "#000",
                    marginTop: "20px",
                    paddingTop: "5 !important",
                    paddingBottom: "5 !important",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Box>
        </AntdModal>
        {/* <>
        {openpricewarning && (
        <Pricewarining
          open={openpricewarning}
          handleClose={handleClosepriceModal}
        price={onSubmitone}
        />)}
        </> */}
      </Container >
      <FooterComponent />
      {
        contactListConfirmationModal && (
          <ContactListModal
            open={contactListConfirmationModal}
            close={handleCloseContactConfirmation}
            confirm={handleSuccessContactConfirmation}
            text={modalText}
          />
        )
      }
      {
        <InvoiceValidation
          open={saletaxOpen}
          handleClose={handleSalesTaxModalClose}
          handleNavigate={handleNavigate}
          handleFocus={handleFocus}
          errorCode={errorCode}
          errorMessage={errorMessage}
          handleCheckSalesID={handleSubmit1((data) => onCreateInvoiceSubmit(data, false, true))}
          handleCheckTotalAmount={handleSubmit1((data) => onCreateInvoiceSubmit(data, false, false))}
          reason={reason}
          setReason={setReason}
        />
      }
    </div >
  );
}

function mapStateToProps({
  auth,
  InvoiceDetails,
  ModelList,
  BillShipCntctLst,
}) {
  return {
    invoiceDetails: InvoiceDetails.invoiceDetails,
    currentUser: auth.currentUser,
    modelList: ModelList.modelList,
    billShipCntctLst: BillShipCntctLst.billShipCntctLst,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createInvoiceDetails,
      getModelSerialList,
      getNewModelSerialList,
      checkContactInDB,
      getBillShipContactList,
      getInvoiceNewContactList,
      getMemoList,
    },
    dispatch
  );
}

CreateInvoiceComponent.propTypes = {
  modelList: PropTypes.arrayOf(PropTypes.object).isRequired,
  getModelSerialList: PropTypes.func.isRequired,
  getNewModelSerialList: PropTypes.func.isRequired,
  getBillShipContactList: PropTypes.func.isRequired,
  getInvoiceNewContactList: PropTypes.func.isRequired,
  getMemoList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInvoiceComponent);
