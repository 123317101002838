import React, { Component } from "react";
import axios from "axios";
import constants from "./../../../../utils/constants";
import { getToken } from "./../../../../utils/Authentication";
const fileDownload = require("js-file-download");

class dashboardaapi extends Component {
    addinvetrysearchdataa(data, accountId, pagecontent = 100, pageNumber = 1) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "post",
            url:
                constants.BASE_URL +
                `inventories/v1/` +
                accountId +
                `/` +
                pagecontent +
                "/" +
                pageNumber,
            headers: headers,
            data: data,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getPublicFeedDealersDAta(data, accountName) {
        const headers = {
            "Content-Type": "application/json",
        };
        var config = {
            method: "post",
            url: constants.BASE_URL + `dealerfeeds/` + accountName,
            headers: headers,
            data: data,
        };
        return axios(config)
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                console.log(e);
                return e;
            });
    }
    productsold(data) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "patch",
            url: constants.BASE_URL + `inventories/sold-status`,
            headers: headers,
            data: data,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getinventryDetails(id) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `inventories/` + id,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getuser(userId) {
        return function (dispatch) {
            const headers = {
                Authorization: `Bearer ${getToken() || ""}`,
                "Content-Type": "application/json",
            };
            axios
                .get(constants.BASE_URL + `users/` + userId, { headers })
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.success === 0 &&
                        response.data.logout === true
                    ) {
                        localStorage.removeItem("token");
                        window.sessionStorage.removeItem("userDetails");
                        localStorage.removeItem("UserBusinessDetails");
                        window.location.href = "/login";
                    } else {
                        dispatch({
                            type: "FETCH_USERINFO_DATA_SUCCESS",
                            payload: response.data,
                        });
                    }
                })
                .catch((error) => { });
        };
    }
    getUserDetails(id) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `users/` + id,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getProductsDetails(id) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `products/` + id,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getAllCountryList() {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `countries/`,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getAllStateList(id) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `countries/` + id + "/states/",
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getinventrypriceList(id) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `products/price-list/` + id,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    productSeach(data, prodId, pagenumber, pagepercontent) {
        var seacrhString = prodId.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
        seacrhString = seacrhString.replaceAll("-", "");

        if (seacrhString === "") {
            seacrhString = null;
        }

        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "post",
            url:
                constants.BASE_URL +
                `products/search/` +
                seacrhString +
                `/` +
                pagepercontent +
                "/" +
                pagenumber,
            headers: headers,
            data: data,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getAllpackgesList() {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `packages/`,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }

    dashboardInventoryCount(payload) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "post",
            url: constants.BASE_URL + `dashboard`,
            headers: headers,
            data: payload,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }

    updateInventryDetails(data) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
        };
        var config = {
            method: "patch",
            url: constants.BASE_URL + `inventories/`,
            headers: headers,
            data: data,
        };
        return axios(config)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.success === 0 &&
                    response.data.logout === true
                ) {
                    localStorage.removeItem("token");
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = "/login";
                } else {
                    return response;
                }
            })
            .catch((e) => {
                return e;
            });
    }
    getallproducts(pagenumber, pagepercontent) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `products/` + pagepercontent + "/" + pagenumber,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getActivepackage() {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `packages/my/active/`,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    getAllProductsdetails(productId) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `products/` + productId,
            headers: headers,
        };
        return axios(config).then((response) => {
            if (
                response &&
                response.data &&
                response.data.success === 0 &&
                response.data.logout === true
            ) {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            } else {
                return response;
            }
        });
    }
    destructuringName(data) {
        if (data.sold == 0 && data.inventory_status == 5) {
            return "inventory - Available to Order.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 4) {
            return "inventory - On Hold/Reserved.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 3) {
            return "inventory - Out on Memo.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 2) {
            return "inventory - Incoming.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 1) {
            return "inventory - Inhand.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 6) {
            return "inventory - Needs Service / Polish.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 7) {
            return "inventory - At Service.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 8) {
            return "inventory - Need to Ship.xlsx";
        } else if (data.sold == 0 && data.inventory_status == 9) {
            return "inventory - Shipped waiting on payment.xlsx";
        } else if (data.sold == 0 && data.inventory_status == "") {
            return "inventory - In Stock.xlsx";
        } else if (data.sold == 1 && data.inventory_status == "") {
            return "inventory - Sold.xlsx";
        } else if (data.sold == "Void" && data.inventory_status == "") {
            return "inventory - Void.xlsx";
        } else if (data.sold == "ALL" && data.inventory_status == "") {
            return "inventory - Total.xlsx";
        }
    }
    downloadInventory(data1, userdId) {
        // const type = 'PDF';
        const type = data1.fileType || "PDF";
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            // 'Content-Type': 'blob'
        };
        data1["fileType"] = type;
        var config = {
            method: "post",
            url: constants.BASE_URL + `inventories/v1/download`,
            headers: headers,
            data: data1,
            responseType: type === "PDF" ? "blob" : "arraybuffer",
        };

        return axios(config).then((response) => {
            if (response && response.status === 204) {
                const data = {
                    status: 0,
                    message: "No Inventories Data",
                    data: [],
                };
                return data;
            } else if (
                response.status === 200 &&
                response.headers &&
                response.headers["content-disposition"] &&
                type === "EX"
            ) {
                const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                });
                fileDownload(blob, "inventories.xlsx");

                const data = {
                    status: 1,
                    message: "Downloaded Successfully",
                };
                return data;
            } else if (type === "PDF" && response.data) {
                const fileName = "inventories_" + new Date().getTime() + "_.pdf";

                const blob = new Blob([response.data]);
                const blob_url = URL.createObjectURL(blob);

                fileDownload(blob, fileName);
                const data = {
                    status: 1,
                    message: "Downloaded Successfully",
                    url: blob_url,
                };
                return data;
            } else {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            }
        });
    }

    downloadDashboardInventory(data1, userdId) {
        // const type = 'PDF';
        const type = data1.fileType || "PDF";
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            // 'Content-Type': 'blob'
        };
        data1["fileType"] = type;
        var config = {
            method: "post",
            url: constants.BASE_URL + `inventories/v1/download`,
            headers: headers,
            data: data1,
            responseType: type === "PDF" ? "blob" : "arraybuffer",
        };
        return axios(config).then((response) => {
            if (response && response.status === 204) {
                const data = {
                    status: 0,
                    message: "No Inventories Data",
                    data: [],
                };
                return data;
            } else if (
                response.status === 200 &&
                response.headers &&
                response.headers["content-disposition"] &&
                type === "EX"
            ) {
                const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                });
                fileDownload(blob, this.destructuringName(data1));
                const data = {
                    status: 1,
                    message: "Downloaded Successfully",
                };
                return data;
            } else if (type === "PDF" && response.data) {
                const fileName = "inventories_" + new Date().getTime() + "_.pdf";

                const blob = new Blob([response.data]);
                const blob_url = URL.createObjectURL(blob);

                fileDownload(blob, fileName);
                const data = {
                    status: 1,
                    message: "Downloaded Successfully",
                    url: blob_url,
                };
                return data;
            } else {
                localStorage.removeItem("token");
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = "/login";
            }
        });
    }

    getAllInvoiceList(accountId, callback) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "get",
            url: constants.BASE_URL + `invoices/v2/searchmodel/${accountId}`,
            headers: headers,
        };
        return axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    return callback(null, response.data);
                }
            })
            .catch((error) => {
                return callback(error, null);
            })
    }

    getInventoryContactDetails(data, callback) {
        const headers = {
            Authorization: `Bearer ${getToken() || ""}`,
            "Content-Type": "application/json",
        };
        var config = {
            method: "post",
            url: constants.BASE_URL + "contacts/contactlist",
            headers: headers,
            data: data,
        };
        axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    return callback(null, response.data);
                }
            })
            .catch((error) => {
                return callback(error, null);
            });
    }

    // getActivepackage(id) {
    //     const headers = {
    //         'Authorization': `Bearer ${getToken() || ''}`,
    //         'Content-Type': 'application/json'
    //     };
    //     var config = {
    //         method: 'get',
    //         url: constants.BASE_URL + 'packages/my/active/',
    //         headers: headers,
    //     };
    //     return axios(config)
    //         .then((response) => {
    //             if(response && response.data && response.data.success === 0 && response.data.logout === true ){
    //                 localStorage.removeItem('token');
    //                 window.sessionStorage.removeItem("userDetails");
    //                 localStorage.removeItem("UserBusinessDetails");
    //                 window.location.href='/login';
    //             }else{
    //                 return response;
    //             }
    //         })

    // }
}
const addinvetrysearchdataa = new dashboardaapi();
export default addinvetrysearchdataa;
