import axios from "axios";
import { getToken } from "../../../../utils/Authentication";
import constants from './../../../../utils/constants';
const fileDownload = require('js-file-download');

export async function getContactList(payload, pageLimit = 100, pageNumber = 1, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + `contacts/list/${pageLimit}/${pageNumber}`,
        headers: headers,
        data: payload
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function createContactList(payload, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'multipart/form-data'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + `contacts/create`,
        headers: headers,
        data: payload
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function downloadContact(data1, userdId) {
    // const type = 'PDF';
    const type = 'EXL';
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        // 'Content-Type': 'blob'
    };
    data1['fileType'] = type;
    var config = {
        method: 'post',
        url: constants.BASE_URL + `contacts/download`,
        headers: headers,
        data: data1,
        responseType: type === 'PDF' ? 'blob' : "arraybuffer"
    };

    return axios(config)
        .then((response) => {

            if (response && response.status === 204) {
                const data = {
                    status: 0,
                    message: "No Inventories Data",
                    data: []
                }
                return data
            } else if (response.status === 200 && response.headers && response.headers['content-disposition'] && type === 'EXL') {
                const blob = new Blob([response.data], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                })
                fileDownload(blob, 'contacts.xlsx')

                const data = {
                    status: 1,
                    message: "Downloaded Successfully",
                }
                return data

            } else if (type === 'PDF' && response.data) {

                const fileName = 'inventories_' + new Date().getTime() + '_.pdf'

                const blob = new Blob([response.data])
                const blob_url = URL.createObjectURL(blob);

                fileDownload(blob, fileName)
                const data = {
                    status: 1,
                    message: "Downloaded Successfully",
                    url: blob_url
                }
                return data
            }
            // } else {
            //     localStorage.removeItem('token');
            //     window.sessionStorage.removeItem("userDetails");
            //     localStorage.removeItem("UserBusinessDetails");
            //     window.location.href = '/login';
            // }
        })


}

export async function updateContactList(payload, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'multipart/form-data'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + `contacts/update`,
        headers: headers,
        data: payload
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function deleteContactList(payload, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + `contacts/removecontact`,
        headers: headers,
        data: payload
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function getContactDetail(payload, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'GET',
        url: constants.BASE_URL + `contacts/` + payload,
        headers: headers,
        data: payload
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};
