import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Grid, Popover, Button, InputLabel, FormControl, FormHelperText, Input, Typography, FormControlLabel, Checkbox, Modal, Box, Select, MenuItem, List, ListItem, ListItemIcon, ListItemText, TextField } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from '@mui/material/Divider';
import { toast } from "react-toastify";
import { createInvoiceDetails, getModelSerialList, getBillShipContactList, checkContactInDB, searchInventoryId } from '../actions/dashboardAction';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../style/style.css";
import Header from "../../../../common/components/Header";

import FooterComponent from "../../../../common/components/Footer";
import { useHistory } from "react-router-dom";
import imageNotAvailable from '../../../../assests/images/img-soon.png';
import { Popover as AntdPopover, Input as AntdInput, Modal as AntdModal } from 'antd';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import "../../../../common/style/SearchBar.css";
// import {formatPhoneNumber, unformatPhoneNumber} from "../../../../common/components/Helper/Utils";
import { SpinnerRoundFilled } from "spinners-react";
import getUserdetailsforsubscrption from '../../dashboard/actions/dashboardaapi';
import ContactListModal from './ContactListModal';
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@mui/material/IconButton";
import Pricewarining from "./Pricewarining";
import { property, set } from "lodash";
import { event } from "jquery";
import { isValid } from "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
const { innerWidth: width, innerHeight: height } = window;

const invoiceObj = [
  { id: "inventoryid", label: "Inventory ID", minWidth: 100, type: "search", required: true, errMsg: "Please Enter Inventory Id" },
  { id: "image", label: "Image", minWidth: 80, type: "image", required: false },
  { id: "serialNumber", label: "Serial No", minWidth: 80, type: "search", required: false, errMsg: "Please Enter Serial No" },
  { id: "product_name", label: "Product Name", minWidth: 140, type: "text", required: false },
  { id: "quantity", label: "Qty", minWidth: 80, type: "number", required: true, errMsg: "Please enter quantity" },
  { id: "currency", label: "Currency", minWidth: 30, type: "select", required: true, errMsg: "Please enter rate" },
  { id: "amount", label: "Amount", minWidth: 65, type: "amount", required: true, errMsg: "Please Enter Amount" },
  { id: "action", label: "Action", minWidth: 70, align: "center" },
];

const billingAddress = [
  { id: "billingToName", label: "Contact Name", minWidth: 80, type: "text_search", required: true, errMsg: "Please enter contact name" },
  { id: "billingToBusinessName", label: "Business Name", minWidth: 80, type: "text", required: true, errMsg: "" },
  { id: "billingToPhoneNo", label: "", minWidth: 80, type: "phone", required: true, errMsg: "" },
  { id: "billingToEmail", label: "Email", minWidth: 80, type: "email", required: true, errMsg: "Please enter valid email" },
  { id: "billingToAddressLine1", label: "Address Line1", minWidth: 80, type: "text", required: false, errMsg: "" },
  { id: "billingToAddressLine2", label: "Address Line2", minWidth: 80, type: "text", required: false, errMsg: "" },
  { id: "billingToCity", label: "City", minWidth: 80, type: "text", required: false, errMsg: "" },
  { id: "billingToCountry", label: "Country", minWidth: 80, type: "select", value: "United States", required: false, errMsg: "" },
  { id: "billingToState", label: "State", minWidth: 80, type: "select", required: false, errMsg: "" },
  { id: "billingToZip", label: "Zipcode", minWidth: 80, type: "number", required: false, errMsg: "" },
];

const shippingAddress = [
  { id: "shippingToName", label: "Contact Name", minWidth: 80, type: "text_search", required: true, errMsg: "Please enter contact name" },
  { id: "shippingToBusinessName", label: "Business Name", minWidth: 80, type: "text", required: true, errMsg: "" },
  { id: "shippingToPhoneNo", label: "", minWidth: 80, type: "phone", required: true, errMsg: "" },
  { id: "shippingToEmail", label: "Email", minWidth: 80, type: "email", required: true, errMsg: "Please enter valid email" },
  { id: "shippingToAddressLine1", label: "Address Line1", minWidth: 80, type: "text", required: false, errMsg: "" },
  { id: "shippingToAddressLine2", label: "Address Line2", minWidth: 80, type: "text", required: false, errMsg: "" },
  { id: "shippingToCity", label: "City", minWidth: 80, type: "text", required: false, errMsg: "" },
  { id: "shippingToCountry", label: "Country", minWidth: 80, type: "select", value: "United States", required: false, errMsg: "" },
  { id: "shippingToState", label: "State", minWidth: 80, type: "select", required: false, errMsg: "" },
  { id: "shippingToZip", label: "Zipcode", minWidth: 80, type: "number", required: false, errMsg: "" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: 600,
  overflowY: "auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CreateInvoiceMiscComponent(props) {
  const { register, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm();
  const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, getValues: getValues1, formState: { errors: errors1 }, watch: watch1, reset: reset1 } = useForm();
  const addressForm = watch1();
  const [rows, setRows] = useState([]);
  const [openModal, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isSameAddress, setSameAddress] = useState(false);
  const [amount, setAmount] = useState(null);
  const [shippingDate, setShippingDate] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [notes, setNotes] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [wireDetails, setWireDetails] = useState(true);
  const [invoiceId, setInvoiceId] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [tax, setTax] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const [paymentmethod, setPaymentMethod] = useState(null);
  const [qtyShrink, setQtyShrink] = useState(true);
  const [descShrink, setDescShrink] = useState(false);
  const [editIdx, setEditIdx] = useState(null);
  const [model, setModelList] = useState(props?.inventoryidList);
  const [serialList, setSerialList] = useState([]);
  const [searchObj, setSearchObj] = useState({});
  const [searchKey, setSearchKey] = useState(0);
  const [modelNo, setModel] = useState(null);
  const [serial, setSerial] = useState(null);
  const [image, setImage] = useState(null);
  const [desc, setDesc] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [inventoryid, setInventoryId] = useState(null);
  const [productid, setProductId] = useState(null);
  const [noData, setNoData] = useState(false);
  const [noBillData, setNoBillData] = useState(false);
  const [noShipData, setNoShipData] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [isSubmitInvoice, setIsSubmitInvoice] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorTarget, setAnchorTarget] = useState(null);
  const [loading, setLoading] = useState(false);
  const [billCntctNm, setBillCntctNm] = useState(null);
  const [shipCntctNm, setShipCntctNm] = useState(null);
  const [billCntctSearchTxt, setBillCntctSearchTxt] = useState(null);
  const [shipCntctSearchTxt, setShipCntctSearchTxt] = useState(null);
  const [isBillTextInput, setIsBillTextInput] = useState(false);
  const [isShipTextInput, setIsShipTextInput] = useState(false);
  const [billCntcts, setBillCntctLst] = useState([]);
  const [shipCntcts, setShipCntcts] = useState([]);
  const [countryListData, setCountryListData] = useState([]);
  const [statesListData, setStatesListData] = useState([]);
  const [shippingStatesListData, setShippingStatesListData] = useState([])
  const [contactListConfirmationModal, SetContactListConfirmationModal] = useState(false)
  const [finalInvoiceData, setFinalInvoiceData] = useState(null);
  const [shippingCountryCode, setShippingCountryCode] = useState("+1")
  const [billingCountryCode, setBillingCountryCode] = useState("+1")
  const [modalText, setModalText] = useState("This is not in your contacts, Do you want to save it?")
  const [UniqueCountryCode, setUniqueCountryCode] = useState([]);
  const [currency, setCurrency] = useState("USD");
  // const[openpricewarning,setopenpricewarning]=useState(false);
  const [onsubmitdata, setonsubmitdata] = useState('');
  const [purchaseprice, setpurchaseprice] = useState('')
  const [price, setprice] = useState(null)
  const [isPopoverVisiblebill, setIsPopoverVisiblebill] = useState(false);
  const [isPopoverVisibleship, setIsPopoverVisibleship] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchInputship, setSearchInputship] = useState('');
  const [isPopoverVisiblemodelno, setIsPopoverVisiblemodelno] = useState(false);
  const [isPopoverVisibleserialno, setIsPopoverVisibleserialno] = useState(false);
  const [searchInputmodelno, setSearchInputmodelno] = useState('');
  const [searchInputserialno, setSearchInputserialno] = useState('');
  const [paymentErrorText, setPaymentErrorText] = useState('');
  const [propsInventoryIdList, setPropsInventoryIdList] = useState([])
  const [inventoryIdFilters, setInventoryIdFilters] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [propsModelList, setPropsModelList] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)
  const open = Boolean(anchorEl);
  const userId = props?.currentUser?.userId ? props?.currentUser?.userId : null;
  const history = useHistory();
  const inputRef = useRef(null);
  const shipinputRef = useRef(null);
  const modelinputRef = useRef(null);
  const serialinputRef = useRef(null);



  useEffect(() => {
    if (props?.location?.state?.inventoryDetails) {
      console.log(props?.location?.state?.inventoryDetails, "inventory misc details")
      setInventoryId(props?.location?.state?.inventoryDetails?.inventoryid)
      setRows([{
        modelNumber: props?.location?.state?.inventoryDetails?.model,
        inventoryid: props?.location?.state?.inventoryDetails?.inventoryid,
        serialNumber: props?.location?.state?.inventoryDetails?.serialno,
        product_name: props?.location?.state?.inventoryDetails?.productName,
        image: props?.location?.state?.inventoryDetails?.image,
        inventoryId: props?.location?.state?.inventoryDetails?.inventoryid,
        productId: props?.location?.state?.inventoryDetails?.productid,
        quantity: props?.location?.state?.inventoryDetails?.quantity ? props?.location?.state?.inventoryDetails?.quantity : 1,
        amount: "",
        currency: currency
      }]);
      const currentRow = {
        amount: "",
        inventoryid: "",
        image: props?.location?.state?.inventoryDetails?.image,
        modelNumber: props?.location?.state?.inventoryDetails?.model,
        inventoryid: props?.location?.state?.inventoryDetails?.inventoryid,
        product_name: props?.location?.state?.inventoryDetails?.productName,
        serialNumber: props?.location?.state?.inventoryDetails?.serialno,
        inventoryId: props?.location?.state?.inventoryDetails?.inventoryid,
        productId: props?.location?.state?.inventoryDetails?.productid,
        quantity: props?.location?.state?.inventoryDetails?.quantity ? props?.location?.state?.inventoryDetails?.quantity : 1,
        from: 'inventory',
        currency: currency
      };
      setAnchorEl(null);
      setCurrentItem(currentRow);
      reset(currentRow);
      setEditIdx(0);
      setModel(props?.location?.state?.inventoryDetails?.inventoryid);
      setSerial(props?.location?.state?.inventoryDetails?.serialno);
      setProductId(props?.location?.state?.inventoryDetails?.productid);
      setprice(props?.location?.state?.inventoryDetails?.purchaseprice);
      setDesc(props?.location?.state?.inventoryDetails?.productName);
      setInventoryId(props?.location?.state?.inventoryDetails?.inventoryid);
      if (props?.location?.state?.inventoryDetails?.desc) {
        setDescShrink(true);
      }
      setModalOpen(!openModal);
    } else {
      setInventoryId(null)
    }
    getcountryList();
  }, []);


  const getcountryList = () => {
    getUserdetailsforsubscrption.getAllCountryList().then((res) => {
      setCountryListData(res.data.data);
      let countries = res.data.data;
      let countryCode = [];
      countries.filter(({ phonecode }) => countryCode.push(phonecode))
      const UniqueCountryCode = [...new Set(countryCode)];
      console.log(UniqueCountryCode)
      setUniqueCountryCode(UniqueCountryCode.sort())
    })
    // us States List 231
    getUserdetailsforsubscrption.getAllStateList(231).then((res) => {
      setStatesListData(res.data.data)
      setShippingStatesListData(res.data.data)
    })
  }

  const isTwoAddressSame = (input, dbData, type) => {
    console.log('isTwoAddressSame')
    console.log(input, dbData)
    let isChanged = false;
    if (type === "shipping") {
      if (
        ((dbData.businessname && dbData.businessname.toLowerCase()) !== (input.shippingToBusinessName && input.shippingToBusinessName.toLowerCase())) ||
        ((dbData.address1 && dbData.address1.toLowerCase()) !== (input.shippingToAddressLine1 && input.shippingToAddressLine1.toLowerCase())) ||
        ((dbData.address2 && dbData.address2.toLowerCase()) !== (input.shippingToAddressLine2 && input.shippingToAddressLine2.toLowerCase())) ||
        ((dbData.city && dbData.city.toLowerCase()) !== (input.shippingToCity && input.shippingToCity.toLowerCase())) ||
        ((dbData.states && dbData.states.toLowerCase()) !== (input.shippingToState && input.shippingToState.toLowerCase())) ||
        ((dbData.country && dbData.country.toLowerCase()) !== (input.shippingToCountry && input.shippingToCountry.toLowerCase())) ||
        ((dbData.zipcode && dbData.zipcode.toLowerCase()) !== (input.shippingToZip && input.shippingToZip.toLowerCase())) ||
        ((dbData.countrycode && dbData.countrycode.toLowerCase()) !== (shippingCountryCode && shippingCountryCode.toLowerCase()))
      ) {
        isChanged = true;
      }
      return isChanged;
    } else {
      if (
        ((dbData.businessname && dbData.businessname.toLowerCase()) !== (input.billingToBusinessName && input.billingToBusinessName.toLowerCase())) ||
        ((dbData.address1 && dbData.address1.toLowerCase()) !== (input.billingToAddressLine1 && input.billingToAddressLine1.toLowerCase())) ||
        ((dbData.address2 && dbData.address2.toLowerCase()) !== (input.billingToAddressLine2 && input.billingToAddressLine2.toLowerCase())) ||
        ((dbData.city && dbData.city.toLowerCase()) !== (input.billingToCity && input.billingToCity.toLowerCase())) ||
        ((dbData.states && dbData.states.toLowerCase()) !== (input.billingToState && input.billingToState.toLowerCase())) ||
        ((dbData.country && dbData.country.toLowerCase()) !== (input.billingToCountry && input.billingToCountry.toLowerCase())) ||
        ((dbData.zipcode && dbData.zipcode.toLowerCase()) !== (input.billingToZip && input.billingToZip.toLowerCase())) ||
        ((dbData.countrycode && dbData.countrycode.toLowerCase()) !== (billingCountryCode && billingCountryCode.toLowerCase()))

      ) {
        isChanged = true;
      }
      return isChanged;
    }
  };

  const onCreateInvoiceSubmit = (formData) => {
    console.log(formData, 'formData')
    setIsSubmitInvoice(true);
    if (!(formData?.billingToBusinessName || formData?.billingToName) && !(formData?.billingToBusinessName || formData?.billingToName)) {
      setIsSubmitInvoice(true);
      return false;
    }

    const data = {
      'userId': userId,
      'inventoryid': inventoryid,
      'billingToBusinessName': formData?.billingToBusinessName,
      'billingToName': formData?.billingToName,
      'billingToAddressLine1': formData?.billingToAddressLine1,
      'billingToAddressLine2': formData?.billingToAddressLine2,
      'billingToPhoneNo': formData?.billingToPhoneNo ? formData?.billingToPhoneNo : null,
      'billingToCountryCode': billingCountryCode,
      'billingToEmail': formData?.billingToEmail,
      'billingToCity': formData?.billingToCity,
      'billingToState': formData?.billingToState,
      'billingToCountry': formData?.billingToCountry,
      'billingToZip': formData?.billingToZip,
      'shippingToBusinessName': formData?.shippingToBusinessName,
      'shippingToName': formData?.shippingToName,
      'shippingToAddressLine1': formData?.shippingToAddressLine1,
      'shippingToAddressLine2': formData?.shippingToAddressLine2,
      'shippingToPhoneNo': formData?.shippingToPhoneNo ? (formData?.shippingToPhoneNo) : null,
      'shippingToCountryCode': shippingCountryCode,
      'shippingToEmail': formData?.shippingToEmail,
      'shippingToCity': formData?.shippingToCity,
      'shippingToState': formData?.shippingToState,
      'shippingToCountry': formData?.shippingToCountry,
      'shippingToZip': formData?.shippingToZip,
      'productDetails': JSON.stringify(rows),
      'shippingPrice': formData?.shippingPrice ? formatPrice(formData?.shippingPrice) : "0.00",
      'discount': formData?.discount ? formatPrice(formData?.discount) : "0.00",
      'tax': formData?.tax ? formatPrice(formData?.tax) : "0.00",
      'deposit': formData?.deposit ? formatPrice(formData?.deposit) : "0.00",
      'terms': formData?.terms,
      'paymentMethod': paymentmethod,
      'shippingMethod': formData?.shippingMethod,
      'shippingDate': shippingDate,
      'trackingNumber': formData?.trackingNumber,
      'notes': notes,
      'wireDetails': wireDetails,
      'isSameAddress': isSameAddress

    };

    let contactValidation = [
      {
        'userId': userId,
        'billingToBusinessName': formData?.billingToBusinessName,
        'billingToName': formData?.billingToName,
        'billingToAddressLine1': formData?.billingToAddressLine1,
        'billingToAddressLine2': formData?.billingToAddressLine2,
        'billingToPhoneNo': formData?.billingToPhoneNo ? (formData?.billingToPhoneNo) : null,
        'billingToCountryCode': billingCountryCode,
        'billingToEmail': formData?.billingToEmail,
        'billingToCity': formData?.billingToCity,
        'billingToState': formData?.billingToState,
        'billingToCountry': formData?.billingToCountry,
        'billingToZip': formData?.billingToZip,
        'type': 'billing'
      },
      {
        'userId': userId,
        'shippingToBusinessName': formData?.shippingToBusinessName,
        'shippingToName': formData?.shippingToName,
        'shippingToAddressLine1': formData?.shippingToAddressLine1,
        'shippingToAddressLine2': formData?.shippingToAddressLine2,
        'shippingToPhoneNo': formData?.shippingToPhoneNo ? (formData?.shippingToPhoneNo) : null,
        'shippingToCountryCode': shippingCountryCode,
        'shippingToEmail': formData?.shippingToEmail,
        'shippingToCity': formData?.shippingToCity,
        'shippingToState': formData?.shippingToState,
        'shippingToCountry': formData?.shippingToCountry,
        'shippingToZip': formData?.shippingToZip,
        'type': 'shipping'
      }

    ];
    console.log("checkContactInDB:checkContactInDB")
    props.checkContactInDB(contactValidation, (success, error) => {
      if (error) {
        console.log(error)
        setIsSubmitInvoice(false);
        toast("Invoice creation failed", {
          autoClose: 2000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        console.log(success, "success:success")
        const filterNewData = success.data.filter((item) => item.isPresent === false)
        const filterExistingData = success.data.filter((item) => item.isPresent === true)
        let isNeedConfirmationModal = false;
        filterExistingData.map((input) => {
          console.log(input)
          const isChanged = isTwoAddressSame(data, input.contactData ? input.contactData[0] : null, input.type);
          input.isChanged = isChanged
          if (isChanged) {
            isNeedConfirmationModal = true;
          }
        });
        console.log(filterNewData.length);
        console.log(filterExistingData.length)
        if (!isSameAddress) {
          console.log("STEP 1")
          if (filterNewData.length > 0 && filterExistingData.length <= 0) {
            console.log("STEP 1A")
            data['filterData'] = filterNewData;
            setModalText("This is not in your contacts, Do you want to save it?")
            setFinalInvoiceData(data);
            SetContactListConfirmationModal(!contactListConfirmationModal)
          }
          else if (filterExistingData.length > 0 && filterNewData.length <= 0 && isNeedConfirmationModal) {
            console.log("STEP 1B")
            data['filterData'] = filterExistingData;
            setModalText("Contact data is changed. Do you want to update it?")
            setFinalInvoiceData(data);
            SetContactListConfirmationModal(!contactListConfirmationModal)
          }
          else if (filterNewData.length > 0 && filterExistingData.length > 0) {
            console.log("STEP 1C")
            const isChanged = isTwoAddressSame(data, filterExistingData[0].contactData[0], filterExistingData[0].type);
            filterExistingData[0].isChanged = isChanged;
            data['filterData'] = filterNewData.concat(filterExistingData);
            setModalText("This is not in your contacts, Do you want to save it?");
            setFinalInvoiceData(data);
            SetContactListConfirmationModal(!contactListConfirmationModal)
          }
          else {
            console.log("STEP 1D")
            createNewInvoiceAPI(data);
          }
        }
        else if (isSameAddress) {
          console.log("STEP 2")
          let isChanged = false;
          if (filterExistingData && filterExistingData.length > 0) {
            isChanged = isTwoAddressSame(data, filterExistingData[0].contactData[0], filterExistingData[0].type);
          }

          if (filterNewData.length > 0) {
            console.log("STEP 2A")
            data['filterData'] = filterNewData;
            setModalText("This is not in your contacts, Do you want to save it?");
            setFinalInvoiceData(data);
            SetContactListConfirmationModal(!contactListConfirmationModal)
          }
          else if (filterExistingData.length > 0 && isChanged) {
            console.log("STEP 2B")
            filterExistingData[0].isChanged = true;
            filterExistingData[1].isChanged = true;
            data['filterData'] = filterExistingData;
            setModalText("Contact data is changed. Do you want to update it?")
            setFinalInvoiceData(data);
            SetContactListConfirmationModal(!contactListConfirmationModal)
          }
          else {
            console.log("STEP 2C")
            createNewInvoiceAPI(data)
          }
        }
        else {
          console.log("STEP 3")
          createNewInvoiceAPI(data)
        }
      }

    })
  };
  const handleCloseContactConfirmation = () => {
    setFinalInvoiceData(null);
    SetContactListConfirmationModal(!contactListConfirmationModal)
    createNewInvoiceAPI(finalInvoiceData)

  };

  const handleSuccessContactConfirmation = () => {
    const data = finalInvoiceData;
    const filterObject = data.filterData;
    setFinalInvoiceData(null);
    SetContactListConfirmationModal(!contactListConfirmationModal);
    filterObject.map(item => {
      console.log("handleSuccessContactConfirmation")
      console.log(item)
      if (!item.isPresent) {
        if (item.type === 'billing') {
          data['isBillNew'] = 1
        } else {
          data['isShipNew'] = 1
        }
      } else {
        if (item.isChanged && isSameAddress) {
          data['isBillChanged'] = 1
          data['isShipChanged'] = 1
        } else if (item.isChanged) {
          if (item.type === 'billing') {
            data['isBillChanged'] = 1
          } else {
            data['isShipChanged'] = 1
          }
        } else {
          data.isChanged = 0;
        }
      }

    })
    createNewInvoiceAPI(data)
  }
  useEffect(() => {
    if (invoiceId !== null) {
      localStorage.setItem("tabInvoiceDashboard", "yes");
      props.history.push({ pathname: '/view/invoice', state: { tab: "invoice", invoiceId } });
      console.log(invoiceId, "invoiceId");
      localStorage.removeItem("tabInvoiceDashboard", true);
      console.log(props.history, "hii history props");
    }
  }, [invoiceId, props.history]);

  const createNewInvoiceAPI = (data) => {
    console.log('createNewInvoiceAPI = (data')
    console.log(data, "dataa")
    if (data && data.filterData) delete data.filterData
    props.createInvoiceDetails(
      data,
      (success) => {
        console.log(success, "hii console success")
        console.log(success.data.id, "hii console data")
        if (success.status === 1) {
          setIsSubmitInvoice(false);
          toast("Invoice created successfully", {
            autoClose: 1000,
            type: success.status === 1 ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
          setLoading(true);
          setLoading(false);
          setTimeout(() => {
            setInvoiceId(success.data.id);
          }, 1000);

        }
        else {

          setIsSubmitInvoice(false);
          toast(success.message, {
            autoClose: 3000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      },
      (error) => {


        setIsSubmitInvoice(false);
        toast("Invoice creation failed", {
          autoClose: 2000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });


      }
    );
  }
  const formatPrice = (val) => {
    if (val.includes('.')) {
      if (val.split('.')[1].length >= 2) {
        const num = parseFloat(val);
        const cleanNum = num.toFixed(2);
        return cleanNum.toString();
      }
    }
    return `${val}.00`;
  }

  const handleAddRow = (invoiceObj) => {
    //   console.log(currentItem)
    if (currentItem) {
      let oldRows = rows;
      oldRows[editIdx] = invoiceObj;
      setRows(oldRows);
    } else {
      setRows((prevArray) => [...prevArray, invoiceObj]);
    }
  };

  const onSubmit = (data) => {


    console.log(purchaseprice, "searchpurchaseprice")
    console.log(price, "inventorydetails price")
    console.log(amount, "enter amount value")
    if ((price && (Number(price) < amount)) || !price) {
      console.log("onSubmit,onSubmit")
      console.log(data);
      setModalOpen(false);
      // setopenpricewarning(false);

    } else {

      toast("This sale is under cost", {
        autoClose: 2000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setModalOpen(false);
    }

    const amt = parseFloat(data.amount)?.toFixed(2);
    const rowData = data;
    rowData.amount = amt;
    rowData.inventoryId = inventoryid;
    rowData.productId = productid;
    rowData.currency = currency;
    rowData.purchaseprice = price;
    setSearchKey((prevKey) => prevKey + 1);
    if (rowData && rowData.from) {
      delete rowData.from
    }
    // rowData.inventoryid = inventoryid;
    // rowData.shippingDate = shippingDate;
    handleAddRow({ ...rowData, ...{ inventoryid: modelNo }, ...{ serialNumber: serial }, ...{ product_name: desc || getValues('product_name') } }); // , ...{dueDate}, ...{isInvoiced: invd}, ...{isShipped: shpd}
    setpurchaseprice(null);
    setprice(null);
    // else{
    //   setonsubmitdata(data)
    //   setopenpricewarning(true)
    // }

  };
  const onSubmitone = () => {



    console.log("onSubmitone")
    console.log(onsubmitdata);

    setModalOpen(false);
    // setopenpricewarning(false);

    const amt = parseFloat(onsubmitdata.amount)?.toFixed(2);
    const rowData = onsubmitdata;
    rowData.amount = amt;
    rowData.inventoryId = inventoryid;
    rowData.inventoryid = inventoryid;
    rowData.productId = productid;
    rowData.currency = currency;
    rowData.purchaseprice = price;
    if (rowData && rowData.from) {
      delete rowData.from
    }
    // rowData.inventoryid = inventoryid;
    // rowData.shippingDate = shippingDate;
    handleAddRow({ ...rowData, ...{ inventoryid: modelNo }, ...{ serialNumber: serial }, ...{ product_name: desc || getValues('product_name') } }); // , ...{dueDate}, ...{isInvoiced: invd}, ...{isShipped: shpd}
    setpurchaseprice(null);
    setprice(null);


  };


  const onChange = (e) => {
    setSameAddress(!isSameAddress);
  };

  useEffect(() => {
    console.log("getValues1()")
    console.log(getValues1())
    if (isSameAddress) {
      // setShipCntctNm(getValues1()['billingToName']);
      shippingAddress.map((item, i) => {
        console.log(item.id)
        console.log(billingAddress[i].id)
        console.log(getValues1()[billingAddress[i].id])
        console.log(":::::::::::::")
        setValue1(item.id, getValues1()[billingAddress[i].id]);
      })
      setValue1('shippingToName', billCntctNm)
      setShippingCountryCode(billingCountryCode)
      setShipCntctNm(billCntctNm);
      console.log(shippingAddress)
    } else {
      setNoShipData(false);
      setShipCntctSearchTxt(null);
      setShipCntctNm(null);
      shippingAddress.map((item, i) => {
        setValue1(item.id, "");
      })
    }
  }, [isSameAddress]);

  useEffect(() => {

    console.log(props.billShipCntctLst, "bilshipcontactlist")
    if (props.billShipCntctLst && props.billShipCntctLst?.length > 0) {
      // setAnchorEl(anchorTarget);    
      if (billCntctSearchTxt) {
        setBillCntctLst(props.billShipCntctLst);
        setShipCntcts(null)
        setIsPopoverVisiblebill(!false)
        setNoBillData(false);
      }
      if (shipCntctSearchTxt) {
        setShipCntcts(props.billShipCntctLst);
        setIsPopoverVisibleship(!false)
        setNoShipData(false);
      }
    } else if (props.billShipCntctLst?.length === 0) {
      if (billCntctSearchTxt) {
        setBillCntctLst([]);
        setNoBillData(true);
      }
      if (shipCntctSearchTxt) {
        setShipCntcts([]);
        setNoShipData(true);
      }
    }
  }, [props.billShipCntctLst]);





  useEffect(() => {
    const timer = setTimeout(() => {
      if (Object.keys(searchObj).length && searchObj?.searchVal?.length > 2) {
        console.log(propsInventoryIdList, "props inventory list");
        setSearchLoading(true)
        searchInventoryId(searchObj, (error, res) => {
          setSearchLoading(false)
          if (error) {
            console.log(error, res)
          }
          if (res && res.data) {
            console.log(res.data, "response for inventory id");
            setPropsInventoryIdList(res.data)

          }
        })
      }
      console.log(props, "innnnn");
    }, 500);
    return () => clearTimeout(timer);
  }, [searchObj]);


  useEffect(() => {
    if (propsInventoryIdList && propsInventoryIdList.length) {
      setAnchorEl(anchorTarget);
    } else {
      if (searchObj?.searchVal) {
        setNoData(true);
      }
    }
    console.log(searchObj);

    if (searchObj?.column === "inventoryid") {
      // setInventoryId(props.inventoryid)
      setModelList(propsInventoryIdList);
    }
  }, [propsInventoryIdList]);








  const handleSearch = (e, column) => {
    setNoData(false);
    console.log(e.target.value, " event target value")
    console.log(column, " event column")
    const text = e.target.value
    const string = text ? text.replace(/[&\/\\#,+!@^&()$~%.'":;*?<>{}]/g, '') : '';
    const modelNmTrim = e.target.value.trimStart()
    // setAnchorTarget(e.currentTarget);
    setCurrentSearch(column);
    if (column === 'inventoryid') {
      setModel(e.target.value)
      setSearchInputmodelno(modelNmTrim)
      console.log(searchInputmodelno, "value")

    } else {
      setSerial(e.target.value);
      setSearchInputserialno(modelNmTrim)
    }
    setSearchObj({ column, searchVal: e.target.value, searchText: string });
  }





  const handleAddItem = () => {
    setModalOpen(!openModal);
    setCurrentItem(null);
    // setDueDate(null);
    setShippingDate(null);
    setAmount(null);
    setInventoryId(null);
    setProductId(null);
    setCurrency("USD")
    setQtyShrink(true);
    setDescShrink(false);
    setEditIdx(null);
    setModel(null);
    setSerial(null);
    setDesc(null);
    setNoData(false);
    reset({
      searchText: "",
      inventoryid: "",
      serialNumber: "",
      image: "",
      product_name: "",
      quantity: 1,
      // rate:"",
      amount: "",
      inventoryid: "",
      productId: "",
      currency: "USD"
      // shippingDate:"",
      //   shippingMethod:"",
      //   trackingNumber:""
      // isShipped:"",
      // isInvoiced:"",
      // dueDate:""
    });
  }

  const handleClosepriceModal = () => {
    // setopenpricewarning(false);
  }


  const handleCloseModal = () => {
    setModalOpen(false);
    setModelList('');
    setModel('');
    setPropsInventoryIdList('');
    if (currentItem && currentItem.from) {
      handleRemoveSpecificRow(0);
    }
    setSearchKey((prevKey) => prevKey + 1);
  }

  const handleRemoveSpecificRow = (idx) => {
    setEditIdx(null);
    const temp = [...rows];
    temp.splice(idx, 1);
    if (temp.length === 0) {
      setShipping(null);
      setDeposit(null);
      setTax(null);
      setDeposit(null);
    }
    setRows(temp);
  };

  const onChangeOfCheckBox = (event) => {
    if (event.target.checked === true) {
      setWireDetails(true);
    } else {
      setWireDetails(false);
    }
  }





  const handleVisibleChange = (visible) => {
    setIsPopoverVisiblebill(visible);
  };
  const handleVisibleChangeship = (visible) => {
    setIsPopoverVisibleship(visible);
  };



  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (shipinputRef.current) {
      shipinputRef.current.focus();
    }
    if (modelinputRef.current) {
      modelinputRef.current.focus();
    }
    if (serialinputRef.current) {
      serialinputRef.current.focus();
    }

  }, []);

  useEffect(() => {
    // const timer = setTimeout(() => {
    console.log(billCntctSearchTxt?.length, "billcntctsearchtxt val......................")
    if (!isBillTextInput && billCntctSearchTxt && billCntctSearchTxt?.length > 2) {
      const search = { type: 'bill', searchVal: billCntctSearchTxt };
      props.getBillShipContactList(search, userId);
    }

    // }, 500);
    // return () => clearTimeout(timer);  
  }, [billCntctSearchTxt]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    if (!isShipTextInput && shipCntctSearchTxt && shipCntctSearchTxt?.length > 2) {
      const search = { type: 'ship', searchVal: shipCntctSearchTxt };
      props.getBillShipContactList(search, userId);
    }
    // }, 500);
    // return () => clearTimeout(timer);  
  }, [shipCntctSearchTxt]);


  const handleContactSearch = (e, column, type) => {

    // setAnchorTarget(e.currentTarget);
    const CntctNmTrim = e.target.value.trimStart()
    console.log(CntctNmTrim, "trim value...........")
    console.log(CntctNmTrim.length, "trim value length  .................")

    if (type === "bill" && column === "billingToName") {
      setBillCntctNm(CntctNmTrim);
      setSearchInput(CntctNmTrim.trim());
      setBillCntctSearchTxt(CntctNmTrim);
      setShipCntctSearchTxt(null);
      setValue1('billingToName', CntctNmTrim)
    } else if (type === "ship" && column === "shippingToName") {
      setShipCntctNm(CntctNmTrim);
      setSearchInputship(CntctNmTrim.trim());
      setShipCntctSearchTxt(CntctNmTrim);
      setBillCntctSearchTxt(null);
      setValue1('shippingToName', CntctNmTrim)
    }
    if (type === "bill" && isSameAddress) {
      const changeColum = column.split("billing")[1];
      setValue1(`shipping${changeColum}`, CntctNmTrim);
    }


  }

  const clearSearchInput = () => {
    setModel(null);
    setSerial(null);
    setDesc(null);
    setAnchorEl(null);
    setSearchInputmodelno("");
    setSearchInputserialno("");
    setAnchorTarget(null);
    setModelList([]);
    setSerialList([]);
    setCurrentItem(null);
    setCurrency("USD");
    setDescShrink(false);
    setNoData(false);
    setSearchObj((prevState) => ({ ...prevState, searchVal: '' }));
    reset({ inventoryid: null, serialNumber: null, product_name: null });
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (val, columnId) => {
    console.log("handleSelection");
    console.log(val, "handle val")
    setModel(val.inventoryid);
    setSerial(val.serialno);
    setpurchaseprice(val.purchaseprice);
    setprice(val.purchaseprice);
    console.log(price, "setprice");
    setDesc(val.technical_details.product_name);
    setQuantity(val.quantity);
    setImage(val?.imagepaths ||
      val?.imagepaths?.free_form ||
      val?.imagepaths?.front ||
      val?.imagepaths?.back ||
      val?.imagepaths?.left ||
      val?.imagepaths?.right ||
      val?.imagepaths?.buckle_opened ||
      val?.imagepaths?.buckle_closed ||
      val?.imagepaths?.wrist_shot || imageNotAvailable);
    setProductId(val?.productid);
    setAnchorEl(null);
    setAnchorTarget(null);
    setValue('inventoryid', val.inventoryid);
    setValue('serialNumber', val.serialno);
    setValue('product_name', val.technical_details.product_name);
    setValue("image",
      val?.imagepaths ||
      val?.imagepaths?.free_form ||
      val?.imagepaths?.front ||
      val?.imagepaths?.back ||
      val?.imagepaths?.left ||
      val?.imagepaths?.right ||
      val?.imagepaths?.buckle_opened ||
      val?.imagepaths?.buckle_closed ||
      val?.imagepaths?.wrist_shot || imageNotAvailable);
    setDescShrink(val.product_name ? true : false);
    setSearchObj((prevState) => ({ ...prevState, searchVal: '', searchText: "" }));
    setModelList([]);
    setSerialList([]);
    setSearchInputmodelno("");
    setSearchInputserialno("");
    setInventoryId(val.inventoryid);
  }

  const clearCntctSearchInput = (type) => {

    if (type === "bill") {
      setNoBillData(false);
      setBillCntctLst([]);
      setBillCntctSearchTxt(null);
      setSearchInput('')
      setBillCntctNm(null);
      if (isSameAddress) {
        setNoShipData(false);
        setShipCntctSearchTxt(null);
        setSearchInputship("")
        setShipCntctNm(null);
        reset1();
      } else {
        setValue1("billingToName", null);
        setValue1("billingToBusinessName", null);
        setValue1("billingToPhoneNo", null);
        setValue1("billingToEmail", null);
        setValue1("billingToAddressLine1", null);
        setValue1("billingToAddressLine2", null);
        setValue1("billingToCity", null,);
        setValue1("billingToState", null,);
        setValue1("billingToCountry", "United States");
        setValue1("billingToZip", null);
      }
    } else {
      setNoShipData(false);
      setShipCntcts([]);
      setSearchInputship("")
      setShipCntctSearchTxt(null);
      setShipCntctNm(null);
      setValue1("shippingToName", null);
      setValue1("shippingToBusinessName", null);
      setValue1("shippingToPhoneNo", null);
      setValue1("shippingToEmail", null);
      setValue1("shippingToAddressLine1", null);
      setValue1("shippingToAddressLine2", null);
      setValue1("shippingToCity", null);
      setValue1("shippingToState", null);
      setValue1("shippingToCountry", "United States");
      setValue1("shippingToZip", null);
      setSameAddress(false)
    }
  }

  //   const changeTextMode = (type)=>{
  //       if(type ==="bill") {
  //         setIsBillTextInput(!isBillTextInput);
  //         setNoBillData(false);
  //       } else {
  //         setIsShipTextInput(!isShipTextInput);
  //         setNoShipData(false);
  //       }
  //   }
  const onchangeDeposit = (e) => {
    const value = e.target.value.replace(/[^0-9\.]/g, "");
    setDeposit(value);
  };

  const paymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };




  const handleContactSelection = async (val, columnId) => {
    if (billCntctSearchTxt && columnId === 'bill') {
      setNoBillData(false);
      setBillCntctNm(val?.contactname);
      setBillCntctSearchTxt("");
      setBillCntctLst([]);
      setSearchInput("")
      setSearchInputship("")
      setBillingCountryCode(val?.countrycode)
      let phoneCode = '';
      if (val.country) {
        const selectedCountry = val.country;
        const filterCountry = countryListData.find((country) => country.name === selectedCountry);
        phoneCode = filterCountry ? filterCountry.phonecode : null;
        if (filterCountry && filterCountry.id) {
          console.log(filterCountry);
          getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
            setStatesListData(res.data.data)
            // setBillingCountryCode(filterCountry.phonecode)
          })
        }
      }

      let phoneNumber = val ? val.phone : null;
      if (phoneNumber && val.country) {
        // phoneNumber = unformatPhoneNumber(phoneNumber, val.country,phoneCode)
        phoneNumber = phoneNumber.replace(/\W+/g, '')
      }
      setValue1("billingToName", val?.contactname);
      setValue1("billingToBusinessName", val?.businessname);
      setValue1("billingToPhoneNo", phoneNumber);
      // setValue1("billingToPhoneNo", (val?.phone ? val?.phone.replace(/\W+/g, '') : null));
      setValue1("billingToEmail", val?.email);
      setValue1("billingToAddressLine1", val?.address1);
      setValue1("billingToAddressLine2", val?.address2);
      setValue1("billingToCity", val?.city);
      setValue1("billingToState", val?.states);
      setValue1("billingToCountry", val?.country);
      setValue1("billingToZip", val?.zipcode);
      if (isSameAddress) {
        shippingFormPatch(val);
      }
    } else {
      setNoShipData(false);
      setShipCntctSearchTxt("");
      setShipCntcts([]);
      shippingFormPatch(val);
    }
  }

  const shippingFormPatch = (val) => {
    setShipCntctNm(val?.contactname);
    setShippingCountryCode(val?.countrycode)
    let phoneCode = '';
    if (val.country) {
      const selectedCountry = val.country;
      const filterCountry = countryListData.find((country) => country.name === selectedCountry)
      phoneCode = filterCountry ? filterCountry.phonecode : null;
      if (filterCountry && filterCountry.id) {
        getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
          setShippingStatesListData(res.data.data)
          // setShippingCountryCode(filterCountry.phonecode)

        })
      }

    }
    let phoneNumber = val ? val.phone : null;
    // if(phoneNumber && val.country){
    //   // phoneNumber = unformatPhoneNumber(phoneNumber, val.country, phoneCode)
    // }
    phoneNumber = phoneNumber ? phoneNumber.replace(/\W+/g, '') : null;
    setValue1("shippingToName", val?.contactname);
    setValue1("shippingToBusinessName", val?.businessname);
    setValue1("shippingToPhoneNo", (phoneNumber));
    // setValue1("shippingToPhoneNo", (val?.phone ? val?.phone.replace(/\W+/g, '') : null));
    setValue1("shippingToEmail", val?.email);
    setValue1("shippingToAddressLine1", val?.address1);
    setValue1("shippingToAddressLine2", val?.address2);
    setValue1("shippingToCity", val?.city);
    setValue1("shippingToState", val?.states);
    setValue1("shippingToCountry", val?.country);
    setValue1("shippingToZip", val?.zipcode);
  }

  const onChangeCounry = (e, id, type = null) => {
    const selectedCountry = e.target.value;
    const filterCountry = countryListData.find((country) => country.name === selectedCountry)
    if (type) {
      handleContactSearch(e, id, "bill");
      setValue1(id, selectedCountry);
      if (filterCountry && filterCountry.id) {
        getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
          setStatesListData(res.data.data)
          // setBillingCountryCode(filterCountry.phonecode)
        })
      }

    }
    else {
      setValue1(id, selectedCountry);
      if (filterCountry && filterCountry.id) {
        getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
          setShippingStatesListData(res.data.data)
          // setShippingCountryCode(filterCountry.phonecode)
        })
      }

    }
  }
  const handleContactAddress = (val) => {
    let address = "";
    if (val?.contactname) {
      address += val?.contactname;
    }
    if (val?.businessname) {
      address += address ? ", " + val?.businessname : val?.businessname;
    }
    if (val?.address1) {
      address += address ? ", " + val?.address1 : val?.address1;
    }
    if (val?.address2) {
      address += address ? ", " + val?.address2 : val?.address2;
    }
    if (val?.city) {
      address += address ? ", " + val?.city : val?.city;
    }
    if (val?.states) {
      address += address ? ", " + val?.states : val?.states;
    }
    if (val?.country) {
      address += address ? ", " + val?.country : val?.country;
    }
    if (val?.zipcode) {
      address += address ? ", " + val?.zipcode : val?.zipcode;
    }
    return address;
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <Container maxWidth="md" style={{ backgroundColor: 'white', minHeight: (height - 114) + 'px' }}>
        <form key={1} onSubmit={handleSubmit1(onCreateInvoiceSubmit)}>
          <Grid container xs={12} sm={12}>
            <h1 style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon
                style={{
                  color: "action",
                  paddingRight: "15px",
                  fontSize: 40,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.goBack();
                }}
              >
                {" "}
              </ArrowBackIcon>
              Create an invoice
            </h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="text"
              style={{
                background: "#000",
                color: "#fff",
                marginTop: 20,
                marginBottom: 20,
              }}
              onClick={handleAddItem}
            >
              Add Item
            </Button>
          </Grid>
          <TableContainer
            sx={{ maxHeight: 440, marginTop: "20px", marginBottom: "20px" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {invoiceObj
                    .filter((item) => item.id !== "currency")
                    .map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length === 0 && (
                  <StyledTableRow>
                    <TableCell colspan={11} style={{ textAlign: "center" }}>
                      No record found
                    </TableCell>
                  </StyledTableRow>
                )}
                {rows?.map((row, idx) => {
                  return (
                    <>
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {invoiceObj
                          ?.filter((item) => item.id !== "currency")
                          .map((column) => {
                            return (
                              <>
                                {column.id !== "action" && (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {
                                      // (column.id === "isShipped" || column.id === "isInvoiced") ? (row[column.id] ? "Yes" : "No") :
                                      column.type === "image" ? (
                                        <img

                                          src={row[column.id]}
                                          width="60"
                                          height="60"
                                        />
                                      ) : column.id === "rate" ||
                                        column.id === "amount" ? (
                                        `${rows[idx].currency}${row[column.id]}`
                                      ) : column.id === "product_name" &&
                                        row[column.id]?.length > 50 ? (
                                        row[column.id]?.slice(0, 50) + "..."
                                      ) : (
                                        row[column.id]
                                      )
                                    }
                                  </TableCell>
                                )}
                                {column.id === "action" && (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ padding: 10 }}
                                  >
                                    <EditIcon
                                      style={{
                                        cursor: "pointer",
                                        width: "19px",
                                      }}
                                      onClick={() => {
                                        console.log(rows.at(idx), 'current item')
                                        setModalOpen(!openModal);
                                        setAnchorEl(null);
                                        setCurrentItem(rows.at(idx));
                                        setCurrency(rows[idx].currency)
                                        reset(rows.at(idx));
                                        setEditIdx(idx);
                                        setModel(rows[idx].inventoryid);
                                        setSerial(rows[idx].serialNumber);
                                        setDesc(rows[idx].product_name);
                                        setprice(rows[idx].purchaseprice)
                                        if (rows[idx].product_name) {
                                          setDescShrink(true);
                                        }
                                      }}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        width: "19px",
                                      }}
                                      onClick={() => {
                                        handleRemoveSpecificRow(idx);
                                      }}
                                    />
                                  </TableCell>
                                )}
                              </>
                            );
                          })}
                      </StyledTableRow>
                    </>
                  );
                })}
                {rows.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        SHIPPING
                      </TableCell>
                      <TableCell colspan={3}>
                        <input
                          type="text"
                          className="form-width-100"
                          {...register1("shippingPrice")}
                          placeholder="$0.00"
                          style={{ height: "30px" }}
                          value={shipping}
                          onChange={(e) =>
                            setShipping(e.target.value.replace(/[^0-9\.]/g, ""))
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        DISCOUNT
                      </TableCell>
                      <TableCell colspan={3}>
                        <input
                          type="text"
                          className="form-width-100"
                          {...register1("discount")}
                          placeholder="$0.00"
                          style={{ height: "30px" }}
                          value={discount}
                          onChange={(e) =>
                            setDiscount(e.target.value.replace(/[^0-9\.]/g, ""))
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        TAX
                      </TableCell>
                      <TableCell colspan={3}>
                        <input
                          className="form-width-100"
                          type="text"
                          {...register1("tax")}
                          placeholder="$0.00"
                          style={{ height: "30px" }}
                          value={tax}
                          onChange={(e) =>
                            setTax(e.target.value.replace(/[^0-9\.]/g, ""))
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        DEPOSIT
                      </TableCell>
                      <TableCell colspan={3}>
                        <input
                          type="text"
                          className="form-width-100"
                          {...register1("deposit")}
                          placeholder="$0.00"
                          style={{ height: "30px" }}
                          value={deposit}
                          // onChange={(e) =>
                          //   setDeposit(e.target.value.replace(/[^0-9\.]/g, ""))
                          // }
                          onChange={onchangeDeposit}
                        />
                      </TableCell>

                    </TableRow>

                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        PAYMENT METHOD
                      </TableCell>
                      <TableCell colspan={3}>
                        <Select


                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          fullWidth
                          {...register1("payment_method")}
                          onChange={paymentMethod}
                          inputProps={{
                            autoComplete: "off",
                          }}
                        >
                          <MenuItem value="Wire">Wire</MenuItem>
                          <MenuItem value="Check">Check</MenuItem>
                          <MenuItem value="Cash">Cash</MenuItem>
                          <MenuItem value="Credit Card">Credit Card</MenuItem>
                          <MenuItem value="Trade">Trade</MenuItem>
                          <MenuItem value="Zelle">Zelle</MenuItem>
                          <MenuItem value="Venmo">Venmo</MenuItem>
                          <MenuItem value="Paypal">Paypal</MenuItem>
                          <MenuItem value="Affirm">Affirm</MenuItem>
                          <MenuItem value="Scrap Gold">Scrap Gold</MenuItem>
                        </Select>
                        {parseFloat(deposit) > 0 && paymentmethod == null && (
                          <FormHelperText className="error-o">Please Select Payment Method</FormHelperText>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        PAYMENT TERM
                      </TableCell>
                      <TableCell colspan={3}>
                        <Select
                          {...register1("terms")}
                          defaultValue="Immediate"
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          fullWidth
                        >
                          <MenuItem value="Immediate">Immediate</MenuItem>
                          <MenuItem value="Net 7 Days">Net 7 Days</MenuItem>
                          <MenuItem value="Net 14 Days">Net 14 Days</MenuItem>
                          <MenuItem value="Net 21 Days">Net 21 Days</MenuItem>
                          <MenuItem value="Net 30 Days">Net 30 Days</MenuItem>
                          <MenuItem value="Net 60 Days">Net 60 Days</MenuItem>
                          <MenuItem value="Net 90 Days">Net 90 Days</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        SHIPPING DATE
                      </TableCell>
                      <TableCell colspan={3}>
                        <div>
                          <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                // className="color-BCBCBC"
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                // disableFuture={true}
                                value={shippingDate}
                                onChange={(date, e) => {
                                  setShippingDate(e);
                                }}
                                isClearable
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                placeholder="MM/DD/YYYY"
                                helperText={""}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        SHIPPING METHOD
                      </TableCell>
                      <TableCell colspan={3}>
                        <Select
                          {...register1("shippingMethod")}
                          onChange={(e) => setShippingMethod(e.target.value)}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          fullWidth
                        >
                          <MenuItem value="USPS">USPS</MenuItem>
                          <MenuItem value="Fedex">Fedex</MenuItem>
                          <MenuItem value="UPS">UPS</MenuItem>
                          <MenuItem value="Ferrary">Ferrary</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        TRACKING NUMBER
                      </TableCell>
                      <TableCell colspan={3}>
                        <input
                          className="form-width-100"
                          type="text"
                          {...register1("trackingNumber")}
                          placeholder="Tracking Number"
                          style={{ height: "30px" }}
                          value={trackingNumber}
                          onChange={(e) => setTrackingNumber(e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        NOTES
                      </TableCell>
                      <TableCell colspan={3}>
                        <input
                          className="form-width-100"
                          type="text"
                          {...register1("notes")}
                          placeholder="Notes"
                          style={{ height: "30px" }}
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);

                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colspan={4} style={{ textAlign: "right" }}>
                        Include Wire Details
                      </TableCell>
                      <TableCell colspan={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB1"
                              className="float-right"
                              checked={wireDetails}
                              value={wireDetails}
                              onChange={onChangeOfCheckBox}
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container style={{ marginBottom: "20px" }}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div>
                <div>
                  <Typography className="color-232323">
                    <Grid
                      container
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "42px",
                      }}
                    >
                      <Grid item xs={6} sm={6} md={6}>
                        <b>BILL TO</b>
                      </Grid>
                      {/* <Grid item xs={6} sm={6} md={6}>
                                { !isBillTextInput ?
                                   <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('bill')}}>Click to Add Contact</Button>
                                   :
                                   <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('bill')}}>Click to Search Contact</Button>
                                }
                            </Grid>
                            */}
                    </Grid>
                  </Typography>
                </div>
                {billingAddress.map((column) => (
                  <>
                    {column.type === "text_search" && (
                      <>
                        <div
                          className="searchInputs"
                          style={{ marginTop: "30px" }}
                        >
                          <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                            {billCntctNm ? (
                              <InputLabel
                                htmlFor="billCntctNm"
                                shrink={true}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            ) : (
                              <InputLabel
                                htmlFor="billCntctNm"
                                shrink={false}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            )}

                            <AntdInput
                              id="billCntctNm"
                              type="text"
                              autoComplete="off"
                              ref={inputRef}
                              value={billCntctNm}
                              {...register1(column.id, {
                                required: column.required ? column.errMsg : false,
                              })}
                              style={{ marginBottom: '0px', marginTop: "10px", fontSize: '16px' }}
                              onChange={(e) => {
                                handleContactSearch(e, column.id, 'bill');
                              }}
                              bordered={false}

                            />
                            {errors[column.id] && (
                              <span style={{ color: "red" }}>
                                {column.errMsg}
                              </span>

                            )}
                            <Divider style={{ marginTop: "5px" }} />
                            <div className="contactSearchIcon">
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                  {!isBillTextInput && (
                                    <>
                                      {!billCntctNm ? (
                                        <SearchIcon
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <CloseIcon
                                          id="clearBtn"
                                          onClick={() => {
                                            clearCntctSearchInput("bill");
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </FormControl>
                          {billCntcts && billCntcts.length > 0 && searchInput.length > 0 && (

                            <div style={{ marginLeft: "-70px", marginTop: "40px" }}>
                              <AntdPopover
                                id={column.id}
                                visible={isPopoverVisiblebill}
                                content={
                                  <div style={{ height: '200px', overflow: 'auto' }}>
                                    {billCntcts?.slice(0, 15).map((value, key) => (
                                      <Typography
                                        style={{ padding: 10, width: 300, cursor: 'pointer' }}
                                        onClick={() => {
                                          handleContactSelection(value, "bill");
                                        }}
                                      >
                                        {handleContactAddress(value)}
                                      </Typography>
                                    ))}
                                  </div>
                                }
                                trigger="click"
                                placement="bottomRight"
                                // placement="bottomLeft"
                                onVisibleChange={handleVisibleChange}
                              >
                                <div style={{ width: 0, height: 0, position: "absolute" }} />
                              </AntdPopover>


                            </div>
                          )}


                        </div>
                        {/* {noBillData && (
                          <div className="dataResult">
                            <a className="dataItem">No data found</a>
                          </div>
                        )} */}
                      </>
                    )}
                    {column.type !== "text_search" && (
                      <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                        {getValues1()[column.id] ? (
                          <InputLabel
                            shrink={true}
                            htmlFor="component-simple"
                            style={{ color: "#818080" }}
                          >
                            {column.label}
                          </InputLabel>
                        ) : (
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            {column.label}
                          </InputLabel>
                        )}
                        {column.type === "select" &&
                          column.id === "billingToState" && (
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={
                                getValues1()[column.id]
                                  ? getValues1()[column.id]
                                  : ""
                              }
                              {...register1(column.id)}
                              onChange={(e) => {
                                handleContactSearch(e, column.id, "bill");
                                setValue1(column.id, e.target.value);
                              }}
                            >
                              {statesListData &&
                                statesListData.map((State) => (
                                  <MenuItem key={State.id} value={State.name}>
                                    {State.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        {column.type === "select" &&
                          column.id === "billingToCountry" && (
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={
                                getValues1()[column.id]
                                  ? getValues1()[column.id]
                                  : "United States"
                              }
                              {...register1(column.id)}
                              onChange={(e) => {
                                onChangeCounry(e, column.id, "bill");
                              }}
                            >
                              {countryListData &&
                                countryListData.map((Country) => (
                                  <MenuItem
                                    key={Country.id}
                                    value={Country.name}
                                  >
                                    {Country.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        {column.type === "phone" && (
                          <>
                            <Grid container>
                              <Grid item xs={2}>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={
                                    billingCountryCode
                                      ? billingCountryCode
                                      : "+1"
                                  }
                                  onChange={(e) => {
                                    console.log(e.target);
                                    setBillingCountryCode(e.target.value);
                                    if (isSameAddress) {
                                      setShippingCountryCode(e.target.value)
                                    }
                                  }}
                                >
                                  {UniqueCountryCode &&
                                    UniqueCountryCode.map((Country) => (
                                      <MenuItem key={Country} value={Country}>
                                        {`${Country}`}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Grid>
                              <Grid item xs={10}>
                                <Input
                                  id="component-simple"
                                  style={{ marginBottom: "10px", width: '100%', marginLeft: '10px' }}
                                  {...register1(column.id, {
                                    required: "Please enter phone number",
                                    pattern: {
                                      value: /^(?!0+$)[0-9]{1,14}$/,
                                      message: "Invalid phone number",
                                    },
                                  })}
                                  placeholder="Enter your phone number"
                                  inputProps={{
                                    type: column.type,
                                    maxLength: 14,
                                    autoComplete: "off",
                                  }}
                                  onKeyPress={(event) => {
                                    if (!/^\d+$/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    handleContactSearch(e, column.id, "bill");
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </>

                        )}
                        {column.type === "email" && (
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            {...register1(column.id, {
                              required: "Please enter email",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Invalid email address",
                              },
                            })}
                            inputProps={{
                              type: column.type,
                              autoComplete: "off",
                            }}
                            onChange={(e) => {
                              handleContactSearch(e, column.id, "bill");
                            }}
                          />
                        )}
                        {column.type === "number" && (
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            {...register1(column.id, {
                              // required: "Please enter zipcode",
                            })}
                            inputProps={{
                              type: "text",
                              autoComplete: "off",
                            }}
                            onKeyPress={(event) => {
                              if (!/^[A-Za-z\d\-_\s]$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              handleContactSearch(e, column.id, "bill");
                            }}
                          />
                        )}
                        {column.type === "text" && (
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            {...register1(column.id, {
                              required: column.errMsg,
                            })}
                            inputProps={{
                              type: column.type,
                              autoComplete: "off",
                            }}
                            onChange={(e) => {
                              handleContactSearch(e, column.id, "bill");
                            }}
                          />
                        )}
                        {errors1[column.id] && errors1[column.id]?.message && (
                          <span style={{ color: "red" }}>
                            {errors1[column.id].message}
                          </span>
                        )}
                      </FormControl>
                    )}
                  </>
                ))}
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div>
                <div>
                  <Typography className="color-232323">
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6}>
                        <b>SHIP TO</b>
                      </Grid>
                      {/* <Grid item xs={6} sm={6} md={6}>
                            { !isShipTextInput ?
                                <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('ship')}}>Click to Add Contact</Button>
                                :
                                <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('ship')}}>Click to Search Contact</Button>
                        }
                        </Grid>   */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        justifyContent="flex-end"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB1"
                              className="float-right"
                              onChange={onChange}
                              checked={isSameAddress === true}
                            />
                          }
                          label="Same as bill address"
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </div>
                {shippingAddress.map((column, i) => (
                  <>
                    {column.type === "text_search" && (
                      <>
                        <div className="searchInputs">
                          <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                            {shipCntctNm ? (
                              <InputLabel
                                htmlFor="shipCntctNm"
                                shrink={true}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            ) : (
                              <InputLabel
                                htmlFor="shipCntctNm"
                                shrink={false}
                                style={{ color: "#424242" }}
                              >
                                {column.label}
                              </InputLabel>
                            )}

                            <AntdInput
                              id="shipCntctNm"
                              type="text"
                              autoComplete="off"
                              ref={shipinputRef}
                              value={shipCntctNm}
                              disabled={isSameAddress}
                              {...register1(column.id, {
                                required: column.required ? column.errMsg : false,
                              })}
                              style={{ marginBottom: '0px', marginTop: "10px", fontSize: '16px' }}
                              onChange={(e) => {
                                handleContactSearch(e, column.id, "ship");
                              }}
                              bordered={false}

                            />
                            {errors[column.id] && (
                              <span style={{ color: "red" }}>
                                {column.errMsg}
                              </span>
                            )}
                            <Divider style={{ marginTop: "5px" }} />
                            <div className="contactSearchIcon">
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6}>
                                  {!isShipTextInput && (
                                    <>
                                      {!shipCntctNm ? (
                                        <SearchIcon
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <CloseIcon
                                          id="clearBtn"
                                          onClick={() => {
                                            clearCntctSearchInput("ship");
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </FormControl>
                          {shipCntcts && shipCntcts.length > 0 && searchInputship.length > 0 && (

                            <div style={{ marginLeft: "-70px", marginTop: "40px" }}>
                              <AntdPopover
                                id={column.id}
                                visible={isPopoverVisibleship}
                                content={
                                  <div style={{ height: '200px', overflow: 'auto' }}>
                                    {shipCntcts?.slice(0, 15).map((value, key) => (
                                      <Typography
                                        style={{ padding: 10, width: 300, cursor: 'pointer' }}
                                        onClick={() => {
                                          handleContactSelection(value, "ship");
                                        }}
                                      >
                                        {handleContactAddress(value)}
                                      </Typography>
                                    ))}
                                  </div>
                                }
                                trigger="click"
                                placement="bottomRight"
                                onVisibleChange={handleVisibleChangeship}
                              >
                                <div style={{ width: 0, height: 0, position: "absolute" }} />
                              </AntdPopover>
                            </div>
                          )}
                        </div>
                        {/* {noShipData && (
                          <div className="dataResult">
                            <a className="dataItem">No data found</a>
                          </div>
                        )} */}
                      </>
                    )}
                    {column.type !== "text_search" && (
                      <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                        {getValues1()[column.id] ? (
                          <InputLabel
                            shrink={true}
                            htmlFor="component-simple"
                            style={{ color: "#818080" }}
                          >
                            {column.label}
                          </InputLabel>
                        ) : (
                          <InputLabel
                            htmlFor="component-simple"
                            style={{ color: "#424242" }}
                          >
                            {column.label}
                          </InputLabel>
                        )}
                        {column.type === "select" &&
                          column.id === "shippingToCountry" && (
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              // value={country}
                              value={
                                getValues1()[column.id]
                                  ? getValues1()[column.id]
                                  : "United States"
                              }
                              {...register1(column.id)}
                              onChange={(e) => {
                                setValue1(column.id, e.target.value);
                                onChangeCounry(e, column.id);
                              }}
                              disabled={isSameAddress}
                            >
                              {countryListData &&
                                countryListData.map((Country) => (
                                  <MenuItem
                                    key={Country.id}
                                    value={Country.name}
                                  >
                                    {Country.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        {column.type === "select" &&
                          column.id === "shippingToState" && (
                            <Select
                              labelId="demo-simple-select-outlined-label-1"
                              id="demo-simple-select-outlined-1"
                              // value={country}
                              value={
                                getValues1()[column.id]
                                  ? getValues1()[column.id]
                                  : ""
                              }
                              {...register1(column.id)}
                              onChange={(e) => {
                                setValue1(column.id, e.target.value);
                              }}
                              disabled={isSameAddress}
                            >
                              {!isSameAddress &&
                                shippingStatesListData &&
                                shippingStatesListData.map((state) => (
                                  <MenuItem key={state.id} value={state.name}>
                                    {state.name}
                                  </MenuItem>
                                ))}
                              {isSameAddress &&
                                statesListData &&
                                statesListData.map((state) => (
                                  <MenuItem key={state.id} value={state.name}>
                                    {state.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        {column.type === "phone" && (
                          <>
                            <Grid container>
                              <Grid item xs={2}>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={
                                    shippingCountryCode
                                      ? shippingCountryCode
                                      : "+1"
                                  }
                                  onChange={(e) => {
                                    console.log(e.target);
                                    setShippingCountryCode(e.target.value);
                                  }}
                                  disabled={isSameAddress}
                                >
                                  {UniqueCountryCode &&
                                    UniqueCountryCode.map((Country) => (
                                      <MenuItem key={Country} value={Country}>
                                        {`${Country}`}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Grid>
                              <Grid item xs={10}>
                                <>
                                  <Input
                                    id="component-simple"
                                    style={{ marginBottom: "10px", width: '100%', marginLeft: '10px' }}
                                    {...register1(column.id, {
                                      required: "Please enter phone number",
                                      pattern: {
                                        value: /^(?!0+$)[0-9]{1,14}$/,
                                        message: "Invalid phone number",
                                      },
                                    })}
                                    placeholder="Enter your phone number"
                                    inputProps={{
                                      type: column.type,
                                      maxLength: 14,
                                      minlength: 1,
                                      autoComplete: "off",
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/^\d+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    disabled={isSameAddress}
                                  />
                                </>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {column.type === "email" && (
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            {...register1(column.id, {
                              required: "Please enter email",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Invalid email address",
                              },
                            })}
                            inputProps={{
                              type: column.type,
                              autoComplete: "off",
                            }}
                            disabled={isSameAddress}
                          />
                        )}
                        {column.type === "number" && (
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            {...register1(column.id, {
                              // required: "Please enter zipcode",
                            })}
                            inputProps={{
                              type: "text",
                              autoComplete: "off",
                            }}
                            onKeyPress={(event) => {
                              if (!/^[A-Za-z\d\-_\s]$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={isSameAddress}
                          />
                        )}
                        {column.type === "text" && (
                          <Input
                            id="component-simple"
                            style={{ marginBottom: "10px" }}
                            {...register1(column.id, {
                              required: column.errMsg,
                            })}
                            inputProps={{
                              type: column.type,
                              autoComplete: "off",
                            }}
                            disabled={isSameAddress}
                          />
                        )}
                        {errors1[column.id] &&
                          errors1[column.id].message &&
                          !isSameAddress && (
                            <span style={{ color: "red" }}>
                              {errors1[column.id].message}
                            </span>
                          )}
                      </FormControl>
                    )}
                  </>
                ))}
              </div>
            </Grid>
          </Grid>
          {isSubmitInvoice && (
            <div class="d-spinner">
              <SpinnerRoundFilled
                size={51}
                thickness={125}
                speed={80}
                color="rgba(57, 107, 172, 1)"
                className="spp"
                enabled={isSubmitInvoice}
              />
            </div>
          )}
          {!isSubmitInvoice && rows.length > 0 && (
            <Grid
              container
              item
              xs={12}
              sm={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="submit"
                variant="text"
                style={{
                  background: "#000",
                  color: "#fff",
                  float: "right",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Create Invoice
              </Button>
            </Grid>
          )}
        </form>
        <AntdModal
          open={openModal}
          footer={null}

          onCancel={handleCloseModal}
        // onClose={handleCloseModal}
        // aria-labelledby="modal-modal-title"
        >
          <Box >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {currentItem ? "Edit Item" : "Add Item"}
            </Typography>
            <form key={3} onSubmit={handleSubmit(onSubmit)}>
              {invoiceObj.map((column) => {

                return (
                  <>
                    {
                      column.id === "inventoryid" && (
                        <div>
                          <Input
                            id="inventoryid"
                            key={searchKey}
                            inputProps={{ type: "text" }}
                            autoComplete="off"
                            value={modelNo}
                            onChange={(e) => {
                              handleSearch(e, "inventoryid");
                            }}
                            variant="outline"
                            placeholder="Inventory Id"
                            className="form-width-100 form-control searchoption boxwidth"
                            endAdornment={
                              searchObj?.searchVal?.length > 0 ? (
                                <InputAdornment position="end">
                                  {searchLoading ? (
                                    <CircularProgress />
                                  ) : (
                                    <IconButton
                                      onClick={() => {
                                        setAnchorEl(null);
                                        setPropsInventoryIdList([]);
                                        setModel("");
                                        setSearchLoading(false);
                                        setSearchObj((prevState) => ({
                                          ...prevState,
                                          searchVal: "",
                                          searchText: "",
                                          column: "",
                                        }));
                                      }}
                                      color="primary"
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ) : null
                            }
                          />
                          {searchLoading ? null : (
                            (searchObj?.searchVal?.length > 0 && searchObj?.column === "inventoryid" && propsInventoryIdList.length > 0) ?
                              <List className="searchlist MuiPaper-elevation1 inventrysearch" style={{ listStyle: "none" }}>
                                {propsInventoryIdList.map((data, idx) => (
                                  <div key={idx}>
                                    <ListItem
                                      onClick={() => handleSelection(data, "inventoryid")}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <ListItemIcon>
                                        <IconButton color="primary">
                                          <img
                                            width="70"
                                            height="60"
                                            src={
                                              data?.imagepaths
                                                ? (data?.imagepaths?.front || data?.imagepaths?.back || data?.imagepaths?.left || data?.imagepaths?.right || data?.imagepaths?.free_form || data?.imagepaths?.buckle_opened || data?.imagepaths?.buckle_closed || data?.imagepaths?.wrist_shot)
                                                : (data?.imagepaths?.images?.toString().split(",")[0]
                                                  ? data?.imagepaths?.images?.toString().split(",")[0]
                                                  : imageNotAvailable)
                                            }
                                            alt=""
                                          />
                                        </IconButton>
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={data.inventoryid + " " + data.technical_details.brand_name}
                                        secondary={data.serialno}
                                        style={{ marginTop: "25px", marginLeft: "80px" }}
                                      />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                  </div>
                                ))}
                              </List> : null)
                          }
                        </div>
                      )
                    }

                    {column.id === "serialNumber" && (
                      <>
                        <div className="searchInputs">
                          <FormControl className="form-width-100">
                            {serial ? (
                              <InputLabel
                                htmlFor="inventoryid"
                                shrink={true}
                                style={{ color: "#424242", marginTop: '10px' }}
                              >
                                {column.label}
                              </InputLabel>
                            ) : (
                              <InputLabel
                                htmlFor="inventoryid"
                                shrink={false}
                                style={{ color: "#424242", marginTop: '10px' }}
                              >
                                {column.label}
                              </InputLabel>
                            )}


                            <AntdInput
                              id="inventoryid"
                              type="text"
                              autoComplete="off"
                              value={serial}
                              ref={serialinputRef}
                              {...register(column.id, {
                                required: column.required ? column.errMsg : false,
                              })}
                              onChange={(e) => {
                                handleSearch(e, column.id);
                              }}
                              style={{ marginBottom: '0px', marginTop: "10px", fontSize: '16px', marginTop: '30px' }}
                              bordered={false}
                            />
                            {errors[column.id] && (
                              <span style={{ color: "red" }}>
                                {column.errMsg}
                              </span>
                            )}
                            <Divider style={{ marginTop: "5px" }} />

                          </FormControl>
                          <div className="searchIcon">
                            {!serial ? (
                              <SearchIcon style={{ marginTop: '15px' }} />
                            ) : (
                              <CloseIcon
                                id="clearBtn"
                                style={{ marginTop: '15px' }}
                                onClick={clearSearchInput}
                              />
                            )}
                          </div>

                          {serialList && serialList.length > 0 && searchInputserialno.length > 0 && (

                            <div style={{ marginLeft: "-360px", marginTop: "50px" }}>
                              <AntdPopover
                                id={column.id}
                                visible={isPopoverVisibleserialno}
                                onVisibleChange={setIsPopoverVisibleserialno}
                                content={
                                  <div style={{ height: "180px", overflow: "auto" }}>
                                    {serialList?.slice(0, 15).map((value) => {
                                      return (
                                        <Typography
                                          key={value}
                                          style={{ padding: 10, width: 200, marginTop: '50px' }}
                                          onClick={() => {
                                            handleSelection(value, "serial");
                                          }}
                                        >
                                          {value["serialno"]}
                                        </Typography>
                                      );
                                    })}
                                  </div>
                                }
                                trigger="click"
                                placement="bottom"


                              >
                                <div style={{ width: 0, height: 0, position: "absolute" }} />
                              </AntdPopover>
                            </div>
                          )}
                        </div>
                        {noData && currentSearch === "serialNumber" && (
                          <div className="dataResult">
                            <a
                              className="dataItem"
                              onClick={() => {
                                props.history.push("/create-inventory");
                              }}
                            >
                              Click here to create new Inventory item
                            </a>
                          </div>
                        )}
                      </>
                    )}

                    {column.id !== "action" &&

                      (column.type === "text" || column.type === "number") && (

                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          {column.id === "product_name" ? (
                            <>
                              <InputLabel
                                style={{ marginTop: '5px' }}
                                // shrink={qtyShrink}
                                htmlFor="component-simple"
                                className="color-292C2F"

                              >
                                {column.label}
                              </InputLabel>


                              <Input
                                style={{ marginTop: '25px' }}
                                value={desc ? desc : ""}
                                id="component-simple"
                                {...register(column.id, {
                                  required: column.required ? column.errMsg : false,
                                })}
                                inputProps={{
                                  type: column.type,
                                  autoComplete: "off",
                                }}

                              />


                              {errors[column.id] && (
                                <span style={{ color: "red" }}>
                                  {column.errMsg}
                                </span>
                              )}
                            </>
                          ) : null}

                          {column.id === "quantity" ? (
                            <>
                              <InputLabel
                                style={{ marginTop: '5px' }}
                                // shrink={qtyShrink}
                                htmlFor="component-simple"
                                className="color-292C2F"
                              >
                                {column.label}
                              </InputLabel>
                              <Input
                                id="component-simple"
                                style={{ marginTop: '25px' }}
                                value={quantity}
                                disabled
                                {...register(column.id, {
                                  required: column.required ? column.errMsg : false,
                                })}
                                inputProps={{
                                  type: column.type,
                                  autoComplete: "off",
                                }}

                              />
                              {errors[column.id] && (
                                <span style={{ color: "red" }}>
                                  {column.errMsg}
                                </span>
                              )}
                            </>

                          ) : null}

                        </FormControl>

                      )
                    }


                    {(column.type === "select" || column.type === "amount") && (
                      <FormControl
                        className={`form-width-${column.minWidth} margin-t-b-20 margin-p-b-20`}
                        style={{
                          marginRight: column.type === "select" ? "16px" : 0,
                        }}
                      >
                        <InputLabel
                          htmlFor="component-simple"
                          className="color-292C2F"
                          style={{ marginTop: '5px' }}
                        >
                          {column.label}
                        </InputLabel>
                        {column.type === "select" ? (
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // defaultValue={"USD"}
                            value={currency}
                            onChange={(e) => {
                              console.log(e.target);
                              setCurrency(e.target.value)
                            }}
                          >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="HK$">HK$</MenuItem>
                            <MenuItem value="Euro">Euro</MenuItem>
                            <MenuItem value="AED">AED</MenuItem>
                          </Select>
                        ) : (
                          <Input
                            id="component-simple"
                            {...register(column.id, { required: true })}
                            inputProps={{
                              type: "text",
                              maxLength: "15",
                              autoComplete: "off",
                            }}
                            disableUnderline={false}
                            value={amount}
                            onChange={(e) => {
                              const val = e.target.value.replace(
                                /[^0-9\.]/g,
                                ""
                              );
                              setAmount(val);
                            }}
                          />
                        )}
                        {errors[column.id] && (
                          <span style={{ color: "red" }}>{column.errMsg}</span>
                        )}
                      </FormControl>
                    )}
                  </>
                );
              })}
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="text"
                  style={{
                    background: !noData ? "#000" : "#cccc",
                    color: "#fff",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                  disabled={noData}
                >
                  Submit
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  onClick={handleCloseModal}
                  style={{
                    background: "#fff",
                    color: "#000",
                    marginTop: "20px",
                    paddingTop: "5 !important",
                    paddingBottom: "5 !important",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>


          </Box>
        </AntdModal>



        {/* <>
        {openpricewarning && (
        <Pricewarining
          open={openpricewarning}
          handleClose={handleClosepriceModal}
        price={onSubmitone}
        />)}
        </> */}

      </Container>
      <FooterComponent />
      {contactListConfirmationModal && (
        <ContactListModal
          open={contactListConfirmationModal}
          close={handleCloseContactConfirmation}
          confirm={handleSuccessContactConfirmation}
          text={modalText}
        />
      )}
    </div>
  );
}

function mapStateToProps({ auth, InvoiceDetails, ModelList, BillShipCntctLst, InventoryidList }) {
  return {

    invoiceDetails: InvoiceDetails.invoiceDetails,
    currentUser: auth.currentUser,
    modelList: ModelList.modelList,
    inventoryidList: InventoryidList.inventoryidList,
    billShipCntctLst: BillShipCntctLst.billShipCntctLst
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createInvoiceDetails,
    getModelSerialList,
    checkContactInDB,
    getBillShipContactList,
    searchInventoryId
  }, dispatch)
}

CreateInvoiceMiscComponent.propTypes = {
  modelList: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventoryidList: PropTypes.arrayOf(PropTypes.object).isRequired,
  getModelSerialList: PropTypes.func.isRequired,
  getBillShipContactList: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoiceMiscComponent);