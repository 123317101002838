import React from 'react';
import alt from "../../../../assests/images/img-soon.png";
import { Grid, CardMedia, Card, CardContent, } from '@material-ui/core';
import moment from 'moment';
export default function CombinedDealer({ item, handleNextPage, type, page }) {

    const { accountid, businessname, conditions, highest_price,
        imagepaths, model_no, inventory_date, lowest_price, quantity } = item;
    const onImagesShow = (row) => { };

    const imageDestructure = (imagepaths) => {
        if (type == 'misc_watch' || type == 'misc_product' || (type == 'standard_watch' && page == 2)) {
            const image = imagepaths ? (imagepaths?.front ? imagepaths?.front : JSON.parse(imagepaths)) : null
            return image ? image : alt;
        }
        else {
            const parseValue = imagepaths ? JSON.parse(imagepaths) : null
            const imageurl = parseValue?.images ? parseValue.images[0].split(
                ","
            )[0]
                : alt
            return imageurl;
        }
    }

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className="dealer-container"
            key={accountid}
        >
            <Card className="alignment-2 dealerbox" style={{ borderRadius: '10px !important', padding: 10 }} onClick={() => handleNextPage(item, inventory_date)}>
                <CardMedia
                    onClick={() => {
                    }}
                    style={{ margin: '0 auto', width: '80%' }}
                    component="img"
                    alt={alt}
                    height="170"
                    onLoad={() => onImagesShow(
                        imagepaths
                    )}
                    src={imageDestructure(imagepaths)}
                />
                <br />
                <div
                    style={{
                        border: "0.5px solid #9F7C50",
                        margin: "0 auto",
                        width: "85%",
                    }}
                ></div>
                <CardContent
                    style={{ paddingLeft: '20px' }}
                    onClick={() => { }}
                >
                    {type == 'standard_watch' && page == 1 ?
                        <Grid container
                            className="textSecondary color-292C2F"
                            style={{ padding: '2px' }}

                        >
                            <Grid item md={5} >Model No</Grid>
                            <Grid item md={7} style={{ wordBreak: "break-word" }}>: {model_no || '--'} </Grid>
                        </Grid> :
                        <>
                            <Grid container
                                className="textSecondary color-292C2F"
                                style={{ padding: '2px' }}

                            >
                                <Grid item md={5} >Dealer Name</Grid>
                                <Grid item md={7} style={{ wordBreak: "break-word" }}>: {businessname || '--'} </Grid>
                            </Grid>
                            <Grid container
                                className="textSecondary color-292C2F"
                                style={{ padding: '2px' }}

                            >
                                <Grid item md={5} >Condition</Grid>
                                <Grid item md={7} >: <span style={{ color: (conditions == '4' || conditions == 'New') ? 'green' : 'red', fontWeight: 'bold' }}>
                                    {(conditions == '4' || conditions == 'New') ? 'New' : "Pre-owned" ?? "--"}</span></Grid>
                            </Grid>
                        </>
                    }
                    <Grid container
                        className="textSecondary color-292C2F"
                        style={{ padding: '2px' }}
                    >
                        <Grid item md={5} style={{ fontWeight: 'bold' }}>Quantity</Grid>
                        <Grid item mx={7} style={{ fontWeight: 'bold' }}>: {quantity
                            ? quantity
                            : "--"}</Grid>
                    </Grid>
                    <Grid container
                        className="textSecondary color-292C2F"
                        style={{ padding: '2px' }}
                    >
                        <Grid item md={5} style={{ fontWeight: 'bold' }}>Lowest Price</Grid>
                        <Grid item mx={7} style={{ fontWeight: 'bold' }}>:
                            {(!Number(lowest_price) && !Number(highest_price))
                                ? " --"
                                : ` $${lowest_price || highest_price}`}
                        </Grid>
                    </Grid>
                    <Grid container
                        className="textSecondary color-292C2F"
                        style={{ padding: '2px' }}
                    >
                        <Grid item md={5} > Highest Price</Grid>
                        <Grid item mx={7}>: {!Number(highest_price) ? " --" : ` $${highest_price}`}</Grid>
                    </Grid>
                    {/* <Grid container
                        className="textSecondary color-292C2F"
                        style={{ padding: '2px' }}
                    >
                        <Grid item md={5} > Inventory Date</Grid>
                        <Grid item md={7}>: {inventory_date
                            ? moment(inventory_date).format('MM/DD/YYYY')
                            : "--"}</Grid>
                    </Grid> */}
                </CardContent>
            </Card>
        </Grid>
    )
}