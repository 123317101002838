import axios from 'axios';
import constants from './../../../../utils/constants';
import { getToken } from "./../../../../utils/Authentication";
import { MonthlyGraph, WeeklyGraph, dailyGraph } from "./../adapters/adapters";
import { toast } from "react-toastify";
import request from '../../../../common/components/Helper/Request';
import { error } from 'jquery';
const fileDownload = require('js-file-download');

export function statesList(id) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `countries/` + id + `/states/`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_STATESLIST_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
}

export function dialColor() {

    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `products/dial_colors`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_COLOR_DIAL_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => {
            })
    });
};

export function inventoryDetailById(id) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `inventories/` + id, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_INVENTORY_DETAIL',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};

//Multiple user
export function getaccountdetails(data, accountId) {

    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        var config = {
            method: 'post',
            url: constants.BASE_URL + `users/accountdetails`,
            headers: headers,
            data: data
        };
        axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_ACCOUNTINFO_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};

//Multiple user
export function CreateBusiness(payload, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    var config = {
        method: 'post',
        url: constants.BASE_URL + `users/createbusiness`,
        headers: headers,
        data: payload
    };
    axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                toast(response.data.message, {
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                return callback(null, response.data);
            }
        }).catch((error) => {
            return callback(error, null);
        })
};

//Multiple user
export function getAccountUserId(userId, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    var config = {
        method: 'get',
        url: constants.BASE_URL + `users/account/${userId}`,
        headers: headers,
    };
    axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data);
            }
        }).catch((error) => {
            return callback(error, null);
        })
};
export function filterBySearch(data) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        var config = {
            method: 'post',
            url: constants.BASE_URL + 'products/filter',
            headers: headers,
            data: data
        };
        axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};

export function filterByInvoicePrice(data) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        var config = {
            method: 'post',
            url: constants.BASE_URL + 'dashboard/invoiceprice',
            headers: headers,
            data: data
        };
        axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_INVOICE_PRICE_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};
export function filterBydboardAccordion(data) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        var config = {
            method: 'post',
            url: constants.BASE_URL + 'invoices/v1/invoicelist/dashboard',
            headers: headers,
            data: data
        };
        axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_DASHBOARD_ACCORDION_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};
export function downloadcharts(data) {
    const headers = {
        Authorization: `Bearer ${getToken() || ""}`,
        // "Content-Type": "application/json",
    };
    var config = {
        method: "post",
        url: constants.BASE_URL + "dashboard/download/dashboarddata",
        headers: headers,
        data: data,
        responseType: 'blob',

    };
    return axios(config)
        .then((response) => {
            if (response && response.success === 0 && response.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                // return callback(null, response.data);
                const blob = new Blob([response.data]
                    , {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                    })
                fileDownload(blob, 'dashboard.xlsx')
                const data = {
                    status: 1,
                    message: "Downloaded Successfully",

                }
                return data
            }
        })
        .catch((error) => {
            const data = {
                status: 0,
                message: "Downloaded Failed",
            }
            return data
        });
}
export function brandList() {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `brands/`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_BRAND_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};

export function DealerList(businessName) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `dealerfeeds/dealer/list`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_DEALER_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};

export function SearchInventoryWatches(data, accountId) {

    return (function (response) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        var config = {
            method: 'post',
            url: constants.BASE_URL + `inventories/v1` + accountId + `/` + constants.DASHBOARD_DEALER_LIMIT + '/' + constants.PAGINATION_START,
            headers: headers,
            data: data
        };
        axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    return response
                }

            }).catch((error) => { })
    });
};


export function fetchInventoryExcelList(userId) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        const data = {
            userId: userId
        }
        axios
            .post(constants.BASE_URL + `inventories/inventories-xl`, data, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_INVENTORY_XL_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
};

export function fetchInventoryWatches(accountId, data, pagecontent, pagenumber) {
    const pageContent = pagecontent ? pagecontent : constants.DASHBOARD_DEALER_LIMIT;
    const pageNumber = pagenumber ? pagenumber : constants.PAGINATION_START;
    return (function (dispatch) {
        // const headers = {
        //     'Authorization': `Bearer ${getToken() || ''}`,
        //     'Content-Type': 'application/json'
        // };
        // axios
        //     .post(constants.BASE_URL + `inventories/` + userdId + `/` + constants.DASHBOARD_DEALER_LIMIT + '/' + constants.PAGINATION_START, { headers })
        //     .then((response) => {
        //         dispatch({
        //             type: 'FETCH_INVENTORY_WATCHES_DATA_SUCCESS',
        //             payload: response.data
        //         });
        //     }).catch((error) => {})

        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        let config = {
            method: 'post',
            url: constants.BASE_URL + `inventories/v1/` + accountId + `/` + pageContent + '/' + pageNumber,
            headers: headers,
            // data: data
        };
        if (data) {
            config.data = data;
        }
        return axios(config)
            .then((response) => {

                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_INVENTORY_WATCHES_DATA_SUCCESS',
                        payload: response.data
                    });
                }

                // return response;
            })

    });
};

export function fetchDealerWatches(data) {
    return (function (dispatch) {


        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        let config = {
            method: 'post',
            url: constants.BASE_URL + 'dealerinventory/list',
            headers: headers,
            data: data
        };
        if (data) {
            config.data = data;
        }
        return axios(config)
            .then((response) => {

                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    return response;
                }
            })

    });
};

export function monthlyList() {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `searches/monthly/1`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    let data = null;
                    if (response.data.data == '') {
                        response['data'] = [{
                            "month": "January",
                            "month_no": 1,
                            "search_count": "0"
                        },
                        {
                            "month": "February",
                            "month_no": 2,
                            "search_count": "0"
                        },
                        {
                            "month": "March",
                            "month_no": 3,
                            "search_count": "0"
                        }
                        ];
                        data = new MonthlyGraph().listAdapt(response.data);
                    } else {
                        data = new MonthlyGraph().listAdapt(response.data.data);
                    }
                    dispatch({
                        type: 'FETCH_MONTHLY_DATA_SUCCESS',
                        payload: data
                    });
                }

            }).catch((error) => { })
    });
};

export function weeklyList() {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `searches/weekly/1`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    let data = null;
                    if (response.data.data == '') {
                        response['data'] = [{
                            "week": "week1",
                            "week_no": 1,
                            "search_count": "0"
                        },
                        {
                            "week": "week2",
                            "week_no": 2,
                            "search_count": "0"
                        },
                        {
                            "week": "week3",
                            "week_no": 3,
                            "search_count": "0"
                        }
                        ];
                        data = new WeeklyGraph().listAdapt(response.data);
                    } else {
                        data = new WeeklyGraph().listAdapt(response.data.data);
                    }
                    dispatch({
                        type: 'FETCH_WEEKLY_DATA_SUCCESS',
                        payload: data
                    });
                }
            }).catch((error) => { })
    });
};

export function dailyList() {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `searches/daily/1`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {

                    let data = null;
                    if (response.data.data == '') {
                        response['data'] = [{
                            "week": "Mar 22",
                            "week_no": 1,
                            "search_count": "0"
                        },
                        {
                            "week": "Mar 23",
                            "week_no": 2,
                            "search_count": "0"
                        },
                        {
                            "week": "Mar 24",
                            "week_no": 3,
                            "search_count": "0"
                        }
                        ];
                        data = new WeeklyGraph().listAdapt(response.data);
                    } else {
                        data = new dailyGraph().listAdapt(response.data.data);
                    }
                    dispatch({
                        type: 'FETCH_DAILY_DATA_SUCCESS',
                        payload: data
                    });
                }
            }).catch((error) => { })
    });
};

export function inventoryCount() {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `searches/daily/2021-03-04`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_INVENTORY_COUNT_DATA_SUCCESS',
                        payload: response.data
                    });
                }
            }).catch((error) => { })
    });
};

export function countriesList() {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `countries/`, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_COUNTRYLIST_DATA_SUCCESS',
                        payload: response.data
                    });
                }

            }).catch((error) => { })
    });
}

export function getuser(userId) {
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `users/` + userId, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: 'FETCH_USERINFO_DATA_SUCCESS',
                        payload: response.data
                    });
                }
            }).catch((error) => { })
    });
}

export function getuserdetails(userId) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    axios
        .get(constants.BASE_URL + `users/` + userId, { headers })
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                // return response.data.data;
                return (null, response.data.data);
            }

        }).catch((error) => { })
}

export function addProfileData(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    var config = {
        method: 'patch',
        url: constants.BASE_URL + `users/`,
        headers: headers,
        data: data
    };
    axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                toast(response.data.message, {
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                return callback(null, response.data);
            }
        }).catch((error) => {
            return callback(error, null);
        })
};
export function getBusinessInfo(data1, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    var config = {
        method: 'patch',
        url: constants.BASE_URL + `users/account`,
        headers: headers,
        data: data1
    };
    axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                toast(response.data.message, {
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                return callback(null, response.data);
            }
        }).catch((error) => {
            return callback(error, null);
        })
};

export function addProfileImage(data, callback) {
    const headers = {
        Authorization: `Bearer ${getToken() || ""}`,
        "Content-Type": "multipart/form-data",
    };
    var config = {
        method: "patch",
        url: constants.BASE_URL + "users/uploads",
        headers: headers,
        data: data,
    };
    axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}



export function removeAccount(removeuser, data, callback) {
    const headers = {
        Authorization: `Bearer ${getToken() || ""}`,
        "Content-Type": 'application/json',
    };
    var config = {
        method: "put",
        url: constants.BASE_URL + "users/removeaccount/" + removeuser,
        headers: headers,
        data: data,
    };
    axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            }
            callback(response, null)

        })
        .catch((error) => {
            callback(null, error)
        });
}

export function bulkuploaddataa(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        const url = constants.BASE_URL + "inventories/upload-xl";
        const headers = {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "multipart/form-data",
        };
        dispatch({ type: "BULK_UPLOAD_REQUESTED" });
        axios
            .post(url, addFormData, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "BULK_UPLOAD_SUCCESS",
                        payload: response.data,
                    });
                    successCallBack(response.data);
                }
            })
            .catch((error) => {
                dispatch({ type: "BULK_UPLOAD_FAILED", payload: error });
                errorCallBack(error);
            });
    };
}

export function bulkUploadBusiness(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        const url = constants.BASE_URL + "contacts/uploadcontact-xl";
        const headers = {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "multipart/form-data",
        };
        dispatch({ type: "BULK_UPLOAD_REQUESTED" });
        axios
            .post(url, addFormData, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "BULK_UPLOAD_SUCCESS",
                        payload: response.data,
                    });
                    successCallBack(response.data);
                }
            })
            .catch((error) => {
                dispatch({ type: "BULK_UPLOAD_FAILED", payload: error });
                errorCallBack(error);
            });
    };
}

export function bulkuploadMiscWatch(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        const url = constants.BASE_URL + "inventories/misc/upload-xl";
        const headers = {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "multipart/form-data",
        };
        dispatch({ type: "BULK_UPLOAD_REQUESTED_FOR_MISC" });
        axios
            .post(url, addFormData, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "BULK_UPLOAD_SUCCESS_FOR_MISC",
                        payload: response.data,
                    });
                    successCallBack(response.data);
                }
            })
            .catch((error) => {
                dispatch({ type: "BULK_UPLOAD_FAILED_FOR_MISC", payload: error });
                errorCallBack(error);
            });
    };
};



export function bulkuploadProduct(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        const url = constants.BASE_URL + "inventories/product/upload-xl";
        const headers = {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "multipart/form-data",
        };
        dispatch({ type: "BULK_UPLOAD_REQUESTED_FOR_MISC" });
        axios
            .post(url, addFormData, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "BULK_UPLOAD_SUCCESS_FOR_MISC",
                        payload: response.data,
                    });
                    successCallBack(response.data);
                }
            })
            .catch((error) => {
                dispatch({ type: "BULK_UPLOAD_FAILED_FOR_MISC", payload: error });
                errorCallBack(error);
            });
    };
};
export function updateProductDetails(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        const url = constants.BASE_URL + "products/update/product/details";
        const headers = {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "multipart/form-data",
        };
        axios
            .post(url, addFormData, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    successCallBack(response.data);
                }
            })
            .catch((error) => {
                errorCallBack(error);
            });
    };
}
export function updatePriceDetails(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        const url = constants.BASE_URL + "products/update/price";
        const headers = {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "multipart/form-data",
        };
        axios
            .post(url, addFormData, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    successCallBack(response.data);
                }
            })
            .catch((error) => {
                errorCallBack(error);
            });
    };
}

export function createInvoiceDetails(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        dispatch({ type: "CREATE_INVOICE_REQUESTED" });
        request({
            method: 'post',
            url: constants.BASE_URL + `invoices/v2/create/invoice`,
            data: addFormData
        })
            .then((response) => {
                if (response && response?.success === 0 && response?.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "CREATE_INVOICE_SUCCESS",
                        payload: response
                    });
                    successCallBack(response);
                }
            })
            .catch((error) => {
                const errorResponse = error?.response?.data
                if (errorResponse?.status == 0 && ['STNE', 'TILE'].includes(errorResponse?.errorcode)) {
                    dispatch({
                        type: "CREATE_INVOICE_SUCCESS",
                        payload: errorResponse
                    });
                    successCallBack(errorResponse);
                }
                else {
                    dispatch({ type: "CREATE_INVOICE_FAILED", payload: error });
                    errorCallBack(error);
                }

            })
    }
}

export function createMemoInvoiceDetails(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        dispatch({ type: "CREATE_INVOICE_REQUESTED" });
        request({
            method: 'post',
            url: constants.BASE_URL + `memo/create`,
            data: addFormData
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    // dispatch({
                    //     type: "CREATE_INVOICE_SUCCESS",
                    //     payload: response
                    // });
                    successCallBack(response);
                }
            })
            .catch((error) => {
                // dispatch({ type: "CREATE_INVOICE_FAILED", payload: error });
                errorCallBack(error);
            })
    }
}

export function checkContactInDB(addFormData, successCallBack, errorCallBack) {
    return function (dispatch) {
        dispatch({ type: "CONTACT_VALIDATION_REQUESTED" });
        request({
            method: 'post',
            url: `${constants.BASE_URL}invoices/check/contact`,
            data: addFormData
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "CHECK_CONTACT_SUCCESS",
                        payload: response
                    });
                    successCallBack(response);
                }
            })
            .catch((error) => {
                dispatch({ type: "CHECK_CONTACT_FAILED", payload: error });
                errorCallBack(error);
            })
    }
}
export function getInvoiceDetails(invoiceId) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    var config = {
        method: 'get',
        url: constants.BASE_URL + `invoices/list/invoicebyid/${invoiceId}`,
        headers: headers,
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return response.data;
            }
        })
        .catch((error) => {
            return error;
        })

}
export const getModelSerialList = (search, userId) => {

    const param = search.column === 'modelNumber' ? 'searchmodel' : 'searchserial';
    return (dispatch) => {
        dispatch({
            type: "FETCH_MODEL_LIST"
        });
        request({
            method: 'get',
            url: `${constants.BASE_URL}invoices/${param}/${userId}/${search.searchVal}`,
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "FETCH_MODEL_SUCCESS",
                        payload: response.data
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: "FETCH_MODEL_FAILURE",
                    payload: error
                });
            })
    }
}

export const getNewModelSerialList = (search) => {

    const param = search.column === 'modelNumber' ? 'searchmodel' : 'searchserial';
    const userDetails = window.sessionStorage.getItem('userDetails');
    const accountId = JSON.parse(userDetails).accountId

    return (dispatch) => {
        dispatch({
            type: "FETCH_MODEL_LIST"
        });
        request({
            method: 'get',
            url: `${constants.BASE_URL}invoices/v1/${param}/${accountId}/${search.searchVal}`,
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "FETCH_MODEL_SUCCESS",
                        payload: response.data
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: "FETCH_MODEL_FAILURE",
                    payload: error
                });
            })
    }
}

export const getBrandModalList = (searchVal) => {

    return (dispatch) => {
        dispatch({
            type: "FETCH_BRANDMODEL_LIST"
        });
        request({
            method: 'post',
            url: `${constants.BASE_URL}products/brandmodels/${searchVal}`,
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "FETCH_BRANDMODEL_SUCCESS",
                        payload: response.data
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: "FETCH_BRANDMODEL_FAILURE",
                    payload: error
                });
            })
    }
}

export const getBillShipContactList = (search, userId) => {
    const param = search.type === 'bill' ? 'searchbilling' : 'searchshiping';
    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
    const accountid = userDetails?.accountId ? userDetails.accountId : null

    return (dispatch) => {
        dispatch({
            type: "FETCH_BILLSHIP_CNTCT_LIST"
        });
        request({
            method: 'get',
            url: `${constants.BASE_URL}invoices/v1/${param}/${accountid}/${search.searchVal}`,
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "FETCH_BILLSHIP_CNTCT_SUCCESS",
                        payload: response.data
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: "FETCH_BILLSHIP_CNTCT_FAILURE",
                    payload: error
                });
            })
    }
}

export const getInvoiceNewContactList = (payload) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_BILLSHIP_CNTCT_LIST"
        });
        return request({
            method: 'post',
            url: constants.BASE_URL + "contacts/contactlist",
            data: payload
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "FETCH_BILLSHIP_CNTCT_SUCCESS",
                        payload: response.data
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: "FETCH_BILLSHIP_CNTCT_FAILURE",
                    payload: error
                });
            })
    }
}

export function updateShippingInformation(addFormData, successCallBack, errorCallBack) {
    return request({
        method: 'post',
        url: `${constants.BASE_URL}invoices/update/invoice/shipping`,
        data: addFormData
    })
        .then((response) => {
            if (response && response.success === 0 && response.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                successCallBack(response);
            }
        })
        .catch((error) => {
            console.log(error);
        })
}

export function updateInvoiceDetails(addFormData, invoiceId, successCallBack, errorCallBack) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    return axios({
        method: 'put',
        url: `${constants.BASE_URL}invoices/update/invoice/${invoiceId}`,
        data: addFormData,
        headers: headers
    })
        .then((res) => {
            const response = res?.data;
            if (response && response.success === 0 && response.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                successCallBack(response);
            }
        })
        .catch((error) => {
            errorCallBack(error);
        })
}
export function voidInvoice(invoiceId, data, CallBack) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    return axios({
        method: 'put',
        url: `${constants.BASE_URL}invoices/void/invoice/${invoiceId}`,
        headers: headers,
        data: data
    })
        .then((res) => {
            const response = res?.data;
            if (response && response.success === 0 && response.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                CallBack(null, response);
            }
        })
        .catch((error) => {
            CallBack(error, null);
        })
};

export function voidInventoryUpdate(data, CallBack) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    return axios({
        method: 'post',
        url: `${constants.BASE_URL}inventories/void`,
        headers: headers,
        data: data
    })
        .then((res) => {
            const response = res?.data;
            if (response && response.success === 0 && response.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                CallBack(null, response);
            }
        })
        .catch((error) => {
            CallBack(error, null);
        })
}


export const searchInventoryId = (data, callback) => {

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };

    const userDetails = window.sessionStorage.getItem('userDetails');
    const accountId = JSON.parse(userDetails).accountId
    const url = `${constants.BASE_URL}invoices/search/miscinv`
    axios.post(url, {
        accountId: accountId,
        inventoryId: data.searchText,
    },
        { headers }
    )
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            }
            else {
                return callback(null, response.data);

            }
        })
        .catch((error) => {
            return callback(null, error)
        })

}

export const deleteInventories = (data, callback) => {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const url = `${constants.BASE_URL}inventories/deleteall`
    axios.post(url, data,
        { headers }
    )
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            }
            else {
                return callback(null, response.data);

            }
        })
        .catch((error) => {
            return callback(error, null)
        })
}

export const shareInventories = (data, callback) => {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const url = `${constants.BASE_URL}inventories/customoffer`
    axios.post(url, data,
        { headers }
    )
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            }
            else {
                return callback(null, response.data);
            }
        })
        .catch((error) => {
            return callback(error, null);
        })
}

export function getBusinessAccountIds(accountId, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    axios
        .get(constants.BASE_URL + `users/accountids/` + accountId, { headers })
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data);
            }

        }).catch((error) => {
            return callback(error, null);
        })
};

export function UpdateMemoDetails(payload, successCallBack, errorCallBack) {
    return function (dispatch) {
        dispatch({ type: "UPDATE_MEMO" });
        request({
            method: 'put',
            url: constants.BASE_URL + `invoices/memoupdate/return`,
            data: payload
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    successCallBack(response);
                }
            })
            .catch((error) => {
                errorCallBack(error);
            })
    }
}

export function getMemoList(memoId = null, successCallBack, errorCallBack) {
    return function (dispatch) {
        request({
            method: 'get',
            url: constants.BASE_URL + `memo/list/${memoId}`,
        })
            .then((response) => {
                if (response && response.success === 0 && response.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    successCallBack(response);
                }
            })
            .catch((error) => {
                errorCallBack(error);
            })
    }
}
