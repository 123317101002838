import React, { useState, useEffect } from "react";
import {
    Grid,
    Container,
    Typography,
    Paper,
    TableContainer,
    Link,
    InputAdornment,
    IconButton,
    InputBase,
    FormControl,
    TextField,
    Tooltip,
    Button,
    InputLabel,
    Select,
    MenuItem,
    Menu,
    Divider,
    Box,
} from "@material-ui/core";
import { alpha } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { useHistory } from "react-router";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@material-ui/icons/Clear";
import BulkUpload from '@mui/icons-material/UploadFileOutlined';
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import RestoreIcon from "@mui/icons-material/Restore";
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TablePagination,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { SpinnerRoundFilled } from "spinners-react";
import AddContactModal from "./addContactModal";
import { bindActionCreators } from "redux";
import DeleteConfirmation from "../../../../common/components/DialogConfirmation";
import {
    deleteContactList,
    getContactList,
    downloadContact,
} from "../actions/contactApi";
import {
    formatDate,
    showErrorToast,
    showSuccessToast,
} from "../../../../common/components/Helper/Utils";
import ContentWrapper from "../../../../common/components/contentWrapper";
import Loader from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

const classificationTypeLookUp = {
    walk_in: "Walk In",
    business_client: "Business Client",
    end_customer: "End Customer",
};

const columns = [
    // { id: "classification_type", label: "Classification Type", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "businessname", label: "Business Name", minWidth: 100 },
    { id: "phoneno", label: "Phone Number", minWidth: 100 },
    { id: "emailid", label: "Email", minWidth: 100 },
    { id: "relationship_type", label: "Relationship Type", minWidth: 100 },
    // { id: "createdon", label: "Created On", minWidth: 100 },
    // { id: "last_purchase_date", label: "Last Purchase From Date", minWidth: 150 },
    // { id: "last_sale_date", label: "Last Sale to Date", minWidth: 150 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Actions", minWidth: 100 },
];

const StyledTableCell = styled(TableCell)(({ theme, minwidth }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        minWidth: minwidth,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderBottom: "none",
        padding: 0,
    },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

function CustomInput({ onFocus, value, onChange, label = "Created On" }) {
    return (
        <TextField
            id="outlined-basic"
            label={label}
            variant="outlined"
            onFocus={onFocus}
            classes={{}}
            style={{ width: "100%" }}
            placeholder="from YYYY/MM/DD to YYYY/MM/DD"
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <CalendarMonthIcon />
                    </InputAdornment>
                ),
                readOnly: true,
            }}
            inputProps={{ autoComplete: "off" }}
        />
    );
}

const ContactManagement = ({ updateContactPage, updateContactData }) => {
    const ContentView = () => {
        const history = useHistory();
        const accountId = window.sessionStorage.getItem("accountId");
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const [anchorE2, setAnchorE2] = useState("");
        const [data, setData] = useState([]);
        const [loader, setLoader] = useState(false);
        const [openDeleteModal, setOpenDeleteModal] = useState(false);
        const [openActiveModal, setOpenActiveModal] = useState(false);
        const [classificationType, setClassificationType] = useState("");
        const [relationshipType, setRelationshipType] = useState("");
        const [createdOnDateValue, setCreatedOnDateValue] = useState([]);
        const [selectedDateValue, setSelectedDateValue] = useState([]);
        const [contactStatus, setContactStatus] = useState("");
        const [searchKeyword, setSearchKeyword] = useState("");
        const [singleData, setSingleData] = useState({});
        const [pageNumber, setPageNumber] = useState(1);
        const [pageLimit, setPageLimit] = useState(100);
        const [noItemFound, setNoItemFound] = useState(null);
        const [totalNoOfContacts, setTotalNoOfContacts] = useState(null);
        const openAction = Boolean(anchorE2);


        const handleClick = (event, row) => {
            setAnchorE2(event.currentTarget);
            setSingleData(row);
        };

        const handleCloseAction = () => {
            setAnchorE2("");
            setSingleData({});
            setOpenDeleteModal(false);
            setOpenActiveModal(false);
        };

        const handleModalViewOpen = (row) => {
            setAnchorE2("");
            updateContactPage("CONTACT_VIEW");
            updateContactData(singleData);
            setSingleData({});
            history.push("/contactmanagement/view/" + row.contactid);
        };

        const handleEdit = (row) => {
            setAnchorE2("");
            updateContactPage("CONTACT_EDIT");
            updateContactData(singleData);
            setSingleData({});
            history.push("/contactmanagement/edit/" + row.contactid);
        };

        const handleChange = (event, setState) => {
            const { value } = event.target;
            setState(value);
        };

        useEffect(() => {
            if (createdOnDateValue && createdOnDateValue.length === 2) {
                setSelectedDateValue(createdOnDateValue);
                getContactManagementList(createdOnDateValue);
            }
            if (
                createdOnDateValue.length === 0 &&
                selectedDateValue &&
                selectedDateValue.length === 2
            ) {
                setSelectedDateValue([]);
                getContactManagementList("clear");
            }
        }, [createdOnDateValue]);

        useEffect(() => {
            getContactManagementList();
        }, [classificationType, relationshipType, contactStatus]);

        const getContactManagementList = (from = null, customPayload = null) => {
            setLoader(true);
            setPageNumber(1);
            let payload = customPayload
                ? customPayload
                : {
                    accountid: accountId,
                    emailsearch: searchKeyword,
                    classification: classificationType,
                    status: contactStatus,
                };
            if (Array.isArray(from) && from.length > 0) {
                payload["contactCreatedInterval"] = {
                    from: from[0].format(),
                    to: from[1].format(),
                };
            }
            getContactList(payload, pageLimit, 1, (error, response) => {
                if (response && response.status == 1) {
                    setNoItemFound((response.data?.length < 100 || response.data?.length === 0) ? "No More Items Left" : null);
                    setData(response.data);
                    setTotalNoOfContacts(response?.contacts_total);
                    setLoader(false);
                } else {
                    showErrorToast(response?.message ?? "Something went wrong");
                    setLoader(false);
                }
                if (error) {
                    showErrorToast(error);
                    setLoader(false);
                }
            });
        };

        const fetchMoreData = () => {
            setPageNumber((prev) => prev + 1);
        };

        useEffect(() => {
            if (pageNumber > 1) {
                fetchData();
            }
        }, [pageNumber]);

        const fetchData = async (from = null, customPayload = null) => {
            let scrollTop = document.documentElement.scrollHeight;
            if (scrollTop >= 600) {
                let payload = customPayload
                    ? customPayload
                    : {
                        accountid: accountId,
                        emailsearch: searchKeyword,
                        classification: classificationType,
                        status: contactStatus,
                    };
                if (Array.isArray(from) && from.length > 0) {
                    payload["contactCreatedInterval"] = {
                        from: from[0].format(),
                        to: from[1].format(),
                    };
                }
                getContactList(payload, pageLimit, pageNumber, (error, response) => {
                    if (response && response.status == 1) {
                        setData((prevList) => [...prevList, ...response.data]);
                        setNoItemFound((response.data?.length < 100 || response.data?.length === 0) ? "No More Items Left" : null);
                    } else {
                        setData([]);
                        showErrorToast(response?.message ?? "Something went wrong");
                    }
                    if (error) {
                        showErrorToast(error);
                    }
                });
            }
        };

        const handleMarkasObsolete = () => {
            setOpenDeleteModal(false);
            if (singleData?.contactid) {
                setLoader(true);
                let payload = {
                    contactid: singleData?.contactid,
                    status: true,
                };
                deleteContactList(payload, (error, response) => {
                    if (response && response.status == 1) {
                        setLoader(false);
                        showSuccessToast("Updated Successfully");
                        getContactManagementList();
                    } else {
                        showErrorToast(response?.message ?? "Something went wrong");
                        setLoader(false);
                    }
                    if (error) {
                        showErrorToast(error);
                        setLoader(false);
                    }
                });
            } else {
                showErrorToast(
                    "Something went wrong, Please refresh the page and try again"
                );
            }
        };

        const handleActivate = () => {
            setOpenActiveModal(false);
            if (singleData?.contactid) {
                setLoader(true);
                let payload = {
                    contactid: singleData?.contactid,
                    status: false,
                };
                deleteContactList(payload, (error, response) => {
                    if (response && response.status == 1) {
                        setLoader(false);
                        showSuccessToast("Updated Successfully");
                        getContactManagementList();
                    } else {
                        showErrorToast(response?.message ?? "Something went wrong");
                        setLoader(false);
                    }
                    if (error) {
                        showErrorToast(error);
                        setLoader(false);
                    }
                });
            } else {
                showErrorToast(
                    "Something went wrong, Please refresh the page and try again"
                );
            }
        };

        const handleAddContact = () => {
            updateContactPage("CONTACT_ADD");
            updateContactData(null);
            history.push("/contactmanagement/add");
        };

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
        };

        const handleEnterEvent = (event) => {
            if (event.key == "Enter") {
                getContactManagementList();
            }
        };

        const handleMouseDown = (event) => {
            event.preventDefault();
        };

        const endAdornment = () => {
            const values = searchKeyword;
            if (values) {
                return (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => {
                                setSearchKeyword("");
                                const payload = {
                                    accountid: accountId,
                                    emailsearch: "",
                                    classification: classificationType,
                                    status: contactStatus,
                                };
                                if (createdOnDateValue && createdOnDateValue.length === 2) {
                                    payload["contactCreatedInterval"] = {
                                        from: createdOnDateValue[0].format(),
                                        to: createdOnDateValue[1].format(),
                                    };
                                }
                                getContactManagementList(null, payload);
                            }}
                            onMouseDown={handleMouseDown}
                            color="primary"
                        >
                            <ClearIcon />
                        </IconButton>
                        <IconButton
                            className="primary-bg color-white"
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            onClick={() => getContactManagementList()}
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                );
            } else {
                return (
                    <InputAdornment position="end">
                        <IconButton
                            className="primary-bg color-white"
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                );
            }
        };

        const downloadExcel = () => {
            const payload = {
                accountid: accountId,
            };
            downloadContact(payload, (err, response) => { });
        };

        const CustomizedUploadMenus = ({ downloadInventory }) => {
            const [anchorEl, setAnchorEl] = React.useState(null);
            const open = Boolean(anchorEl);

            const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
            };

            const handleClose = (data) => {
                setAnchorEl(null);
                if (data) {
                    // downloadInventory(data);
                }
            };

            const bulUploadStandaWatch = () => {
                history.push({
                    pathname: "/bulk-upload-bussiness",
                });
            };

            const bulUploadMiscBucket = () => {
                history.push({
                    pathname: "/bulk-upload-endcustomer",
                });
            };

            const bulUploadMiscProduct = () => {
                history.push({
                    pathname: "/misc-product-bulk-upload",
                });
            };

            return (
                <>
                    <Button
                        id="demo-customized-button"
                        aria-controls={open ? "demo-customized-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        className={"text-capitalize primary-bg color-white"}
                        style={{ float: "right" }}
                        endIcon={<KeyboardArrowDownOutlined />}
                    >
                        <BulkUpload />
                        &nbsp;Bulk Upload
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={bulUploadStandaWatch} disableRipple>
                            Bulk Upload Business Client
                        </MenuItem>
                        <MenuItem onClick={bulUploadMiscBucket} disableRipple>
                            Bulk Upload End Customer
                        </MenuItem>
                        {/* <MenuItem onClick={bulUploadMiscProduct} disableRipple>
                            Bulk Upload 3
                        </MenuItem> */}
                    </StyledMenu>
                </>
            );
        };

        return (
            <div className="space-bottom-30 someextrahight extra-space-10">
                <Tooltip title="Scroll to top" arrow placement="bottom">
                    <div style={{
                        position: "fixed",
                        bottom: "65px",
                        right: "45px"
                    }}>
                        <ScrollUpButton
                            EasingType="easeInOutBounce"
                            ShowAtPosition={1000}
                            AnimationDuration={500}
                            style={{
                                backgroundColor: '#CEA05F',
                                borderRadius: '50%',
                                padding: "10px",
                                border: "none",
                                fill: "#fff",
                                bottom: "60px"
                            }}
                        />
                    </div>
                </Tooltip>
                <Container>
                    <Grid container spacing={3} style={{ marginBottom: '2%' }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6">Contact Management</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "20px",
                            }}
                        >
                            <Button
                                className="text-capitalize primary-bg color-white"
                                style={{ width: "10rem" }}
                                onClick={downloadExcel}
                            >
                                Download Excel
                            </Button>
                            <CustomizedUploadMenus />
                            <Button
                                className="text-capitalize primary-bg color-white"
                                style={{ width: "10rem" }}
                                onClick={() => handleAddContact()}
                            >
                                Add Contact
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl variant="outlined" className="formControl">
                                <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    className="font-14"
                                >
                                    Contact Classification
                                </InputLabel>
                                <Select
                                    style={{ color: "black" }}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Contact Classification"
                                    value={classificationType}
                                    onChange={(e) => handleChange(e, setClassificationType)}
                                >
                                    <MenuItem value="ALL">All</MenuItem>
                                    <MenuItem value="walk_in">Walk In</MenuItem>
                                    <MenuItem value="business_client">Business Client</MenuItem>
                                    <MenuItem value="end_customer">End Customer</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                        <FormControl variant="outlined" className="formControl">
                            <InputLabel
                                id="demo-simple-select-outlined-label"
                                className="font-14"
                            >
                                Relationship Type
                            </InputLabel>
                            <Select
                                style={{ color: "black" }}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Relationship Type"
                                value={relationshipType}
                                onChange={(e) => handleChange(e, setRelationshipType)}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="supplier">Supplier</MenuItem>
                                <MenuItem value="customer">Customer</MenuItem>
                                <MenuItem value="partner">Partner</MenuItem>
                                <MenuItem value="memo_supplier">Memo Supplier</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                        <Grid item xs={12} md={4}>
                            <FormControl variant="outlined" className="formControl">
                                <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    className="font-14"
                                >
                                    Status
                                </InputLabel>
                                <Select
                                    style={{ color: "black" }}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Status"
                                    value={contactStatus}
                                    onChange={(e) => handleChange(e, setContactStatus)}
                                >
                                    <MenuItem value="ALL">All</MenuItem>
                                    <MenuItem value="obsolete">Obsolete</MenuItem>
                                    <MenuItem value="non_obsolete">Non Obsolete</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DatePicker
                                range
                                render={<CustomInput />}
                                value={createdOnDateValue}
                                containerStyle={{ display: "block" }}
                                onChange={(dateObjects) => {
                                    setCreatedOnDateValue(dateObjects);
                                }}
                                rangeHover
                                dateSeparator=" to "
                                plugins={[<DatePanel />]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className="form-width-99">
                                <div style={{ marginLeft: "5px" }}>
                                    <Paper
                                        sx={{ p: "2px 0px" }}
                                        style={{ height: "55px", display: "flex", width: "100%" }}
                                    >
                                        <InputBase
                                            fullWidth
                                            style={{ flex: 1, marginLeft: "20px" }}
                                            inputProps={{
                                                "aria-label": "Search by Name / Business Name / Email",
                                            }}
                                            placeholder="Search by Name / Business Name / Email"
                                            endAdornment={endAdornment()}
                                            onKeyPress={handleEnterEvent}
                                            value={searchKeyword}
                                            onChange={(e) => handleChange(e, setSearchKeyword)}
                                        />
                                    </Paper>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {loader ? (
                        <div className="d-spinner">
                            <SpinnerRoundFilled
                                size={51}
                                thickness={125}
                                speed={80}
                                color="rgba(57, 107, 172, 1)"
                                className="spp"
                                enabled={loader}
                            />
                        </div>
                    ) : (
                        <InfiniteScroll
                            style={{ overflow: "hidden" }}
                            dataLength={data?.length || 0}
                            next={() => fetchMoreData()}
                            hasMore={noItemFound ? false : true}
                            loader={
                                <div style={{ margin: "25px auto", width: "100px" }}>
                                    <Loader
                                        type="Puff"
                                        color="#9F7C50"
                                        height={50}
                                        width={50}
                                    />
                                    <div style={{ marginLeft: "-10px" }}>
                                        <h4 className="color-p">Loading....</h4>
                                    </div>
                                </div>
                            }
                        >
                            <Grid container spacing={1}>
                                {!loader ?
                                    <Grid item xs={12} md={12}>
                                        <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
                                            <Typography variant="h6"># of Contacts : </Typography>&nbsp;
                                            <Typography variant="h6"> {totalNoOfContacts || "-"} </Typography>
                                        </Box>
                                    </Grid>
                                    : null}
                                <Grid item xs={12}>
                                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                                        <TableContainer>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <StyledTableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                minwidth={column.minWidth}
                                                            >
                                                                {column.label}
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <>
                                                        {data?.length > 0 && data
                                                            // .slice(
                                                            //     page * rowsPerPage,
                                                            //     page * rowsPerPage + rowsPerPage
                                                            // )
                                                            .map((row) => {
                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={row.id}
                                                                    >
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            let fullName = row.firstname || row.lastname ? `${row.firstname || ""} ${row.lastname || ""}` : "-";
                                                                            return (
                                                                                <>
                                                                                    {column.id === "action" ? (
                                                                                        <TableCell
                                                                                            key={column.id}
                                                                                            align={column.align}
                                                                                        >
                                                                                            <StyledEngineProvider>
                                                                                                <MoreVertIcon
                                                                                                    key={row.contactid}
                                                                                                    aria-haspopup="true"
                                                                                                    aria-expanded={
                                                                                                        openAction
                                                                                                            ? "true"
                                                                                                            : undefined
                                                                                                    }
                                                                                                    style={{
                                                                                                        color: "#1976d2",
                                                                                                        textDecoration: "none",
                                                                                                        textTransform: "none",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={(e) => {
                                                                                                        handleClick(e, row);
                                                                                                    }}
                                                                                                ></MoreVertIcon>
                                                                                                <StyledMenu
                                                                                                    id="demo-customized-menu"
                                                                                                    MenuListProps={{
                                                                                                        "aria-labelledby":
                                                                                                            "demo-customized-button",
                                                                                                    }}
                                                                                                    anchorEl={anchorE2}
                                                                                                    open={
                                                                                                        openAction &&
                                                                                                        singleData?.contactid ===
                                                                                                        row?.contactid
                                                                                                    }
                                                                                                    onClose={() =>
                                                                                                        handleCloseAction()
                                                                                                    }
                                                                                                >
                                                                                                    <MenuItem
                                                                                                        key={row.id + "View"}
                                                                                                        onClick={() =>
                                                                                                            handleModalViewOpen(row)
                                                                                                        }
                                                                                                    >
                                                                                                        <VisibilityIcon />
                                                                                                        View Details
                                                                                                    </MenuItem>
                                                                                                    <MenuItem
                                                                                                        key={row.id + "Edit"}
                                                                                                        onClick={() =>
                                                                                                            handleEdit(row)
                                                                                                        }
                                                                                                        disabled={
                                                                                                            singleData?.isdeleted
                                                                                                        }
                                                                                                    >
                                                                                                        <EditIcon />
                                                                                                        Edit
                                                                                                    </MenuItem>
                                                                                                    {!singleData?.isdeleted ? (
                                                                                                        <MenuItem
                                                                                                            key={row.id + "Obsolete"}
                                                                                                            onClick={() => {
                                                                                                                setOpenDeleteModal(
                                                                                                                    true
                                                                                                                );
                                                                                                                setAnchorE2("");
                                                                                                            }}
                                                                                                            disabled={
                                                                                                                singleData?.isdeleted
                                                                                                            }
                                                                                                        >
                                                                                                            <BlockIcon />
                                                                                                            Mark as Obsolete
                                                                                                        </MenuItem>
                                                                                                    ) : (
                                                                                                        <MenuItem
                                                                                                            key={row.id + "Active"}
                                                                                                            onClick={() => {
                                                                                                                setOpenActiveModal(
                                                                                                                    true
                                                                                                                );
                                                                                                                setAnchorE2("");
                                                                                                            }}
                                                                                                        >
                                                                                                            <RestoreIcon />
                                                                                                            Reactivate Contact
                                                                                                        </MenuItem>
                                                                                                    )}
                                                                                                    <Divider />
                                                                                                    {/* <MenuItem
                                                                                                    key={row.id + "AddPurchase"}
                                                                                                    onClick={() => { }}
                                                                                                >
                                                                                                    Additional Purchases
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    key={row.id + "AddSales"}
                                                                                                    onClick={() => { }}
                                                                                                >
                                                                                                    Additional Sales
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    key={row.id + "AddSold"}
                                                                                                    onClick={() => { }}
                                                                                                >
                                                                                                    Additional Sold Items
                                                                                                </MenuItem> */}
                                                                                                </StyledMenu>
                                                                                            </StyledEngineProvider>
                                                                                        </TableCell>
                                                                                    ) : (
                                                                                        <TableCell
                                                                                            key={column.id}
                                                                                            align={column.align}
                                                                                        >
                                                                                            {column.id === "createdon" ? (
                                                                                                <StyledTableCell>
                                                                                                    {/* {row.createdon
                                                                                                    ? moment(
                                                                                                        row.createdon
                                                                                                    ).format("MM/DD/YYYY")
                                                                                                    : ""} */}
                                                                                                    {row.createdon
                                                                                                        ? formatDate(
                                                                                                            row.createdon,
                                                                                                            "MM/DD/YYYY"
                                                                                                        )
                                                                                                        : ""}
                                                                                                </StyledTableCell>
                                                                                            ) : column.id === "name" ? (
                                                                                                <StyledTableCell>
                                                                                                    {fullName}
                                                                                                </StyledTableCell>
                                                                                            ) : column.id === "phoneno" ? (
                                                                                                <StyledTableCell>
                                                                                                    {row.phoneno ? `${row.phonecode ? `(${row.phonecode}) ` : ""}${row.phoneno}` : "-"}
                                                                                                    {/* {row.phoneno ?? "-"} */}
                                                                                                </StyledTableCell>
                                                                                            ) : column.id ===
                                                                                                "last_purchase_date" ||
                                                                                                column.id ===
                                                                                                "last_sale_date" ? (
                                                                                                <StyledTableCell>
                                                                                                    {"-"}
                                                                                                </StyledTableCell>
                                                                                            ) : column.id ===
                                                                                                "relationship_type" ? (
                                                                                                <StyledTableCell>
                                                                                                    {row?.contact_relationtype
                                                                                                        ?.map((item) => item?.value)
                                                                                                        .filter(Boolean)
                                                                                                        .join(", ") || "-"}
                                                                                                </StyledTableCell>
                                                                                            ) : column.id ===
                                                                                                "classification_type" ? (
                                                                                                <StyledTableCell>
                                                                                                    {classificationTypeLookUp[
                                                                                                        value
                                                                                                    ] || ""}
                                                                                                </StyledTableCell>
                                                                                            ) : column.id === "status" ? (
                                                                                                <StyledTableCell>
                                                                                                    {row?.isdeleted ? (
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "orange",
                                                                                                                fontWeight: 600,
                                                                                                            }}
                                                                                                        >
                                                                                                            Obsolete
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "green",
                                                                                                                fontWeight: 600,
                                                                                                            }}
                                                                                                        >
                                                                                                            Active
                                                                                                        </span>
                                                                                                    )}
                                                                                                </StyledTableCell>
                                                                                            ) : (
                                                                                                <StyledTableCell>
                                                                                                    {value || "-"}
                                                                                                </StyledTableCell>
                                                                                            )}
                                                                                        </TableCell>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={data.length || 0}
                                            rowsPerPage={rowsPerPage}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        /> */}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </InfiniteScroll>
                    )}
                    {data?.length === 0 && !loader && noItemFound &&
                        <Box
                            component="span"
                            sx={{
                                display: "block",
                                border: "2px dashed grey",
                                width: "100%",
                                padding: "50px 72px",
                                // marginTop: "20px",
                            }}
                        >
                            {" "}
                            <Container>
                                <Typography
                                    variant="h6"
                                    className="color-BCBCBC"
                                    component="p"
                                    align="center"
                                >
                                    <b>No Records Found</b>
                                </Typography>
                            </Container>
                        </Box>
                    }

                    {/* Obsolete Popup */}
                    {openDeleteModal && (
                        <DeleteConfirmation
                            title={"Warning!"}
                            message={
                                <>
                                    Once a contact is marked as <b>Obsolete</b>, invoices can no
                                    longer be created for it.
                                </>
                            }
                            openDeleteModal={openDeleteModal}
                            setOpenDeleteModal={setOpenDeleteModal}
                            handleConfirmation={handleMarkasObsolete}
                            disabled={false}
                        />
                    )}

                    {openActiveModal && (
                        <DeleteConfirmation
                            title={"Warning!"}
                            message={
                                <>
                                    Are you sure, you want to <b>Activate</b>, the contact.
                                </>
                            }
                            openDeleteModal={openActiveModal}
                            setOpenDeleteModal={setOpenActiveModal}
                            handleConfirmation={handleActivate}
                            disabled={false}
                        />
                    )}
                </Container>
            </div >
        );
    };

    return <ContentWrapper contentView={ContentView()} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateContactPage: (type) => ({ type: type }),
            updateContactData: (payload) => ({ type: "CONTACT_VIEW_DATA", payload }),
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactManagement);
