import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@mui/material/TextField";
import {
    Button,
    DialogActions,
    FormControl,
    FormHelperText,
    Typography,
    Grid,
} from "@material-ui/core";
import { trim } from "lodash";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";

export default function InvoiceValidation({
    open,
    errorCode,
    errorMessage = "Something went wrong",
    handleClose,
    handleNavigate,
    handleCheckSalesID,
    handleCheckTotalAmount,
    handleFocus,
    reason,
    setReason
}) {
    const [errorText, setErrorText] = React.useState("");

    const validateReason = () => {
        if (!reason) {
            setErrorText("Please Enter Reason");
            return;
        }
        else if (reason?.length >= 1000) {
            setErrorText('Status cannot exceed 1000 characters.');
            return;
        }
        else if (reason) {
            handleCheckTotalAmount();
        }
    }

    const ReasonValidation = (e) => {
        const regex = /^[0-9a-zA-Z _*-]*$/;
        const { value } = e.target;
        setReason(value);
        if (!regex.test(value)) {
            setErrorText('Enter valid Reason');
        } else {
            setErrorText('');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
        >
            <DialogTitle>
                Warning!
                <IconButton style={{ float: "right" }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div>{errorMessage}</div>
                {errorCode === "TILE" &&
                    <FormControl style={{ width: '400px', margin: '25px 0px' }}>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Reason"
                            multiline
                            maxRows={4}
                            required={true}
                            value={reason}
                            style={{ width: '400px !important' }}
                            onChange={ReasonValidation}

                        />
                        <FormHelperText className="error-o">
                            {errorText}
                        </FormHelperText>
                    </FormControl>
                }
            </DialogContent>
            <DialogActions>
                {errorCode === "STNE" && <>
                    <Button color="primary" onClick={handleFocus}>
                        Add Sales Tax Amount
                    </Button>
                    <Button color="primary" onClick={handleNavigate}>
                        Add Sales Tax ID
                    </Button>
                    <Button color="primary" onClick={handleCheckSalesID}>
                        Proceed
                    </Button>
                </>}
                {errorCode === "TILE" && <>
                    <Button color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={validateReason}>
                        Proceed
                    </Button>
                </>}
            </DialogActions>
        </Dialog>
    );
}
